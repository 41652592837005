import { Component, Inject, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { M_Contact } from '../../models/M_Contact';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MASTER_CLIENT } from '../../constants/masters';
import { ViewPath } from 'src/app/app-routing.module';
import { M_Vehicle } from '../../models/Vehicles/M_Vehicle';
import { ClassSearcherComponent } from '../class-searcher/class-searcher.component';
import { RouterService } from 'src/app/services/router.service';
import { ApiService } from 'src/app/services/Api/api.service';

export interface IAssignClient {
  client: M_Contact | undefined;
  vehicle: M_Vehicle;
}

@Component({
  selector: 'app-assign-client-vn-vo',
  templateUrl: './assign-client-vn-vo.component.html',
  styleUrls: ['./assign-client-vn-vo.component.css']
})
export class AssignClientVnVoComponent {
  client = MASTER_CLIENT;
  v = ViewPath;
  form: UntypedFormGroup;
  loaded = false;

  @ViewChild("csearcher") contact_asign?: ClassSearcherComponent<M_Contact>;
  constructor(public apiS: ApiService, private formBuilder: UntypedFormBuilder, public routerS: RouterService, public dialog: MatDialogRef<M_Contact>, @Inject(MAT_DIALOG_DATA) public data: IAssignClient) {
    this.form = this.formBuilder.group({
      client_id: ['', Validators.required],
      vehicle_id: ['', Validators.required],
    });
    this.form.patchValue({ vehicle_id: this.data.vehicle?.vehicle_id });
    this.form.patchValue({ client_id: data.client?.client_id });
  }

  goCreateClient() {
    this.routerS.goTo(this.v.createContact);
  }
  disabledIf(contact : M_Contact){
   return contact.billing_address == undefined
  }
}