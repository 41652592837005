import { Directive, ElementRef, Input, OnInit, SimpleChanges } from '@angular/core';

/**
 * Directive that is used to put the class 'dn_i' (display: none !important) to the element where it is.
 * Example :
 * <p [dn_i]="true">This paragraph will not be seen/p>
 */
@Directive({
  selector: '[dn_i]'
})
export class DnIDirective implements OnInit {

  @Input('dn_i') dn: boolean | undefined;

  constructor(private el: ElementRef<HTMLElement>) {
  }

  ngOnInit(): void {
    this.setDisplay(this.dn)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dn']) {
      this.setDisplay(this.dn);
    }
  }

  setDisplay(displayNone: boolean | undefined) {
    if (displayNone) {
      this.el.nativeElement.classList.add("dn_i")
    }
    else {
      this.el.nativeElement.classList.remove("dn_i")
    }
  }

}
