import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { or_status, or_status_close, or_status_open } from 'src/app/custom-classes/or_states';
import { M_Action } from 'src/app/models/M_Action';
import { M_GroupTask } from 'src/app/models/M_GroupTask';
import { ApiService } from 'src/app/services/Api/api.service';
import { OrTimerService } from 'src/app/services/or-time.service';

@Component({
  selector: 'app-shared-access-or',
  templateUrl: './shared-access-or.component.html',
  styleUrls: ['./shared-access-or.component.css']
})
export class SharedAccessOrComponent {

  action: M_Action | undefined;

  s_o = or_status_open;
  s_c = or_status_close;

  constructor(private apiS: ApiService, public orTimeS: OrTimerService, public dRef: MatDialogRef<SharedAccessOrComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {orId : number, sharedUserId : number}) {

    if (this.data.orId) {
      this.apiS.action.getAction(this.data.orId).then(res => {
        if (res != undefined && res instanceof M_Action) {
          console.log(res);
          this.action = res;
        }
      })
    }
    else {
      this.dRef.close();
    }
  }

  startStopTime() {
    this.orTimeS.startStop(this.action);
  }

  openGroup(group : M_GroupTask) {
    this.apiS.action.openGroup(group.id).then(_res => {
      group.changeStatus(or_status_open);
    })
  }

  closeGroup(group : M_GroupTask) {
    this.apiS.action.closeGroup(group.id).then(_res => {
      group.changeStatus(or_status_close);
    })
  }
  
  getStatus(group : M_GroupTask) {
    if (group.type.interno && group.isInvoiced()) {
      let a: [or_status, string] = [group.state, 'Tramitado']
      return a;
    };
    return group.state;
  }

}
