<app-close-dialog-button></app-close-dialog-button>

<!-- OR -->
<ng-container *ngIf="data.to == 'or' ">
    <p mat-dialog-title>{{data.to == 'or' ? 'Nueva OR': 'Nuevo presupuesto'}}</p>
    <div mat-dialog-content *ngIf="data.to == 'or' ">
        <!-- <p mat-dialog-subtitle>Se generará una OR a partir de esta</p>-->
        <app-or-form #aof [ngClass]="{'dn': !orFormLoaded}"></app-or-form>

        <ng-container *ngIf="!orFormLoaded">
            <app-skeleton [width]="200" [height]="50"></app-skeleton>
            <div class="mt10"></div>
            <app-skeleton [width]="200" [height]="50"></app-skeleton>
            <div class="mt10"></div>
            <app-skeleton [width]="200" [height]="50"></app-skeleton>
        </ng-container>

    </div>
    <div mat-dialog-actions *ngIf="orFormLoaded">
        <button mat-button mat-dialog-close>Cancelar</button>
        <button color="primary" mat-flat-button cdkFocusInitial (click)="startRA()">Ok</button>
    </div>
</ng-container>


<!-- BUDEGT -->
<ng-container *ngIf="data.to == 'budget' ">
    <p mat-dialog-title>Nuevo presupuesto</p>
    <div mat-dialog-content>
        <p mat-dialog-subtitle>¿Qué cliente quieres asociar?</p>
        <app-class-searcher #cs [cssClass]="'mw400'" [selectedOnLoad]="data.action.client_id" [width100]="true" [masterClass]="client"
            searchPlaceHolder="Buscar cliente" [specialRow]="''" [extendedInfo]="false"
            [createData]="{
                text : 'Nuevo cliente / empresa',
                by : v.createContact,
            }">
        </app-class-searcher>
    </div>
    <div mat-dialog-actions>
        <button mat-button mat-dialog-close>Cancelar</button>
        <button color="primary" mat-flat-button mat-dialog-close cdkFocusInitial (click)="trasnfer()">Ok</button>
    </div>
</ng-container>