<app-close-dialog-button></app-close-dialog-button>
<p mat-dialog-title mat-dialog-title-img>
    <img src="./assets/img/modules/modules.png">
</p>
<div mat-dialog-content>
    <mat-stepper linear #stepper class="hidden-stepper-header">
        <!-- FIST STEP -->
        <mat-step>
            <p>
                Los módulos en Eina son componentes adicionales que permiten extender y personalizar la funcionalidad
                del
                sistema según las necesidades específicas del negocio.
            </p>
        </mat-step>

        <mat-step>
            <p>
                Cada módulo habilita diferentes características y pantallas dentro de Eina, modificando su
                comportamiento y
                agregando herramientas que facilitan tareas específicas.
            </p>
        </mat-step>

        <mat-step>
            <p>
                Activar o desactivar un módulo cambia lo que el usuario puede ver y gestionar en la interfaz,
                permitiendo
                que el
                sistema se adapte mejor al flujo de trabajo de cada empresa.
            </p>
        </mat-step>
    </mat-stepper>
    <app-stepper-step-indicator [stepper]="stepper"></app-stepper-step-indicator>
</div>
<div mat-dialog-actions>
    <button color="whtie" mat-flat-button cdkFocusInitial (click)="stepper.previous()"
        *ngIf="stepper.selectedIndex != 0">
        Atrás
    </button>
    <button color="primary" mat-flat-button cdkFocusInitial
        (click)="stepper.selectedIndex == stepper.steps.length -1 ? dRef.close() : {}; stepper.next();">
        {{stepper.selectedIndex == stepper.steps.length -1 ? 'Genial' : 'Siguiente' }}
    </button>
</div>