import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { ProductLineTableComponent } from 'src/app/components/product-line-table/product-line-table/product-line-table.component';
import { IProductLineTableComponent } from 'src/app/interfaces/IProductLineTableComponent';
import { M_Contact } from 'src/app/models/M_Contact';
import { M_Invoice } from 'src/app/models/M_Invoice';
import { M_CustomProduct } from 'src/app/models/Products/M_CustomProduct';
import { M_Product } from 'src/app/models/Products/M_Product';
import { PreviewService } from 'src/app/services/preview.service';
import { ParameterStateComponent } from 'src/app/components/parameter-state/parameter-state.component';
import { RouterService } from 'src/app/services/router.service';

@Component({
  selector: 'app-abono-invoice',
  templateUrl: './abono-invoice.component.html',
  styleUrls: ['./abono-invoice.component.css']
})
export class AbonoInvoiceComponent extends ParameterStateComponent implements IProductLineTableComponent {

  invoice: M_Invoice | undefined;

  @ViewChild(ProductLineTableComponent) productLineTable!: ProductLineTableComponent;
  get blocksLine(): boolean { return false; }

  constructor(routerS: RouterService, route: ActivatedRoute, private apiS: ApiService, public previewS: PreviewService) {
    super(routerS, route, ["or", "invoice"])
  }

  override onParam(param: string, value: string): void {
    if (param == "invoice") {
      let v = value.getNumber();
      this.apiS.bill(v).then(res => {
        this.invoice = res;
      })
    }
  }

  get client() {
    return this.invoice ? this.invoice.client : undefined;
  }


  abonar() {
    this.apiS.abonar(this.invoice!.id!).then(res => {
      this.previewS.showPreview("A", res.token, undefined, res.invoice_id);
      this.routerS.goTo(ViewPath.invoices)
    })
  }

  addProduct(p: M_Product | M_CustomProduct): void {
    throw new Error('Method not implemented.');
  }
  addTime(time: M_CustomProduct): void {
    throw new Error('Method not implemented.');
  }
  removeProduct(p: M_Product | M_CustomProduct): void {
    throw new Error('Method not implemented.');
  }
  getClientDiscount(p: M_Product | M_CustomProduct): number | null {
    return null;
  }
  addComment(comment: M_CustomProduct): void {
    throw new Error('Method not implemented.');
  }
  getClient(): M_Contact | undefined {
    return undefined;
  }
}
