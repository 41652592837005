import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { M_Vehicle } from '../../models/Vehicles/M_Vehicle';
import { ParamsService } from '../../services/params.service';
import { M_Contact } from '../../models/M_Contact';
import { M_Appointment } from '../../models/M_Appointment';
import { UserService } from '../../services/EinaMainData/user.service';
import { RouterService } from 'src/app/services/router.service';
import { M_Order } from 'src/app/models/M_Order';

@Component({
  selector: 'app-vehicle-row, app-client-row, app-appointment-row, app-order-row',
  templateUrl: './item-row.component.html',
  styleUrls: ['./item-row.component.css']
})
export class ItemRowComponent {
  
  v = ViewPath;

  @Input({required : true}) item!: M_Vehicle | M_Contact | M_Appointment | M_Order ;
  @Input() newTab: boolean = false;
  @Output() onClick: EventEmitter<any> = new EventEmitter();

  constructor(public params: ParamsService, private routerS: RouterService, private userS: UserService) { }
  goItem() {
    if (!this.disabled) {
      this.onClick.emit();

      if (this.item instanceof M_Contact) {
        this.params.go(this.v.contactDetails, this.item.client_id, undefined, this.newTab)
      }

      else if (this.item instanceof M_Vehicle) {
        this.params.goVehicleDetails(this.item, this.newTab)
      }

      else if (this.item instanceof M_Appointment) {
        this.routerS.goWithQueryParams(ViewPath.cargataller, {
          section: 'appointment',
          id: this.item.id,
          date: this.item.appointment_date,
          apfilter0: this.item.state
        })
      }

      else if (this.item instanceof M_Order) {
        this.routerS.goWithQueryParams(ViewPath.editOrder, { order: this.item.id })
      }

    }
  }
  
  get isItemDeleted() {
    return this.item instanceof M_Contact || this.item instanceof M_Vehicle ? this.item.deleted : false;
  }

  get disabled() {
    return this.isItemDeleted || this.userS.userIsMechanic;
  }

  isV(d: any): d is M_Vehicle {
    return d instanceof M_Vehicle;
  }
  
  isC(d: any): d is M_Contact {
    return d instanceof M_Contact;
  }

  isA(d: any): d is M_Appointment {
    return d instanceof M_Appointment;
  }

  isO(d: any): d is M_Order {
    return d instanceof M_Order;
  }

}
