import { Injectable } from '@angular/core';
import { ApiService } from './Api/api.service';
import { M_Signing } from '../models/Signing/M_Signing';
import { IDestroyOnLogout } from '../interfaces/IDestroyOnLogout';
import { DestroyOnLogOut } from '../decorators/DestroyOnLogOut';
import { SessionService } from './session.service';

@DestroyOnLogOut()
@Injectable({
  providedIn: 'root'
})
export class SigningTimeService implements IDestroyOnLogout {

  signingData: M_Signing | undefined;
  today: Date;

  /** Llamar a m  */
  constructor(private sessionS: SessionService, private apiS: ApiService) {
    console.log("⏱️ Signing service")
    this.today = new Date();
    this.ticTac();
  }

  destroyData() {
    this.signingData = undefined;
  }

  getData() {
    if (this.sessionS.hasSession()) {
      this.apiS.signing.getMyPresence().then(res => {
        console.log(res);
        this.signingData = res;
      })
    }
  }

  /** Refesh */
  ticTac() {
    setInterval(() => { }, 1000);
  }

  startStop() {
    if (!this.signingData?.workingTimer) { return; }
    const s = this.signingData.workingTimer.schedule;
    this.signingData?.workingTimer.startStop();
    this.apiS.signing.startEnd(this.signingData?.workingTimer).then(res => {
      this.signingData!.workingTimer = res;
      this.signingData!.workingTimer.schedule = s;
      this.signingData?.refresHistory(res);
    })
  }

  pauseResume() {
    if (!this.signingData?.workingTimer) { return; }
    this.signingData.workingTimer.pauseResume();
    const s = this.signingData.workingTimer.schedule;
    this.apiS.signing.pauseResume(this.signingData?.workingTimer, this.signingData?.workingTimer.pauses.lastItem()).then(res => {
      this.signingData!.workingTimer = res;
      this.signingData!.workingTimer.schedule = s;
    })
  }

  get isPaused() { return this.signingData?.workingTimer.isPaused || false }
  get elapsed() { return this.signingData?.workingTimer.elapsed; }
  get canStop(): boolean { return this.signingData?.workingTimer.canEnd || false; }
  get canStart(): boolean { return this.signingData?.workingTimer.canStart || false; }
  get canPause(): boolean { return this.signingData?.workingTimer.canPause || false; }
  get canResume(): boolean { return this.signingData?.workingTimer.canResume || false; }
  get canPauseResume(): boolean { return this.signingData?.workingTimer.canPauseResume || false; }

  get loaded(): boolean { return this.signingData != undefined; }

  get workedPercentage(): number | undefined {
    if (this.expectedMinutes && this.workedTime) {
      return (this.workedTime / this.expectedMinutes) * 100;
    }
    return undefined;
  }

  get todaySchedule() {
    return this.signingData?.schedules[this.today.weekDay()]
  }

  /** Expected time in string */
  get expectedTimeString(): string | undefined {
    return this.todaySchedule?.getTotalTimeString() || "Sin horario para hoy";
  }

  /** Expected time in minutes */
  get expectedMinutes(): number | undefined {
    return this.todaySchedule?.getTotalTimeInMinutes();
  }

  get workedTime(): number | undefined { return this.signingData?.workingTimer.workedMinutes }
  get mustSigning(): string { return " " }
  get mustLogout(): string { return " " }
}
