
<form [formGroup]="form" eForm>
  <mat-form-field appearance="outline">
  <mat-label>Año</mat-label>
  <mat-select formControlName="date_version" [value]="2024">
    <mat-option *ngFor="let year of years" [value]="year">
      {{ year }}
    </mat-option>
  </mat-select>
</mat-form-field>
</form>