import { AbstractControl, AsyncValidatorFn, ValidationErrors } from "@angular/forms";
import { M_Vehicle } from "../models/Vehicles/M_Vehicle";
import { AlreadyExistsService } from "../services/already-exists.service";
import { ViewPath } from "src/app/app-routing.module";
import { ApiService } from "../services/Api/api.service";

export function asyncPlate(apiS: ApiService, existsS: AlreadyExistsService, getCurrentVehicle?: () => M_Vehicle | undefined): AsyncValidatorFn {
    console.info("Checking plate");
    return (control: AbstractControl): Promise<ValidationErrors | null> => {

        const currentVehicle = getCurrentVehicle ? getCurrentVehicle() : undefined;
        const vehiclePlate = currentVehicle?.license;

        console.info("Current vehicle", currentVehicle);
        console.info("Vehicle plate", vehiclePlate)

        if (vehiclePlate == control.value || !control.value || control.disabled) { return Promise.resolve(null) }

        return apiS.checkPlate(control.value).then(res => {
            if (res instanceof M_Vehicle) {
                existsS.show({
                    title: "¡Atención!",
                    message: "Ya existe un vehículo con la matrícula",
                    message2: "Recordamos que no se pueden crear dos vehículos con la misma matrícula",
                    value: res.license!,
                    view: ViewPath.vehicleDetails,
                    param_id: res.vehicle_id,
                    accept_text: "Ver vehículo"
                })
                return { "license-exists": true }
            }
            return null;
        })
    };
}