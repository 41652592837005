import { Component, Input, ViewChild, ChangeDetectorRef, Optional } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, AbstractControl, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ViewPath } from "src/app/app-routing.module";
import { BrandsModelsSeriesComponent } from "src/app/brands-models-series/brands-models-series.component";
import { MASTER_CLIENT, MASTER_CLIENT_MINIFIY } from "src/app/constants/masters";
import { ComercialVehicleType } from "src/app/enums/ComercialVehicleType";
import { RegistrationTaxEnum, registrationText } from "src/app/enums/RegistrationTaxEnum";
import { VehicleType } from "src/app/enums/VehicleType";
import { IConceptLineTableComponent } from "src/app/interfaces/IConceptLineTableComponent";
import { M_Brand } from "src/app/models/M_Brand";
import { M_Concept } from "src/app/models/M_Concept";
import { M_Contact } from "src/app/models/M_Contact";
import { M_Model } from "src/app/models/M_Model";
import { M_Vehicle } from "src/app/models/Vehicles/M_Vehicle";
import { AlreadyExistsService } from "src/app/services/already-exists.service";
import { ApiService } from "src/app/services/Api/api.service";
import { CompanyService } from "src/app/services/EinaMainData/company.service";
import { RouterService } from "src/app/services/router.service";
import { ClassSearcherComponent } from "../../class-searcher/class-searcher.component";
import { ConceptsLineTableComponent } from "../../concepts-line-table/concepts-line-table.component";
import { ImageToggleComponent } from "../../image-toggle/image-toggle.component";
import { PdfDocumentsUploadComponent } from "../../pdf-documents-upload/pdf-documents-upload.component";
import { DialogConfirmPlateComponent } from "../dialog-confirm-plate/dialog-confirm-plate.component";
import { createVehicleForm } from "../vehicleGenericLogic";

@Component({
  selector: 'app-vn-vo-form',
  templateUrl: './vn-vo-form.component.html',
  styleUrls: ['./vn-vo-form.component.css']
})
export class VnVoFormComponent implements IConceptLineTableComponent {

  M_CLIENT = MASTER_CLIENT;
  client = MASTER_CLIENT_MINIFIY;
  RTE = RegistrationTaxEnum;
  CVT = ComercialVehicleType;
  selectedType: number | null = 1;
  registrationText = registrationText;

  /** Show only the requiered fields */
  @Input() onlyRequired = false;
  @Input() requiredFieldsTitle?: string
  /** !!  */
  @Input() isStep = false;
  @Input() concepts: M_Concept[] = [];

  @ViewChild('brandsModelSerie') brandsModelSerie?: BrandsModelsSeriesComponent;
  @ViewChild(PdfDocumentsUploadComponent) pdfUploadComponent!: PdfDocumentsUploadComponent;
  @ViewChild(ImageToggleComponent) serieToggle!: ImageToggleComponent;
  @ViewChild(ConceptsLineTableComponent) conceptLineTable!: ConceptsLineTableComponent;
  @ViewChild('ownerProvider') ownerProvider?: ClassSearcherComponent<M_Contact>

  v = ViewPath;
  public form: UntypedFormGroup;
  lastb1 = "";
  lastb2 = "";
  VT = VehicleType;
  minDate: Date;
  vehicle: M_Vehicle | undefined
  freePlateEedit = false;
  selectedFile: File | null = null;
  provisionalConcepts: M_Concept[] = [];

  constructor(private fb: UntypedFormBuilder, private existsS: AlreadyExistsService,
    private apiS: ApiService, private chdRef: ChangeDetectorRef, private routerS: RouterService, private d: MatDialog,
    @Optional() public dialogRef: MatDialogRef<any>, public companyS: CompanyService) {

    /** Form generation */
    this.form = createVehicleForm(this.fb, this.apiS, this.existsS, () => this.getCurrentVehicle());

    this.minDate = new Date();
    this.form.get("battery_1_no")?.disable();
    this.form.get("battery_2_no")?.disable();


    /** When the engine_type 'select' changes, diable or enable some fields */
    this.form.get('engine_type')?.valueChanges.subscribe(val => {
      let b1 = this.form.get("battery_1_no")!;
      let b2 = this.form.get("battery_2_no")!;
      /** If the motor type is gas, disable the electric vehicle fields */
      if (val == 0) {
        this.lastb1 = b1.value;
        b1.setValue('');
        b1.disable();
        this.lastb2 = b2.value;
        b2.disable();
        b2.setValue('');
      }
      else {
        b1.enable();
        b1.patchValue(this.lastb1)
        b2.enable();
        b2.patchValue(this.lastb2)
      }
    })

    this.form.get("type")?.valueChanges.subscribe(val => {
      this.form.patchValue({ serie: null });
    })

    this.form.get('model')?.valueChanges.subscribe(val => {
      if (val instanceof M_Model && val.details) {
        this.form.patchValue({ price: val.details.price })
        this.form.patchValue({ cost: val.details.cost })
        this.form.patchValue({ registration_tax: val.details.tax_registration })
        this.form.patchValue({ brand_id: val.id_brand })
        this.form.patchValue({ model_id: val.id })

      }
      else if (val instanceof M_Model) {
        this.form.patchValue({ brand_id: val.id_brand })
        this.form.patchValue({ model_id: val.id })
      }
    })

    this.form.get('brand')?.valueChanges.subscribe(val => {
      if (val instanceof M_Brand) {
        this.form.patchValue({ brand_id: val.id });
      }
    });

    /** Set up comercial vehicle form */
    this.form.get('comercialType')?.valueChanges.subscribe(v => {
      this.requiredIf(this.form.get('client_id'), "vn", "vo");
      this.requiredIf(this.form.get('client_origin'), "vo");
      this.requiredIf(this.form.get('registration_tax'), "vn");
      this.requiredIf(this.form.get('license'), 'vo');
      this.requiredIf(this.form.get('brand_id'), 'vn', 'vo');
      this.requiredIf(this.form.get('model_id'), 'vn', 'vo');
      this.requiredIf(this.form.get('chassis'), "vn");
    })

    /** !! */
    this.form.get('comercialType')?.setValue(this.CVT.VN);
  }

  uploadFile(file: File) {
    const formData = new FormData();
    if (this.selectedFile) {
      formData.append('pdf_file', this.selectedFile);
    }
  }

  addConcept(c: M_Concept): void {
    this.getConcepts().push(c);
  }

  getConcepts() {
    if (this.vehicle) { return this.vehicle.concepts };
    if (this.concepts) { return this.concepts };
    return this.provisionalConcepts;
  }

  getCurrentVehicle(): M_Vehicle | undefined {
    return this.vehicle;
  }

  enablePlateEdition() {
    this.d.open(DialogConfirmPlateComponent, {
      data: {
        title: "Edición de matrícula",
        message: "¿Estas seguro que quieres editar la matrícula?",
      }
    }).afterClosed().subscribe(res => {
      if (res != true) {
        this.form.get('license')?.disable();
      } else {
        this.freePlateEedit = true;
        this.form.get('license')?.enable();
      }
    }
    );
  }

  showMe(v: VehicleType): boolean {
    let value = this.form.get('type')?.value;
    if (value) {
      return this.form.get('type')?.value == v;
    }
    return false;
  }

  goCreateClient() {
    this.routerS.goTo(this.v.createContact);
  }
  onTypeChange(event: any) {
    const selectedValue = event.value;

    let filterValue: number;  // Definimos la variable

    // Realizamos la conversión de valores
    if (selectedValue === 1) {
      filterValue = 0;  // Moto -> 0
    } else if (selectedValue === 2) {
      filterValue = 1;  // Coche -> 1
    } else {
      filterValue = -1;  // Un valor por defecto si el valor no es 1 ni 2
    }
    // Ahora `filterValue` tiene siempre un valor definido
    if (this.brandsModelSerie) {
      this.brandsModelSerie.onFilterValueChange(filterValue);
    }
  }
  /** Patch the current form with a vehicle. */
  patchFormWithVehicle(vehicle: M_Vehicle) {
    this.vehicle = vehicle;
    this.chdRef.detectChanges();
    if (this.vehicle != undefined) {
      this.form.patchValue(this.vehicle)
    }
    let licenseControl = this.form.get('license');
    if (licenseControl?.value) {
      this.form.get('license')?.disable();
    }

    let c = this.form.get('client_id')
    c?.removeValidators(Validators.required);
    c?.updateValueAndValidity();
  }

  get licenseBlocked() {
    return this.vehicle != undefined && this.form.get('license')?.value && !this.freePlateEedit;
  }

  /** !! */
  get loaded() {
    return true;
  }

  /** !!  */
  get clientProvAndCompany() {
    // return this.invoiceTo?.selected;
    return true;
  }

  get isVn() {
    return this.form.get('comercialType')?.value == this.CVT.VN;
  }

  get isVo() {
    return this.form.get('comercialType')?.value == this.CVT.VO;
  }

  onFileSelected(file: File | null) {
    if (file) {
      this.selectedFile = file;
      this.uploadFile(file);
    } else {
      this.selectedFile = null;
    }
  }

  requiredIf(control: AbstractControl<any, any> | null, ...is: ("vn" | "vo")[]) {
    if (!control) { return; }

    if (this.vehicleIs(...is)) {
      control.addValidators(Validators.required);
    }
    else {
      control.removeValidators(Validators.required);
    }
    control.updateValueAndValidity();
  }

  vehicleIs(...is: ("vn" | "vo")[]) {
    let ok = true;
    if (is.includes("vn")) { ok = this.isVn; if (ok) { return true; } }
    if (is.includes("vo")) { ok = this.isVo; if (ok) { return true; } }
    return ok;
  }

  /** HERE */
  checkPdfRequired() {
    let f = this.form.get('pdf_vn_vo')!;
    if (this.isPdfRequired) { f.addValidators(Validators.required); }
    else { f.removeValidators(Validators.required); }
    f.updateValueAndValidity();
  }

  get isPdfRequired() {
    let c = this.ownerProvider?.selected;
    if (!c) { return false; }
    return c.isCompany || c.isProvider
  }

  get isOnDialog() {
    return Object.keys(this.dialogRef).length != 0;
  }

  get buyTabIsOk() {
    return this.ownerProvider?.selected != undefined;
  }

}