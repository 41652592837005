<app-page-structure [pageTitle]="'Albaranes'" class="users-table" [masterClass]="'albaran'" [autocompleteID]="'albaran'"
    searchLabel="Buscar albarán" listTitleText="Listado de albaranes"
    [data]="[]"
    [displayedHeader]=" ['Número',      'Cliente',      'Estado',     'Tipo',       'Realización']"
    [displayedColumns]="['title_id',    'client',       'status',     'type',       'date_user']"
    [specialText]="     [undefined,     getClient,      undefined,    undefined,    undefined]"
    [specialClass]="    [undefined,     undefined,      undefined,    undefined,    undefined]"
    [preIcon]="         [preicon,       undefined,      undefined,    undefined,    undefined]" [filters]="filters"
    [filter]="f"
    [rightMenu]="rightMenu"
    [createButton]="
        {
            text : 'Crear albarán' , 
            icon : 'assignment' , 
            view : v.createEditAlbaran, 
            loading : !subS.status,
            cssClass : 'onboarding-albaran-create'
        }"
    (onclickRow)="params.go(v.createEditAlbaran, $event.id)" [quickFilter]="quickFilter">
</app-page-structure>

<ng-template #rightMenu>
    <button mat-menu-item (click)="openMassiveAlbaran()">
        <mat-icon>receipt_long</mat-icon>
        Facturación massiva
    </button>
</ng-template>