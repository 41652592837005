<!-- Edit discount group -->
<app-go-back [text]="'Grupos de descuento'" [v]="v.discountGroups"></app-go-back>
<app-card [contentLoaded]="!loadingGroup">

    <div class="df aic">
        <app-card-title>{{isEdit ? 'Crear grupo de descuento' : 'Editar grupo de descuento'}}</app-card-title>
        <button class="mla" mat-mini-fab color="primary" *ngIf="editing" [matMenuTriggerFor]="rightMenuTag">
            <mat-icon>more_horiz</mat-icon>
        </button>
        <mat-menu #rightMenuTag>
            <button *ngIf="editing" mat-menu-item (click)="deleteDiscountGroup(editing)">
                <mat-icon>delete</mat-icon>
                Eliminar grupo
            </button>
        </mat-menu>
    </div>



    <mat-form-field appearance="outline" style="max-width: 300px;">
        <mat-label>Nombre</mat-label>
        <input type="string" matInput [formControl]="fc">
    </mat-form-field>

    <app-card-subtitle>Líneas del grupo</app-card-subtitle>
    <table class="table w100 group-discount-table">
        <thead>
            <tr>
                <th>Nombre*</th>
                <th>Grupo Dto.*</th>
                <th>
                    <mat-icon class="th-icon">shopping_cart_checkout</mat-icon>
                    Dto. Normal
                </th>
                <th>
                    <mat-icon class="th-icon">bolt</mat-icon>
                    Dto. Urgente
                </th>
                <th class="th-background">Dto. cliente 1</th>
                <th class="th-background">Dto. cliente 2</th>
                <th class="th-background">Dto. cliente 3</th>
                <th class="th-background">Dto. cliente 4</th>
                <th class="th-background">Dto. cliente 5</th>
                <th class="small-action"></th> <!-- Delete line -->
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let line of discoungGroups">

                <ng-container *ngIf="!line.deleted">

                    <!-- Name -->
                    <td>
                        <mat-form-field appearance="outline" class="small-form-field">
                            <input matInput [(ngModel)]="line.code_line" [required]="true">
                        </mat-form-field>
                    </td>

                    <!-- Grupo Dto.-->
                    <td>
                        <mat-form-field appearance="outline" class="small-form-field">
                            <input matInput [(ngModel)]="line.p_group" [required]="true">
                        </mat-form-field>
                    </td>

                    <!-- Dto. Normal -->
                    <td>
                        <mat-form-field appearance="outline" class="small-form-field">
                            <input matInput DecimalInput [max]="99" [(ngModel)]="line.p_type_1" type="number">
                            <span matSuffix>%</span>
                        </mat-form-field>
                    </td>

                    <!-- Dto. Urgente -->
                    <td>
                        <mat-form-field appearance="outline" class="small-form-field">
                            <input matInput DecimalInput [max]="99" [(ngModel)]="line.p_type_2" type="number">
                            <span matSuffix>%</span>
                        </mat-form-field>
                    </td>

                    <!-- Dto. cliente 1 -->
                    <td>
                        <mat-form-field appearance="outline" class="small-form-field">
                            <input matInput DecimalInput [max]="99" [(ngModel)]="line.v_cat_1" type="number">
                            <span matSuffix>%</span>
                        </mat-form-field>
                    </td>

                    <!-- Dto. cliente 2 -->
                    <td>
                        <mat-form-field appearance="outline" class="small-form-field">
                            <input matInput DecimalInput [max]="99" [(ngModel)]="line.v_cat_2" type="number">
                            <span matSuffix>%</span>
                        </mat-form-field>
                    </td>

                    <!-- Dto. cliente 3 -->
                    <td>
                        <mat-form-field appearance="outline" class="small-form-field">
                            <input matInput DecimalInput [max]="99" [(ngModel)]="line.v_cat_3" type="number">
                            <span matSuffix>%</span>
                        </mat-form-field>
                    </td>

                    <!-- Dto. cliente 4 -->
                    <td>
                        <mat-form-field appearance="outline" class="small-form-field">
                            <input matInput DecimalInput [max]="99" [(ngModel)]="line.v_cat_4" type="number">
                            <span matSuffix>%</span>
                        </mat-form-field>
                    </td>

                    <!-- Dto. cliente 5 -->
                    <td>
                        <mat-form-field appearance="outline" class="small-form-field">
                            <input matInput DecimalInput [max]="99" [(ngModel)]="line.v_cat_5" type="number">
                            <span matSuffix>%</span>
                        </mat-form-field>
                    </td>

                    <!-- Remove line -->
                    <td>
                        <button mat-icon-button (click)="deleteLine(line)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                </ng-container>
            </tr>
        </tbody>
    </table>

    <!-- No line message -->
    <div class="m10" *ngIf="!discoungGroups.length">
        <p class="c_t2">¡Hora de añadir una línea! 👇🏻</p>
    </div>

    <div class="mb10">
        <button mat-stroked-button (click)="appendLine()" color="primary">
            <mat-icon class="c_p">list_alt</mat-icon>
            Añadir línea
        </button>
    </div>

    <div class="df jcc">
        <button mat-flat-button color="primary" [disabled]="isOkDisabled" (click)="createEdit()">
            Guardar
        </button>
    </div>


</app-card>