import { Component } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { SubscriptionService } from '../../services/EinaMainData/subscription.service';
import { EnumSubscription } from '../../enums/EnumSubscription';
import { M_Subscription } from '../../models/M_Subscription';
import { RouterService } from 'src/app/services/router.service';
import { SETTINGS_TAB } from 'src/app/views/settings/settings.component';

export enum EnumBannerColor {
  GREEN,
  ORANGE,
  RED,
  BLUE
}

/**
 * Banner that shows when user is not subscribed
 * [10-15] remaining days --> green banner
 * [5-10]  remaining days --> orange banner
 * [0-5]   remaining days --> red banner
 */
@Component({
  selector: 'app-subscription-days-left',
  templateUrl: './subscription-days-left.component.html',
  styleUrls: ['./subscription-days-left.component.css']
})
export class SubscriptionDaysLeftComponent {

  v = ViewPath;
  ST = SETTINGS_TAB;
  
  /** 0 - GREEN;  1 - ORANGE;  2 - RED */
  color: EnumBannerColor = 0;
  enumSub = EnumSubscription;

  constructor(public subS: SubscriptionService, public routerS: RouterService) {
    this.subS.onChangeSubscription.subscribe(status => {
      if (status == undefined) { return }
      this.setBannerColor(status);
    })

    /**
     * The subscription service has probably already emited the "onChangeSubscription". 
     * So we update it with his actual value. 
    */
    try{this.setBannerColor(subS.status);}
    catch{}
  }

  setBannerColor(status: M_Subscription | undefined) {
    if (status == undefined) { return; }
    switch (status.type) {
      case EnumSubscription.TRIAL:
        this.color = status.remaining_days <= 5 ? EnumBannerColor.RED :
          status.remaining_days <= 10 ? EnumBannerColor.ORANGE :
            EnumBannerColor.GREEN;
        break;
      case EnumSubscription.BASIC:
        this.color = EnumBannerColor.RED;
        break;
      case EnumSubscription.PRO:
        //Only gonna show if will_cancel is true
        this.color = EnumBannerColor.BLUE;
        break;
    }
  }
}