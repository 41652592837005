<form eForm style="max-width: unset;" [formGroup]="form">

    <app-close-dialog-button *ngIf="isOnDialog"></app-close-dialog-button>


    <p mat-dialog-title *ngIf="isOnDialog">Venta vehículo</p>

    <div class="advanced-details-container" mat-dialog-content [ngClass]="isOnDialog ? 'on-dialog' : 'not-dialog'">

        <div class="title" *ngIf="!isOnDialog">
            <app-go-back (click)="returnGarage()"></app-go-back>
            <app-view-title *ngIf="loaded">{{"Venta vehículo"}}
            </app-view-title>
        </div>

        <div class="ig">
            <app-card [contentLoaded]="loaded" class="">
                <app-card-title>Información general</app-card-title>
                <div class="df" *ngIf="ve">
                    <app-vehicle-row [item]="ve" [newTab]="isOnDialog"></app-vehicle-row>
                    <app-vn-vo-label [type]="ve.comercialType" *ngIf="ve.comercialType"></app-vn-vo-label>
                </div>
                <app-card-subtitle>¿A quién se lo vendes?</app-card-subtitle>
                <div eForm-section eForm-wrapper>
                    <app-class-searcher [required]="true" [showLeftNumber]="false" #csearcher
                        class="onboarding-???" [masterClass]="client" [allData]="" [width100]="true"
                        searchPlaceHolder="Buscar cliente" [extendedInfo]="false" [form_]="form"
                        [formCname]="'client_id'" (onSelect)="selectClientInv($event)"
                        (onRemove)="classearcherChanged = true" [required]="true" [createData]="{
                        text : 'Nuevo cliente / empresa',
                        by : v.createContact,
                    }">
                    </app-class-searcher>
                </div>

                <div>
                    <app-card-subtitle>Venta</app-card-subtitle>
                    <div eForm-section eForm-wrapper>
                        <mat-form-field appearance="outline">
                            <mat-label>Precio venta</mat-label>
                            <input type="number" min="0" matInput formControlName="price">
                            <mat-hint *ngIf="ve?.registration_tax">Se aplicarà el
                                {{this.ve?.registration_tax}}%</mat-hint>
                        </mat-form-field>

                        <mat-form-field appearance="outline" *ngIf="isVn">
                            <mat-label>Impuesto matriculación</mat-label>
                            <mat-select formControlName="registration_tax">
                                <mat-option [value]="RTE.Low"> {{registrationText(RTE.Low)}}</mat-option>
                                <mat-option [value]="RTE.MediumLow">{{registrationText(RTE.MediumLow)}}</mat-option>
                                <mat-option [value]="RTE.MediumHigh">{{registrationText(RTE.MediumHigh)}}</mat-option>
                                <mat-option [value]="RTE.High">{{registrationText(RTE.High)}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                </div>

                <app-card-subtitle>¿Qué agente ha realizado la venta?</app-card-subtitle>
                <div eForm-section eForm-wrapper>
                    <app-class-searcher #agentSearcher class="w100"
                        [required]="false"
                        [masterClass]="client" searchPlaceHolder="Buscar agente"
                        [disableIf]="disabledIf.bind(this)"
                        [noSelectionPlaceHolder]="'Ningún agente seleccionado'" [createData]="undefined">
                    </app-class-searcher>
                </div>

            </app-card>
        </div>

        <div class="concepts">
            <app-card [contentLoaded]="loaded" class="pSection mt10">
                <app-card-title>
                    Conceptos
                    <p class="nmb fss fw500">Los conceptos se añadirán en la factura actual.</p>
                </app-card-title>
                <app-concepts-line-table [concepts]="ve ? ve.concepts : []" [vehicle]="this.ve"
                    [isInfo]="false"></app-concepts-line-table>
                <table class="breakdown no-pointer" *ngIf="ve?.concepts">
                    <tr>
                        <td class="fw400 total">
                            Subtotal
                        </td>
                        <td><span class="c_p fw600" money [val]="subtotal"></span></td>
                    </tr>
                    <tr>
                        <td *ngIf="iva4Exists" class="fw400 total">IVA 4%</td>
                        <td *ngIf="iva4Exists"><span class="c_p fw600" money [val]="totalIVA4"></span></td>
                    </tr>

                    <tr>
                        <td *ngIf="iva10Exists" class="fw400 total">IVA 10%</td>
                        <td *ngIf="iva10Exists"><span class="c_p fw600" money [val]="totalIVA10"></span></td>
                    </tr>

                    <tr>
                        <td *ngIf="iva21Exists" class="fw400 total">IVA 21%</td>
                        <td *ngIf="iva21Exists"><span class="c_p fw600" money [val]="totalIVA21"></span></td>
                    </tr>
                    <tr>
                        <td *ngIf="regTaxConcepts || regTaxV" class="fw400 total">IEDMT {{regTaxVehicle}}%</td>
                        <td *ngIf="regTaxConcepts || regTaxV"><span class="c_p fw600" money [val]="totalIVATax"></span>
                        </td>
                    </tr>

                    <tr>
                        <td *ngIf="ivaExentexists" class="fw400 total">EXENTO</td>
                        <td *ngIf="ivaExentexists"><span class="c_p fw600" money [val]="totalIVAExempt"></span></td>
                    </tr>
                    <tr>
                        <td class="fsm fw500 total">Total</td>
                        <td><span class="c_p fw600" money [val]="totalSell"></span></td>
                    </tr>
                </table>
            </app-card>
        </div>
        <div class="products">
            <app-card [contentLoaded]="loaded">
                <app-card-title>
                    Productos adicionales
                    <p class="nmb fss fw500">Si añades productos, se generará una OR tras crear la factura.</p>
                </app-card-title>
                <app-product-line-table *ngIf="this.ve" [products]="ve.products"
                    [lineTypes]="['product', 'custom','time','comment']" [showSave]="false" [showLocation]="false"
                    [showProductsStock]="false"
                    [extendedInfoConfig]="{showColumn:true, actions:['manual-fault','remove-reservation']}">
                </app-product-line-table>
                <table [class]="'breakdown no-pointer '" *ngIf="getTotalBreakdown() as totals">
                    <tr>
                        <td class="fw400 total">
                            Subtotal
                            <p class="fss fsi nmb" *ngIf="totals.client_excent_iva">Cliente exento de IVA</p>
                        </td>
                        <td><span class="c_p fw600" money [val]="totals.subtotal"></span></td>
                    </tr>
                    <tr *ngFor="let row of totals.iva_rows">
                        <td *ngIf="row.iva != 0" class="fw400 total">IVA ({{row.iva}}%)</td>
                        <td *ngIf="row.iva == 0" class="fw400 total">EXENTO</td>
                        <td><span class="c_p fw600" money [val]="row.iva == 0? row.subtotal : row.total"></span></td>
                    </tr>
                    <tr>
                        <td class="fsm fw500 total">Total</td>
                        <td><span class="c_p fw600" money [val]="totals.total"></span></td>
                    </tr>
                </table>
                <div class="df jcc" *ngIf="!isOnDialog">
                    <div [ngTemplateOutlet]="createButton"></div>
                </div>
            </app-card>
        </div>
    </div>
</form>


<div mat-dialog-actions *ngIf="isOnDialog">
    <div [ngTemplateOutlet]="createButton"></div>
</div>

<ng-template #createButton>
    <button class="onboarding-invoice-finish" [disabled]="!this.form.valid" subscribed-user mat-flat-button
        color="primary" (click)="sellVehicle()">
        Crear factura {{ve?.products?.length ? ' y OR' : ''}}
    </button>
</ng-template>