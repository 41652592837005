import { Component, ViewChild } from '@angular/core';
import { forkJoin } from 'rxjs';
import { ViewPath } from 'src/app/app-routing.module';
import { ClassSearcherComponent } from 'src/app/components/class-searcher/class-searcher.component';
import { emittedRow } from 'src/app/components/excel-column-matcher/excel-column-matcher.component';
import { exelColumId, storeHouseExpectedColunmns } from 'src/app/components/excel-column-matcher/imports/storehouse-entry';
import { ProductLineTableComponent } from 'src/app/components/product-line-table/product-line-table/product-line-table.component';
import { MASTER_PROVIDER } from 'src/app/constants/masters';
import { ContactEnum } from 'src/app/enums/ContactEnum';
import { IProductLineTableComponent } from 'src/app/interfaces/IProductLineTableComponent';
import { M_Contact } from 'src/app/models/M_Contact';
import { M_DiscountGroup } from 'src/app/models/M_DiscountGroupLine';
import { M_CustomProduct } from 'src/app/models/Products/M_CustomProduct';
import { M_Product } from 'src/app/models/Products/M_Product';
import { ApiService } from 'src/app/services/Api/api.service';
import { RouterService } from 'src/app/services/router.service';
import { SnackService } from 'src/app/services/snack.service';
import { setUpDiscountGroupByString } from 'src/app/utils/FunctionUtils';

@Component({
  selector: 'app-tarifas',
  templateUrl: './tarifas.component.html',
  styleUrls: ['./tarifas.component.css']
})
export class TarifasComponent implements IProductLineTableComponent {

  M_CLIENT = MASTER_PROVIDER;
  ce = ContactEnum;

  @ViewChild(ClassSearcherComponent) cs!: ClassSearcherComponent<M_Contact>;
  @ViewChild(ProductLineTableComponent) productLineTable!: ProductLineTableComponent;

  expectedColums = storeHouseExpectedColunmns.filter(col => col.id != exelColumId.QUANTITY && col.id != exelColumId.COST);
  allProducts: M_Product[] = [];
  tariff_products: M_Product[] = [];
  discountGroups: M_DiscountGroup[] | undefined;

  loaded = false;
  v = ViewPath;

  constructor(private routerS: RouterService, private apiS: ApiService, private snack: SnackService) {
    const a = this.apiS.products();
    const b = this.apiS.getAllDiscountGroups();
    forkJoin([a, b]).subscribe(res => {
      this.allProducts = res[0];
      this.discountGroups = res[1];
      this.loaded = true;
    })
  }

  onRowEnter(row: emittedRow) {
    console.log("Ref : ", row[exelColumId.REF]);
    console.log("Name : ", row[exelColumId.NAME]);
    console.log("Cantidad : ", row[exelColumId.QUANTITY]);
    console.log("Coste : ", row[exelColumId.COST]);
    console.log("PVP (tarifa) : ", row[exelColumId.PVP_T]);
    console.log("Discount : ", row[exelColumId.DISCOUNT]);
    console.log("Discount Group : ", row[exelColumId.DISCOUNT_GROUP]);
    console.log("Grupo contable : ", row[exelColumId.ACCOUNTING_GROUP]);

    let p = new M_Product({});
    p.reference = row[exelColumId.REF] as string;
    p.name = row[exelColumId.NAME] as string;
    p.quantity = row[exelColumId.QUANTITY] as number;
    p.buy_price = row[exelColumId.COST] as number;
    p.pvp_t = row[exelColumId.PVP_T] as number;
    p.discount = row[exelColumId.DISCOUNT] as number;
    /** To do : discount groups */
    setUpDiscountGroupByString(this.discountGroups, row[exelColumId.DISCOUNT_GROUP] as string, p);
    p.accounting_group_name = row[exelColumId.DISCOUNT] as string;

    let finded = this.allProducts.find(realProduct => realProduct.reference.toLocaleLowerCase().trim() == p.reference.toLocaleLowerCase().trim());
    if (finded) {
      p.product_id = finded.product_id;
      p.locations = finded.locations;
      if (finded.locations[0]) { p.selected_location = finded.locations[0].id; }
      p.pvp_t_ant = finded.pvp_t;
    }

    this.tariff_products.push(p);
  }

  enter() {
    console.log(this.tariff_products);
    if (this.cs.selected) {
      this.apiS.importTarifa(this.cs.selected, this.tariff_products).then(res => {
        this.snack.show("'Tarifa importada! 👍")
        this.routerS.goTo(this.v.products);
      })
    }
  }


  get blocksLine(): boolean { return false; }
  addProduct(p: M_Product | M_CustomProduct): void { }
  addTime(time: M_CustomProduct): void { }
  addComment(comment: M_CustomProduct): void { }
  removeProduct(p: M_Product | M_CustomProduct): void {
    if (p != undefined && p.instanceofProduct()) {
      this.tariff_products.removeElement(p);
    }
  }
  getClient(): M_Contact | undefined { return undefined }
  getClientDiscount(p: M_Product | M_CustomProduct): number | null { return null }

}
