import { Component, EventEmitter, Input, Output } from '@angular/core';
import { projectConfiguration } from 'src/app/app.module';
import { M_Action } from 'src/app/models/M_Action';
import { M_Appointment } from 'src/app/models/M_Appointment';
import { M_User } from 'src/app/models/M_User';

@Component({
  selector: 'lib-or-change-worker',
  templateUrl: './or-change-worker.component.html',
  styleUrls: ['./or-change-worker.component.css']
})
export class OrChangeWorkerComponent {

  @Input() day!: Date;
  @Input() item!: M_Action | M_Appointment;
  @Input() canAssign!: boolean;
  @Input() allUsers: M_User[] = [];
  @Output() onAssignUser: EventEmitter<M_User> = new EventEmitter();

  core = projectConfiguration;

  constructor() { }

  userHasHolidays(u: M_User) {
    var currentUser = this.allUsers.find(user => user.id == u.id);
    if (!currentUser) { return false }
    return currentUser.holidays.some(d => d.isEquals(this.day))
  }

  isClickable(u: M_User) {
    return !this.userHasHolidays(u) && !this.isUserAlreadyAssigned(u);
  }

  onClickUser(u: M_User) {
    if (this.isClickable(u)) {
      this.onAssignUser.emit(u)
    }
  }

  isUserAlreadyAssigned(user: M_User) {
    if (this.item instanceof M_Action) {
      return this.item.assigned?.id == user.id;
    }
    else {
      return this.item.receptor_user?.id == user.id;
    }
  }

  get canChangebyStatus() {
    if (this.item instanceof M_Action) {
      return this.item.status.open || this.item.status.pending
    }
    else {
      return this.item.isPending;
    }
  }

  get textCannotChange() {
    if (this.item instanceof M_Action) {
      if (this.item.status.pending) { return "No puedes cambiar el operario de una OR ya cerrada" }
      else if (this.item.status.invoiced) { return "No puedes cambiar el operario de una OR ya facturada" }
    }
    else {
      if (this.item.isCanceled) { return "No puedes cambiar el receptor en una cita cancelada" }
      if (this.item.isDone) { return "No puedes cambiar el receptor en una cita ya hecha" }
    }
    return "No se puede cambiar el usuario"
  }

}
