<app-view-title>Informes</app-view-title>

<app-card [contentLoaded]="loaded" [noStyle]="true">

    <!-- Company and center filter -->
    <div class="report nmb">
        <form eForm>
            <div eForm-wrapper>
                <div eForm-section>
                    <mat-form-field appearance="outline">
                        <mat-label>Centro</mat-label>
                        <mat-select [formControl]="center">
                            <mat-option *ngFor="let center of companyS.company?.centers" [value]="center.id">
                                {{center.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>

    <!-- Stock -->
    <div class="report" *ngIf="config.showReport.stock">
        <app-card-title>Stock</app-card-title>
        <div *ngIf="stockChart" eForm-wrapper>
            <app-report-filters [defaultDay]="defaultDay" [comparable]="false"
                [hasTimeRange]="false" [showUser]="false"
                (onFilters)="updateIcis($event, icisStock, 'stock')"></app-report-filters>

            <div eForm-wrapper class="wrapper-of-wrapper">
                <apx-chart [series]="stockChart.series!" [chart]="stockChart.chart!" [stroke]="stockChart.stroke!"
                    [dataLabels]="stockChart.dataLabels!" [plotOptions]="stockChart.plotOptions!"
                    [xaxis]="stockChart.xaxis!" [colors]="stockChart.colors!" [fill]="stockChart.fill!"
                    [yaxis]="stockChart.yaxis!" [legend]="stockChart.legend!">
                </apx-chart>
                <div class="report-cards-container" *ngIf="icisStock">
                    <app-report-card [title]="'Stock total'"
                        [val]="[icisStock.STOCK_total_stock, undefined]"></app-report-card>
                    <app-report-card [title]="'Stock total VN Honda'"
                        [val]="[icisStock.STOCK_stock_VN_honda, undefined]"></app-report-card>
                    <app-report-card [title]="'Stock total VO otras'"
                        [val]="[icisStock.STOCK_stock_VO_otras, undefined]"></app-report-card>
                    <app-report-card [title]="'Stock total VO Honda'"
                        [val]="[icisStock.STOCK_stock_total_VO_honda, undefined]"></app-report-card>
                </div>
            </div>


        </div>
    </div>

    <!-- Sells -->
    <div class="report" *ngIf="config.showReport.sells">
        <app-card-title>Ventas</app-card-title>
        <div *ngIf="sellsChart" eForm-wrapper>

            <app-report-filters [defaultDay]="defaultDay" [comparable]="true"
                [hasTimeRange]="true" [showUser]="false"
                (onFilters)="updateIcis($event, icisSells, 'sells')"></app-report-filters>

            <div eForm-wrapper class="wrapper-of-wrapper">
                <apx-chart [series]="sellsChart.series!" [chart]="sellsChart.chart!" [stroke]="sellsChart.stroke!"
                    [dataLabels]="sellsChart.dataLabels!" [plotOptions]="sellsChart.plotOptions!"
                    [xaxis]="sellsChart.xaxis!" [colors]="sellsChart.colors!" [fill]="sellsChart.fill!"
                    [yaxis]="sellsChart.yaxis!" [legend]="sellsChart.legend!">
                </apx-chart>
                <div class="report-cards-container" *ngIf="icisSells">
                    <app-report-card [title]="'Ventas directas totales'"
                        [val]="[icisSells[0].SELLS_total_direct_sells, icisSells[1]?.SELLS_total_direct_sells]"></app-report-card>
                    <app-report-card [title]="'Ventas agentes totales'"
                        [val]="[icisSells[0].SELLS_total_agents_sells, icisSells[1]?.SELLS_total_agents_sells]"></app-report-card>
                    <app-report-card [title]="'Ventas totales'"
                        [val]="[icisSells[0].SELLS_total_sells, icisSells[1]?.SELLS_total_sells]"></app-report-card>
                </div>
            </div>

        </div>
    </div>

    <!-- ORs -->
    <div class="report alterative-background" *ngIf="config.showReport.ors">
        <app-card-title>ORs</app-card-title>
        <div *ngIf="orsChart" eForm-wrapper>

            <app-report-filters [defaultDay]="defaultDay" [comparable]="true" [hasTimeRange]="true" [showUser]="true"
                (onFilters)="updateIcis($event, icisOrs, 'ors')"></app-report-filters>

            <div eForm-wrapper class="wrapper-of-wrapper">
                <apx-chart [series]="orsChart.series!" [chart]="orsChart.chart!" [stroke]="orsChart.stroke!"
                    [dataLabels]="orsChart.dataLabels!" [plotOptions]="orsChart.plotOptions!" [xaxis]="orsChart.xaxis!"
                    [colors]="orsChart.colors!" [fill]="orsChart.fill!" [yaxis]="orsChart.yaxis!"
                    [legend]="orsChart.legend!">
                </apx-chart>
                <div class="report-cards-container" *ngIf="icisOrs">
                    <app-report-card [title]="'Total OR'"
                        [val]="[icisOrs[0].ORS_total_ors(isHonda), icisOrs[1]?.ORS_total_ors(isHonda)]"></app-report-card>
                    <app-report-card [title]="'Total horas facturadas'"
                        [val]="[icisOrs[0].ORS_total_invoiced_hours(isHonda), icisOrs[1]?.ORS_total_invoiced_hours(isHonda)]"></app-report-card>
                    <app-report-card [title]="'Horas de presencia'"
                        [val]="[icisOrs[0].ORS_presence_hours(isHonda), icisOrs[1]?.ORS_presence_hours(isHonda)]"></app-report-card>
                    <app-report-card [title]="'Horas trabajadas'"
                        [val]="[icisOrs[0].ORS_worked_hours(isHonda), icisOrs[1]?.ORS_worked_hours(isHonda)]"></app-report-card>
                </div>
                <div class="report-cards-container" *ngIf="icisOrs">
                    <app-report-card [title]="'Beneficio horas'"
                        [val]="[icisOrs[0].ORS_hours_profit(isHonda), icisOrs[1]?.ORS_hours_profit(isHonda)]"
                        [percentage]="true"></app-report-card>
                    <app-report-card [title]="'Ocupación'"
                        [val]="[icisOrs[0].ORS_occupation(isHonda), icisOrs[1]?.ORS_occupation(isHonda)]"
                        [percentage]="true"></app-report-card>
                    <app-report-card [title]="'Eficiencia'"
                        [val]="[icisOrs[0].ORS_efficiency(isHonda), icisOrs[1]?.ORS_efficiency(isHonda)]"
                        [percentage]="true"></app-report-card>
                    <app-report-card [title]="'Productividad'"
                        [val]="[icisOrs[0].ORS_productivity(isHonda), icisOrs[1]?.ORS_productivity(isHonda)]"
                        [percentage]="true"></app-report-card>
                </div>
            </div>
        </div>
    </div>
</app-card>