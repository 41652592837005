import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CustomFile } from '../custom-classes/CustomFile';
import { M_User } from '../models/M_User';

@Injectable({
  providedIn: 'root'
})
export class ProfilePictureService {

  profileImageSubject: Subject<[cf : CustomFile, u : M_User]> = new Subject();
  
  constructor() { }
  onChangeProfilePicture(data : [cf : CustomFile, u : M_User]) {
    this.profileImageSubject.next(data)
  }
}
