<p mat-dialog-title>{{!loaded? 'Subiendo' : '¡Document importado!'}}</p>

<mat-dialog-content>
    <script src="https://cdn.lordicon.com/qjzruarw.js"></script>

    <div *ngIf="!loaded" class="df jcc">
        <lord-icon src="https://cdn.lordicon.com/jhhpknof.json" trigger="loop"
            colors="primary:#3a3347,secondary:#646e78,tertiary:#92140c,quaternary:#2ca58d,quinary:{{warn()}},senary:#ebe6ef"
            class="lord-huge">
        </lord-icon>
    </div>

    <div *ngIf="loaded" class="df jcc">
        <script src="https://cdn.lordicon.com/qjzruarw.js"></script>
        <lord-icon src="https://cdn.lordicon.com/hrqqslfe.json" trigger="loop" delay="9999"
            colors="primary:{{primary()}},secondary:#ebe6ef" class="lord-huge">
        </lord-icon>
    </div>

    <p mat-dialog-subtitle>RESULT
        <button mat-icon-button *ngIf="loaded" [matTooltip]="'Copiar los resultados'" (click)="copyClippboard()">
            <mat-icon *ngIf="loaded">content_copy</mat-icon>
        </button>
        <button mat-icon-button *ngIf="loaded" [matTooltip]="'Descargar resultados'" (click)="downloadFile()">
            <mat-icon *ngIf="loaded">file_download</mat-icon>
        </button>
    </p>
    <div *ngIf="!loaded">
        <p class="c_t2">Una vez se procese el archivo, podrás ver los resultados.</p>
        <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
    </div>
    <div [ngClass]="loaded ? 'show-r' : 'hidden-r'">
        <p *ngIf="result == undefined || result.isEmpty">Sin resultados</p>
        <div *ngIf=" result != undefined">
            <p class="succes" *ngFor="let succes of result.success">{{succes}}</p>
            <p class="fail" [ngClass]="data.expectetResponse.length == 0 ? 'updated' : ''"
                *ngFor="let fail of result.fail">{{fail}}</p>
        </div>
    </div>

</mat-dialog-content>

<div mat-dialog-actions *ngIf="loaded" class="df jcc">
    <button mat-flat-button color="primary" [mat-dialog-close]="true">Ok</button>
</div>