<!-- Adviser and Mechanic dashboard -->
<app-core-carga-taller *ngIf="subS.hasProPermissions; else noSub" [workloadData]="wS.workloadData"
    (deliveryWarn)="openWarnDialog($event)" (onModifyAppointment)="onModifyAppointment($event)"
    (onDragFinished)="onDragFinished()">
</app-core-carga-taller>

<ng-template #noSub>
    <div class="df jcc fdc aic bottom-entrance-blurred">
        <lord-icon class="lord-big" trigger="in" delay="1000" src="https://cdn.lordicon.com/cniojwli.json">
        </lord-icon>
        <p class="fsi fsl mt10">¡Echa el freno!</p>
        <p>Para poder acceder a la <span class="fw600 underline_b">carga de taller</span> debes tener la subscripción PRO.</p>
        <p *ngIf=""></p>


        <p [not-roles]="[R.ADMIN, R.GESTOR]" class="c_t2">¡No te quedes atascado, comunícate con el administrador del taller para desbloquear el acceso completo a Eina!</p>
    
        <button super-user mat-flat-button color="primary" (click)="routerS.goWithQueryParams(v.settings, {'tab' : ST.SUBSCRIPTION})" class="mt10" >
            Subscribirse
        </button>

    </div>
</ng-template>