import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ViewPath } from 'src/app/app-routing.module';
import { ParameterStateComponent } from 'src/app/components/parameter-state/parameter-state.component';
import { M_Invoice } from 'src/app/models/M_Invoice';
import { ApiService } from 'src/app/services/Api/api.service';
import { PreviewService } from 'src/app/services/preview.service';
import { RouterService } from 'src/app/services/router.service';
import { PayAllInvoiceDialogComponent } from '../invoices/pay-check-control/pay-all-invoice-dialog.component';
import { M_LastMovements } from 'src/app/models/M_LastMovement';
import { TypePaymentService } from 'src/app/services/type-payment-service';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';
import { invoice_states, invoice_states_partial, invoice_states_pending } from 'src/app/custom-classes/invoice_states';
import { M_TypePayment } from 'src/app/models/M_TypePayment';
import { or_types } from 'src/app/custom-classes/or_types';

@Component({
  selector: 'app-invoice-details',
  templateUrl: './invoice-details.component.html',
  styleUrls: ['./invoice-details.component.css']
})
export class InvoiceDetailsComponent extends ParameterStateComponent {

  v = ViewPath;
  invoice: M_Invoice | undefined;

  constructor(routerS: RouterService, route: ActivatedRoute, private apiS: ApiService, private previewS: PreviewService,
    private d: MatDialog, private typePaymentS: TypePaymentService, private confirmD: ConfirmDialogService) {
    super(routerS, route, ["bill"]);
  }

  override onParam(param: string, value: string): void {
    this.apiS.getInvoiceId(value.getNumber()).then(res => {
      this.invoice = res;
    });
  }

  previsualize(bill: M_Invoice) {
    this.previewS.showPreview("I", bill.token, undefined, bill.company_scope_id);
  }

  openPayCheckControl() {
    if (!this.invoice) { return; }
    this.d.open(PayAllInvoiceDialogComponent, { panelClass: 'customDialog', data: { invoice: this.invoice, last_movements: this.invoice.last_movement, pending: true, typePayment: this.typePaymentS.typePaymentArray } }).afterClosed().subscribe(res => {
      if (res instanceof M_LastMovements) {
        let movement = this.invoice!.last_movement.find(lm => lm.id == res.id);
        if (movement) { movement.deleted = true; }
      }
    });
  }

  reSendInvoice() {
    return this.apiS.reSendInvoice(this.invoice!.token!)
  }

  deleteLastPayment(last_movement: M_LastMovements) {

    if (!this.invoice) { return; }

    this.confirmD.show({
      title: "¿Seguro que quieres eliminar el cobro?",
      body: "Se eliminara el cobro de la factura",
      confirmTxt: "Eliminar",
      showCancel: true,
      type: "danger"
    }).afterClosed().subscribe(res => {
      if (res) {
        this.apiS.deleteLastPayment(last_movement).then(res => {
          this.invoice!.last_movement.removeElement(last_movement);
          let movement = this.invoice!.last_movement.find(lm => lm.id == res.id);
          if (movement) { movement.deleted = true; }
        })
        if (this.invoice!.last_movement.length == 1) {
          this.pendingChip(invoice_states_pending);
        }
        this.partialChip(invoice_states_partial);
      }
    });

  }

  partialChip(status: invoice_states) {

    if (status == this.invoice!.state) {
      return;
    }
    this.apiS.changeStateInvoice(this.invoice!, status).then(res => {
      this.invoice!.state = invoice_states_partial;
    });
  }

  pendingChip(status: invoice_states) {
    if (!this.invoice) { return; }
    if (status == this.invoice.state) { return; }
    this.apiS.changeStateInvoice(this.invoice, status).then(res => {
      this.invoice!.state = invoice_states_pending;
      this.invoice!.last_movement = [];
    });
  }


  deleteTypePayment(type_payment: M_TypePayment) {
    this.typePaymentS.deleteTypePayment(type_payment).then(res => {
      // if (res) { this.newFilterPayment(); }
    })
  }

  get orType() {
    if (!this.invoice) { return undefined };
    let ot = this.invoice.invoice_type_or_type[1];
    if (ot instanceof or_types) {
      return ot;
    }
    return undefined
  }

}
