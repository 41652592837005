import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { asyncPlate } from 'src/app/validators/plateAsyncValidator';
import { validatorTax } from 'src/app/validators/tax';

export function createVehicleForm(formBuilder: FormBuilder, apiS: any, existsS: any, getCurrentVehicle: () => any): FormGroup {
  return formBuilder.group({
    /** Normal vehicle */
    vehicle_id: [],
    type: ['bike', Validators.required],
    license: ['', {
      validators: [Validators.required],
      asyncValidators: [asyncPlate(apiS, existsS, getCurrentVehicle)],
    }],
    brand: [''],
    model: [''],
    chassis: [''],
    serie: [],
    color: ['', []],
    insurance: ['', []],
    cc: ['', []],
    engine_type: ['', []],
    license_date: ['', []],
    fabrication_date: ['', []],
    hire_date: ['', []],
    km: ['', []],
    observations: ['', []],
    last_itv: ['', []],
    next_itv: ['', []],
    electric: ['', []],
    battery_1_no: ['', []],
    battery_2_no: ['', []],
    clientInvoice_id: ['', []],
    version: [''],

    /** New vehicle fields */
    color_id: [''],
    end_warranty: [''],
    number_warranty: [''],
    gears_type: [''],

    /** Comercial */
    comercialType: [],
    registration_tax: [''],
    price: [''],
    cost: [''],
    budget_id: [],
    serie_vehicle: [],
    num_motor: [''],
    workshop_model: [''],
    seller: [''],
    deposit: [''],
    exp_documentation: [''],
    num_prov: [''],
    brand_id: ['', Validators.required],
    model_id: ['', Validators.required],
    serie_id: [''],
    brand_name: [''],
    model_name: [''],
    serie_name: [''],
    client: [''],
    client_id: [''],
    tax: ['', validatorTax()],
    date: [''],
    doc_prov_num: [''],

    /** New comercial fields */
    /** Vn */
    in_deposit: [''],
    financial_expenses: [''],
    delivery_num: [''],
    documentation_expiration: [''],
    accounting: [''],
    year: [''],
    pdf_vn_vo : [null]
    
  });
}