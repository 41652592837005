import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { EinaDataService } from "../services/EinaMainData/eina-data.service";
import { ViewPath } from "src/app/app-routing.module";
import { SnackService } from "../services/snack.service";
import { ERROR_MODULE_NO_ACTIVE } from "../constants/constants";


@Injectable({
  providedIn: 'root'
})
export class ModuleGuard {

  constructor(private einaDataS: EinaDataService, private router: Router, private snackS: SnackService) { }

  canActivate(next: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      /** Get Eina main user data */
      this.einaDataS.getEinaData().then(res => {
        if (!res) { resolve(false); }
        else {

          let modules: number[] | undefined;
          
          if (next.data && next.data['modules']) {
            modules = next.data['modules'];
          }

          if (modules == undefined || !modules.length) { resolve(true); }
          else {
            let hasModule = res.company.hasSomeModule(...modules);
            if (!hasModule) {
              this.snackS.show(ERROR_MODULE_NO_ACTIVE);
              let url = ViewPath.pageNotFound.path;
              this.router.navigate([url]);
            }
            resolve(hasModule);
          }
        }
      })
    }
    )
  }
}



