<app-page-structure [masterClass]="'client'" pageTitle="Contactos" [filters]="filters" [filter]="f"
    [autocompleteID]="'clients'" searchLabel="Buscar contacto" searchOnboardingClass="onboarding-search-client"
    listTitleText="Listado de contactos"
    noDataCreateNew="Crear un nuevo cliente" [data]="[]"
    [displayedHeader]=" ['Nombre',     'Identificador',     'Email',      'Teléfono', 'Ventas']"
    [displayedColumns]="['name',       'nif',     'email',      'phone',    'c_client']"
    [specialText]="     [completeName, undefined,  undefined,   undefined,     emptySells]"
    [specialClass]="    [undefined,    undefined,   undefined,   undefined,     sellsClass]"
    [cellZize]="        ['big',    undefined,   'big',   undefined,     'small']"
    [preIcon]="[preIconName,    undefined,   undefined,   undefined,     preIconSells]"
    [dataUpload]="features.dataUpload ? v.dataupload : undefined" 
    (onclickRow)="params.go(v.contactDetails, $event.client_id)"
    [quickFilter]="quickFilter"
    [exportExcel]=" {endpoint : e.exportContacts , singular : 'contacto', plural : 'contactos'}"
    [createButton]="
        {
            text : 'Crear contacto' , 
            icon : 'contacts', view : 
            v.createContact, 
            cssClass : 'onboarding-create-client'
        }" >
</app-page-structure>