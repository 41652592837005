<ng-container *ngIf="provider != undefined">
    <mat-form-field appearance="outline" class="w100">
        <mat-label>Buscar producto</mat-label>
        <input [formControl]="fc" matInput placeholder="Buscar producto">
    </mat-form-field>
    <p class="c_t2">💡 Si un producto está en un pedido no finalizado, aparecerá abajo.</p>

    <ng-container *ngIf="loaded">
        <div *ngFor="let p of filtered | slice:0:MAX_RESULTS" class="product-item-list">
            <div mat-menu-item (click)="onClickProduct(p)" class="product-item" [ngClass]="{'added' : entry.containsProduct(p, 'products')}"
                [disabled]="entry.containsProduct(p, 'products')">
                <mat-icon style="min-width: 24px;">{{entry.containsProduct(p, 'products') ? 'done' : 'add'}}</mat-icon>
                <mat-icon smartIcon style="min-width: 24px;">{{p.icon}}</mat-icon>
                {{p.name}} <span class="show_hover fss ml5">Añadir individualmente</span>
            </div>

            <div mat-menu-item class="order-subitem" *ngFor="let o of p.unfinishedOrders"
                [ngClass]="{'subitem-added' : entry.containsOrder(o)}" [disabled]="entry.containsOrder(o)"
                (click)="onClickOrder(o)">
                <mat-icon>{{entry.containsOrder(o) ? 'done' : 'add'}}</mat-icon>
                {{o.order}} <span class="fss c_t2 ml5" *ngIf="o.quantityOf(p) as prodQuant"> ({{p.unitOrUnitsText(prodQuant)}})</span>
                <span class="show_hover fss ml5">Añadir pedido</span>
            </div>
        </div>

        <div *ngIf="!filtered.length" mat-menu-item class="product-item-list">
            Parece que no hay coincidencias 🔎
        </div>
    </ng-container>

    <ng-container *ngIf="!loaded">
        <div mat-menu-item class="product-item-list">
            <mat-progress-bar mode="indeterminate" [color]="'accent'"></mat-progress-bar>
        </div>
    </ng-container>
</ng-container>