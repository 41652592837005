<app-close-dialog-button></app-close-dialog-button>

<p mat-dialog-title>Nuevo anticipo</p>

<div mat-dialog-content>
    <form [formGroup]="form" eForm>
        <app-card-subtitle [first]="true"></app-card-subtitle>
        <app-payment-chips [fc]="form.get('type_payment_id')!"></app-payment-chips>
        <div eForm-section eForm-wrapper>
            <mat-form-field class="example-full-width">
                <mat-label>Importe</mat-label>
                <input formControlName="import" [min]="0.01" matInput type="number" DecimalInput>
                <mat-error *ngIf="form.get('import')?.hasError('error_import')">El importe ingresado supera el saldo
                    pendiente por pagar.</mat-error>
                <mat-error *ngIf="this.form.get('import')?.hasError('min')">El valor debe ser mayor que 0.01</mat-error>
            </mat-form-field>
        </div>
    </form>
</div>

<div mat-dialog-actions align="center">
    <button [mat-dialog-close]="false" mat-button>Cancelar</button>
    <button *ngIf="!this.data.editLastMovement" [disabled]="!form.valid" color="primary" mat-flat-button
        (click)="updateForm()">Guardar</button>
    <button *ngIf="this.data.editLastMovement" [disabled]="!form.valid" color="primary" mat-flat-button
        (click)="editForm()">Guardar</button>
</div>