import { getArrayOf } from "../utils/FunctionUtils";
import { M_Concept } from "./M_Concept";

export class M_ModelDetails {
    id: number;
    model_id: number;
    company_id: number;
    price: number;
    cost: number;
    accounting: number;
    tax: number;
    tax_registration: number;
    campaign_description: string | undefined;
    campaign_accounting: any | undefined;
    created_at: Date | undefined;
    updated_at: Date | undefined;

    /** New fields */
    serie_id: number;
    serie_name: string;
    version: string;
    gears_type: string;
    gears: number;
    cylinder: number;
    percentage_cost: number;
    chassis_prefix: string;
    tax_type: string;
    license_tax: string;
    weight: number;
    intrastat: string;
    description: string;
    year: number;
    concepts : M_Concept[] =[];
    constructor(d: any) {
        this.id = d.id;
        this.model_id = d.model_id;
        this.company_id = d.company_id;
        this.price = d.price;
        this.cost = d.cost;
        this.accounting = d.accounting;
        this.tax = d.tax;
        this.tax_registration = d.tax_registration
        this.campaign_description = d.campaign_description || undefined;
        this.campaign_accounting = d.campaign_accounting || undefined;
        this.created_at = d.created_at ? new Date(d.created_at) : undefined;
        this.updated_at = d.updated_at ? new Date(d.updated_at) : undefined;
        
        /** New fields */
        this.serie_id = d.serie_id;
        this.serie_name = d.serie_name;
        this.version = d.version;
        this.gears_type = d.gears_type;
        this.gears = d.gears;
        this.cylinder = d.cylinder;
        this.percentage_cost = d.percentage_cost;
        this.chassis_prefix = d.chassis_prefix;
        this.tax_type = d.tax_type;
        this.license_tax = d.license_tax;
        this.weight = d.weight;
        this.intrastat = d.intrastat;
        this.description = d.description;
        this.year = d.year;
        if(d.concepts){
            this.concepts = getArrayOf(M_Concept, d.concepts);
        }
    }
}