
<div *ngIf="movementsPay;else notMovements">
    <div *ngFor="let a of movements; let last = last" [class]="'activity activity-0 onboarding-invoice-payment-finish-result'">
    <div class="df">
        <div class="df fdc aic">
            <div class="circle ">
                <mat-icon smartIcon class="c_w bizum_white">{{a.type_payment.icon}}</mat-icon>
            </div>
            <div class="circle-line" [ngClass]="{'last':isTotalPay && last}"></div>
        </div>
        <div class="text-section">
            <p class="title">
                <span class="fw500" money [val]="a.import"></span>
                <span class="c_t2"> | </span>
                <span class="c_t2 size_secondtext ">{{a.type_payment.payment}}
                    <mat-icon *ngIf="canDelete" class="vam delete_style" (click)="onDeleteMovement.emit(a)">delete</mat-icon>
                </span>
            </p>
            <p class="subtitle">
                <span class="c_p size_secondtext" *ngIf="a.isAdvance">Anticipo</span>
                <span  class="c_g2 size_secondtext"  *ngIf="a.isAdvance"> | </span>
                <span class="c_g2 size_secondtext">{{a.created_at.dayMonthYearFormat()}}</span>
            </p>
        </div>
    </div>
    <div class="df" *ngIf="last && showLastCircle">
        <div class="df fdc aic div-last">
            <div class="circle-last"></div>
            <div class="circle-line" [ngClass]="{'last': last}"></div>
        </div>
    </div>
</div>
</div>
<ng-template #notMovements>
    <div class="df jcc aic fdc">
        <h1 class="c_t2">Sin Datos</h1>
        <h1><mat-icon class="c_t2 icon_size" >trending_down</mat-icon></h1>
    </div>

</ng-template>