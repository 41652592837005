<div eForm-section>
    <app-class-searcher *ngIf="!notShowBrand" #csearcherB [masterClass]="brand" class="w100" [width100]="true"
        [form_]="form" [formCname]="'brand_id'" searchPlaceHolder="Marca" [extendedInfo]="false" [required]="true"
        [createData]="undefined" (onSelect)="onBrandSelect($event)" (onRemove)="onBrandRemove($event)"  [disabled]="isDisable">
    </app-class-searcher>

    <app-class-searcher *ngIf="!showSerie" #csearcherS [masterClass]="serie" class="w100" [width100]="true"
        [form_]="form" [formCname]="'serie_id'" searchPlaceHolder="Serie" [extendedInfo]="false"
        [required]="!notRequiredSerie!" [createData]="{
                        text : 'Nueva serie',
                        by : v.createSerie,
                    }" (onSelect)="onSerieSelect($event)" (onRemove)="onSerieRemove($event)" [formInitEmit]="false"  [disabled]="isDisable">
    </app-class-searcher>
    <app-class-searcher *ngIf="!notShowModel" #csearcherM [masterClass]="model" class="w100" [width100]="true"
        [form_]="form" [formCname]="'model_id'" searchPlaceHolder="Modelo" [extendedInfo]="false" [required]="true"
        [createData]="{
                        text : 'Nuevo modelo',
                        by : v.createSerie,
                    }" (onSelect)="onSelectModel($event)" [disabled]="isDisable">
    </app-class-searcher>
    <ng-content></ng-content>
</div>