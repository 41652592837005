<div class="container">
    <app-card-title [first]="true">Generar datos para iCIS</app-card-title>
    <form [formGroup]="formulario" eForm>

        <!-- Ventas -->
        <app-card-subtitle>Ventas</app-card-subtitle>
        <div eForm-wrapper>
            <div eForm-section>
                <mat-form-field>
                    <mat-label>Jefe de ventas</mat-label>
                    <input matInput type="number" formControlName="salesManager" />
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Vendedores</mat-label>
                    <input matInput type="number" formControlName="salesReps" />
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Aprendices</mat-label>
                    <input matInput type="number" formControlName="salesApprentices" />
                </mat-form-field>
            </div>
            <div eForm-section>
                <mat-form-field>
                    <mat-label>Administración</mat-label>
                    <input matInput type="number" formControlName="salesAdmin" />
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Otros</mat-label>
                    <input matInput type="number" formControlName="salesOthers" />
                </mat-form-field>
            </div>
        </div>

        <!-- Mano de Obra -->
        <app-card-subtitle>Mano de obra</app-card-subtitle>
        <div eForm-wrapper>
            <div eForm-section>
                <mat-form-field>
                    <mat-label>Jefe de taller</mat-label>
                    <input matInput type="number" formControlName="workshopManager" />
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Técnicos</mat-label>
                    <input matInput type="number" formControlName="technicians" />
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Aprendices</mat-label>
                    <input matInput type="number" formControlName="workshopApprentices" />
                </mat-form-field>
            </div>
            <div eForm-section>
                <mat-form-field>
                    <mat-label>Administración</mat-label>
                    <input matInput type="number" formControlName="workshopAdmin" />
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Otros</mat-label>
                    <input matInput type="number" formControlName="workshopOthers" />
                </mat-form-field>
            </div>
        </div>

        <!-- Recambios y Accesorios -->
        <app-card-subtitle>Recambios y accesorios</app-card-subtitle>
        <div eForm-wrapper eForm-section>
            <mat-form-field>
                <mat-label>Jefe de recambios</mat-label>
                <input matInput type="number" formControlName="sparePartsManager" />
            </mat-form-field>
            <mat-form-field>
                <mat-label>Vendedor de recambios</mat-label>
                <input matInput type="number" formControlName="sparePartsSalesRep" />
            </mat-form-field>
            <mat-form-field>
                <mat-label>Responsable de tienda</mat-label>
                <input matInput type="number" formControlName="storeManager" />
            </mat-form-field>
            <mat-form-field>
                <mat-label>Aprendices</mat-label>
                <input matInput type="number" formControlName="sparePartsApprentices" />
            </mat-form-field>
            <mat-form-field>
                <mat-label>Administración</mat-label>
                <input matInput type="number" formControlName="sparePartsAdmin" />
            </mat-form-field>
            <mat-form-field>
                <mat-label>Otros</mat-label>
                <input matInput type="number" formControlName="sparePartsOthers" />
            </mat-form-field>
        </div>

        <!-- Administración General -->
        <app-card-subtitle>Administración general</app-card-subtitle>
        <div eForm-wrapper eForm-section>
            <mat-form-field>
                <mat-label>Gerente</mat-label>
                <input matInput type="number" formControlName="generalManager" />
            </mat-form-field>
            <mat-form-field>
                <mat-label>Aprendices</mat-label>
                <input matInput type="number" formControlName="generalApprentices" />
            </mat-form-field>
            <mat-form-field>
                <mat-label>Administración</mat-label>
                <input matInput type="number" formControlName="generalAdmin" />
            </mat-form-field>
            <mat-form-field>
                <mat-label>Otros</mat-label>
                <input matInput type="number" formControlName="generalOthers" />
            </mat-form-field>
        </div>

        <!-- Fecha -->
        <app-card-subtitle>Fecha hasta período</app-card-subtitle>
        <div eForm-wrapper eForm-section>
            <mat-form-field appearance="outline">
                <mat-label>Mes</mat-label>
                <mat-select formControlName="month">
                    <mat-option *ngFor="let month of months" [value]="month.num">{{month.month}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Año</mat-label>
                <mat-select formControlName="year">
                    <mat-option [value]="year" *ngFor="let year of years">{{year}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="mt10"></div>

        <div class="df jcc gap10">
            <button mat-raised-button color="primary" (click)="generate()">Generar</button>
            <button mat-raised-button color="primary" (click)="generate()">Guardar</button>
        </div>

    </form>
</div>