import { IPageStructureItem } from "src/app/interfaces/IPageStructureItem";
import { match } from "src/app/services/search.service";
import { M_Brand } from "../M_Brand";
import { M_AccountingGroup } from "../AccountingGroups/M_AccountingGroup";

export class M_Color implements IPageStructureItem {
    id:number;
    code: string;
    name:string;
    brand_id: number | undefined;
    brand_name: string;
    serie_id: number  | undefined;
    serie_name: string;
    version: string;
    model_id: number | undefined;
    model_name: string;
    percentage_cost: number;
    price : number | undefined;
    cost: number;
    accounting: M_AccountingGroup | undefined;
    accounting_id: number;
    effective_date: Date;
    manufacturer_id: number;
    brand: M_Brand | undefined;
    type: string | number | undefined;
    constructor(d: any) {
        this.id = d.id;
        this.code = d.code;
        this.name = d.name;
        if (typeof d.brand_id === 'string') {
            const brandIdAsNumber = Number(d.brand_id);
            if (!isNaN(brandIdAsNumber)) {
                this.brand_id = brandIdAsNumber; 
            } else {
              this.brand_id = undefined;
            }
        }
        if(typeof d.model_id !='number'){
            if (typeof d.model_id === 'string') {
                const modelIdAsNumber = Number(d.model_id);
                if (!isNaN(modelIdAsNumber)) {
                    this.model_id = modelIdAsNumber; 
                } else {
                  this.model_id = undefined;
                }
            }
        }else{
            this.model_id = d.model_id;
        }
        if (typeof d.serie_id === 'string') {
            const serieIdAsNumber = Number(d.serie_id);
            if (!isNaN(serieIdAsNumber)) {
                this.serie_id = serieIdAsNumber;
            } else {
              this.serie_id = undefined;
            }
        }
        this.brand_name = d.brand_name;
        this.serie_name = d.serie_name;
        this.version = d.version;
        this.accounting_id = d.accounting_id;
        this.model_name = d.model_name;
        this.percentage_cost = d.percentage_cost;
        this.cost = d.cost;
        this.price = d.price;
        this.effective_date = new Date(d.effective_date);
        this.manufacturer_id = d.manufacturer_id;
        if(typeof d.type ==="string"){
            if(d.type ==='Normal'){
                this.type = 0;
            }
            else if(d.type ==="Metalizada"){
                this.type = 1;
            }
            else{
                this.type = 0;
            }
        }
        if(d.brand){
            this.brand = new M_Brand(d.brand);
        }
        if(d.acc_groups){
            this.accounting = new M_AccountingGroup(d.acc_groups);
        }
    }
    defaultSearchFilter(text: string): boolean {
        return match(text, this.code, this.name, this.brand_name);
    }
    get itemId(): number | undefined {
        return this.id;
    }

}
