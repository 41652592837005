import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_Contact } from 'src/app/models/M_Contact';
import { ParamsService } from 'src/app/services/params.service';
import { SubscriptionService } from 'src/app/services/EinaMainData/subscription.service';
import { ModifyObservationsComponent } from './modify-observations/modify-observations.component';
import { ViewPath } from 'src/app/app-routing.module';
import { feature } from 'src/app/utils/FeaturesController';
import { AdvancesClientDetailsDialogComponent } from './advances-client-details-dialog/advances-client-details-dialog.component';
import { PendingPaymentDialogComponent } from './pending-payment-dialog/pending-payment-dialog.component';
import { M_Company } from 'src/app/models/M_Company';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';
import { ParameterStateComponent } from 'src/app/components/parameter-state/parameter-state.component';
import { getAccentColor, getPrimaryColor, getWarnColor } from 'src/app/utils/FunctionUtils';
import { FileUploadComponent } from 'src/app/components/file-upload/file-upload.component';
import { RouterService } from 'src/app/services/router.service';
import { CustomFile } from 'src/app/custom-classes/CustomFile';
import { DeleteService } from 'src/app/services/delete.service';
import { ManualReservationComponent, manualReservationData } from 'src/app/components/manual-reservation/manual-reservation.component';
import { RolesEnum } from 'src/app/enums/RolesEnum';
import { ReservesProductTableComponent } from 'src/app/components/reserves-product-table/reserves-product-table.component';
import { M_Reservation } from 'src/app/models/M_Reservation';
import { IMassiveType, MassiveInvoiceComponent } from 'src/app/components/massive-invoice/massive-invoice.component';

@Component({
  selector: 'app-client-details',
  templateUrl: './client-details.component.html',
  styleUrls: ['./client-details.component.css']
})
export class ClientDetailsComponent extends ParameterStateComponent implements OnInit {
  primary = getPrimaryColor;
  warn = getWarnColor;
  accent = getAccentColor;
  feature = feature;
  loaded: boolean = false;
  c?: M_Contact;
  v = ViewPath;
  company: M_Company | undefined;
  selectedPayment: any;
  haveOrders: number | undefined
  R = RolesEnum;
  @ViewChild("dniFrontComponent") dniFrontComponent?: FileUploadComponent;
  @ViewChild("dniBackComponent") dniBackComponent?: FileUploadComponent;
  @ViewChild('documentation') documentationComponent!: FileUploadComponent;
  @ViewChild(ReservesProductTableComponent) reservesComponent!: ReservesProductTableComponent;


  constructor(route: ActivatedRoute, public override routerS: RouterService,
    private apiS: ApiService, public companyS: CompanyService, public params: ParamsService, private d: MatDialog,
    private chdRef: ChangeDetectorRef, public subS: SubscriptionService, public deleteS: DeleteService) {
    super(routerS, route, ["client"]);
  }

  ngOnInit(): void {}

  override onState(state: any) {
    if (state instanceof M_Contact) {
      this.c = new M_Contact(state); //--> Es necesario crear un objeto nuevo. En caso contrario, los vehiculos pierden sus funciones de classe.
      this.chdRef.detectChanges();
      this.loaded = true;
    }
  }

  override onParam(_k: string, v: any) {
    this.apiS.getClientById(v).then(res => {
      if (res == undefined) {
        this.c = undefined;
      }
      if (res instanceof M_Contact) {
        this.c = res;
        this.haveOrders = this.c?.orders?.length;
        this.fillImages(this.c);
      }
      this.loaded = true;
      this.chdRef.detectChanges();
    })

  }

  emptyVehicles() {
    if (this.c != undefined) {
      return this.c.vehicles.length == 0;
    }
    else {
      return false;
    }
  }

  get getClientSince() {
    if (this.c) {
      let d = this.c.createdAt!.differenceWith(new Date());
      return d.breakdown.years ? this.formatYear(d.breakdown.years) + " " + this.formatMonth(d.breakdown.months - (12 * d.breakdown.years)) :
        d.breakdown.months ? this.formatMonth(d.breakdown.months) + " " + this.formatDays(d.breakdown.days - (31 * d.breakdown.months)) :
          d.breakdown.days ? this.formatDays(d.breakdown.days) : 'Hoy';
    }
    return "";
  }

  formatDays(day: number) {
    return day <= 0 ? '' : day + " " + (day > 1 ? "días" : "día")
  }

  formatMonth(month: number) {
    return month <= 0 ? '' : month + " " + (month > 1 ? "meses" : "mes")
  }

  formatYear(years: number) {
    return years <= 0 ? '' : years + " " + (years > 1 ? "años" : "año")
  }

  fillImages(c: M_Contact) {
    if (c.hasDniFront()) {
      this.dniFrontComponent!.setImageFromDatabase(c.dni_front!)
    }
    if (c.hasDniBack()) {
      this.dniBackComponent!.setImageFromDatabase(c.dni_back!)
    }

    if (c.documentation) {
      this.documentationComponent.setImagesFromDatabase(c.documentation)
    }
  }

  modifyObservations() {
    if (this.c) {
      this.d.open(ModifyObservationsComponent, { data: this.c.observations }).beforeClosed().subscribe(val => {
        if (val != undefined) {
          let clientCopy = new M_Contact(this.c);
          clientCopy.observations = val;
          this.apiS.client.updateClient(clientCopy).then(_res => {
            this.c!.observations = val;
          });
        }
      })
    }
  }

  dniUpload(c: CustomFile, side: "dni_front" | "dni_back") {
    this.apiS.uploadImage(c, side, ["client_id", this.c!.client_id]).then(_res => {
    })
  }

  uploadDocumentImages(c: CustomFile) {
    this.apiS.uploadImage(c, "docu_img", ["client_id", this.c!.client_id]).then(res => {
      if (res.image_id) {
        c.database_id = res.image_id;
      }
    })
  }

  removeImage(c: CustomFile) {
    if (c.database_id) {
      this.apiS.deleteImage(c.database_id);
    }
  }

  openManualReservation(c: M_Contact) {
    this.d.open<ManualReservationComponent, manualReservationData>(ManualReservationComponent, { data: { client: c }, autoFocus: false })
      .afterClosed().subscribe(res => {
        if (res instanceof M_Reservation) {
          this.reservesComponent.addReserve(res);
        }
      });
  }

  openPendingPaymentDialog() {
    this.d.open(PendingPaymentDialogComponent, { data: this.c!, autoFocus: false })
  }

  openFlotasOrDialog() {
    this.d.open<MassiveInvoiceComponent, IMassiveType>(MassiveInvoiceComponent, {
      data: {
        type: "or",
        client_id: this.c?.client_id,
      },
      autoFocus: false
    }).afterClosed().subscribe(res => {
      if (res == true){
        this.routerS.refresh();
      }
    })
  }

  openAdvancesDialog() {
    this.d.open(AdvancesClientDetailsDialogComponent, { data: this.c!, autoFocus: false })
  }

}
