<div class='advanced-details-container-stockmodule'>
    <div *ngIf="product">
        <app-go-back class="back" [text]="product.name" (click)="goProduct()" [show]="loaded"></app-go-back>
    </div>
    <app-card [contentLoaded]="loaded">
        <div [ngClass]="{'dn' : product == undefined}">
            <app-card-title>Movimiento manual </app-card-title>

            <ng-container *ngIf="product">
                <app-card-subtitle [first]="true" [noMarginBottom]="true">
                    {{product.name}}
                </app-card-subtitle>

                <p class="c_t2 nmb">Ref: {{product.reference}}</p>

                <p class="c_t2">
                    <!-- SIN modulo recambios -->
                    <span *ngIf="!companyS.recambiosModule">
                        <span decimal [val]="product.stock < 0 ? 0 : product.stock"></span>
                        {{product.stock == 1 ? 'existencia' : 'existencias'}}
                    </span>

                    <!-- CON modulo recambios -->
                    <span *ngIf="companyS.recambiosModule">
                        <span decimal [val]="product.totalDisp < 0 ? 0 : product.totalDisp"></span>
                        {{product.totalDisp == 1 ? 'existencia' : 'existencias'}}
                    </span>
                </p>


            </ng-container>

            <div class="df fdc jcc w100">
                <mat-tab-group color="primary">

                    <!-- ENTRANCE -->
                    <mat-tab>
                        <form [formGroup]="form_in" eForm>
                            <div class="mt10" eForm-wrapper>
                                <ng-template mat-tab-label class="vac df fdc jcc w100">
                                    <mat-icon class="mr10">save_alt</mat-icon>
                                    Entrada
                                </ng-template>

                                <div eForm-section>
                                    <app-select-location [ngClass]="{'dn_i' : !product?.control_stock}" *ngIf="stores"
                                        [stores]="stores" [control]="form_in.get('location_id')!">
                                    </app-select-location>
                                </div>

                                <div eForm-section>
                                    <mat-form-field>
                                        <mat-label>Motivo de movimiento</mat-label>
                                        <mat-select formControlName="reason">
                                            <mat-option value="Regularización de stock">Regularización de
                                                stock</mat-option>
                                            <mat-option value="Otros">Otros</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div eForm-section>
                                    <app-add-remove #ar [label]="'Cantidad'" [showSaveIcon]="false"
                                        [total]="this.product ? this.product.stock : 0" [form]="form_in"
                                        [forceMinMaxValue]="true" [minValue]="1" [initValue]="1"
                                        [addRemoveOutside]="false" [formCName]="'quantity'"
                                        [hint]="'Total: ' + import(ar)">
                                    </app-add-remove>
                                    <mat-form-field [formCName]="'precioCoste'">
                                        <mat-label>Coste</mat-label>
                                        <input class="tac" matInput formControlName="precioCoste" type="number"
                                            decimal />
                                    </mat-form-field>
                                </div>
                            </div>
                        </form>
                    </mat-tab>


                    <!-- EXIT -->
                    <mat-tab>
                        <form [formGroup]="form_out">
                            <div class="mt10" eForm-wrapper>
                                <div class="df fdc jcc w100">
                                    <ng-template mat-tab-label class="vac df fdc jcc w100">
                                        <mat-icon class="mr10">receipt_long</mat-icon>
                                        Salida
                                    </ng-template>
                                    <div class="df jcc w100 mt20" [ngClass]="{'dn_i' : !product?.control_stock}">
                                        <app-select-location #location_form_out [product]="product" class="w100"
                                            [control]="form_out.get('location_id')!"
                                            (onSelectLocation)="patchQuantity($event, form_out)">
                                        </app-select-location>
                                    </div>

                                    <div class="df jcc w100">
                                        <mat-form-field class="w100">
                                            <mat-label>Motivo de movimiento</mat-label>
                                            <mat-select formControlName="reason">
                                                <mat-option value="Regularización de stock">Regularización de
                                                    stock</mat-option>
                                                <mat-option value="Dar de baja producto">Dar de baja
                                                    producto</mat-option>
                                                <mat-option value="Otros">Otros</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="w100">
                                        <app-add-remove [label]="'Cantidad'" [showSaveIcon]="false"
                                            [forceMinMaxValue]="true" [total]="this.product ? this.product.stock : 0"
                                            [minValue]="1" [maxValue]="location_form_out.disp" [initValue]="1"
                                            [form]="form_out" [addRemoveOutside]="false"
                                            [formCName]="'quantity'"></app-add-remove>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </mat-tab>


                    <!-- INTERN -->
                    <mat-tab *ngIf="product?.control_stock">
                        <form [formGroup]="form_intern">
                            <ng-template mat-tab-label class="vac df fdc jcc w100">
                                <mat-icon class="mr10">warehouse</mat-icon>
                                Interno
                            </ng-template>

                            <div class="mt10" eForm-wrapper>
                                <div>
                                    <app-select-location #location_from [product]="product" class="w100"
                                        [label]="'Origen'" [control]="form_intern.get('location_from')!"
                                        (onSelectLocation)="patchQuantity($event, form_intern)"></app-select-location>
                                </div>
                                <div>
                                    <app-select-location [product]="product" class="w100" [label]="'Destino'"
                                        [control]="form_intern.get('location_to')!"></app-select-location>
                                </div>
                                <div>
                                    <app-add-remove [label]="'Cantidad'" [showSaveIcon]="false"
                                        [forceMinMaxValue]="true" [total]="this.product ? this.product.stock : 0"
                                        [minValue]="0" [maxValue]="location_from.disp" [initValue]="1"
                                        [form]="form_intern" [addRemoveOutside]="false"
                                        [formCName]="'quantity'"></app-add-remove>
                                </div>
                            </div>
                        </form>
                    </mat-tab>

                </mat-tab-group>

            </div>

            <!-- Comment -->
            <div [formGroup]="currentForm">
                <app-card-subtitle>Comentario</app-card-subtitle>
                <div form-wrapper>
                    <mat-form-field class="w100 df jcc">
                        <mat-label>Deja un comentario</mat-label>
                        <textarea class="comment" matInput placeholder="Ex. Entrada manual para control de inventario"
                            formControlName="comment"></textarea>
                    </mat-form-field>
                </div>
            </div>

            <div class="df jcc mt10">
                <button subscribed-user mat-flat-button color="primary" (click)="onSave()"
                    [disabled]="!currentFormValid">
                    <span>Guardar</span>
                </button>
            </div>
        </div>

    </app-card>
</div>