<app-close-dialog-button></app-close-dialog-button>

<!-- Mat dialog title-->
<p mat-dialog-title>Generada OR de cargo interno</p>

<!-- Dialog content -->
<div mat-dialog-content>
    <p> <span>Se ha generado la </span>
        <span class="fw600">{{data.title_id}} <app-or-type-label class="type-label-generated-interno" [tipo]="[c_i]" [wrapOn]="1" [showText]="false"></app-or-type-label></span>
        <span>con los productos adicionales seleccionados.</span>
    </p>
</div>

<!-- Mat dialog actions -->
<div mat-dialog-actions>
    <button mat-button mat-dialog-close>Cancelar</button>
    <button color="primary" mat-flat-button (click)="goAction()">Ver OR</button>
</div>