import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_User } from 'src/app/models/M_User';
import { SubscriptionService } from 'src/app/services/EinaMainData/subscription.service';
import { filter } from 'src/app/custom-classes/MasterFilter';
import { ParamsService } from 'src/app/services/params.service';
import { PageStructureComponent } from 'src/app/components/page-structure/page-structure.component';
import { LayoutSendEmailComponent } from 'src/app/components/layout-send-email/layout-send-email.component';
import { getPrimaryColor, getWarnColor } from 'src/app/utils/FunctionUtils';
import { RouterService } from 'src/app/services/router.service';
import { SessionService } from 'src/app/services/session.service';
import { CentersFilter, Filter } from 'src/app/custom-classes/Filter';
import { EinaDataService } from 'src/app/services/EinaMainData/eina-data.service';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TeamComponent implements OnInit {
  @ViewChild(PageStructureComponent) ps!: PageStructureComponent<M_User>;
  @ViewChild(LayoutSendEmailComponent) sendEmail?: LayoutSendEmailComponent;
  v = ViewPath;
  primary = getPrimaryColor;
  warn = getWarnColor;
  f = filter;
  filters: Filter[] = [new CentersFilter(this.einaDataS)]
  loading = false;

  constructor(public routerS: RouterService, private apiS: ApiService,
    public sessionS: SessionService, private chdRef: ChangeDetectorRef, public subS: SubscriptionService,
    public params: ParamsService, private einaDataS: EinaDataService) {
    this.getAllusers();

    this.subS.onChangeSubscription.subscribe(_v => {
      this.chdRef.detectChanges();
    })
  }

  ngOnInit(): void { }

  getAllusers() {
    this.loading = true;
    this.apiS.users(true).then(resp => {
      if (this.ps) {
        this.ps.initTable(resp);
        this.loading = false;
      }
    });
  }

  get circleTooltip() {
    if (this.subS.status) {
      if (!this.subS.hasProPermissions) {
        return "Se necesita el plan PRO para poder crear usuarios"
      }
    }
    return undefined;
  }

  async resendConfirm() {
    this.ps.selected!.confirm_sent = true;
    return this.apiS.reSendConfirmation(this.ps.selected!.id);
  }

  roleName(u: M_User) {
    return u.roleName;
  }

  center(u: M_User) {
    return u.center?.name;
  }

}
