
<app-page-structure #ps [pageTitle]="'Series'" [masterClass]="undefined" [autocompleteID]="'series'"
    searchLabel="Buscar serie" listTitleText="Listado de series" [data]="[]"
    [displayedHeader]=" ['Cod.Serie', 'Nombre','Marca','Grp.Contable','Última modificación']"
    [displayedColumns]="['serie','name','brand_name','codeAccounting','updated_at']"
    [specialText]="[undefined,undefined,undefined,codeAccounting,undefined]"
    [quickFilter]="quickFilter" [filter]="f" [filters]="filtersToShow"
    (onclickRow)="onDialogRef($event)"
    [goBackCustom]="{
        text: 'Listado vehículos',
        view: v.garage
    }"
    (onClickCreateButton)="onDialogRef($event)"
    [createButton]="
        {
            text : 'Crear serie' , 
            icon : 'barcode_reader',
        }" [quickFilter]="quickFilter">
        
</app-page-structure>
