<form eForm class="mb20">
    <div eForm-wrapper>
        <div eForm-section>
            <ng-container *ngIf="hasTimeRange">
                <mat-form-field appearance="outline">
                    <mat-label>Año (1)</mat-label>
                    <mat-select [formControl]="year1">
                        <mat-option [value]="year" *ngFor="let year of years">{{year}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Mes (1)<option value=""></option></mat-label>
                    <mat-select [formControl]="month1">
                        <mat-option *ngFor="let month of months" [value]="month.num">{{month.month}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>

            <ng-container *ngIf="comparable">
                <mat-form-field appearance="outline">
                    <mat-label>Año (2)</mat-label>
                    <mat-select [formControl]="year2">
                        <mat-option [value]="null">--</mat-option>
                        <mat-option [value]="year" *ngFor="let year of years">{{year}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Mes (2)<option value=""></option></mat-label>
                    <mat-select [formControl]="month2">
                        <mat-option [value]="null">--</mat-option>
                        <mat-option *ngFor="let month of months" [value]="month.num">{{month.month}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>
        </div>
        <div eForm-section *ngIf="showUser">
            <app-class-searcher [required]="false" [searchPlaceHolder]="'Buscar operario'" [cssClass]="'mw400'" [masterClass]="USER" [createData]="undefined"></app-class-searcher>
        </div>
        <div class="df jcfe">
            <button style="width: 25%;" mat-flat-button color="primary" [disabled]="isDisabled" (click)="emitFilters()">
                Aplicar filtros
            </button>
        </div>
    </div>
</form>