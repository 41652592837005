<app-close-dialog-button></app-close-dialog-button>

<p mat-dialog-title mat-dialog-title-img>
    <img src="./assets/img/modules/integrations.jpg">
</p>

<div mat-dialog-content>
    <mat-stepper linear #stepper class="hidden-stepper-header">
        <mat-step>
            <p>
                Las integraciones en Eina son funcionalidades adicionales que se pueden activar para complementar las
                capacidades del sistema, sin alterar su funcionamiento principal ni el flujo de trabajo del taller.
            </p>
        </mat-step>
        <mat-step>
            <p>
                A diferencia de los módulos, que pueden cambiar la interfaz o las opciones básicas de Eina, estas
                integraciones
                funcionan como herramientas opcionales para cubrir necesidades específicas sin interferir en el uso
                cotidiano
                del sistema.
            </p>
        </mat-step>
    </mat-stepper>
    <app-stepper-step-indicator [stepper]="stepper"></app-stepper-step-indicator>
</div>
<div mat-dialog-actions>
    <button color="whtie" mat-flat-button cdkFocusInitial (click)="stepper.previous()"
        *ngIf="stepper.selectedIndex != 0">
        Atrás
    </button>
    <button color="primary" mat-flat-button cdkFocusInitial
        (click)="stepper.selectedIndex == stepper.steps.length -1 ? dRef.close() : {}; stepper.next();">
        {{stepper.selectedIndex == stepper.steps.length -1 ? 'Genial' : 'Siguiente' }}
    </button>
</div>