import { M_Smpt } from "./M_Smpt";
import { M_TemplateField } from "./M_TemplateField";
import { VehicleType } from "../enums/VehicleType";
import { DEFAULT_IVA } from "../constants/constants";
import { ModuleFactory } from "./Modules/Factory/ModuleFactory";
import { ModulesEnum } from "../enums/ModulesEnum";
import { getArrayOf } from "../utils/FunctionUtils";
import { T_Footers } from "../views/settings/company-tab/documentation-config/documentation-config.component";
import { M_Center } from "./M_Center";
import { BaseModule } from "./Modules/Factory/BaseModule";
import { M_StoreHouse } from "./M_StoreHouse";

export class M_Company {
    logo: string | undefined;
    email: string;
    phone: string;
    name: string;
    cif: string;
    province: string;
    address: string;
    address2: string | undefined;
    city: number;
    zip: string;
    ccaa: number;
    primary_color: string | undefined;
    secondary_color: string | undefined;
    footer: string | undefined;
    footer2: string | undefined;
    footer3: string | undefined;
    footer4: string | undefined;
    footer5: string | undefined;
    default_vehicle: VehicleType = 1;
    smtp: M_Smpt | undefined;
    iva: number;
    template_fields: M_TemplateField[] = [];
    id: number | undefined;
    first_login: boolean = false;
    missing_info: boolean = true;
    modules: BaseModule[] = [];
    centers: M_Center[] = [];

    constructor(d: any) {
        this.id = d.id
        this.logo = d.logo;
        this.name = d.name;
        this.email = d.email;
        this.phone = d.phone;
        this.cif = d.cif;
        this.address = d.address;
        this.address2 = d.address2 ? d.address2 : undefined;
        this.city = d.city;
        this.zip = d.zip;
        this.ccaa = d.ccaa;
        this.province = d.province;
        this.primary_color = d.primary_color;
        this.secondary_color = d.secondary_color;
        this.footer = d.footer;
        this.footer2 = d.footer2;
        this.footer3 = d.footer3;
        this.footer4 = d.footer4;
        this.footer5 = d.footer5;
        this.default_vehicle = d.default_vehicle ? d.default_vehicle : VehicleType.car;
        this.smtp = d.smtp ? new M_Smpt(d.smtp) : undefined;
        this.iva = d.iva != undefined ? d.iva : DEFAULT_IVA;
        this.centers = getArrayOf(M_Center, d.centers)
        this.template_fields = getArrayOf(M_TemplateField, d.template_fields)
        this.first_login = d.first_login ? d.first_login : false;
        this.missing_info = this.isMissingInfo();

        let mods = d.modules;
        if (mods && Array.isArray(mods)) {
            mods.forEach(m => {
                if (m.pivot && m.pivot.mod_block) { return; } //Well, backend things
                this.modules.push(ModuleFactory.createModule(m));
            })
        }
    }

    getMaxAppointments(): number | undefined {
        return undefined;
    }

    getAllFootersByOrder(): T_Footers {
        return {
            footer2: this.footer2, // budget
            footer3: this.footer3, // ra
            footer4: this.footer4, // or
            footer5: this.footer5, // invoice
        }
    }

    isMissingInfo(): boolean {
        return !this.name || !this.cif || !this.address || !this.city || !this.zip || !this.email || !this.phone;
    }

    get recambios(): boolean {
        return this.modules.find(module => module.id == ModulesEnum.RECAMBIOS) != undefined;
    }

    get vnvo(): boolean {
        return this.modules.find(module => module.id == ModulesEnum.VNVO) != undefined;
    }

    get mtr(): boolean {
        return this.modules.find(module => module.id == ModulesEnum.MTR) != undefined;
    }

    hasModule(m: ModulesEnum) {
        return this.modules.find(module => module.id == m) != undefined;
    }

    hasSomeModule(...m: ModulesEnum[]) {
        return m.some(module => { return this.hasModule(module) });
    }

    addModule(m: ModulesEnum) {
        if (!this.hasModule(m)) {
            this.modules.push(ModuleFactory.createModule({ id: m }))
        }
    }

    removeModule(m: ModulesEnum) {
        let toRemove = this.modules.find(module => module.id == m);
        if (toRemove) { this.modules.removeElement(toRemove); }
    }

    toggleModule(m: ModulesEnum) {
        if (!this.hasModule(m)) {
            this.addModule(m)
        }
        else {
            this.removeModule(m);
        }
    }

    removeCenter(c: M_Center) {
        let find = this.centers.find(center => center.id == c.id);
        if (find) { 
            console.log("Removing", c.name, "center")
            this.centers.removeElement(find) 
        };
    }

    removeStoreHouse(storehouse: M_StoreHouse) {
        this.centers.forEach(c => {
            let find = c.storehouses.findIndex(sh => sh.id == storehouse.id)
            if (find != -1) {
                c.storehouses.removeIndex(find)
            }
        })
    }

    addOrUpdateCenter(c: M_Center) {
        let find = this.centers.findIndex(center => center.id == c.id);
        if (find == -1) {
            console.log("Adding", c.name, "center")
            this.centers.push(c);
        }
        else {
            console.log("Updating", c.name, "center")
            this.centers[find] = c;
        }
    }
}