import { Component, Input } from '@angular/core';
import { M_Contact } from '../../models/M_Contact';
import { M_GroupTask } from '../../models/M_GroupTask';
import { M_Product } from '../../models/Products/M_Product';
import { M_CustomProduct } from '../../models/Products/M_CustomProduct';
import { M_TotalsBreakdown } from '../../services/totals-calculator.service';

@Component({
  selector: 'app-action-totals',
  templateUrl: './action-totals.component.html',
  styleUrls: ['./action-totals.component.css']
})
export class ActionTotalsComponent {

  constructor() { }

  @Input({ required: true }) groups: M_GroupTask[] | M_GroupTask | undefined;
  @Input() invoiceClient?: M_Contact;
  @Input() responsive?: boolean = false;



  get totalsBreakdown() {
    if (!this.groups) { return new M_TotalsBreakdown([], undefined) }
    else if (Array.isArray(this.groups)) {
      let isInterno = this.groups.some(g => g.type.interno);
      let products: (M_Product | M_CustomProduct)[] = []
      this.groups.forEach(g => {
        products.push(...g.getAllProducts());
      })
      return new M_TotalsBreakdown(products, this.invoiceClient, isInterno)
    }
    else {
      return this.groups.getTotalBreakdown(this.groups.invoice_contact || this.invoiceClient)
    }
  }

  get showTotals() {
    if (!this.groups) { return false }
    else if (Array.isArray(this.groups)) { return this.groups.some(g => g.hasTasks()) }
    else { return this.groups.hasTasks() }
  }

}
