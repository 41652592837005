<ng-container *ngIf="ps.createButton">
    <div *ngIf="!ps.createButton.loading; else circleLoad" [matTooltip]="ps.createButton.tooltip ? ps.createButton.tooltip : ''">

        <!-- Desktop flat button -->
        <button [disabled]="ps.createButton.disabled" mat-flat-button color="primary"
            [class]="'page-structure-create-button desktop ' + (ps.createButton.cssClass ? ps.createButton.cssClass : '')"
            (click)="ps.onClickAdd()">
            <mat-icon smartIcon *ngIf="ps.createButton"
                [ngClass]="{'c_w' : !ps.createButton.disabled}">{{ps.createButton.icon}}</mat-icon>
            {{ps.createButton.text}}
        </button>

        <!-- Phone circular button -->
        <button [disabled]="ps.createButton.disabled" mat-fab color="primary"
            [class]="'page-structure-create-button mobile ' + (ps.createButton.cssClass ? ps.createButton.cssClass : '') "
            (click)="ps.onClickAdd()">
            <mat-icon smartIcon *ngIf="ps.createButton" class="c_w">{{ps.createButton.icon}}</mat-icon>
            <mat-icon class="add_absolute">add</mat-icon>
        </button>
    </div>

    <ng-template #circleLoad>

        <!-- Desktop flat skeleton button -->
        <div class="page-structure-create-button skeleton desktop">
            <app-skeleton [width]="150" [height]="36"></app-skeleton>
        </div>

        <!-- Phone circular skeleton button -->
        <div class="page-structure-create-button skeleton mobile">
            <app-skeleton [circle]="true" [width]="56" [height]="56"></app-skeleton>
        </div>

    </ng-template>

</ng-container>