import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-icis',
  templateUrl: './icis.component.html',
  styleUrls: ['./icis.component.css']
})
export class IcisComponent {
  formulario: FormGroup;
  years = this.generateYears(2000, new Date().getFullYear())
  months = [
    { num: 1, month: 'Enero' },
    { num: 2, month: 'Febrero' },
    { num: 3, month: 'Marzo' },
    { num: 4, month: 'Abril' },
    { num: 5, month: 'Mayo' },
    { num: 6, month: 'Junio' },
    { num: 7, month: 'Julio' },
    { num: 8, month: 'Agosto' },
    { num: 9, month: 'Septiembre' },
    { num: 10, month: 'Octubre' },
    { num: 11, month: 'Noviembre' },
    { num: 12, month: 'Diciembre' }
  ];

  constructor(private fb: FormBuilder) {
    this.formulario = this.fb.group({
      salesManager: [0],
      salesReps: [0],
      salesApprentices: [0],
      salesAdmin: [0],
      salesOthers: [0],
    
      // Workshop
      workshopManager: [1],
      technicians: [0],
      workshopApprentices: [0],
      workshopAdmin: [0],
      workshopOthers: [0],
    
      // Spare Parts
      sparePartsManager: [0],
      sparePartsSalesRep: [0],
      storeManager: [0],
      sparePartsApprentices: [0],
      sparePartsAdmin: [0],
      sparePartsOthers: [0],
    
      // General Administration
      generalManager: [0],
      generalApprentices: [0],
      generalAdmin: [0],
      generalOthers: [0],
    
      // Date
      month: [new Date().getMonth() + 1],
      year: [new Date().getFullYear()]
    });
  }

  generate() {
    console.log('Datos generados:', this.formulario.value);
  }

  guardar() {
    console.log('Datos generados:', this.formulario.value);
  }

  generateYears(startYear: number, endYear: number): number[] {
    const years = [];
    for (let year = endYear; year >= startYear; year--) { years.push(year); }
    return years;
  }

}
