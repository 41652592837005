<!-- Optional close button-->
<app-close-dialog-button></app-close-dialog-button>

<!-- Mat dialog title-->
<p mat-dialog-title>Faltas</p>

<!-- Dialog content -->
<div mat-dialog-content>
    <app-fault-product-table (onGoFault)="closeDialog()" [product]="p" [faults]="p.unfinishedFaults"></app-fault-product-table>
    <button mat-stroked-button (click)="openManualFault()">
        <mat-icon>add</mat-icon>
        Añadir falta
    </button>
</div>