import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/Api/api.service';
import { sumTimes } from 'src/app/utils/TimeFunctions';
import { M_Action } from 'src/app/models/M_Action';
import { M_ORTime } from 'src/app/models/M_ORTime';
import { M_User } from 'src/app/models/M_User';
import { ConfirmData, ConfirmDialogService } from 'src/app/services/confirm-dialog.service';
import { UserService } from 'src/app/services/EinaMainData/user.service';

@Component({
  selector: 'app-history-time-or-dialog',
  templateUrl: './history-time-or-dialog.component.html',
  styleUrls: ['./history-time-or-dialog.component.css']
})
export class HistoryTimeOrDialogComponent implements OnInit {

  @Input({ required: true }) or!: M_Action;
  @Input() workers?: M_User[] = [];
  @Input() canRemove?: boolean | undefined;

  constructor(
    public dialogRef: MatDialogRef<HistoryTimeOrDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: {},
    private apiS: ApiService, private confirmD: ConfirmDialogService, private userS: UserService) { }

  ngOnInit(): void { }

  canDelete(user_id: number) {
    let isMe = this.userS.isMe(user_id);
    return (this.userS.userIsAdminOrAdviser || isMe) && this.canRemove;
  }

  getUserByTime(time: M_ORTime) {
    if (!this.workers) { return undefined; }
    return this.workers.find(w => w.id == time.user_id)
  }

  deleteTimeLine(id: number) {
    var v: ConfirmData = {
      title: "Eliminar registro",
      body: "¿Seguro que quieres eliminar este registro del historial?",
      showCancel: true,
      type: "danger"
    }

    this.confirmD.show(v).afterClosed().subscribe(res => {
      if (res == true) {
        this.apiS.deleteTimeLine(id)
          .then(res => {
            this.or.removeTime(id);
          })
          .catch(e => {
            this.dialogRef.close(new Error(e))
          })
      }
    })
  }

  getTotalTime() {
    if (this.or) {
      return sumTimes(this.or.timers.map(timer => timer.elapsed), { prefix: "short" })
    }
    return undefined;
  }

  get someTimeFinished() {
    return this.or && this.or.timers.some(t => t.end_time != undefined);
  }
}
