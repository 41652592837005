import { Component, ViewChild } from '@angular/core';
import { SubscriptionService } from '../../services/EinaMainData/subscription.service';
import { ParamsService } from '../../services/params.service';
import { ViewPath } from '../../app-routing.module';
import { CompanyService } from '../../services/EinaMainData/company.service';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_Concept } from '../../models/M_Concept';
import { Filter } from 'src/app/custom-classes/Filter';
import { getAccentColor, getPrimaryColor, getWarnColor } from 'src/app/utils/FunctionUtils';
import { PageStructureComponent } from '../page-structure/page-structure.component';
import { RouterService } from 'src/app/services/router.service';

@Component({
  selector: 'app-concepts',
  templateUrl: './concepts.component.html',
  styleUrls: ['./concepts.component.css']
})
export class ConceptsComponent {
  displayedHeaders: string[] = [];
  displayedColumns: string[] = [];
  specialText: any[] = [];
  specialClass: any[] = [];
  filters: Filter[] | undefined = undefined
  cellZize: any[] = [];
  refreshReference = false;
  v = ViewPath;
  primary = getPrimaryColor;
  accent = getAccentColor;
  warn = getWarnColor;
  @ViewChild(PageStructureComponent) ps!: PageStructureComponent<M_Concept>;

  constructor(private apiS: ApiService, public subS: SubscriptionService,
    public routerS: RouterService, public params: ParamsService, public companyS: CompanyService) {
  }

  getConcepts() {
    this.apiS.concepts().then(res => {
      this.ps.initTable(res);
    })
  }
  ngOnInit() {
    this.getConcepts();
    if (this.companyS.recambiosModule) {
      /* Datos Tabla Productos CON módulo recambios */
      this.displayedHeaders = ['Nombre', 'Precio', 'Coste', 'IVA', 'Descuento'];
      this.displayedColumns = ['name', 'price', 'cost', 'tax', 'percentage']
      this.specialText = [undefined, 'money', 'money', undefined,]
      this.specialClass = [undefined, undefined, undefined, undefined]
      this.cellZize = ['big', undefined, undefined, undefined, undefined]

    } else {
      /* Datos Tabla Productos SIN módulos */
      this.displayedHeaders = ['Nombre', 'Precio', 'Coste', 'IVA', 'Descuento'];
      this.displayedColumns = ['NAME', 'price', 'cost', 'tax', 'percentage']
      this.specialText = [undefined, 'money', 'money', undefined,]
      this.specialClass = [undefined, undefined, undefined, undefined]
      this.cellZize = ['big', undefined, undefined, undefined, undefined]

    }
  }
}
