import { Injectable } from '@angular/core';
import { EinaDataService } from '../services/EinaMainData/eina-data.service';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { ViewPath } from '../app-routing.module';
/**
 * The views that implement this guard are only displayed if the user has filled in all the company data.
 */

@Injectable({
  providedIn: 'root'
})
export class CompanyRequiredGuard {

  constructor(private einaDataS: EinaDataService, private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      /** Get Eina main user data */
      this.einaDataS.getEinaData().then(res => {
        if (!res) { resolve(false); }
        else {
          if (this.einaDataS.company.missing_info){
            this.router.navigate([ViewPath.companyRequired.path]);
            resolve(false);
          }
          resolve(true)
        }
      })
    }
    )
  }
}