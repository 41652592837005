<table class="today-breakdown"
    *ngIf="signingData?.workingTimer?.scheduleHasMorining || signingData?.workingTimer?.scheduleHasAfternoon">
    <tr>
        <th>Entrada</th>
        <th>Salida</th>
        <th>Motivo</th>
    </tr>
    <tr *ngIf="signingData?.workingTimer?.scheduleHasMorining" (click)="openPresenceEdit(signingData?.workingTimer)">
        <td>{{ signingData?.workingTimer?.morning_start?.hourMinuteSecond() ||
            "--:--:--"}}</td>
        <td>{{ signingData?.workingTimer?.morning_end?.hourMinuteSecond() ||
            "--:--:--"}}</td>
        <td class="motivo">{{signingData?.workingTimer?.reason || ''}}</td>
    </tr>
    <tr *ngIf="signingData?.workingTimer?.scheduleHasAfternoon" (click)="openPresenceEdit(signingData?.workingTimer)">
        <td>{{ signingData?.workingTimer?.afternoon_start?.hourMinuteSecond() ||
            "--:--:--"}}</td>
        <td>{{ signingData?.workingTimer?.afternoon_end?.hourMinuteSecond() ||
            "--:--:--"}}</td>
        <td class="motivo">{{signingData?.workingTimer?.reason_2 || ''}}</td>
    </tr>
</table>