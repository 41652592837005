<app-close-dialog-button></app-close-dialog-button>

<p mat-dialog-title>Explicación de los roles</p>

<div mat-dialog-content>
    <!-- Admin -->
    <p mat-dialog-subtitle>Administrador</p>
    <p>Es la primera persona que se ha registrado en Eina. Solo puede haber uno.</p>
    <p><span>• Tiene acceso completo a Eina.</span></p>

    <!-- Gestor -->
    <p mat-dialog-subtitle>Gestor</p>
    <p>Muy similar al administrador. Puede haber más de uno.</p>
    <p><span>• Tiene acceso completo a Eina.</span></p>

    <!-- Adviser -->
    <p mat-dialog-subtitle>Asesor</p>
    <p>Es designado por el administrador.</p>
    <p><span>• Tiene acceso a 'Gestión', 'Taller' y 'Finanzas'.</span></p>

    <!-- Adviser -->
    <p mat-dialog-subtitle>Comercial</p>
    <p>Es designado por el administrador.</p>
    <p><span>• Tiene acceso a 'Gestión', 'Comercial' y 'Finanzas'.</span></p>

    <!-- Adviser -->
    <p mat-dialog-subtitle>Recambista</p>
    <p>Es designado por el administrador.</p>
    <p><span>• Tiene acceso a 'Gestión', 'Recambios' y 'Finanzas'.</span></p>

    <!-- Mechanic-->
    <p mat-dialog-subtitle>Mecánico</p>
    <p>Es designado por el administrador.</p>
    <p><span>• Solo puede ver la carga de taller, crear y editar OR.</span></p>
</div>

<div mat-dialog-actions>
    <button color="primary" mat-flat-button mat-dialog-close cdkFocusInitial>¡Genial!</button>
</div>