import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ViewPath } from 'src/app/app-routing.module';
import { AccountingGroupSelectorComponent } from 'src/app/components/accounting-group-selector/accounting-group-selector.component';
import { BrandModelInputComponent } from 'src/app/components/brand-model-input/brand-model-input.component';
import { ParameterStateComponent } from 'src/app/components/parameter-state/parameter-state.component';
import { M_AccountingGroup } from 'src/app/models/AccountingGroups/M_AccountingGroup';
import { M_Brand } from 'src/app/models/M_Brand';
import { M_Model } from 'src/app/models/M_Model';
import { M_Serie } from 'src/app/models/M_Serie';
import { M_Color } from 'src/app/models/Vehicles/M_Color';
import { ApiService } from 'src/app/services/Api/api.service';
import { RouterService } from 'src/app/services/router.service';
import { SnackService } from 'src/app/services/snack.service';

@Component({
  selector: 'app-create-color',
  templateUrl: './create-color.component.html',
  styleUrls: ['./create-color.component.css']
})
export class CreateColorComponent  extends ParameterStateComponent implements OnInit{
  v = ViewPath;
  public color:M_Color | undefined;
  public acc_group:M_AccountingGroup[]=[];
  public loaded: boolean = false;
  public form: UntypedFormGroup;
  public isEdit: boolean = false;
  finishType: number = 0;
  @ViewChild(BrandModelInputComponent, { static: true }) inputsBrandAndModelSerie!: BrandModelInputComponent;
  @ViewChild(AccountingGroupSelectorComponent, { static: true }) accGroup!: AccountingGroupSelectorComponent;
  constructor(private apiS: ApiService, private formBuilder: UntypedFormBuilder, route: ActivatedRoute, routerS: RouterService,
   public snackS : SnackService,
    @Optional() public dialogRef: MatDialogRef<CreateColorComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: M_Color | undefined,
  ) {
    super(routerS, route, ["color"]);
    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      accounting_id: [''],
      accounting_detail: [''],
      accounting_agent: [''],
      color: [''],
      version: [''],
      brand_id:['', [Validators.required]],
      model_id:['', [Validators.required]],
      serie_id:[''],
      serie_name:[''],
      brand_name:[''],
      model_name:[''],
      code:[''],
      price:[''],
      percentage_cost:[''],
      cost:[''],
      type:[0],
      id:['']
    }) 
    if(this.isOnDialog && this.data != undefined){
      this.color = new M_Color(this.data);
      this.isEdit = true;
      this.loaded = true;
      this.form.patchValue(this.data);
      console.log(this.form.getRawValue());
      if(this.color.accounting != undefined){
        this.form.patchValue({accounting_id:this.color?.accounting?.id});
        this.form.get('accounting_id')?.disable();
      }
    }
  }
ngOnInit(): void {

 
}
  override onParam(_k: string, v: any) {
    console.log(v);
    if(v != undefined){
      this.apiS.getColorById(v).then(res => {
        if (res) {
          this.form.patchValue(res);
          this.color = res;
          this.loaded = true;
          if(this.color?.accounting == undefined){
            this.callGroupCntApi();
          }
        }
      })
    }else{
      this.callGroupCntApi();
    }
    
  }
  get showInputs(){
    return this.inputsBrandAndModelSerie.showingSeries.length > 0;
  }
  onFinishTypeChange(value: number): void {
    if (value === 0) {
      this.form.patchValue({type:0});
    } else if (value === 1) {
      this.form.patchValue({type:1});
    }
    else{
    }
  }
  selectBrand(b: M_Brand) {
    this.form.patchValue({ brand_id: b?.id,brand_name:b?.name});
  }
  get isOnDialog() {
    return Object.keys(this.dialogRef).length != 0;
  }
  selectSerie(s: M_Serie) {
    console.log(s);
    this.form.patchValue({ serie_id: s?.id,serie_name:s?.name});
  }
  selectModel(m:M_Model){
    this.form.patchValue({ model_id: m?.id,model_name:m?.name});
  }
  get valueAccountingName(){  
    return this.form.get('accounting')?.value; 
  }
  clearSerie(){
    this.form.patchValue({accounting:""});
    this.form.get('accounting')?.enable();
  }
  callGroupCntApi(){
    this.apiS.getGroupAccouting().then(res=>{
      if(res){
        this.acc_group = res;
      }
    });
  }
  onChangeSelect(acc:M_AccountingGroup){
    this.form.get('accounting')?.setValue(acc);
  }
  hasSerieAndModelo(): boolean {
    return this.form.get('serie')?.value != undefined && this.form.get('modelo')?.value != undefined;
  }
  create(){
    let s = this.form.get('serie')?.value;
    console.log(this.form.getRawValue());
    if (this.form.valid) {
      this.apiS.createColor(this.form.getRawValue()).then(res=>{
        if(res){
          this.color = res;
          if(!this.isEdit){
            this.snackS.show('Creado correctamente el color.')
          }else{
            this.snackS.show('Actualizado correctamente el color.')
          }
          this.dialogRef.close(res);
          //this.routerS.goWithQueryParams(this.v.models, {[PS_HIGHLIGHT] : res.id});
          
        }
      });
    }
   
  }
}
