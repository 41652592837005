
<app-close-dialog-button></app-close-dialog-button>

<!-- Mat dialog title-->
<p mat-dialog-title>Seleccionar dirección</p>

<!-- Dialog content -->
<div mat-dialog-content>
    <div *ngFor="let address of data.contact.extra_adress" class="selectable_location" [ngClass]="{'selected' : address.id == currentSelected?.id}" (click)="currentSelected = address">
        <div class="df jcfs">
            <mat-icon style="min-width: 24px;">location_on</mat-icon>
            <div class="df fdc">
                <p class="main-name nmb fw600">{{address.name}}</p>
                <p class="address-text c_t2 nmb">{{address.toText()}}</p>
            </div>
        </div>
    </div>
</div>

<!-- Mat dialog actions -->
<div mat-dialog-actions>
    <button mat-button mat-dialog-close>Cancelar</button>
    <button color="primary" mat-flat-button [mat-dialog-close]="currentSelected" cdkFocusInitial [disabled]="!currentSelected">Ok</button>
</div>
