import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { R_Zip } from 'src/app/models/R_Zip';
import { ApiService } from 'src/app/services/Api/api.service';

@Component({
  selector: 'app-full-address',
  templateUrl: './full-address.component.html',
  styleUrls: ['./full-address.component.css']
})
export class FullAddressComponent implements OnInit {

  loadingZip = false;
  selectedPayment: any;
  arrayZips: R_Zip[] | undefined = [];
  personalizadoSelected = false;
  finalsearh = "";

  @Input({ required: true }) form!: FormGroup;
  @Input({ required: true }) city!: number | undefined;

  constructor(private chdRef: ChangeDetectorRef, private apiS: ApiService) {
  }

  ngOnInit(): void {
    this.form.get('zip')?.valueChanges.subscribe(v => {
      this.onZipChange(v);
      this.chdRef.detectChanges();
    })
    this.form.get('province')?.disable();
    this.form.get('ccaaObject')?.disable();
  }

  onZipChange(searched: string) {
    console.log("onZipChange");
    this.finalsearh = searched;
    let zipControl = this.form.get('zip')!;

    if (!searched) {
      this.markZipAsInvalid(zipControl);
    }
    else if (searched && !this.loadingZip) {
      this.loadingZip = true;
      this.apiS.getLocationByZip(searched).then(res => {

        this.arrayZips = res;
        if (this.arrayZips?.length == 0) {
          this.markZipAsInvalid(zipControl);
        }

        if (this.arrayZips && this.arrayZips.length === 1) {
          // Si solo hay un registro, selecciona automáticamente ese registro
          let selectedZip = this.arrayZips[0];

          // Realiza las operaciones necesarias para marcar el ZIP como válido
          this.markZipAsValid(zipControl, selectedZip);

        } else {
          let selectedZip = this.arrayZips?.find(zip => zip.id == this.city);
          if (selectedZip) {
            this.markZipAsValid(zipControl, selectedZip)
          }
        }

        this.loadingZip = false;

        /** Make another call if necessary */
        if (searched != this.finalsearh) {
          this.onZipChange(this.finalsearh);
        }
      });
    }
  }


  stateChange(event: MatSelectChange) {
    const selectedValue = event.value;

    this.selectedPayment = this.arrayZips?.find(
      (zip) => zip.id === selectedValue
    );
    let zipControl = this.form.get('zip')!;

    if (selectedValue) {

      this.markZipAsValid(zipControl, this.selectedPayment)
    }

    if (selectedValue === 'personalizado') {
      this.personalizadoSelected = true;
      this.form.get('city')?.setValidators([Validators.required]);
    }
    else {
      if (this.personalizadoSelected) {
        this.personalizadoSelected = false;
        this.form.get('city')?.clearValidators();
      }
    }

    this.form.get('city')?.updateValueAndValidity();
  }

  markZipAsValid(zip: AbstractControl, model: R_Zip) {
    zip.setErrors(null);
    this.form.patchValue({ 'city': model.id })
    this.form.patchValue({ 'province': model.provincia })
    this.form.patchValue({ 'ccaaObject': model.ccaa.ccaaname })
    this.form.patchValue({ 'ccaa': model.ccaa.id })
    this.chdRef.detectChanges();
  }

  markZipAsInvalid(zip: AbstractControl) {
    zip.markAsDirty();
    zip.markAsTouched();
    zip.setErrors({ notfound: true });
    this.form.patchValue({ 'city': undefined })
    this.form.patchValue({ 'province': undefined })
    this.form.patchValue({ 'ccaaObject': undefined })
    this.form.patchValue({ 'ccaa': undefined })
  }

}
