import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { EinaDataService } from "../services/EinaMainData/eina-data.service";

@Injectable({
  providedIn: 'root'
})
export class EinaDataGuard {

  constructor(private einaDataS: EinaDataService) { }

  canActivate(next: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      /** Get Eina main user data */
      this.einaDataS.getEinaData().then(res => {
        if (res) {
          resolve(true);
        }
        else {
          resolve(false);
        }
      })
    }
    )
  }
}

