<div class="advanced-details-container my-presence-grid">
    <!-- Today time-->
    <div class="today">
        <app-card [contentLoaded]="signingComponent.signingS.loaded">
            <app-card-title>Tu jornada hoy</app-card-title>
            <div class="today-container">
                <div super-user (click)="openSchedule()" class="today-square schedule" [matTooltip]="'Ver horario'">
                    <mat-icon class="c_t2">schedule</mat-icon>
                    <p class="fw600">Horario</p>
                    <p>{{signingComponent.signingS.expectedTimeString}}</p>
                </div>
                <div class="vertical-bar"></div>
                <div class="today-square">
                    <mat-icon class="c_t2">location_away</mat-icon>
                    <p class="fw600">Centro</p>
                    <p>{{signingComponent.signingS.signingData?.center?.name || '??'}}</p>
                </div>
                <div class="df fw1 work-time-container">
                    <app-signing-timer #signingComponent class="df fdc fw1"></app-signing-timer>
                </div>
                <div class="today-breakdown-container">
                    <app-today-signing-breakdown
                        [signingData]="signingComponent.signingS.signingData"></app-today-signing-breakdown>
                </div>
            </div>
        </app-card>
    </div>


    <!-- My presence-->
    <div class="full">
        <app-card [contentLoaded]="signingComponent.signingS.loaded">
            <ng-container *ngIf="signingS.signingData">
                <app-card-title>Mi presencia</app-card-title>
                <div class="df aic jcfe gap5">
                    <ng-container *ngIf="refDay.value">
                        <div class="df aic">
                            <button mat-icon-button (click)="less()">
                                <mat-icon>chevron_left</mat-icon>
                            </button>
                            {{refDay.value.getMonthName() + " " + refDay.value.getYear()}}
                            <button mat-icon-button [disabled]="!canPlus" (click)="plus()">
                                <mat-icon>chevron_right</mat-icon>
                            </button>
                        </div>

                    </ng-container>
                </div>
                <table class="eina-table">
                    <thead>
                        <tr>
                            <th>Fecha</th>
                            <th>Entrada mañana</th>
                            <th>Salida mañana</th>
                            <th>Entrada tarde</th>
                            <th>Salida tarde</th>
                            <th>Descanso</th>
                            <th>Horario teórico</th>
                            <th>Total horas</th>
                            <th>Diferencia</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let signing of signingS.signingData?.fullHistory"
                            [ngClass]="{'today-highlight': signing.day.isToday()}" 
                            (click)="openPresenceEdit(signing)">
                            <!-- Fecha -->
                            <td>{{signing.day.getDayName(true)}}<br>{{signing.day.dayMonthYearFormat()}}</td>
                            <!-- Entrada mañana -->
                            <td>
                                {{
                                signing.morning_start ? signing.morning_start.hourMinuteSecond() :
                                signing.schedule.morningStart ? "--:--:--" : 'Sin horario'
                                }}
                            </td>
                            <!-- Salida mañana -->
                            <td>
                                {{
                                signing.morning_end ? signing.morning_end.hourMinuteSecond() :
                                signing.schedule.morningEnd ? "--:--:--" : 'Sin horario'
                                }}
                            </td>
                            <!-- Entrada tarde -->
                            <td>
                                {{
                                signing.afternoon_start ? signing.afternoon_start.hourMinuteSecond() :
                                signing.schedule.afternoonStart ? "--:--:--" : 'Sin horario'
                                }}
                            </td>
                            <!-- Salida tarde -->
                            <td>
                                {{
                                signing.afternoon_end ? signing.afternoon_end.hourMinuteSecond() :
                                signing.schedule.afternoonEnd ? "--:--:--" : 'Sin horario'
                                }}
                            </td>
                            <!-- Descanso -->
                            <td>{{signing.break}}</td>
                            <!-- Horario teórico -->
                            <td>{{signing.schedule.getTotalTimeString() || "Sin horario"}}</td>
                            <!-- Total horas -->
                            <td>{{signing.elapsed}}</td>
                            <!-- Diferencia -->
                            <td *ngIf="signing.getTeoricDifference() as diff" [ngClass]="{
                                    'red-time' : diff.minutes > 0 || diff.hours > 0,
                                    'green-time' : diff.minutes < 0 || diff.hours < 0
                                    }">
                                {{abs(diff.hours)+"h " + abs(diff.minutes) + "m"}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ng-container>
        </app-card>
    </div>

</div>