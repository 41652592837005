import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { M_Vehicle } from 'src/app/models/Vehicles/M_Vehicle';
import { endpoints } from '../../../constants/Endpoints';
import { M_Contact } from 'src/app/models/M_Contact';

@Injectable({
  providedIn: 'root'
})
export class ApiVehicleService {

  constructor(private http: HttpClient) { }

  /** TO DO : Return Vehicle !! */
  createVehicleWithClientId(v: M_Vehicle, c: M_Contact) {
    v.client_id = c.client_id;
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.createUpdateVehicle.url, v).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }

  editVehicle(body: M_Vehicle) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.createUpdateVehicle.url, body).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }
}
