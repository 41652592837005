<div class="df fww mb10">
    <app-card-title>{{isCreation ? 'Crear centro' : center?.name}}</app-card-title>
    <button class="mla" mat-mini-fab [color]="'white'" [mat-menu-trigger-for]="details"
        *ngIf="!isCreation && companyS.mtr">
        <mat-icon>more_horiz</mat-icon>
    </button>

    <mat-menu #details>
        <button mat-menu-item (click)="routerS.goTo(v.accountingGroups)">
            <mat-icon>table_view</mat-icon>
            Grupos contables
        </button>
    </mat-menu>
</div>

<form eForm [formGroup]="form">
    <div eForm-section>
        <mat-form-field appearance="outline">
            <mat-label>Nombre</mat-label>
            <input type="string" matInput formControlName="name">
        </mat-form-field>
    </div>
    <app-full-address [form]="form" [city]="center?.city"></app-full-address>

    <div eForm-wrapper>
        <mat-tab-group [color]="'primary'" mat-stretch-tabs="false">
            <mat-tab>
                <ng-template mat-tab-label>
                    Taller y citas
                </ng-template>
                <app-workshop-and-appointments [form]="WC" [center]="center"></app-workshop-and-appointments>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    Calendario
                </ng-template>
                <app-holidays-company></app-holidays-company>
            </mat-tab>
            <!-- <mat-tab>
                <ng-template mat-tab-label>
                    Horario
                </ng-template>
                <app-week-schedule #schdeule class="mla mra" [prespective]="'center'" [showCheckbox]="false" [showSave]="false" [fullWidthTable]="true"></app-week-schedule>
            </mat-tab> -->
            <mat-tab>
                <ng-template mat-tab-label>
                    Almacenes
                </ng-template>
                <div [dn_i]="companyS.recambiosModule">
                    <div class="left-border">
                        <p class="fw600">¿Quieres poder dar de alta almacenes y controlar mejor tu stock? 🤔</p>
                        <p>¡Tenemos lo que necesitas!</p>
                        <p class="nmb">Échale un vistazo al módulo de
                            <span class="c_b tdu" [go]="v.moduleDetails" [param]="{'id' : ME.RECAMBIOS}" [newTab]="true">
                                recambios
                            </span>
                        </p>
                    </div>
                </div>
                <app-storehouses [dn_i]="!companyS.recambiosModule"></app-storehouses>
            </mat-tab>
            <mat-tab *ngIf="companyS.mtr">
                <ng-template mat-tab-label>
                    Contabilidad
                </ng-template>
                <app-center-accounting></app-center-accounting>
            </mat-tab>
        </mat-tab-group>
    </div>

    <div class="df jcc mt10">
        <!-- !schdeule.isOk -->
        <button [color]="'primary'" mat-flat-button [disabled]="!form.valid" (click)="saveCenter()">
            Guardar
        </button>
    </div>

</form>