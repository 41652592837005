import { BaseModule, ModuleDetails } from "./Factory/BaseModule";
import { M_MTRModule } from "./M_MTRModule";

export class M_RecambiosModule extends BaseModule {
    get name() { return "Recambios"; }
    get corename() { return "recambios"; }
    get title() { return "El módulo que todo recambista necesita"; }
    get desc() { return "Simplifica la gestión de repuestos, reduce errores y mantén todo bajo control con nuestro módulo avanzado."; }
    override isMTR(): this is M_MTRModule { return false; }

    generateFuncionalities() {
        return [
            { icon: "local_shipping", title: "Proveedores", tooltip: "Crear contactos de tipo 'proveedor'" },
            { icon: "storefront", title: "Almacenes", tooltip: "Crear almacenes" },
            { icon: "production_quantity_limits", title: "Reservas/faltas", tooltip: "Gestionar reservas y faltas de productos" },
            { icon: "assignment", title: "Pedidos/albaranes", tooltip: "Generación de pedidos y albaranes" },
        ];
    }

    generateModuleDetails(): ModuleDetails[] {
        return [
            {
                text: `Con el módulo de recambios, dispondrás de un nuevo apartado en el menú llamado "Almacén", que incluye una amplia variedad de funcionalidades. ¡No dudes en explorarlo! 👀`,
                scrType: "img",
                src: `${this.basePath}/recambios1.png`
            },
            {
                text: "Gestiona y genera presupuestos para tus recambios de manera rápida y sencilla. Descubre cómo ahorrar tiempo y evitar errores comunes.",
                scrType: "video",
                src: `${this.basePath}/presupuestos_recambios.mp4`
            },
            {
                text: "Facilita la gestión de albaranes de recambios. Todo bajo control, desde su creación hasta el seguimiento.",
                scrType: "video",
                src: `${this.basePath}/albaranes_recambios.mp4`
            },
            {
                text: "Controla tus pedidos de recambios con precisión. Desde el estado del pedido hasta su recepción, todo en un solo lugar.",
                scrType: "video",
                src: `${this.basePath}/pedidos_recambios.mp4`
            },
            {
                text: "Registra y administra las entradas de recambios al almacén sin complicaciones. Optimiza tu inventario con este módulo.",
                scrType: "video",
                src: `${this.basePath}/entradas_recambios.mp4`
            }
        ];
    }   
}