import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { Filter, FilterOption, TagFilter, UserFilter } from '../../custom-classes/Filter';
import { or_status } from '../../custom-classes/or_states';
import { RouterService } from '../../services/router.service';
import { WorkloadData } from '../../custom-classes/WorkloadData';
import { PeriodEnum } from '../../enums/PeriodEnum';
import { AppointmentStatus } from '../../enums/AppointmentStatus';
import { StorageService } from '../../services/storage.service';
import { ConfirmData, ConfirmDialogService } from '../../services/confirm-dialog.service';
import { MemoryParamsService } from '../../services/memory-params.service';
import { M_Appointment } from 'src/app/models/M_Appointment';
import { M_Action } from 'src/app/models/M_Action';
import { M_User } from 'src/app/models/M_User';
import { Week } from 'src/app/custom-classes/Workload/Week';
import { Month } from 'src/app/custom-classes/Workload/Month';
import { WorkloadDay } from 'src/app/custom-classes/Workload/WorkloadDay';
import { M_Center } from 'src/app/models/M_Center';
import { OR_TYPE_FILTER } from 'src/app/constants/SharedFilters';
import { SessionService } from 'src/app/services/session.service';
import { MatDialog } from '@angular/material/dialog';
import { SigningTimerComponent } from '../signing-timer/signing-timer.component';
import { ChangeSharedUserComponent } from 'src/app/views/shared-acces-workload/change-shared-user/change-shared-user.component';
import { UserService } from 'src/app/services/EinaMainData/user.service';
import { WorkingOrTimerComponent } from '../working-or-timer/working-or-timer.component';
import { SharedAccessOrComponent } from '../shared-access-or/shared-access-or.component';

/**
 * Query params : 
 * period --> (0 day, 1 week, 2 month)
 * section --> ("or" OR "appointment")
 * id --> id of item to highlight.
 * date --> go to specific day
 */
@Component({
  selector: 'app-core-carga-taller',
  templateUrl: './core-carga-taller.component.html',
  styleUrls: ['./core-carga-taller.component.css']
})
export class CoreCargaTallerComponent implements OnInit {

  @Output() onModifyAppointment: EventEmitter<[M_Appointment, ("hour")]> = new EventEmitter();
  @Output() onDragFinished: EventEmitter<any> = new EventEmitter();
  @Output() deliveryWarn: EventEmitter<M_Action> = new EventEmitter();

  /** Necessary input for initialize the component */
  @Input() workloadData!: WorkloadData;
  @ViewChild(MatSelect) matSelect?: MatSelect;

  @ViewChild(WorkingOrTimerComponent) timer?: WorkingOrTimerComponent;
  @ViewChild(SigningTimerComponent) signing?: SigningTimerComponent;


  /********** GRID **********/
  currentWeek: Week | undefined;
  currentMonth: Month | undefined;

  /********** COMMON **********/
  /** Search filter */
  searchFilter: string = "";
  loaded_ = false;
  pe = PeriodEnum;
  selectedPeriod: PeriodEnum = PeriodEnum.WEEK
  refDay = new Date();
  users: M_User[] = [];
  shared_user: M_User | undefined;

  currentFilters: Filter[] = [];

  sectionFilter = new TagFilter(
    'Test',
    undefined,
    new FilterOption('Órdenes de reparación', 'build'),
    new FilterOption('Citas', 'event'),
  ).disableMultiple();

  storage_key = "workload_section"

  /********** OR **********/
  /** Filters */
  statusFilter: TagFilter = new TagFilter("Estado", or_status, new FilterOption("Abierta"), new FilterOption("Cerrada"), new FilterOption("Facturada"));
  typeFilter: TagFilter = OR_TYPE_FILTER();
  userFilter = new UserFilter("Usuarios");
  or_filters: Filter[] = [this.userFilter, this.statusFilter, this.typeFilter];

  /** Main calendar OR */
  or: M_Action[] = [];
  center: M_Center | undefined;

  highlightId: number | undefined;
  sectionParam: "or" | "appointment" | undefined;

  /********** APPOINTMENTS **********/
  /** Filters */
  appointemtStatus: TagFilter = new TagFilter("Estado", AppointmentStatus, new FilterOption("Pendiente"), new FilterOption("Hecha"), new FilterOption("Cancelada"));
  alreadyClientFilter: TagFilter = new TagFilter("Cliente", undefined, new FilterOption("Registrado", "how_to_reg"), new FilterOption("No registrado", "no_accounts"));
  appo_filters: Filter[] = [this.appointemtStatus, this.alreadyClientFilter];
  /** Main calendar Appointments */
  appointments: M_Appointment[] = [];


  constructor(public chdRef: ChangeDetectorRef, private memoryP: MemoryParamsService, public routerS: RouterService,
    public sessionS: SessionService, private confirmD: ConfirmDialogService, route: ActivatedRoute,
    private storageS: StorageService, private d: MatDialog, private userS: UserService) {

    /** Pre-filtered appointment (0 --> pending) */
    this.appointemtStatus.selectedOptions.push(0)
    /** Pre-filtered OR status (0 --> abierta, 1 --> cerrada ) */
    this.statusFilter.selectedOptions.push(0, 1);

    route.queryParams.subscribe(q => {
      if (q['period']) {
        var v = Number(q['period']);
        if (v == PeriodEnum.WEEK) {
          this.selectedPeriod = PeriodEnum.WEEK;
        }
        else if (v == PeriodEnum.MONTH) {
          this.selectedPeriod = PeriodEnum.MONTH;
        }
      }
      if (q['section']) {
        let s = q['section'];
        if (s == "or" || s == "appointment") {
          this.sectionParam = s;
        }
      }
      if (q['id']) {
        this.highlightId = (q['id'] as string).getNumber();
      }
      if (q['date']) {
        try {
          this.refDay = new Date(q['date']);
        }
        catch { }
      }

      this.or_filters.forEach((f, index) => {
        if (q['orfilter' + index]) {
          f.initByParam(Number(q['orfilter' + index]))
        }
      })

      this.appo_filters.forEach((f, index) => {
        if (q['apfilter' + index]) {
          f.initByParam(Number(q['apfilter' + index]))
        }
      })
    })
  }


  /** If the user has the value on storage, dont show the alert */
  initView(view: "or" | "appointment", showAlert: boolean = true) {

    this.getCenter();

    console.log("Init view")

    if (view == "or") {
      if (this.workloadData.ORpermisison) {
        this.currentFilters = this.or_filters;
        this.workloadData.isOrView = true;
        this.updateSectionFilter();
      }
      else if (showAlert) {
        var d: ConfirmData = { title: "Sin permisos", body: "Actualmente no tienes permisos para ver la página de OR" }
        this.confirmD.show(d)
        this.initView("appointment");
      }
    }

    if (view == "appointment") {
      if (this.workloadData.AppoPermisison) {
        this.currentFilters = this.appo_filters;
        this.workloadData.isOrView = false;
        this.updateSectionFilter();

      }
      else if (showAlert) {
        var d: ConfirmData = { title: "Sin permisos", body: "Actualmente no tienes permisos para ver la página de citas" }
        this.confirmD.show(d)
        this.initView("or");
      }
      else {
        this.initView("or");
      }
    }

    this.onRefDayChanges(true);
  }

  getCenter() {
    this.workloadData.data.general.center().then(res => {
      this.center = res;
    })
  }


  /** By default, the OR view */
  ngAfterViewInit() {

    /** If ther is a param. Is more important than the storage or the default project view */
    if (this.sectionParam != undefined) {
      this.initView(this.sectionParam);
    }

    /** If there is no param... */
    else {
      var onStorage = this.storageS.get(this.storage_key);

      /**No stored value, init with default view */
      if (onStorage == undefined) {
        this.initView(this.workloadData.data.defaultView);
      }

      /** There is some value on storage */
      else {
        this.initView(onStorage == "or" ? "or" : "appointment", false);
      }
    }

    this.workloadData.data.general.users().then(res => {
      this.users = res;
      this.userFilter.setUsers(this.users);

      if (this.userS.userIsMechanic && !this.sessionS.isSharedAccessView) {
        this.userFilter.addRemoveUserFilter(this.userS.user);
      }

      if (this.sessionS.isSharedAccessView) {
        this.setSharedUser(this.users[0]);
      }
    })

    this.chdRef.detectChanges();
  }

  setSharedUser(user: M_User) {
    if (this.sessionS.isSharedAccessView) {
      this.userFilter.clear();
      this.userFilter.addRemoveUserFilter(user);
      this.shared_user = user;
      this.sessionS.setSharedUser(user);

      if (this.timer && this.signing) {
        this.timer?.refreshTimer();
        this.signing?.restart();
      }
      else {
        console.warn("Canno't reset the OR timer or the Signing timer")
      }
    }
  }

  updateSectionFilter() {
    if (this.workloadData.isOrView) {
      this.sectionFilter.selectedOptions = [];
      this.sectionFilter.selectedOptions.push(0);
    }
    else {
      this.sectionFilter.selectedOptions = [];
      this.sectionFilter.selectedOptions.push(1);
    }
  }

  /** Change the OR / Appointment view */
  changeView(or: boolean) {
    this.storageS.save(this.storage_key, or ? "or" : "appointment");
    this.memoryP.add(["section", or ? "or" : "appointment"])

    // --> The workload data is not always defined (Getting query params of the url)
    if (this.workloadData && or != this.workloadData.isOrView) {
      this.workloadData.changeView(or);
      this.searchFilter = "";
      this.currentFilters = this.workloadData.isOrView ? this.or_filters : this.appo_filters;
      this.onRefDayChanges();
      this.chdRef.detectChanges();
    }

    this.updateSectionFilter();
  }

  applySearchFilter(value: string) {
    this.searchFilter = value;
    this.chdRef.detectChanges();
  }

  ngOnInit(): void {
    this.chdRef.detectChanges();
  }

  getTooltipByFilter(p: PeriodEnum, next: boolean) {
    let v = "";
    switch (p) {
      case this.pe.WEEK:
        v = "Semana"; break;
      case this.pe.MONTH:
        v = "Mes"; break;
    }
    return next ? "Siguiente " + v : v + " anterior"
  }

  arrowNextByFilter(p: PeriodEnum, next: boolean) {
    let v = 0;
    switch (p) {
      case this.pe.WEEK:
        v = 7; break;
      case this.pe.MONTH:
        v = 30; break;
    }

    if (next) {
      this.refDay.plusDays(v);
    }
    else {
      this.refDay.minusDays(v);
    }

    this.selectedPeriod = p;
    this.onRefDayChanges();
  }

  onSelectedPeriodChange(e: number) {
    this.selectedPeriod = e;
    this.memoryP.add(["period", e.toString()])
    this.onRefDayChanges();
  }

  bigRefByDay(p: PeriodEnum) {
    switch (p) {
      case this.pe.WEEK:
        return "Del " + this.currentWeek?.first.v.getDate() +
          " al " + this.currentWeek?.last.v.getDate() +
          " de " + this.currentWeek?.last.v.getMonthName(true).toLocaleLowerCase() +
          " de " + this.currentWeek?.last.v.getYear(true);
      case this.pe.MONTH:
        return this.refDay.getMonthName(true) + " de " + this.refDay.getYear(true);
    }
  }

  getItemOfDay(d: Date, reverse = false) {
    if (reverse) {
      return !this.workloadData.isOrView ?
        this.or.filter(or => { return or.schedule?.isEquals(d) }) :
        this.appointments.filter(appo => { return appo.appointment_date!.isEquals(d) })
          .sort((a, b) => (a.appointment_date?.getTime() || 0) - (b.appointment_date?.getTime() || 0));
    }
    else {
      return this.workloadData.isOrView ?
        this.or.filter(or => { return or.schedule?.isEquals(d) }) :
        this.appointments.filter(appo => { return appo.appointment_date!.isEquals(d) })
          .sort((a, b) => (a.appointment_date?.getTime() || 0) - (b.appointment_date?.getTime() || 0));
    }
  }

  onRefDayChanges(isInit = false) {
    this.loaded_ = false;
    let fromDate = new Date();
    let toDate = new Date();

    switch (this.selectedPeriod) {
      case (PeriodEnum.WEEK): {
        let w = this.getweekDaysByDay(this.refDay);
        if (isInit) {
          if (w.containsToday) {
            w = this.todayOnLeft();
          }
        }
        this.chdRef.detectChanges();
        fromDate = w.first.v;
        toDate = w.last.v;
        this.currentWeek = w;
        break;
      }
      case (PeriodEnum.MONTH): {
        let m = this.getMonthByDay(this.refDay)
        fromDate = m.first.v;
        toDate = m.last.v;
        this.currentMonth = m;
        break;
      }
    }

    this.workloadData.isOrView ? this.onRefDayChangeOr(fromDate, toDate) : this.onRefDayChangeApponimtent(fromDate, toDate);
    this.memoryP.add(['date', this.refDay.toString()]);
  }

  setDataOnWeekOrMonth() {
    switch (this.selectedPeriod) {
      case (PeriodEnum.WEEK): {
        this.currentWeek?.days.forEach(d => {
          d.data = this.getItemOfDay(d.v);
        })
        break;
      }
      case (PeriodEnum.MONTH): {
        this.currentMonth?.weeks.forEach(w => {
          w?.days.forEach(d => {
            d.data = this.getItemOfDay(d.v);
          })
        })
        break;
      }
    }
  }

  onRefDayChangeOr(fromDate: Date, toDate: Date) {
    const a = this.workloadData.data.or.schedules(fromDate, toDate, true);
    const b = this.workloadData.data.appointments.appointments(fromDate, toDate, true);
    forkJoin([a, b]).subscribe(res => {
      this.or = res[0];
      this.appointments = res[1];
      this.refreshUserOrFilterNumber(this.or);
      this.setDataOnWeekOrMonth();
      this.loaded_ = true;
    })
  }

  onRefDayChangeApponimtent(fromDate: Date, toDate: Date) {
    const a = this.workloadData.data.appointments.appointments(fromDate, toDate, true);
    const b = this.workloadData.data.or.schedules(fromDate, toDate, true);
    forkJoin([a, b]).subscribe(res => {
      this.appointments = res[0];
      this.or = res[1];
      this.setDataOnWeekOrMonth();
      this.loaded_ = true;
    })
  }

  /** Get the first day of week  */
  getFirstWeekDayByDay(d: Date) {
    for (let i = 0; i <= 7; i++) {
      if (d.getDay() == 1) {
        return d;
      }
      d.minusDays(1);
    }
    throw Error("Cannot get the first day of week")
  }

  getweekDaysByDay(d: Date) {
    let first = this.getFirstWeekDayByDay(d);
    let week: WorkloadDay[] = [new WorkloadDay(new Date(first), this.getItemOfDay(first))];
    for (let i = 0; i <= 5; i++) {
      first.plusDays(1);
      week.push(new WorkloadDay(new Date(first), this.getItemOfDay(first)));
    }
    let finalWeek = new Week(week)
    return finalWeek;
  }

  todayOnLeft() {
    let week: WorkloadDay[] = [];
    for (let i = 0; i <= 6; i++) {
      let today = new Date();
      let next = new Date(today.plusDays(i));
      week.push(new WorkloadDay(next, this.getItemOfDay(today)));
    }
    return new Week(week);
  }

  /** The first day represneted on screen, not always is the same motnh */
  getFirstMonthDayByDay(d: Date) {
    let date = new Date(d.getFullYear(), d.getMonth(), 1);
    return new Date(this.getFirstWeekDayByDay(new Date(date)));
  }

  getLastMonthDayByDay(d: Date) {
    let date = new Date(d.getFullYear(), d.getMonth() + 1, 0);
    return new Date(date);
  }

  getMonthByDay(d: Date) {
    const firstDay = new Date(d.getFullYear(), d.getMonth(), 1);
    let first = this.getFirstMonthDayByDay(d);
    let month: Month = new Month();
    for (let i = 0; i < 5; i++) {
      month.appendWeek(this.getweekDaysByDay(first));
      if (i != 4) {
        first.plusDays(6);
      }
      // Check if the month needs to display 6 weeks on the screen
      else {
        first.plusDays(1);
        if (firstDay.getMonth() == first.getMonth()) {
          month.appendWeek(this.getweekDaysByDay(first));
        }
      }
    }
    this.refDay!.getDayName()
    return month;
  }

  isOtherMonth(d: Date) {
    return this.refDay?.getMonth() != d.getMonth();
  }

  goToSpecificWeek(d: Date) {
    this.selectedPeriod = this.pe.WEEK;
    this.refDay = d;
    this.chdRef.detectChanges();
    this.onRefDayChanges();
  }

  refreshUserOrFilterNumber(or: M_Action[]) {
    this.userFilter.or = or;
  }

  get isWeek() { return this.selectedPeriod == this.pe.WEEK; }


  changeSharedUser(signingC: SigningTimerComponent) {
    const dialogRef = this.d.open(ChangeSharedUserComponent, {
      panelClass: 'full-screen-dialog',
      data: this.users,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((selectedUser: M_User) => {
      if (selectedUser) {
        this.setSharedUser(selectedUser);
        signingC.restart();
      }
    });
  }

  onClickTimer(or: M_Action) {
    /** Shared acces OR */
    this.d.open(SharedAccessOrComponent, { data: { orId: or.id, sharedUserId: this.sessionS.sharedUserId }, autoFocus: false })
      .afterClosed().subscribe(res => {
        if (res instanceof M_Action) {
          or.groups = res.groups;
          or.refreshStatus();
        }
      })
  }

}
