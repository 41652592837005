<div class="advanced-details-container">
    <app-go-back [v]="v.accountingGroups" [text]="'Listado de grupos contables'"></app-go-back>
    <app-card [contentLoaded]="loaded">

        <div *ngIf="ag">

            <div class="df aic">
                <app-card-title>{{isEdit ? 'Edición' : 'Creación'}} de grupo contable</app-card-title>
                <button class="mla" mat-mini-fab [color]="'white'" *ngIf="isEdit" [matMenuTriggerFor]="deleteMenu">
                    <mat-icon>more_horiz</mat-icon>
                </button>
                <mat-menu #deleteMenu>
                    <button mat-menu-item (click)="deleteAccountingGroup()">
                        <mat-icon>delete</mat-icon>
                        Eliminar
                    </button>
                </mat-menu>
            </div>

            <form eForm [formGroup]="form">
                <div eForm-section *ngIf="!isEdit">
                    <mat-form-field appearance="outline" style="max-width: 300px;">
                        <mat-label>Tipo</mat-label>
                        <mat-select (selectionChange)="onChangeSelector($event)" [value]="ACT.COMERCIAL" formControlName="type">
                            <mat-option [value]="ACT.COMERCIAL">Comercial</mat-option>
                            <mat-option [value]="ACT.TIME">Tiempo</mat-option>
                            <mat-option [value]="ACT.PRODUCTS">Productos</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <p *ngIf="isEdit && ag"> <span class="fw600">Tipo</span> : {{ag.type_name}}</p>
                <div eForm-section>
                    <mat-form-field appearance="outline" style="max-width: 300px;">
                        <mat-label>Nombre</mat-label>
                        <input type="string" matInput formControlName="name">
                    </mat-form-field>

                    <mat-form-field appearance="outline" *ngIf="!isEdit" style="max-width: 300px;">
                        <mat-label>Centro</mat-label>
                        <mat-select formControlName="center" formControlName="center_id">
                            <mat-option *ngFor="let center of einaDataS.company.centers" [value]="center.id">
                                {{ center.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="outline" style="max-width: 200px;">
                        <mat-label>Código</mat-label>
                        <input type="string" matInput formControlName="code">
                    </mat-form-field>
                </div>
            </form>

            <app-accounting-group-table [data]="dataF" [type]="'F'" [dn_i]="!show(dataF)"
                [acType]="ag.type"></app-accounting-group-table>
            <app-accounting-group-table [data]="dataA" [type]="'A'" [dn_i]="!show(dataA)"
                [acType]="ag.type"></app-accounting-group-table>
            <app-accounting-group-table [data]="dataC" [type]="'C'" [dn_i]="!show(dataC)"
                [acType]="ag.type"></app-accounting-group-table>

            <div class="df jcc">
                <button mat-flat-button color="primary" [disabled]="!form.valid" (click)="save()">Guardar</button>
            </div>
        </div>
    </app-card>
</div>