<div class="advanced-details-container" *ngIf="ve != undefined || (ve == undefined && !loaded); else notfound">
    <app-go-back class="back" [text]="'Listado vehículos'" [v]="v.garage" [show]="loaded"></app-go-back>
    <div class="vehicle">
        <app-card [contentLoaded]="loaded" class="bc_b1">

            <app-advanced-details *ngIf="ve">

                <app-advanced-title>
                    <div class="df aic gap5">
                        {{ve.getName()}}
                        <app-vn-vo-label *ngIf="ve.comercialType" [type]="ve.comercialType"></app-vn-vo-label>
                        <app-reserve-budget *ngIf="ve.budget_reserve"  [reserve]="ve.budget_reserve.reserve" ></app-reserve-budget>
                    </div>
                </app-advanced-title>

                <app-advanced-subtitle>
                    {{ve.chassis}}
                </app-advanced-subtitle>

                <app-advanced-main-actions *ngIf="ve">
                    
                    <button mat-flat-button color="primary"
                        (click)="params.go(v.sellVehicle, ve.vehicle_id, 'vehicle')">
                        <mat-icon>sell</mat-icon>
                        Vender
                    </button>
                    
                    <button mat-flat-button color="primary"  [go]="v.createOr"
                        [param]="ve!.vehicle_id">
                        <mat-icon>build</mat-icon>
                        Abrir OR Comercial
                    </button>
                </app-advanced-main-actions>

                <app-advanced-menu-actions>
                    <button mat-menu-item color="primary" (click)="params.go(v.createVnVo, ve!.vehicle_id!)">
                        <mat-icon>edit</mat-icon>
                        Editar
                    </button>
                    
                    <button mat-menu-item (click)="goAssignClient(ve)">
                        <mat-icon>published_with_changes</mat-icon>
                        Asignar cliente/agente
                    </button>
                </app-advanced-menu-actions>

            </app-advanced-details>

            <app-advanced-details-footer>
                <app-footer-title>Ver más datos de vehículo</app-footer-title>
                <app-footer-right *ngIf="ve && ve.client">
                    <app-contact-client-rectangle [client]="ve.client"></app-contact-client-rectangle>
                </app-footer-right>
                <app-footer-content>
                    <app-section>
                        <app-section-title>Ficha</app-section-title>
                        <app-section-content>
                            <app-card-row-content *ngIf="ve != undefined" [data]="
                        [
                            ['Chasis', ve.chassis],
                            ['Precio', ve.price, {money : true}],
                            ['Coste',  ve.cost, {money : true} ],
                        ]">
                            </app-card-row-content>
                        </app-section-content>
                    </app-section>

                    <app-section>
                        <app-section-title>Documentos</app-section-title>
                        <app-section-content>
                            <div class="documentos">
                                <div>
                                    <app-card-subtitle [first]="true" [noMarginBottom]="true"
                                        class="df aic">Documentación
                                        <button mat-icon-button (click)="documentation.clickInputTrigger()" color="p3">
                                            <mat-icon>add_circle</mat-icon>
                                        </button>
                                    </app-card-subtitle>
                                    <p *ngIf="!hasDocuImages()" class="c_t2">Aún no has subido imágenes</p>
                                    <lib-file-upload #documentation class="hidde_add" [uploadtype]="'multiple'"
                                        [maxImagesLength]="5" [maxSize]="6"
                                        (stateChanged)="uploadImages($event, 'docu_img')"
                                        (onRemoveImage)="removeImage($event)">
                                    </lib-file-upload>
                                </div>
                                <div>
                                    <app-card-subtitle [first]="true" [noMarginBottom]="true" class="df aic">Imágenes
                                        vehículo
                                        <button mat-icon-button (click)="vehicleImages.clickInputTrigger()" color="p3">
                                            <mat-icon>add_circle</mat-icon>
                                        </button>
                                    </app-card-subtitle>
                                    <p *ngIf="!hasVehicleImages()" class="c_t2">Aún no has subido imágenes</p>
                                    <lib-file-upload #vehicleImages class="hidde_add" [phoneMultipleUpload]="true"
                                        [uploadtype]="'multiple'" [maxImagesLength]="5" [maxSize]="6"
                                        (stateChanged)="uploadImages($event, 'vehicle_img')"
                                        (onRemoveImage)="removeImage($event)">
                                    </lib-file-upload>
                                </div>
                            </div>
                        </app-section-content>
                    </app-section>
                    <app-section>
                        <app-section-title>Ubicación</app-section-title>

                        <app-section-content>
                            <div class="df mt10" *ngIf="ve?.location != undefined; else notLocation">
                                <span><mat-icon class="vab">location_on</mat-icon>
                                {{location}}</span>
                                
                            </div>
                            <ng-template #notLocation>
                                <div class="mt10"><span class="c_t2"><mat-icon class="c_t2 vab">wrong_location</mat-icon>No hay ubicación.</span></div>
                            </ng-template>
                        </app-section-content>
                    </app-section>
                </app-footer-content>
            </app-advanced-details-footer>
        </app-card>
    </div>
    <div class="BottomCard">
        <app-card [contentLoaded]="loaded" [noData]="ve && !ve.concepts.length">
            <app-card-title>Conceptos</app-card-title>
            <ng-container *ngIf="ve && ve.concepts.length">
                <app-concepts-line-table [concepts]="ve.concepts" [vehicle]="ve" [isInfo]="true"
                    [canModifyTable]="false"></app-concepts-line-table>
            </ng-container>
        </app-card>
    </div>
</div>

<ng-template #notfound>
    <app-model-not-found [title]="'No se ha encontrado el vehículo'" [goText]="'Ir al listado de vehículos'"
        [view]="v.vehicles">
    </app-model-not-found>
</ng-template>