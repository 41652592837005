import { match } from "../services/search.service";
import { or_status } from "./or_states";
import { or_types } from "./or_types";
import { action_types } from "./action_types";
import { invoice_type } from "./invoice_types";
import { AppointmentStatus } from "../enums/AppointmentStatus";
import { invoice_states } from "./invoice_states";
import { order_status } from "./order_states";
import { albaran_status } from "./albaran_status";
import { IClassSearcher } from "../interfaces/IClassSearcher";
import { M_Action } from "../models/M_Action";
import { M_User } from "../models/M_User";
import { M_Center } from "../models/M_Center";
import { EinaDataService } from "../services/EinaMainData/eina-data.service";
import { VehicleType } from "../enums/VehicleType";

export class FilterOption {
    constructor(public name: string, public icon?: string, public hidden?: boolean) { }
}

export enum FilterEnum {
    UNDEFINED = -1,
    DAY = 0,
    SLIDER = 1,
    ENUMFILTER = 2,
    TAGFILTER = 3,
    TEXTFILTER = 4,
    USERFILTER = 5,
    CHECKBOXFILTER = 6,
    CLASSSEARCHER = 7,
    BUTTONTOGGLEFILTER = 8,
    CENTERSFILTER = 9,
}


/** FILTER CLASS */
export abstract class Filter {
    filterId: FilterEnum = FilterEnum.UNDEFINED;
    specificKey: keyof any | undefined;
    master: any[] | undefined;
    options = new Map<number, FilterOption>();
    id: number | undefined;
    constructor(filterId: FilterEnum, public label: string, options?: FilterOption[], specificKey?: keyof any, master?: any[]) {
        this.filterId = filterId;
        this.master = master;
        if (options) {
            for (let i = 0; i < options.length; i++) {
                this.options.set(i, options[i]);
            }
        }
        if (specificKey) {
            this.specificKey = specificKey;
        }
    }

    setId(id: number) { this.id = id; return this; }
    memoryInit(f: Filter) { throw Error("Not implemented") }
    initByParam(v: number) { throw Error("Not implemented") }

    pushOption(...options: FilterOption[]) {
        var l = this.options.entries.length;
        for (let i = 0; i < options.length; i++) {
            this.options.set(l + i, options[i]);
        }
    }

    abstract checkFilter(v: any): boolean;
    abstract get activated(): boolean;
    abstract clear(): void;

}

/** MOVEMENTS FILTER */
export class MoveFilters extends Filter {
    optionsLength = 0;
    constructor(label: string) {
        let o = [
            new FilterOption("Todos", "list_alt"),
            new FilterOption("Asociados con facturas", "receipt_outline"),
            new FilterOption("Entrada almacén", "archive"),
            new FilterOption("Salida almacén", "move_down")];
        super(FilterEnum.UNDEFINED, label, o)
        this.optionsLength = o.length;
    }
    override checkFilter(v: any): boolean { return true; }
    override get activated() { return true; }
    override clear() { }
}

/** ENUM FILTER */
export class EnumFilter extends Filter {
    optionsLength = 0;
    selectedOption: number = -1;
    nullable: boolean = true;
    constructor(label: string, ...options: FilterOption[]) {
        super(FilterEnum.ENUMFILTER, label, options)
        this.optionsLength = options.length;
    }

    setNullable(v: boolean) {
        this.nullable = v;
        return this;
    }

    override checkFilter(v: number): boolean {
        return v == this.selectedOption;
    }

    override initByParam(v: number) { this.selectedOption = v; }

    override get activated() { return this.selectedOption != -1; }

    override clear() { this.selectedOption = -1; }
}


/** EINA CENTERS FILTER */
export class CentersFilter extends Filter {

    centers: M_Center[] = [];
    selected: M_Center | undefined;

    constructor(einaDataS: EinaDataService) {
        super(FilterEnum.CENTERSFILTER, "Centro")
        this.centers = einaDataS.company.centers;
    }

    override checkFilter(id: number): boolean {
        if (!this.selected) { return true; }
        return this.selected.id == id;
    }

    override get activated(): boolean {
        return this.selected != undefined;
    }

    override clear(): void {
        this.selected = undefined;
    }

}

export class TextFilter extends Filter {
    searchedText: string | undefined;
    exactmatch: boolean = false;
    constructor(label: string, specifickey?: keyof any, master?: any[] | undefined, exactmatch?: boolean) {
        super(FilterEnum.TEXTFILTER, label, undefined, specifickey, master);
        if (exactmatch) { this.exactmatch = exactmatch; }
    }
    override checkFilter(v: string | undefined): boolean {
        if (this.searchedText && v) {
            return this.exactmatch ? this.searchedText == v : match(this.searchedText, v);
        }
        return false;
    }

    checkbyProduct(p: any) {
        if (this.specificKey) {
            var v = p[this.specificKey];
            if (typeof v == "string") {
                return this.checkFilter(v);
            }
        }
        return false;
    }

    override memoryInit(f: Filter) {
        if (f instanceof TextFilter) {
            this.searchedText = f.searchedText;
        }
    }

    override get activated() { return this.searchedText != undefined && this.searchedText != "" && this.searchedText.replace(/ /g, '').length != 0; }

    override clear() { this.searchedText = undefined }
}

export class TagFilter extends Filter {
    optionsLength = 0;
    multiple = true;
    selectedOptions: number[] = []
    typeOfLabel: typeof or_types | typeof or_status | typeof action_types | typeof AppointmentStatus | typeof invoice_type | typeof invoice_states | typeof order_status | typeof albaran_status | typeof VehicleType | undefined;

    constructor(label: string, type: typeof or_types | typeof or_status | typeof action_types | typeof AppointmentStatus | typeof invoice_type | typeof invoice_states | typeof order_status | typeof albaran_status | typeof VehicleType| undefined, ...options: FilterOption[]) {
        super(FilterEnum.TAGFILTER, label, options)
        this.optionsLength = options.length;
        this.typeOfLabel = type;
    }

    disableMultiple() {
        this.multiple = false;
        return this;
    }
    isVehicleType(){
        return this.typeOfLabel == VehicleType;
    }
    isOrTypesTags() {
        return this.typeOfLabel == or_types;
    }

    isAppointmentTags() {
        return this.typeOfLabel == AppointmentStatus;
    }

    isOrStatusTags() {
        return this.typeOfLabel == or_status;
    }

    isActionTags() {
        return this.typeOfLabel == action_types;
    }

    isInvoiceTypeTags() {
        return this.typeOfLabel == invoice_type;
    }

    isInvoiceStatesTags() {
        return this.typeOfLabel == invoice_states;
    }

    isOrderStausTags() {
        return this.typeOfLabel == order_status;
    }

    isAlbaranStatus() {
        return this.typeOfLabel == albaran_status;
    }

    get circularPrefix() {
        return this.isOrTypesTags() || this.isOrStatusTags() || this.isAppointmentTags() || this.isInvoiceStatesTags() || this.isOrderStausTags() || this.isAlbaranStatus() || this. isVehicleType();
    }

    generateOptionsByArray(values: (string | undefined)[]) {
        var filterOptions: FilterOption[] = []
        values.forEach(value => {
            if (value) {
                if (filterOptions.every(fo => { return fo.name != value })) {
                    filterOptions.push(new FilterOption(value));
                }
            }
        })
        this.pushOption(...filterOptions);
    }


    /** If at least one value of the object matches, the funcion returns true */
    override checkFilter(v: number[]): boolean {
        var ok = false;
        this.selectedOptions.forEach(selected => {
            v.forEach(objectValue => {
                if (selected == objectValue) {
                    ok = true;
                }
            })
        })

        return ok;
    }

    /** Check the filter against the label name */
    checkFilterByString(v: string | undefined): boolean {
        if (v == undefined) { return false; }
        var options: FilterOption[] = [];
        this.selectedOptions.forEach(option => {
            var o = this.options.get(option);
            if (o) { options.push(o); }
        })
        if (options.length == 0) {
            return false;
        }
        return options.some(option => option.name.toLocaleLowerCase() == v.toLocaleLowerCase());
    }

    pushSelectedOption(v: number) {
        if (!this.selectedOptions.includes(v)) {
            this.selectedOptions.push(v);
        }
    }

    removeOption(v: number) {
        if (this.multiple) {
            if (this.selectedOptions.includes(v)) {
                this.selectedOptions.removeElement(v);
            }
        }

    }

    toggleSelected(v: number) {
        if (this.multiple) {
            if (this.isSelected(v)) {
                this.removeOption(v);
            }
            else {
                this.pushSelectedOption(v);
            }
        }
        else {
            this.selectedOptions = [];
            this.pushSelectedOption(v);
        }
    }

    isSelected(v: number) {
        return this.selectedOptions.includes(v)
    }

    override initByParam(v: number) { this.pushSelectedOption(v); }

    override get activated() { return this.selectedOptions.length != 0; }

    override clear() { this.selectedOptions = [] }

}

export class UserFilter extends Filter {
    users: M_User[] = [];
    or: M_Action[] = [];
    userFilter: { users: M_User[], noOneFilter: boolean } = { users: [], noOneFilter: false };
    loaded = false;
    constructor(label: string) {
        super(FilterEnum.USERFILTER, label, undefined);
    }

    /** Manually init the users */
    setUsers(u: M_User[]) {
        this.users = u;
        this.loaded = true;
    }

    override checkFilter(id: number | undefined) {
        if (this.userFilter.users.length == 0 && !this.userFilter.noOneFilter) {
            return true;
        }
        else if (this.userFilter.users.length == 0 && this.userFilter.noOneFilter) {
            return id == undefined;
        }
        else {
            return this.userFilter.users.filter(u => { return u.id == id }).length != 0;
        }
    }

    isSelected(u: M_User) {
        return this.userFilter.users.includes(u);
    }

    addRemoveUserFilter(u: M_User) {
        const usr = this.users.find(user => user.id == u.id);
        if (usr) {
            if (this.userFilter.users.includes(usr)) {
                this.userFilter.users.removeElement(usr)
            }
            else {
                this.userFilter.users.push(usr);
                this.preserveFiltersSense("users")
            }
        }
    }

    addRemoveNoOneFilter() {
        this.userFilter.noOneFilter = !this.userFilter.noOneFilter;
        this.preserveFiltersSense("no-one");
    }

    preserveFiltersSense(userWant: "no-one" | "users") {
        if (userWant == "no-one") {
            this.userFilter.users = [];
        }
        else {
            this.userFilter.noOneFilter = false;
        }
    }

    override get activated() { return this.userFilter.users.length != 0 || this.userFilter.noOneFilter == true; }
    override clear() { this.userFilter.users = [] }
}

export class SliderFilter extends Filter {
    max: number;
    minval: number = 0;
    maxval: number = 0;
    constructor(label: string, max: number, specificField?: keyof any) {
        super(FilterEnum.SLIDER, label, undefined, specificField);
        this.max = this.setMax(max);
        this.maxval = this.max;
    }

    changeMax(v: number) {
        this.max = this.setMax(v);
        this.maxval = this.max;
    }

    changeMin(v: number) {
        this.minval = v;
    }

    private setMax(v: number) {
        //If max value has decimals... next number
        if (v % 1 != 0) {
            return Math.ceil(v);
        }
        return v;
    }

    override checkFilter(v: number | undefined) {
        if (v != undefined) {
            return v >= this.minval && v <= this.maxval;
        }
        return false;
    }

    checkbyProduct(p: any) {
        if (this.specificKey) {
            var v = p[this.specificKey];
            if (typeof v == "number") {
                return this.checkFilter(v);
            }
        }
        return false;
    }

    override get activated() { return this.maxval != this.max || this.minval != 0; }

    override clear() { this.maxval = this.max; this.minval = 0; }

}

/** DAY FILTER */
export class DayFilter extends Filter {
    showDefault = true;
    optionsLength = 0;
    selectedOption: number = -1;
    from: Date | undefined;
    to: Date | undefined;
    future = false;

    constructor(label: string, showDefault = true, future = false) {
        let o = [new FilterOption("Hoy"),
        new FilterOption(future ? "Próximos 5 días" : "Últimos 5 días"),
        new FilterOption("Este mes"),
        new FilterOption(future ? "Próximos mes" : "Mes anterior"),
        new FilterOption("Rango de fechas")];
        super(FilterEnum.DAY, label, o);
        this.future = future;
        this.optionsLength = o.length;
        if (showDefault != undefined) {
            this.showDefault = showDefault;
        }
    }

    setFrom(v: string | undefined) {
        this.from = v ? new Date(v) : undefined;
    }

    setTo(v: string | undefined) {
        this.to = v ? new Date(v) : undefined;
    }

    override checkFilter(c: Date | undefined) {
        if (c == undefined) {
            return false;
        }
        /** Today filter */
        if (this.selectedOption == 0) {
            let today = new Date()
            return c.isEquals(today)
        }
        /** 5 days filter */
        if (this.selectedOption == 1) {
            if (!this.future) {
                let today = new Date()
                return (c > today.minusDays(5) && c < new Date()) || c.isToday();
            }
            else {
                let today = new Date()
                return (c < today.plusDays(5) && c > new Date()) || c.isToday();
            }
        }
        /** This month */
        if (this.selectedOption == 2) {
            let thisMonth = new Date();
            return c.isMonthEqual(thisMonth);
        }
        /** Last/Next month */
        if (this.selectedOption == 3) {
            if (!this.future) {
                let thisMonth = new Date().minusMonths(1);
                return c.isMonthEqual(thisMonth);
            }
            else {
                let thisMonth = new Date().plusMonths(1);
                return c.isMonthEqual(thisMonth);
            }
        }
        /** Date from and to */
        if (this.selectedOption == 4 && this.from != undefined && this.to != undefined) {
            return (c >= this.from && c <= this.to) || c.isDayMonthEquals(this.from) || c.isDayMonthEquals(this.to)
        }
        return false;
    }

    override initByParam(v: number): void {
        this.selectedOption = v;
    }

    override get activated() { return this.selectedOption != -1; }

    override clear() { this.selectedOption = -1; }

}

/** CHECKBOX FILTER */
export class CheckBoxFilter extends Filter {
    checked: true | false | null;
    checkBoxName: string | undefined;
    showLabel: boolean = true;;

    constructor(label: string, checkBoxName?: string, showLabel = true) {
        super(FilterEnum.CHECKBOXFILTER, label);
        this.checked = null;
        this.checkBoxName = checkBoxName;
        this.showLabel = showLabel;
    }

    override checkFilter(val: boolean | undefined) {
        if (val == undefined) { return false; };
        return val == this.checked
    }

    override get activated() { return this.checked != null; }
    override clear() { this.checked = null; }
}

/** CHECKBOX FILTER */
export class ButtonToggleFilter extends Filter {
    checked: true | false | null;
    checkBoxName: string | undefined;
    showLabel: boolean = true;;

    constructor(label: string, checkBoxName?: string, showLabel = true) {
        super(FilterEnum.BUTTONTOGGLEFILTER, label);
        this.checked = null;
        this.checkBoxName = checkBoxName;
        this.showLabel = showLabel;
    }

    override checkFilter(val: boolean | undefined) {
        if (val == undefined) { return false; };
        return val == this.checked
    }

    override get activated() { return this.checked != null; }
    override clear() { this.checked = null; }
}


/** MASTER (any object) FILTER. */
export class ClassSearcherFilter extends Filter {
    selected_id: number | undefined;
    object: IClassSearcher<any>;
    searcherLabel: string;
    loaded = false;
    constructor(label: string, object: IClassSearcher<any>, searcherLabel: string) {
        super(FilterEnum.CLASSSEARCHER, label);
        this.object = object;
        this.searcherLabel = searcherLabel;
    }

    override checkFilter(id: number | undefined) {
        if (id == undefined) { return false; };
        return id == this.selected_id;
    }

    override get activated() { return this.selected_id != undefined; }
    override clear() { return this.selected_id = undefined; }
}