import { Component } from '@angular/core';
import { SigningTimeService } from 'src/app/services/signing-time.service';

@Component({
  selector: 'app-signing-timer',
  templateUrl: './signing-timer.component.html',
  styleUrls: ['./signing-timer.component.css']
})
export class SigningTimerComponent {
  constructor(public signingS: SigningTimeService) { }

  get isWorking() {
    return this.signingS.signingData?.workingTimer.isWorking;
  }

  get isPaused() {
    return this.signingS.signingData?.workingTimer.isPaused;
  }

  restart() {
    this.signingS.getData();
  }

  // get shouldSigning() {
  //   return this.signingS.signingData?.now.shouldSigning;
  // }

  startStop() {
    this.signingS.startStop()
  }

  pauseResume(){
    this.signingS.pauseResume()
  }

}
