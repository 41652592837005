import { Component, ViewChild } from '@angular/core';
import { M_Contact } from '../models/M_Contact';
import { RouterService } from '../services/router.service';
import { ViewPath } from '../app-routing.module';
import { MASTER_CLIENT_MINIFIY, MASTER_VECHILE_MINIFY } from '../constants/masters';
import { M_Vehicle } from '../models/Vehicles/M_Vehicle';
import { CreateMasterInDialogService } from '../services/create-master-in-dialog.service';
import { ClassSearcherComponent } from '../components/class-searcher/class-searcher.component';
import { BuyProductVehicleFormComponent } from '../buy-product-vehicle-form/buy-product-vehicle-form.component';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ApiService } from '../services/Api/api.service';
import { TemplateFieldsService } from '../services/template-fields.service';
import { PreviewService } from '../services/preview.service';
import { ParamsService } from '../services/params.service';
import { M_Purchase } from '../models/M_Purchase';
import { SnackService } from '../services/snack.service';
import { PS_HIGHLIGHT } from '../constants/constants';

@Component({
  selector: 'app-create-purchase',
  templateUrl: './create-purchase.component.html',
  styleUrls: ['./create-purchase.component.css']
})
export class CreatePurchaseComponent {

  @ViewChild('vehicleSearcher', { static: true }) vehicleSearcher_!: ClassSearcherComponent<M_Vehicle>;
  @ViewChild('csearcher') clientSearcherComponent?: ClassSearcherComponent<M_Contact>
  @ViewChild(BuyProductVehicleFormComponent, { static: true }) buyForm!: BuyProductVehicleFormComponent;
  
  vehicle = MASTER_VECHILE_MINIFY;
  client = MASTER_CLIENT_MINIFIY;
  v = ViewPath;
  selectedFile: File | null = null;
  formData = new FormData();
  minDate = new Date();
  client_select: M_Contact | undefined;
  vehicle_select: M_Vehicle | undefined
  activeTab: string | undefined;
  selectedTab: number = 1;


  constructor(private routerS: RouterService, public createMasterS: CreateMasterInDialogService, private apiS: ApiService,
    private templateService: TemplateFieldsService, public previewS: PreviewService, public paramsS: ParamsService,
    public snackS: SnackService) {
  }

  handleClientSelection(selectedClient: M_Contact) {
    this.client_select = selectedClient;
  }
  changeClient(selectedVehicle: M_Vehicle) {
    this.vehicle_select = selectedVehicle;
  }
  goCreateClient() {
    this.routerS.goTo(this.v.createContact);
  }

  onTabChange(event: MatTabChangeEvent): void {
    const tabIndex = event.index;
    this.selectedTab = tabIndex === 0 ? 1 : 2;
  }

  get sell_form(): M_Vehicle | undefined {
    if (this.buyForm.form.valid) {
      return new M_Vehicle(this.buyForm.form.value);
    }
    return undefined;
  }
  get selectorTab() {
    return this.activeTab;
  }

  create() {
    this.buyForm.form.patchValue({ type: this.selectedTab });  // Añadir el tipo de compra
    const formData = new FormData();
    // Trabajar compra de recambios
    if (this.selectedTab == 1) {
      // Agregar valores del formulario
      Object.keys(this.buyForm.form.value).forEach(key => {
        formData.append(key, this.buyForm.form.get(key)?.value);
      });
      if (this.buyForm.pdfUploadComponent.selectedFile) {
        formData.append('pdf_file', this.buyForm.pdfUploadComponent.selectedFile);

      }
      this.apiS.sellArticles(formData).then(res => {
        if (res instanceof M_Purchase) {
          this.routerS.goWithQueryParams(this.v.purchases, { [PS_HIGHLIGHT]: res.id });
          this.snackS.show("Factura compra recambios creada con éxito");
        }
      })

    }
    // trabajar compra vehiculo
    else {
      // Agregar valores del formulario
      Object.keys(this.buyForm.form.value).forEach(key => {
        formData.append(key, this.buyForm.form.get(key)?.value);
      });
      if (this.buyForm.client_select?.type === 2 || this.buyForm.client_select?.type === 3) {
        if (this.buyForm.pdfUploadComponent.selectedFile) {
          formData.append('pdf_file', this.buyForm.pdfUploadComponent.selectedFile);

        }
      }
      // llamada endpoint
      this.templateService.showTemplateSteps("CV", this.buyForm.vehicle_select?.vehicle_id, "CV", this.buyForm.vehicle_select?.type, undefined, this.buyForm.form.value).afterClosed().subscribe(res => {
        if (res) {
          if (this.buyForm.vehicle_select && this.buyForm.client_select) {
            //preview finish and save pdf after call endpoint sell vehicle.
            this.buyForm.form.patchValue({ mode: "save", buy_transac_id: res });
            this.previewS.showPreview("CV", "CV", undefined, undefined, false, undefined, undefined, undefined, this.buyForm.form.value);
            this.apiS.sellVehicleClient(formData).then(_resp => {
              this.snackS.show("Factura compra vehículo creada con éxito");
            });
          }
        }
      });
    }
  }
  
}
