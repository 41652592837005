import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TypePaymentService } from 'src/app/services/type-payment-service';

@Component({
  selector: 'app-payment-chips',
  templateUrl: './payment-chips.component.html',
  styleUrls: ['./payment-chips.component.css']
})
export class PaymentChipsComponent {

  @Input({ required: true }) fc!: AbstractControl<any, any>
  @Output() onSelect: EventEmitter<number> = new EventEmitter();

  constructor(public typePaymentS: TypePaymentService) { }

  select(v: number) {
    this.fc.setValue(v);
    this.onSelect.emit(v);
  }

  /** Esto esta mal, en realidad el numero 5 es custom. Pero como de backend llega anticipo == 5, pues no lo mostramos */
  get noAdvanceArray() {
    return this.typePaymentS.typePaymentArray.filter(tp => tp.id != 5)
  }

}
