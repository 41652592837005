<app-card [nopadding]="true" class="company-parent-card">
    <div class="df">
        <mat-tab-group [ngClass]="responsiveS.w >= 900 ? 'vertical-tabs' : 'vetical-to-horitzontal'" [selectedIndex]="selectedTab" (selectedTabChange)="tabChanged($event)">
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon>domain</mat-icon>
                    Empresa
                </ng-template>
                <app-company-form></app-company-form>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon [svgIcon]="'file-pdf'"></mat-icon>
                    Documentación
                </ng-template>
                <app-documentation-config></app-documentation-config>
            </mat-tab>
            <mat-tab [labelClass]="'onboarding-company-emails-tab'">
                <ng-template mat-tab-label>
                    <mat-icon>mail</mat-icon>
                    Correo electrónico
                </ng-template>
                <app-smtp></app-smtp>
            </mat-tab>
        </mat-tab-group>
    </div>
</app-card>