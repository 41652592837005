import { Component, Input } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { M_Contact } from 'src/app/models/M_Contact';
import { ResponsiveService } from 'src/app/services/responsive.service';

@Component({
  selector: 'app-contact-client-rectangle',
  templateUrl: './contact-client-rectangle.component.html',
  styleUrls: ['./contact-client-rectangle.component.css']
})
export class ContactClientRectangleComponent {
  v = ViewPath;

  constructor(public responsiveS: ResponsiveService) { }

  @Input({ required: true }) client!: M_Contact;
  @Input() onlyIconOn!: number | undefined;

  get clickable() {
    return !this.client.deleted && this.client.client_id;
  }

  get tooltip(){
    return this.client.deleted ? "Cliente eliminado" : !this.client.client_id ? "Cliente no registrado" : ""
  }
}
