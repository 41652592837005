import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ViewPath } from 'src/app/app-routing.module';
import { getAccentColor, getWarnColor } from 'src/app/utils/FunctionUtils';
import { RouterService } from 'src/app/services/router.service';
import { RolesEnum } from 'src/app/enums/RolesEnum';
import { SETTINGS_TAB } from 'src/app/views/settings/settings.component';

@Component({
  selector: 'app-try-pro-dialog',
  templateUrl: './try-pro-dialog.component.html',
  styleUrls: ['./try-pro-dialog.component.css']
})
export class TryProDialogComponent implements OnInit {
  accent = getAccentColor;
  warn = getWarnColor;
  R = RolesEnum;
  v = ViewPath;
  ST = SETTINGS_TAB;
  
  constructor(public dialogRef: MatDialogRef<TryProDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string | undefined,
    public routerS: RouterService) {
  }
  ngOnInit(): void { }
}
