import { Directive, ElementRef, Renderer2, Input, OnDestroy, AfterContentInit } from '@angular/core';
import { Router, Params } from '@angular/router';
import { ParamsService } from '../services/params.service';
import { Views } from '../custom-classes/View';
import { RouterService } from '../services/router.service';


/**
 * Surround the tag that contains this directive with an anchor (<a>) that contains the path of the "params" input.
 * If the route is in the params service, it will not be necessary to pass the parameter name.
 * 
 * Examples : 
 * 
 * 1- Route on the params service:
 * <button mat-icon-button [go]="v.createProduct" [param]="ps.selected!.product_id">
 *  <mat-icon>edit</mat-icon>
 * </button>
 * 
 * 2 - Route with custom params:
 * <button mat-stroked-button [go]="v.createInvoice" [param]="{'newproductid' : ps.selected!.product_id, 'highlight_prod_id' : ps.selected!.product_id}">
 *  <mat-icon>receipt_long</mat-icon>
 *  Facturar artículo
 * </button> 
 */

@Directive({
  selector: '[go]'
})
export class GoDirective implements AfterContentInit, OnDestroy {

  /** The view we want it to go to */
  @Input('go') link!: Views | undefined;
  /** The wanted params. It can be of type string, number, or params */
  @Input() param?: string | number | Params;
  /** Open on new tab ? */
  @Input() newTab?: boolean;
  /** Replace the current url? */
  @Input() replaceUrl?: boolean
  /** Function to execute after user click */
  @Input() afterGo?: () => void;

  private anchor: HTMLAnchorElement | undefined;
  private clickHandler: (() => void) | undefined;

  constructor(private el: ElementRef, private renderer: Renderer2, private router: Router, private paramsS: ParamsService, private routerS: RouterService) { }

  ngAfterContentInit() {
    setTimeout(() => {
      this.createAnchor();
      this.wrapElementWithAnchor();
      this.addClickListener();
    }, 0);
  }

  ngOnDestroy() {
    this.removeClickListener();
  }

  /** Generates the URL string based on the link path and optional parameters **/
  private getUrlString(): string | undefined {
    if (!this.link) { return undefined; }
    let url = `/${this.link.path}`; // Start with the base path
    if (this.paramObj) {
      const urlTree = this.router.createUrlTree([this.link.path], { queryParams: this.paramObj });
      url = this.router.serializeUrl(urlTree); // Convert the URL tree to a URL string
    }
    return url;
  }

  private get paramObj(): Params | undefined {
    if (!this.link || !this.param) { return undefined; }
    if (typeof this.param != "string" && typeof this.param != "number") {
      return this.param; // Params it's params xd
    }
    const paramName = this.paramsS.getStateNameByView(this.link);
    if (paramName) {
      return { [paramName]: this.param };
    }
    return undefined;
  }

  /** Creates an anchor element with the href attribute set to the URL generated by getUrlString **/
  private createAnchor() {
    let urlString = this.getUrlString();
    if (!this.link || !urlString) { return; }
    this.anchor = this.renderer.createElement('a');
    this.renderer.setAttribute(this.anchor, 'href', urlString);
    if (this.newTab) {
      this.renderer.setAttribute(this.anchor, 'target', '_blank');
    }
    this.renderer.setAttribute(this.anchor, 'rel', 'noopener noreferrer');
    this.renderer.addClass(this.anchor, 'go-anchor');
  }

  private wrapElementWithAnchor() {
    if (!this.anchor) { return; }
    const parent = this.renderer.parentNode(this.el.nativeElement);
    if (parent) {
      /**  Insert the anchor before the original element and then move the original element inside the anchor **/
      this.renderer.insertBefore(parent, this.anchor, this.el.nativeElement);
      this.renderer.appendChild(this.anchor, this.el.nativeElement);
    } else {
      /** Append the original element inside the anchor if there is no parent **/
      this.renderer.appendChild(this.anchor, this.el.nativeElement);
    }
  }

  private addClickListener() {
    if (this.anchor && this.link) {
      this.clickHandler = this.renderer.listen(this.anchor, 'click', (event: MouseEvent) => {

        /** Check if modifier keys (Ctrl, Cmd, Shift) are pressed **/
        if (event.ctrlKey || event.metaKey || event.shiftKey) {
          /** Allow the default action (open in a new tab) **/
          return;
        }

        /** Prevent default action and navigate using Angular router if no modifier keys are pressed **/
        event.preventDefault();

        /** Normal click */
        if (this.paramObj) {
          this.routerS.goWithQueryParams(this.link!, this.paramObj, this.newTab, this.replaceUrl)
        }
        else {
          this.routerS.goTo(this.link!, this.newTab, this.replaceUrl);
        }

        /** Execute the fucntion "afterGo" if exists */
        if (this.afterGo) {
          this.afterGo();
        }

      });
    }
  }

  private removeClickListener() {
    if (this.clickHandler) {
      this.clickHandler();
    }
  }
}
