import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ViewPath } from 'src/app/app-routing.module';
import { Filter, TagFilter, FilterOption, ClassSearcherFilter } from 'src/app/custom-classes/Filter';
import { ApiService } from 'src/app/services/Api/api.service';
import { SubscriptionService } from 'src/app/services/EinaMainData/subscription.service';
import { ParamsService } from 'src/app/services/params.service';
import { SessionService } from 'src/app/services/session.service';
import { PageStructureComponent } from '../page-structure/page-structure.component';
import { M_Brand } from 'src/app/models/M_Brand';
import { Views } from 'src/app/custom-classes/View';
import { filter } from 'src/app/custom-classes/MasterFilter';
import { CreateEditBrandComponent } from 'src/app/create-edit-brand/create-edit-brand.component';
import { VehicleType } from 'src/app/enums/VehicleType';
import { MASTER_BRAND_BRANDMODEL } from 'src/app/constants/masters';
export enum PageBrandEnum {
  VEHICLE_TYPE = 1, 
  DATA_SERIE = 2,
  BRAND = 3,
}
@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.css']
})
export class BrandsComponent {
  brand_filter = MASTER_BRAND_BRANDMODEL;
  filtersToShow: Filter[] = [
    new TagFilter("Vehículo", VehicleType, new FilterOption("Moto", "two_wheeler "), new FilterOption("Coche", "directions_car_filled")).setId(PageBrandEnum.VEHICLE_TYPE),
    new ClassSearcherFilter("Marcas", MASTER_BRAND_BRANDMODEL, "Buscar marca").setId(PageBrandEnum.BRAND),
  ];
  f = filter;
  v = ViewPath
  @ViewChild(PageStructureComponent) ps!: PageStructureComponent<M_Brand>;
  constructor(public apiS: ApiService, private d: MatDialog, public subS: SubscriptionService,
    public sessionS: SessionService, public params: ParamsService){}
  ngOnInit(): void {
    this.apiS.getBrands().then(res => {
      this.ps.initTable(res);
    })
  }
  get quickFilter() {
    let f = this.filtersToShow.find(f => f.id == PageBrandEnum.VEHICLE_TYPE);
    if (f instanceof TagFilter) { return f };
    return undefined;
  }
  viewClick(event: any): Views {

    return this.v.createBrand;
  }
  icon(m:M_Brand){return m.icon;}
  typeVehicle(m:M_Brand){return m.typeVehicle;}
  getIds(event: any): number {

    return event.id;

  }
  onDialogRef(brand:M_Brand | undefined){
    return this.d.open(CreateEditBrandComponent,{data:brand}).afterClosed().subscribe(res=>{});
    // params.go(viewClick($event),getIds($event)) call front brand
  }
}
