import { getArrayOf } from "src/app/utils/FunctionUtils";
import { M_Schedule } from "../M_Schdeule";
import { M_SigningPause } from "./M_SigningPause";

export class M_SigningTime {

    id: number | undefined
    day: Date;
    morning_start: Date | undefined;
    morning_end: Date | undefined;
    reason: string | undefined;
    afternoon_start: Date | undefined;
    afternoon_end: Date | undefined;
    reason_2: string | undefined;
    schedule: M_Schedule;

    pauses: M_SigningPause[] = [];

    constructor(d: any) {
        if (d.day) {
            this.day = new Date(d.day);
        }
        else {
            throw Error("Signing without day")
        }
        this.id = d.id;
        this.morning_start = d.morning_start ? new Date(d.morning_start) : undefined;
        this.morning_end = d.morning_end ? new Date(d.morning_end) : undefined;
        this.reason = d.reason ? d.reason : undefined;
        this.afternoon_start = d.afternoon_start ? new Date(d.afternoon_start) : undefined;
        this.afternoon_end = d.afternoon_end ? new Date(d.afternoon_end) : undefined;
        this.reason_2 = d.reason_2 ? d.reason_2 : undefined;
        this.schedule = d.schedule;
        this.pauses = getArrayOf(M_SigningPause, d.pauses);
    }

    get break() {
        return this.schedule?.break;
    }

    startStop() {
        if (!this.morning_start) { this.morning_start = new Date() }
        else if (!this.morning_end) { this.morning_end = new Date() }
        else if (!this.afternoon_start) { this.afternoon_start = new Date() }
        else if (!this.afternoon_end) { this.afternoon_end = new Date() }
        console.log("To send backend:", this);
    }

    pauseResume() {
        if (this.isPaused) {
            let pause = this.pauses.find(p => p.end_time == undefined);
            if (pause) { 
                pause.end_time = new Date();
            }
        }
        else {
            this.pauses.push(new M_SigningPause({
                start_time: new Date()
            }))
        }

    }

    getTeoricDifference(): { hours: number, minutes: number } {
        const teoricMinutes = this.schedule.getTotalTimeInMinutes();
        const workedMinutes = this.workedMinutes || 0;
        const differenceInMinutes = Math.ceil(teoricMinutes - workedMinutes);

        const absoluteMinutes = Math.abs(differenceInMinutes);
        const hours = Math.floor(absoluteMinutes / 60);
        const minutes = absoluteMinutes % 60;

        const sign = differenceInMinutes < 0 ? -1 : 1;
        return { hours: hours * sign, minutes: minutes * sign };
    }


    get scheduleHasMorining(): boolean {
        return this.schedule.morningStart != "" && this.schedule.morningEnd != "";
    }

    get scheduleHasAfternoon(): boolean {
        return this.schedule.afternoonStart != "" && this.schedule.afternoonEnd != "";
    }

    get canStart() {
        return (!this.morning_start && this.scheduleHasMorining) ||
            (this.morning_start && this.morning_end && !this.afternoon_start && this.scheduleHasAfternoon);
    }

    get canEnd() {
        return (this.morning_start && !this.morning_end && this.scheduleHasMorining) ||
            (this.morning_start && this.morning_end && this.afternoon_start && !this.afternoon_end && this.scheduleHasAfternoon);
    }

    get canPause() {
        return ((this.morning_start != undefined && this.morning_end == undefined) || (this.afternoon_start != undefined && this.afternoon_end == undefined)) &&
            this.pauses.every(p => p.end_time != undefined);
    }

    get canResume() {
        return this.pauses.some(p => p.end_time == undefined);
    }

    get canPauseResume() {
        return this.canPause || this.canResume;
    }

    get isPaused() {
        return this.pauses.some(p => p.end_time == undefined);
    }

    get elapsed(): string {
        const now = new Date();
        let totalSeconds = 0;

        if (!this.morning_start) { return "--:--:--"; }

        /** Morning */
        if (this.morning_start && !this.morning_end) {
            totalSeconds += this.morning_start.differenceWith(now).totals.seconds;
        }
        else if (this.morning_start && this.morning_end) {
            totalSeconds += this.morning_start.differenceWith(this.morning_end).totals.seconds;
        }

        /** Afternoon */
        if (this.afternoon_start && !this.afternoon_end) {
            totalSeconds += this.afternoon_start.differenceWith(now).totals.seconds;
        }
        else if (this.afternoon_start && this.afternoon_end) {
            totalSeconds += this.afternoon_start.differenceWith(this.afternoon_end).totals.seconds;
        }

        /** Subtract pauses */
        for (const pause of this.pauses) {
            if (pause.end_time) {
                totalSeconds -= pause.start_time.differenceWith(pause.end_time).totals.seconds;
            } else {
                totalSeconds -= pause.start_time.differenceWith(now).totals.seconds;
            }
        }

        // Ensure non-negative totalSeconds
        totalSeconds = Math.max(totalSeconds, 0);

        const hours = Math.floor(totalSeconds / 3600).toString().padStart(2, '0');
        const minutes = Math.floor((totalSeconds % 3600) / 60).toString().padStart(2, '0');
        const seconds = (totalSeconds % 60).toString().padStart(2, '0');

        return `${hours}:${minutes}:${seconds}`;
    }


    get isWorking() {
        return (this.morning_start && !this.morning_end || (this.morning_start && this.morning_end && this.afternoon_start && !this.afternoon_end))
        && !this.isPaused;
    }

    // get shouldSigning() {
    //     if (this.isWorking) { return false; }
    //     if (this.morningStart && this.morningEnd && this.afternoonStart && this.afternoonEnd) { return false; }
    //     const now = new Date();
    //     if (!this.morningStart) { 
    //         return this.schedule.morningStart; 
    //     }
    //     else if (!this.morningEnd) { 
    //         return true; 
    //     }
    //     else if (!this.afternoonStart) { 
    //         return true; 
    //     }
    //     else if (!this.afternoonEnd) { 
    //         return true;
    //     }
    //     return false;
    // }

    /** In minutes */
    get workedMinutes() {
        const now = new Date();
        let minutes = 0;

        if (!this.morning_start) { return 0; }

        /** Morning */
        if (this.morning_start && !this.morning_end) {
            minutes += this.morning_start.differenceWith(now).totals.minutes;
        }
        else if (this.morning_start && this.morning_end) {
            minutes += this.morning_start.differenceWith(this.morning_end).totals.minutes;
        }

        /** Afternoon */
        if (this.afternoon_start && !this.afternoon_end) {
            minutes += this.afternoon_start.differenceWith(now).totals.minutes;
        }
        else if (this.afternoon_start && this.afternoon_end) {
            minutes += this.afternoon_start.differenceWith(this.afternoon_end).totals.minutes;
        }

        return minutes;
    }

    editPresence(morningStart: string, morningEnd: string, afternoonStart: string, afternoonEnd: string, reason : string | undefined, reason2 : string | undefined) {
        let newMS = morningStart ? new Date(this.day).setHHmm(morningStart) : undefined;
        let newME = morningEnd ? new Date(this.day).setHHmm(morningEnd) : undefined;
        let newAS = afternoonStart ? new Date(this.day).setHHmm(afternoonStart) : undefined;
        let newAE = afternoonEnd ? new Date(this.day).setHHmm(afternoonEnd) : undefined;

        this.morning_start = newMS;
        this.morning_end = newME;
        this.afternoon_start = newAS;
        this.afternoon_end = newAE;
        this.reason = reason;
        this.reason_2 = reason2;
    }

    get finished() {
        return ((this.schedule.morningStart && this.morning_start) || !this.schedule.morningStart) &&
            ((this.schedule.morningEnd && this.morning_end) || !this.schedule.morningEnd) &&
            ((this.schedule.afternoonStart && this.afternoon_start) || !this.schedule.afternoonStart) &&
            ((this.schedule.afternoonEnd && this.afternoon_end) || !this.schedule.afternoonEnd);
    }

}