<app-view-title>Frontend documentation</app-view-title>


<mat-tab-group color="primary">
    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon>draw</mat-icon>
            Diseño
        </ng-template>
        <div class="tag">Colors</div>

        <div class="df">
            <div *ngFor="let color of ['p', 'a', 'r', 'g', 'o', 'y', 'ice']" style="margin-right: 30px;">
                <div class="df" *ngFor="let c of [0,1,2,3,4]">
                    <div class="frontend_color" [style]="getColor(color, c)"></div>
                    <p>{{getVar(color, c)}}</p>
                </div>
                <span class="fw600">{{color == 'p' ? 'Primary' : color == 'a' ? 'Accent' : color == 'r' ? 'Warn' :
                    ''}}</span>

            </div>
        </div>

        <div class="tag">mat-button</div>
        <div class="tip">La primmera letra mayúscula, el resto minúsculas</div>
        <p>
            <button mat-button color="primary">Button</button>
            <button mat-button color="primary" [disabled]="true">Button</button>

            <button mat-button color="accent">Button</button>
            <button mat-button color="accent" [disabled]="true">Button</button>

            <button mat-button color="warn">Button</button>
            <button mat-button color="warn" [disabled]="true">Button</button>

            <button mat-button color="orange">Button</button>
            <button mat-button color="orange" [disabled]="true">Button</button>

            <button mat-button color="green">Button</button>
            <button mat-button color="green" [disabled]="true">Button</button>

            <button mat-button color="white">Button</button>
            <button mat-button color="white" [disabled]="true">Button</button>
        </p>

        <div class="tag">mat-stroked-button</div>
        <div class="tip">La primmera letra mayúscula, el resto minúsculas</div>
        <p>
            <button mat-stroked-button color="primary">Button</button>
            <button mat-stroked-button color="primary" [disabled]="true">Button</button>

            <button mat-stroked-button color="accent">Button</button>
            <button mat-stroked-button color="accent" [disabled]="true">Button</button>

            <button mat-stroked-button color="warn">Button</button>
            <button mat-stroked-button color="warn" [disabled]="true">Button</button>

            <button mat-stroked-button color="orange">Button</button>
            <button mat-stroked-button color="orange" [disabled]="true">Button</button>

            <button mat-stroked-button color="green">Button</button>
            <button mat-stroked-button color="green" [disabled]="true">Button</button>

            <button mat-stroked-button color="white">Button</button>
            <button mat-stroked-button color="white" [disabled]="true">Button</button>
        </p>


        <div class="tag">mat-raised-button</div>
        <div class="tip">La primmera letra mayúscula, el resto minúsculas</div>
        <p>
            <button mat-raised-button color="primary">Button</button>
            <button mat-raised-button color="primary" [disabled]="true">Button</button>

            <button mat-raised-button color="accent">Button</button>
            <button mat-raised-button color="accent" [disabled]="true">Button</button>

            <button mat-raised-button color="warn">Button</button>
            <button mat-raised-button color="warn" [disabled]="true">Button</button>

            <button mat-raised-button color="orange">Button</button>
            <button mat-raised-button color="orange" [disabled]="true">Button</button>

            <button mat-raised-button color="green">Button</button>
            <button mat-raised-button color="green" [disabled]="true">Button</button>

            <button mat-raised-button color="white">Button</button>
            <button mat-raised-button color="white" [disabled]="true">Button</button>
        </p>


        <div class="tag">mat-flat-button</div>
        <div class="tip">La primmera letra mayúscula, el resto minúsculas</div>
        <p>
            <button mat-flat-button color="primary">Button</button>
            <button mat-flat-button color="primary" [disabled]="true">Button</button>

            <button mat-flat-button color="accent">Button</button>
            <button mat-flat-button color="accent" [disabled]="true">Button</button>

            <button mat-flat-button color="warn">Button</button>
            <button mat-flat-button color="warn" [disabled]="true">Button</button>

            <button mat-flat-button color="orange">Button</button>
            <button mat-flat-button color="orange" [disabled]="true">Button</button>

            <button mat-flat-button color="green">Button</button>
            <button mat-flat-button color="green" [disabled]="true">Button</button>

            <button mat-flat-button color="white">Button</button>
            <button mat-flat-button color="white" [disabled]="true">Button</button>

            <button mat-flat-button color="ice">Button</button>
            <button mat-flat-button color="ice" [disabled]="true">Button</button>

        </p>

        <div class="tag">mat-icon-button</div>
        <div class="tip">Sin tips</div>
        <p>
            <button mat-icon-button color="primary"><mat-icon>person</mat-icon></button>
            <button mat-icon-button color="primary" [disabled]="true"><mat-icon>person</mat-icon></button>

            <button mat-icon-button color="accent"><mat-icon>person</mat-icon></button>
            <button mat-icon-button color="accent" [disabled]="true"><mat-icon>person</mat-icon></button>

            <button mat-icon-button color="warn"><mat-icon>person</mat-icon></button>
            <button mat-icon-button color="warn" [disabled]="true"><mat-icon>person</mat-icon></button>

            <button mat-icon-button color="orange"><mat-icon>person</mat-icon></button>
            <button mat-icon-button color="orange" [disabled]="true"><mat-icon>person</mat-icon></button>

            <button mat-icon-button color="green"><mat-icon>person</mat-icon></button>
            <button mat-icon-button color="green" [disabled]="true"><mat-icon>person</mat-icon></button>

            <button mat-icon-button color="white"><mat-icon>person</mat-icon></button>
            <button mat-icon-button color="white" [disabled]="true"><mat-icon>person</mat-icon></button>
        </p>


        <div class="tag">mat-fab</div>
        <div class="tip">Por defecto, color primario</div>
        <p>
            <button mat-fab color="primary"><mat-icon>person</mat-icon></button>
            <button mat-fab color="primary" [disabled]="true"><mat-icon>person</mat-icon></button>

            <button mat-fab color="accent"><mat-icon>person</mat-icon></button>
            <button mat-fab color="accent" [disabled]="true"><mat-icon>person</mat-icon></button>

            <button mat-fab color="warn"><mat-icon>person</mat-icon></button>
            <button mat-fab color="warn" [disabled]="true"><mat-icon>person</mat-icon></button>

            <button mat-fab color="orange"><mat-icon>person</mat-icon></button>
            <button mat-fab color="orange" [disabled]="true"><mat-icon>person</mat-icon></button>

            <button mat-fab color="green"><mat-icon>person</mat-icon></button>
            <button mat-fab color="green" [disabled]="true"><mat-icon>person</mat-icon></button>

            <button mat-fab color="white"><mat-icon>person</mat-icon></button>
            <button mat-fab color="white" [disabled]="true"><mat-icon>person</mat-icon></button>
        </p>

        <div class="tag">mat-mini-fab</div>
        <div class="tip">Por defecto, color primario</div>
        <p>
            <button mat-mini-fab color="primary"><mat-icon>person</mat-icon></button>
            <button mat-mini-fab color="primary" [disabled]="true"><mat-icon>person</mat-icon></button>

            <button mat-mini-fab color="accent"><mat-icon>person</mat-icon></button>
            <button mat-mini-fab color="accent" [disabled]="true"><mat-icon>person</mat-icon></button>

            <button mat-mini-fab color="warn"><mat-icon>person</mat-icon></button>
            <button mat-mini-fab color="warn" [disabled]="true"><mat-icon>person</mat-icon></button>

            <button mat-mini-fab color="orange"><mat-icon>person</mat-icon></button>
            <button mat-mini-fab color="orange" [disabled]="true"><mat-icon>person</mat-icon></button>

            <button mat-mini-fab color="green"><mat-icon>person</mat-icon></button>
            <button mat-mini-fab color="green" [disabled]="true"><mat-icon>person</mat-icon></button>

            <button mat-mini-fab color="white"><mat-icon>person</mat-icon></button>
            <button mat-mini-fab color="white" [disabled]="true"><mat-icon>person</mat-icon></button>
        </p>


        <div class="tag">Fab y mini-fab circulares</div>
        <div class="tip">Se añade la classe 'circular' al tag del button</div>
        <p>
            <button mat-fab color="primary" class="circular"><mat-icon>person</mat-icon></button>
            <button mat-fab color="primary" [disabled]="true" class="circular"><mat-icon>person</mat-icon></button>
            <button mat-mini-fab color="primary" class="circular"><mat-icon>person</mat-icon></button>
            <button mat-mini-fab color="primary" [disabled]="true" class="circular"><mat-icon>person</mat-icon></button>
        </p>


        <div class="tag">mat-icon</div>
        Se usan <a href="https://fonts.google.com/icons?icon.set=Material+Symbols" [target]="'_blank'">Material
            Symbols</a>


        <div class="tag">mat-icon (Outline)</div>
        <div class="tip">Por defecto, los mat-icon son outline</div>
        <mat-icon>search</mat-icon>
        <mat-icon>home</mat-icon>
        <mat-icon>settings</mat-icon>
        <mat-icon>favorite</mat-icon>

        <div class="tag">mat-icon (Filled)</div>
        <div class="tip">Si queremos usar los iconos filled, usar la directiva [filled]=true</div>
        <div>
            <mat-icon [filled]="true">search</mat-icon>
            <mat-icon [filled]="true">home</mat-icon>
            <mat-icon [filled]="true">settings</mat-icon>
            <mat-icon [filled]="true">favorite</mat-icon>
        </div>

        <div class="tag">mat-icon (Colors)</div>
        <div class="tip">Sin tips</div>
        <mat-icon>person</mat-icon>
        <mat-icon color="accent">person</mat-icon>
        <mat-icon color="warn">person</mat-icon>
        <mat-icon color="orange">person</mat-icon>
        <mat-icon color="green">person</mat-icon>
        <mat-icon color="white">person</mat-icon>


        <div class="tag">Lord Icons</div>
        <p>Los Lord Icons deben ser los "Wired Lineal"</p>
        <p><a href="https://siniwin.atlassian.net/wiki/spaces/SINIWIN/pages/1011908702/Lord+Icons"> Lord Icons
                Confluence</a></p>
        <p><a href="https://lordicon.com/icons/wired/lineal"> Lord Icons Web</a></p>
        <div class="tip">Existen 5 tamaños predefinidos: <span class="tag">.lord-small</span>, <span
                class="tag">.lord-medium,</span>, <span class="tag">.lord-big</span>, <span
                class="tag">.lord-large</span>,
            <span class="tag">.lord-huge</span>
        </div>
        <script src="https://cdn.lordicon.com/lordicon.js"></script>
        <lord-icon class="lord-small" src="https://cdn.lordicon.com/jpzirtpz.json" trigger="hover"></lord-icon>
        <lord-icon class="lord-medium" src="https://cdn.lordicon.com/jpzirtpz.json" trigger="hover"></lord-icon>
        <lord-icon class="lord-big" src="https://cdn.lordicon.com/jpzirtpz.json" trigger="hover"></lord-icon>
        <lord-icon class="lord-large" src="https://cdn.lordicon.com/jpzirtpz.json" trigger="hover"></lord-icon>
        <lord-icon class="lord-huge" src="https://cdn.lordicon.com/jpzirtpz.json" trigger="hover"></lord-icon>

    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon>code</mat-icon>
            Components
        </ng-template>
        <div class="tag">app-view-title</div>
        <div class="tip">La primmera letra mayúscula, el resto minúsculas</div>
        <app-view-title>Ejemplo</app-view-title>

        <div class="tag">app-card-title</div>
        <div class="tip">La primmera letra mayúscula, el resto minúsculas</div>
        <app-card-title>Ejemplo</app-card-title>

        <div class="tag">app-card-subtitle</div>
        <div class="tip">La primmera letra mayúscula, el resto minúsculas</div>
        <app-card-subtitle [first]="true">Ejemplo</app-card-subtitle>

        <div class="tag">app-card</div>
        <div class="tip">Siempre utilizar los tags de <span class="tag">app-card-title</span> y <span
                class="tag">app-card-subtitle</span></div>
        <app-card>
            <app-card-title>Card title</app-card-title>
            <app-card-subtitle [first]="true">Card subtitle 1</app-card-subtitle>
            <p>Some text</p>
            <app-card-subtitle>Card subtitle 2</app-card-subtitle>
            <p>Some text 2</p>
        </app-card>


        <div class="tag">Diálogos</div>
        <div class="tip">Utilizar SIEMPRE la plantilla del componente <span class="tag">StandardDialogComponent</span>
            (copy
            &
            paste)</div>
        <div class="tip">Par los títulos y los subtitilos se utilizan las directivas <span
                class="tag">mat-dialog-title</span> y
            <span class="tag">mat-dialog-subtitle</span>
        </div>
        <button mat-flat-button color="warn" (click)="standarDialog()">Ver ejemplo</button>
        <button mat-flat-button color="warn" (click)="noCardDialog()">Dialogo sin contorno de 'card'</button>

        <div class="tag">Diálogos de confirmación</div>
        <div class="tip">Utilizar SIEMPRE el servicio de <span class="tag">ConfirmDialogService</span></div>
        <button mat-flat-button color="primary" (click)="confirmDialog('confirm')">Ver ejemplo (confirm)</button>
        <button mat-flat-button color="accent" (click)="confirmDialog('info')">Ver ejemplo (info)</button>
        <button mat-flat-button color="orange" (click)="confirmDialog('warn')">Ver ejemplo (warn)</button>
        <button mat-flat-button color="warn" (click)="confirmDialog('danger')">Ver ejemplo (danger)</button>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon>history_edu</mat-icon>
            Directivas
        </ng-template>
        <div class="tag">Roles directive</div>
        <div class="tip">
            Usar la directica <span class="tag">admin</span>, <span class="tag">advisor</span>, <span
                class="tag">recambista</span>, <span class="tag">mechanic</span> o <span class="tag">mechanic</span> a
            algún tag HTML.
            El tag en cuestión, solo lo podrá ver el usuario del rol especificado.
        </div>
        <div class="tip">
            Si un elemento lo tiene que ver más de un rol, puedes usar la directiva <span class="tag">roles</span>
            Ejemplo <span class="tag">
                < sometag [roles]="[R.ADMIN, R.ADVISER]" / sometag>
            </span>
        </div>
        <div class="tip">
            Por otro lado, si quieres especificar los roles que no puede ver el tag, usar la directicva <span
                class="tag">not-roles</span>
            Ejemplo <span class="tag">
                < sometag [not-roles]="[R.COMERCIAL, R.RECAMBISTA]" / sometag>
            </span>
        </div>


        <div class="tag">Modules directive</div>
        <div class="tip">
            <p>Usar las directivas <span class="tag">recambios</span> o <span class="tag">vnvo</span> en algún tag.
                El tag en cuestión, solo lo podrá ver los usuarios con el módulo especificado.
            </p>
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon>list_alt</mat-icon>
            Formularios
        </ng-template>

        <p class="mt10"></p>
        <app-card-subtitle>Los formularios siempre deben seguir esta estructura : </app-card-subtitle>

        <mat-tab-group color="primary" class="formtab">
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon>code</mat-icon>
                    HTML
                </ng-template>
                <pre><code [highlight]="aef.formExample" [languages]="['html']"></code></pre>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon>visibility</mat-icon>
                    Ejemplo
                </ng-template>
                <app-example-form #aef></app-example-form>
            </mat-tab>
        </mat-tab-group>
    </mat-tab>
</mat-tab-group>