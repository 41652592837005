import { ChangeDetectorRef, Component, Input, Optional, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_Vehicle } from '../../models/Vehicles/M_Vehicle';
import { AlreadyExistsService } from '../../services/already-exists.service';
import { VehicleType } from '../../enums/VehicleType';
import { ImageToggleComponent } from '../image-toggle/image-toggle.component';
import { MASTER_CLIENT, MASTER_CLIENT_MINIFIY } from '../../constants/masters';
import { DialogConfirmPlateComponent } from './dialog-confirm-plate/dialog-confirm-plate.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RegistrationTaxEnum, registrationText } from '../../enums/RegistrationTaxEnum';
import { ComercialVehicleType } from '../../enums/ComercialVehicleType';
import { M_Model } from '../../models/M_Model';
import { RouterService } from 'src/app/services/router.service';
import { ClassSearcherComponent } from '../class-searcher/class-searcher.component';
import { M_Contact } from 'src/app/models/M_Contact';
import { M_Brand } from 'src/app/models/M_Brand';
import { BrandsModelsSeriesComponent } from 'src/app/brands-models-series/brands-models-series.component';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';
import { createVehicleForm } from './vehicleGenericLogic';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-vehicleform',
  templateUrl: './vehicleform.component.html',
  styleUrls: ['./vehicleform.component.css']
})
export class VehicleformComponent {

  M_CLIENT = MASTER_CLIENT;
  client = MASTER_CLIENT_MINIFIY;
  RTE = RegistrationTaxEnum;
  CVT = ComercialVehicleType;

  registrationText = registrationText;

  /** Show only the requiered fields */
  @Input() onlyRequired = false;
  @Input() requiredFieldsTitle?: string

  @ViewChild('brandsModelSerie') brandsModelSerie?: BrandsModelsSeriesComponent;
  @ViewChild(ImageToggleComponent) serieToggle!: ImageToggleComponent;
  @ViewChild("clientSearcher") ownerSearcher?: ClassSearcherComponent<M_Contact>;

  v = ViewPath;
  public form: UntypedFormGroup;
  lastb1 = "";
  lastb2 = "";
  selectedType: number | null = null;
  VT = VehicleType;
  minDate: Date;
  vehicle: M_Vehicle | undefined
  client_select: M_Contact | undefined
  freePlateEedit = false;
  allData: M_Brand[] | undefined;
  constructor(private fb: UntypedFormBuilder, private existsS: AlreadyExistsService,
    private apiS: ApiService, private chdRef: ChangeDetectorRef, private routerS: RouterService, private d: MatDialog,
    @Optional() public dialogRef: MatDialogRef<any>, public companyS: CompanyService) {

    /** Form generation */
    this.form = createVehicleForm(this.fb, this.apiS, this.existsS, () => this.getCurrentVehicle());

    this.minDate = new Date();
    this.form.get("battery_1_no")?.disable();
    this.form.get("battery_2_no")?.disable();


    /** When the engine_type 'select' changes, diable or enable some fields */
    this.form.get('engine_type')?.valueChanges.subscribe(val => {
      let b1 = this.form.get("battery_1_no")!;
      let b2 = this.form.get("battery_2_no")!;
      /** If the motor type is gas, disable the electric vehicle fields */
      if (val == 0) {
        this.lastb1 = b1.value;
        b1.setValue('');
        b1.disable();
        this.lastb2 = b2.value;
        b2.disable();
        b2.setValue('');
      }
      else {
        b1.enable();
        b1.patchValue(this.lastb1)
        b2.enable();
        b2.patchValue(this.lastb2)
      }
    })

    this.form.get("type")?.valueChanges.subscribe(val => {
      this.form.patchValue({ serie: null });
    })

    this.form.get('model')?.valueChanges.subscribe(val => {
      if (val instanceof M_Model && val.details) {
        this.form.patchValue({ price: val.details.price })
        this.form.patchValue({ cost: val.details.cost })
        this.form.patchValue({ registration_tax: val.details.tax_registration })
        this.form.patchValue({ brand_id: val.id_brand })
        this.form.patchValue({ model_id: val.id })

      }
      else if (val instanceof M_Model) {
        this.form.patchValue({ brand_id: val.id_brand })
        this.form.patchValue({ model_id: val.id })
      }
    })

    this.form.get('brand')?.valueChanges.subscribe(val => {
      if (val instanceof M_Brand) {
        this.form.patchValue({ brand_id: val.id });
      }
    });
    if(!this.vehicle){
      this.brandsModelSerie?.onFilterValueChange(1);
    }
    
  }
  onTypeChange(event: any) {
    const selectedValue = event.value;

    let filterValue: number;  // Definimos la variable

    // Realizamos la conversión de valores
    if (selectedValue === 1) {
      filterValue = 0;  // Moto -> 0
    } else if (selectedValue === 2) {
      filterValue = 1;  // Coche -> 1
    } else {
      filterValue = -1;  // Un valor por defecto si el valor no es 1 ni 2
    }
    // Ahora `filterValue` tiene siempre un valor definido
    if (this.brandsModelSerie) {
      this.brandsModelSerie.onFilterValueChange(filterValue);
    }
  }
  getCurrentVehicle(): M_Vehicle | undefined {
    return this.vehicle;
  }

  enablePlateEdition() {
    this.d.open(DialogConfirmPlateComponent, {
      data: {
        title: "Edición de matrícula",
        message: "¿Estas seguro que quieres editar la matrícula?",
      }
    }).afterClosed().subscribe(res => {
      if (res != true) {
        this.form.get('license')?.disable();
      } else {
        this.freePlateEedit = true;
        this.form.get('license')?.enable();
      }
    }
    );
  }

  showMe(v: VehicleType): boolean {
    let value = this.form.get('type')?.value;
    if (value) {
      return this.form.get('type')?.value == v;
    }
    return false;
  }

  goCreateClient() {
    this.routerS.goTo(this.v.createContact);
  }

  /** Patch the current form with a vehicle. */
  patchFormWithVehicle(vehicle: M_Vehicle) {
    this.vehicle = vehicle;
    this.chdRef.detectChanges();
    if (this.vehicle != undefined) {
      this.form.patchValue(this.vehicle)
    }

    let licenseControl = this.form.get('license');
    if (licenseControl?.value) {
      this.form.get('license')?.disable();
    }
  }

  get licenseBlocked() {
    return this.vehicle != undefined && this.form.get('license')?.value && !this.freePlateEedit;
  }

  get loaded() {
    return true;
  }

  get isOnDialog() {
    return Object.keys(this.dialogRef).length != 0;
  }
}