import { IPageStructureItem } from "../interfaces/IPageStructureItem";
import { match } from "../services/search.service";

export class M_Product_Move implements IPageStructureItem {
    id: number;
    invoice_id: number;
    buy_id: number;
    product_id: number;
    user_id: number;
    quantity: number
    type: "manual" | "import";
    details: "rem" | "add";
    created_at: Date;
    updated_at: Date | undefined;
    control_stock: boolean | undefined;
    name: string | undefined
    constructor(d: any) {
        this.id = d.id;
        this.name = d.name;
        this.invoice_id = d.invoice_id;
        this.buy_id = d.buy_id;
        this.user_id = d.user_id;
        this.product_id = d.product_id;
        this.quantity = d.quantity;
        this.type = d.type;
        this.details = d.details;
        this.created_at = new Date(d.created_at);
        this.updated_at = d.updated_at ? new Date(d.updated_at) : undefined;
        this.control_stock = d.control_stock;
    }

    isAdd() {
        return this.details == 'add';
    }

    isRemove() {
        return this.details == 'rem';
    }

    isImport() {
        return this.type == 'import';
    }

    get itemId() {
        return this.id;
    }

    defaultSearchFilter(text: string): boolean {
        const invoiceIdString = this.invoice_id !== null && this.invoice_id !== undefined ? this.invoice_id.toString() : '';
        const quantityString = this.quantity !== null && this.quantity !== undefined ? this.quantity.toString() : '';
        const type = this.type || ''; // Asegúrate de que type no sea null o undefined
        const isAddString = this.isAdd() ? 'importado' : 'salida';

        return match(text, invoiceIdString, quantityString, type, isAddString);
    }
}