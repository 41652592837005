<div class="ad-first-section">

    <div class="df fw1 fww">
        <div class="mra">
            <!-- Title -->
            <p class="advanced-title df aic mr10_i">
                <ng-content select="app-advanced-title"></ng-content>
            </p>

            <!-- Subtitle -->
            <p class="advanced-subtitle">
                <ng-content select="app-advanced-subtitle"></ng-content>
            </p>

            <!-- Heading -->
            <p class="advanced-heading">
                <ng-content select="app-advanced-heading"></ng-content>
            </p>

            <!-- Heading 2 -->
            <p class="advanced-heading2">
                <ng-content select="app-advanced-heading2"></ng-content>
            </p>

        </div>

        <!-- Main actions-->
        <ng-content select="app-advanced-main-actions"></ng-content>

    </div>

    <!-- Side actions -->
    <button mat-mini-fab [color]="'white'" [mat-menu-trigger-for]="sideActions" *ngIf="showButton">
        <mat-icon>more_horiz</mat-icon>
    </button>

    <!-- Side actions menu -->
    <mat-menu #sideActions>
        <ng-content select="app-advanced-menu-actions"></ng-content>
    </mat-menu>

</div>