import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { M_Order } from 'src/app/models/M_Order';
import { AllIncomingOrdersInDialogComponent } from '../all-incoming-orders-in-dialog/all-incoming-orders-in-dialog.component';

@Component({
  selector: 'app-incoming-orders-provider',
  templateUrl: './incoming-orders-provider.component.html',
  styleUrls: ['./incoming-orders-provider.component.css']
})
export class IncomingOrdersProviderComponent implements OnInit {

  MAX_RESULTS = 2;
  @Input({ required: true }) orders!: M_Order[];
  filtered_orders: M_Order[] = [];

  constructor(private d: MatDialog) { }

  ngOnInit(): void {
    this.filtered_orders = this.orders.filter(order => !order.finished);
  }

  openAllOrders() {
    this.d.open(AllIncomingOrdersInDialogComponent, { data: this.filtered_orders, autoFocus: false })
  }

}
