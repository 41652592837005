import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { ConceptsLineTableComponent } from 'src/app/components/concepts-line-table/concepts-line-table.component';
import { VehicleType } from 'src/app/enums/VehicleType';
import { M_ComercialBudget } from 'src/app/models/M_ComercialBudget';
import { M_Concept } from 'src/app/models/M_Concept';
import { Location } from '@angular/common';
import { MASTER_CLIENT } from 'src/app/constants/masters';
import { M_Contact } from 'src/app/models/M_Contact';
import { MatDialog } from '@angular/material/dialog';
import { M_Vehicle } from 'src/app/models/Vehicles/M_Vehicle';
import { SellVehicleComponent, SellVehicleDialogData } from '../sell-vehicle/sell-vehicle.component';
import { ParamsService } from 'src/app/services/params.service';
import { M_Invoice } from 'src/app/models/M_Invoice';
import { IProductLineTableComponent } from 'src/app/interfaces/IProductLineTableComponent';
import { M_Product } from 'src/app/models/Products/M_Product';
import { M_CustomProduct } from 'src/app/models/Products/M_CustomProduct';
import { ProductLineTableComponent } from 'src/app/components/product-line-table/product-line-table/product-line-table.component';
import { IConceptLineTableComponent } from 'src/app/interfaces/IConceptLineTableComponent';
import { M_Model } from 'src/app/models/M_Model';
import { ParameterStateComponent } from 'src/app/components/parameter-state/parameter-state.component';
import { ClassSearcherComponent } from 'src/app/components/class-searcher/class-searcher.component';
import { RouterService } from 'src/app/services/router.service';
import { SnackService } from 'src/app/services/snack.service';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';
import { or_status_close } from 'src/app/custom-classes/or_states';
import { M_StockVehicles } from 'src/app/models/M_StockVehicles';
import { MatSelectChange } from '@angular/material/select';
import { SteppFormAndFileComponent } from 'src/app/stepp-form-and-file/stepp-form-and-file.component';
import { BrandsModelsSeriesComponent } from 'src/app/brands-models-series/brands-models-series.component';
import { M_Brand } from 'src/app/models/M_Brand';
import { M_Serie } from 'src/app/models/M_Serie';

@Component({
  selector: 'app-create-edit-comercial-budget',
  templateUrl: './create-edit-comercial-budget.component.html',
  styleUrls: ['./create-edit-comercial-budget.component.css']
})

export class CreateEditComercialBudgetComponent extends ParameterStateComponent implements IProductLineTableComponent, IConceptLineTableComponent {

  v = ViewPath;
  master_client = MASTER_CLIENT;

  @ViewChild(ProductLineTableComponent) productLineTable!: ProductLineTableComponent;
  @ViewChild('clientSearcher') clientSearcherComponent?: ClassSearcherComponent<M_Contact>
  @ViewChild(BrandsModelsSeriesComponent) brandsModelSerie?: BrandsModelsSeriesComponent;
  @ViewChild(ConceptsLineTableComponent) conceptLineTable!: ConceptsLineTableComponent;

  form: UntypedFormGroup;
  formHasChanges = false;
  cBudget: M_ComercialBudget | undefined;

  loaded_brand: boolean = false;
  stockVehicle: M_StockVehicles | undefined;
  isChecked: boolean = false;
  provisionalConcepts: M_Concept[] = [];
  provisionalProducts: (M_Product | M_CustomProduct)[] = [];

  Idbrand_model: number = 0;
  id_model: number = 0;
  items: M_Vehicle[] = [];
  selectedVehicle: M_Vehicle | undefined;

  budgetLoaded = false;


  constructor(routerS: RouterService, route: ActivatedRoute, fb: FormBuilder, private apiS: ApiService,
    private confirmD: ConfirmDialogService, private snackS: SnackService, private location: Location, private d: MatDialog,
    private paramsS: ParamsService, private chdRef: ChangeDetectorRef) {
    super(routerS, route, ["budget", "client"]);
    this.form = fb.group({
      id: [],
      client_id: ['', Validators.required],
      brand_id: ['', Validators.required],
      model_id: ['', Validators.required],
      price: ['', Validators.required],
      type: [VehicleType.bike],
      brand: [''],
      model: [''],
      serie: [''],
      serie_id: [''],
      vehicle: [''],
      vehicle_id: [''],
      reserve: ['']
    })



  }

  override onParam(param: string, value: string): void {
    if (param == "budget") {
      this.apiS.vnvo.getComercialBudget(value.getNumber()).then(res => {
        this.cBudget = res;
        this.setComercialBudgetToForm(this.cBudget);
        this.cargarDatosSelect(this.cBudget.brand_id, this.cBudget.model_id);
        this.startDetectingChanges();
        this.budgetLoaded = true;
      })
    }
    if (param == "client") {
      this.apiS.getClientById(value.getNumber()).then(res => {
        this.clientSearcherComponent?.select(value.getNumber())
        this.budgetLoaded = true;
      })
    }
    
  }

  override noParams(): void {
    this.startDetectingChanges();
    this.budgetLoaded = true;
  }

  startDetectingChanges() {
    console.log("Start detecting changes")
    this.form.valueChanges.subscribe(v => {
      if (this.loaded && !this.form.disabled) {
        this.formHasChanges = true;
      }
    })
  }

  stateChange(event: MatSelectChange) {
    this.selectedVehicle = event.value
  }
  ngAfterViewInit() {
    this.form_;
  }
  addConcept(c: M_Concept): void {
    this.getConcepts().push(c);
  }

  getConcepts() {
    return this.cBudget ? this.cBudget.concepts : this.provisionalConcepts;
  }

  handleFieldClear() {
    this.stockVehicle = undefined;
    this.selectedVehicle = undefined;
  }

  getClient() {
    return this.clientSearcherComponent?.selected;
  }

  getClientDiscount(p: M_Product | M_CustomProduct): number | null {
    let disc = this.clientSearcherComponent?.selected?.getClientDiscount(p);
    return disc ? disc : null;
  }

  get hasSelectedClient() {
    return this.clientSearcherComponent?.selected != undefined;
  }

  get sinStock() {
    if (this.stockVehicle != undefined) {
      return !this.stockVehicle.available_vehicles || this.stockVehicle.available_vehicles.length === 0;
    }
    return false;
  }

  patchPrice(m: M_Model) {
    this.Idbrand_model = m.id_brand
    this.id_model = m.id;
    if (m.details?.price) {
      this.form.patchValue({ price: m.details.price })
    }
    this.cargarDatosSelect(this.Idbrand_model, this.id_model);
  }

  cargarDatosSelect(brand: number, model: number) {
    this.apiS.getStockBrandAndModel(brand, model).then(res => {
      if (res instanceof M_StockVehicles) {
        this.loaded_brand = true;
        this.stockVehicle = res;
      }
    });
  }

  setComercialBudgetToForm(cBudget: M_ComercialBudget) {
    this.form.patchValue(cBudget);
    console.log("sd", cBudget);
    this.clientSearcherComponent?.select(cBudget.client_id);
    setTimeout(() => {
      if (cBudget.brand_id) {
        this.brandsModelSerie?.brandSearcherComponent?.select(cBudget.brand_id);
      }
      if (cBudget.serie_id) {
        this.brandsModelSerie?.serieSearcherComponent?.select(cBudget.serie_id, { emitEvent: false });
      }
      if (cBudget.model_id) {
        this.brandsModelSerie?.modelSearcherComponent?.select(cBudget.model_id);
      }
      this.chdRef.markForCheck(); // Actualiza el estado de detección de cambios
    }, 0);
    this.chdRef.detectChanges();
    this.clientSearcherComponent?.select(cBudget.client_id);
    this.formHasChanges = false;
    this.updateDisabled();
  }

  updateDisabled() {
    /** This is bad. Refactor needed */
    if (this.cBudget?.status.pending) {
      this.form.get('brand_id')?.disable({ emitEvent: true });
      this.form.get('model_id')?.disable({ emitEvent: true });
      this.form.get('vehicle')?.disable({ emitEvent: true });
      this.form.get('price')?.disable({ emitEvent: false });
    }
  }

  goBudgets() {
    this.routerS.goTo(ViewPath.comercialBudget);
  }

  createEdit(): Promise<boolean> {
    return new Promise(resolve => {
      if (!this.isCreateEditEnabled) {
        resolve(true);
      }
      if (this.selectedVehicle != undefined) {
        this.form.patchValue({ vehicle_id: this.selectedVehicle.vehicle_id });
      }
      this.form.patchValue({ reserve: this.isChecked });


      if (this.brandsModelSerie?.modelSearcherComponent?.selected instanceof M_Model) {
        let m = this.brandsModelSerie.modelSearcherComponent?.selected
        this.form.patchValue({ model: m, model_name: m.name, model_id: m.id });
      }
      if (this.brandsModelSerie?.brandSearcherComponent?.selected instanceof M_Brand) {
        let b = this.brandsModelSerie.brandSearcherComponent?.selected
        this.form.patchValue({ brand: b, brand_name: b.name, brand_id: b.id });
      }
      if (this.brandsModelSerie?.serieSearcherComponent?.selected instanceof M_Serie) {
        let s = this.brandsModelSerie.serieSearcherComponent?.selected
        this.form.patchValue({ serie: s, serie_name: s.name, serie_id: s.id });
      }
      let budget = new M_ComercialBudget(this.form.getRawValue());
      budget.concepts = this.getConcepts();
      budget.products = this.getProducts();


      return this.apiS.vnvo.createEditComercialBudget(budget).then(res => {
        this.cBudget = new M_ComercialBudget(res);
        this.setComercialBudgetToForm(this.cBudget);
        this.cBudget.id = res.id;
        this.cBudget.token = res.token;
        this.form.patchValue({ id: this.cBudget.id});
        this.chdRef.detectChanges();
        this.conceptLineTable.clearUnsaved();
        this.productLineTable.clearUnsaved();
        this.formHasChanges = false;
        this.location.replaceState("/" + this.v.createComercialBudget.path + "?budget=" + this.cBudget.id);
        resolve(true);
      })
    })
  }

  getProducts() {
    return this.cBudget ? this.cBudget.products : this.provisionalProducts;
  }

  addProduct(p: M_Product | M_CustomProduct): void {
    p.id = undefined;
    this.getProducts().push(p);
  }

  addTime(time: M_CustomProduct) {
    this.addProduct(time)
    this.chdRef.detectChanges();
  }

  addComment(comment: M_CustomProduct): void {
    this.addProduct(comment)
    this.chdRef.detectChanges();
  }

  removeProduct(p: M_CustomProduct | M_Product): void {
    this.formHasChanges = true;
    this.getProducts().removeElement(p);
  }

  deleteComercialB() {
    if (!this.cBudget) { return; }
    this.confirmD.show({
      title: "Eliminar presupuesto",
      body: "¿Está seguro de que desea eliminar este presupuesto?",
      confirmTxt: "Sí",
      type: "danger"
    }).afterClosed().subscribe(res => {
      if (res) {
        this.apiS.vnvo.deleteConmercialBudget(this.cBudget!.id).then(res => {
          this.routerS.goTo(ViewPath.comercialBudget);
          this.snackS.show("Presupuesto eliminado")
        })
      }
    })
  }

  closeBudget() {
    if (!this.cBudget) { return; }
    this.createEdit().then(res => {
      this.apiS.vnvo.closeComercialBudget(this.cBudget!.id).then(res => {
        this.cBudget!.status = or_status_close;
        this.updateDisabled();
      })
    })
  }


  /** !! */
  toStock(invoiceAfterCreate: boolean = false) {
    if (!this.cBudget) { return; }
    const vehicle = new M_Vehicle(this.cBudget);
    this.d.open<SteppFormAndFileComponent>(SteppFormAndFileComponent, { data: { budget: this.cBudget, concepts: this.cBudget.concepts, vehicle: vehicle }, autoFocus: false }).afterClosed().subscribe(res => {
      if (res) {
        this.cBudget!.vehicle = res;
      }
    });
  }

  goToVehicle(v: M_Vehicle) {
    this.paramsS.goVehicleDetails(v)
  }

  invoice(v: M_Vehicle) {
    if (!this.cBudget) { return; }
    if (!this.cBudget.vehicle) {
      this.toStock(true);
    }
    else {
      this.d.open<SellVehicleComponent, SellVehicleDialogData>(SellVehicleComponent, { data: { budget: this.cBudget }, autoFocus: false }).afterClosed().subscribe(res => {
        if (res instanceof M_Invoice) {
          this.cBudget!.invoice = res;
        }
      })
    }
  }

  goToInvoice(i: M_Invoice) {
    this.paramsS.go(ViewPath.invoiceDetails, i.id!);
  }

  get blocksLine(): boolean { return true; }

  get titleBudget() {
    return this.cBudget?.num_budget ? this.cBudget.num_budget : "Nuevo presupuesto";
  }

  get isCreateEditEnabled() {
    return this.isEdit ? this.isEditEnabled : this.isCreateEnabled;
  }

  get isEditing() {
    return this.isEdit || this.isEditEnabled;
  }

  get isEditEnabled() {
    return this.form.valid &&
      this.cBudget != undefined && !this.cBudget.status.pending &&
      (this.conceptLineTable?.hasChanges || this.productLineTable?.hasChanges || this.formHasChanges)
  }
  get form_() {
    return this.form.value;
  }
  private get isCreateEnabled() { return this.form.valid; }
  get isEdit() { return this.cBudget != undefined; }
  get loaded() { return this.budgetLoaded && this.clientSearcherComponent?.loaded; }
  get isClosed() { return this.cBudget && this.cBudget.status.pending; }
  get isReserve() { return this.cBudget?.reserve; }
  get showDeleteMenu() { return this.isEdit && !this.isClosed }
  get isOpenOrUdefined() { return !this.cBudget || this.cBudget.status.open; }



}
