import { defaultSchedule, defaultSigningTime } from "../../constants/constants";
import { getArrayOf } from "../../utils/FunctionUtils";
import { M_Center } from "../M_Center";
import { M_Schedule } from "../M_Schdeule";
import { M_SigningTime } from "./M_SigningTime";
import { M_User } from "../M_User";

export class M_Signing {

    center: M_Center;
    workingTimer: M_SigningTime;
    schedules: M_Schedule[] = [];
    private history: M_SigningTime[] = [];

    refDay: Date | null;
    fullHistory: M_SigningTime[] = [];


    constructor(d: any) {

        console.log("Constructing M_Signing")

        this.center = new M_Center(d.center);
        let u = new M_User(d.user);
        this.schedules = u.schedules.length ? u.schedules : defaultSchedule();

        /** Generating today schedule */
        this.workingTimer = d.workingTimer ? new M_SigningTime(d.workingTimer) : defaultSigningTime();
        this.setScheduleByDay(this.schedules, this.workingTimer);

        this.refDay = new Date();

        this.history = getArrayOf(M_SigningTime, d.user.working_time_history);
        this.history.forEach(h => {
            this.setScheduleByDay(this.schedules, h);
        })
        this.generateFullCalendar();
    }

    setScheduleByDay(s: M_Schedule[], signingTime: M_SigningTime): M_Schedule {
        return signingTime.schedule = s[signingTime.day.weekDay()];
    }

    changeSchedule(s: M_Schedule[]) {
        this.schedules = s;
        this.setScheduleByDay(this.schedules, this.workingTimer);
        this.history.forEach(signing => {
            this.setScheduleByDay(this.schedules, signing);
        })
    }

    refresHistory(st?: M_SigningTime) {

        if (!st) {
            this.generateFullCalendar();
            return;
        }

        let index = this.history.findIndex(h => h.id == st.id);
        if (index != -1) {
            this.history[index] = st;
        }
        if (index == -1) {
            this.history.push(st);
        }

        if (this.workingTimer.id == st.id) {
            this.workingTimer = st;
        }

        this.generateFullCalendar();
    }

    private generateFullCalendar() {
        console.log("Refreshing full history")
        if (!this.refDay) { return; }
        this.fullHistory = [];
        let today = new Date();
        let from = new Date(this.refDay.firstMonthDay());
        let to = new Date(this.refDay.lastMonthDay());

        for (let aux = from; aux <= to; aux.plusDays(1)) {
            if (aux < today || aux.isDayMonthEquals(today)) {
                let already = this.history.find(h => h.day.isDayMonthEquals(aux));
                if (already) {
                    this.fullHistory.push(already);
                }
                else {
                    let signingToPush = new M_SigningTime({ day: new Date(aux) })
                    this.setScheduleByDay(this.schedules, signingToPush)
                    this.fullHistory.push(signingToPush);
                }
            }
        }
        // this.fullHistory.sort((a, b) => a.day > b.day ? -1 : 1)
    }
}