import { Views } from "../custom-classes/View";
import { Endpoint } from "../custom-classes/Endpoint";
import { endpoints } from "../constants/Endpoints";
import { errorCodes } from "../constants/ErrorCodes";

export interface I_ProjectConfiguration {
    landingView: Views;
    multiUserLanding: Views;
    dashboardView: Views;
    errorView: Views;
    errorHandlerEndpoint?: Endpoint;
    checkVersionEndpoint?: Endpoint;
}


export class ProjectConfiguration implements I_ProjectConfiguration {
    /** All the app endpoints */
    endpoints: Endpoint[];
    /** Main view no auth */
    landingView: Views;
    /** Main view for multi user login */
    multiUserLanding : Views;
    /** The main view, usually Dashboard */
    dashboardView: Views;
    /** Error view */
    errorView: Views;
    /** Endpoint to send the errors. Send a `CustomLog` class on the body. Check CustomErrorHandler.ts*/
    errorHandlerEndpoint?: Endpoint;
    /** Endpoint to check the last forntend version. Usually used to force refresh de frontend cached content. 
     *  The current version is on 'version' of logalStorage.
     * 
     *  The endpoint return should be  : ["version", number]
    */
    checkVersionEndpoint?: Endpoint;
    /** Controlled errors by the app. The error should return : {  error_code : number} */
    errorCodes?: Map<number, string>;

    constructor(data: I_ProjectConfiguration) {
        this.endpoints = this.parseEndpoints(endpoints);
        this.errorCodes = errorCodes;
        this.landingView = data.landingView;
        this.multiUserLanding = data.multiUserLanding;
        this.errorView = data.errorView;
        this.dashboardView = data.dashboardView;
        this.errorHandlerEndpoint = data.errorHandlerEndpoint;
        this.checkVersionEndpoint = data.checkVersionEndpoint;
    }

    parseEndpoints(endpoints: Endpoint[] | object) {
        if (Array.isArray(endpoints)) {
            return endpoints;
        }
        else {
            let allEndpoints: Endpoint[] = []
            Object.keys(endpoints).forEach(key => {
                allEndpoints.push(endpoints[key as keyof typeof endpoints])
            });
            return allEndpoints;
        }
    }
}