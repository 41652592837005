import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { projectConfiguration } from 'src/app/app.module';
import { M_Action } from 'src/app/models/M_Action';
import { M_User } from 'src/app/models/M_User';

@Component({
  selector: 'lib-or-week-card',
  templateUrl: './or-week-card.component.html',
  styleUrls: ['./or-week-card.component.css', '../carga-taller/drag-and-drop-grid/grid-card/grid-card.component.css']
})
export class OrWeekCardComponent implements OnInit {
  @Input() item!: M_Action;
  @Input() canAssign!: boolean;
  @Input() allUsers!: M_User[];
  @Input() day!: Date;
  @Input() onFilters!: boolean;
  @Input() canContact: boolean | undefined;
  @Output() deliveryWarn: EventEmitter<M_Action> = new EventEmitter();
  @Output() onAssignUser: EventEmitter<M_User> = new EventEmitter();
  @Output() onGoOR: EventEmitter<any> = new EventEmitter();
  core = projectConfiguration;

  constructor() { }

  ngOnInit(): void {}

  preventCardClick(e: Event) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

}
