import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  constructor() { }
  match(searched: string, ...value: string[]) {
    return match(searched, ...value);
  }
}

export function match(searched: string | undefined, ...value: (string | number | undefined)[]) {
  if (searched == undefined) {
    return false;
  }
  if (value) {
    for (let i = 0; i < value.length; i++) {
      let val = value[i];
      if (val != undefined) {
        let finalValue: string = typeof val == "number" ? val.toString() : val;
        finalValue = finalValue.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase();
        searched = searched.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase();

        if (finalValue.includes(searched)) {
          return true;
        }

        if (finalValue.length > searched.length) {
          finalValue = finalValue.substring(0, searched.length)
        }

        if (finalValue == searched) {
          return true;
        }
      }
    }
  }
  return false;
}