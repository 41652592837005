<!-- CHIPS SELECTOR ON DESKTOP -->
<div class="paymentChips fww" [moreThan]="715">
    <div *ngFor="let payment of noAdvanceArray" class="chip-container">
        <mat-chip [value]="payment.id" [ngClass]="{'selected-chip': payment.id === fc.value}"
            (click)="select(payment.id)">
            <mat-icon matChipAvatar smartIcon class="mr5">{{payment.icon}}</mat-icon>
            {{payment.payment}}
        </mat-chip>
    </div>
</div>

<!-- MAT SELECT ON PHONE -->
<div class="df" [lessOr]="715">
    <mat-form-field [appearance]="'outline'" class="w100">
        <mat-label>Tipo de cobro</mat-label>
        <mat-select #selectedValue (selectionChange)="select($event.value)" [value]="fc.value">
            <mat-option *ngFor="let payment of noAdvanceArray" [value]="payment.id">
                <mat-icon matChipAvatar smartIcon class="mr5">{{payment.icon}}</mat-icon>
                <p class="nmb df aic">{{payment.payment}}</p>
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>