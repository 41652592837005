import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { M_Action } from '../../models/M_Action';
import { M_Invoice } from '../../models/M_Invoice';
import { ParamsService } from '../../services/params.service';
import { MatTableDataSource } from '@angular/material/table';
import { FiltersComponent } from '../filters/filters.component';
import { Filter, FilterOption, TagFilter } from 'src/app/custom-classes/Filter';
import { or_status } from 'src/app/custom-classes/or_states';
import { or_types } from 'src/app/custom-classes/or_types';
import { RouterService } from 'src/app/services/router.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { match } from 'src/app/services/search.service';
import { M_Purchase } from 'src/app/models/M_Purchase';
import { _MatTabGroupBase } from '@angular/material/tabs';
import { INVOICE_TYPE_FILTER, OR_TYPE_FILTER } from 'src/app/constants/SharedFilters';

@Component({
  selector: 'app-action-history',
  templateUrl: './action-history.component.html',
  styleUrls: ['./action-history.component.css'],
})
export class ActionHistoryComponent {
  @Input() actions: M_Action[] = [];
  @Input() invoices: M_Invoice[] = [];
  @Input() purchases: M_Purchase[] = [];
  @Input() clientSide!: boolean;
  @Input() notShowFiler!: boolean;
  @Input() isVehicle!: boolean;
  @ViewChild(FiltersComponent) filter_html!: FiltersComponent;
  @Output() onSearch: EventEmitter<string> = new EventEmitter();
  v = ViewPath;
  min = 3;
  numberResults: number = this.min;
  data: (M_Invoice | M_Action | M_Purchase)[] = [];
  dataSource: MatTableDataSource<M_Action | M_Invoice | M_Purchase> = new MatTableDataSource<M_Action | M_Invoice | M_Purchase>(
    []
  );
  dataMostrada: (M_Action | M_Invoice | M_Purchase)[] = [];
  filasAdicionales = 5;
  filteredActions: (M_Invoice | M_Action | M_Purchase)[] = [];
  textoBoton: string = 'Ver más';
  statusFilter: TagFilter = new TagFilter(
    'Estado',
    or_status,
    new FilterOption('Abierta'),
    new FilterOption('Cerrada'),
    new FilterOption('Facturada')
  ).setId(1);
  typeFilterInvoice: TagFilter = INVOICE_TYPE_FILTER.setId(3);
  action_filters: Filter[] = [
    this.statusFilter,
    OR_TYPE_FILTER().setId(2),
    this.typeFilterInvoice,
  ];
  dataFiltrada: (M_Action | M_Invoice | M_Purchase)[] = [];

  constructor(
    private chdRef: ChangeDetectorRef,
    public params: ParamsService,
    private routerS: RouterService,
    public responsiveS: ResponsiveService,
    public paramsS: ParamsService,
  ) { }

  ngAfterViewInit() {
    this.actions = this.actions.filter((a) => !a.type_id.isRa);
    this.data.push(...this.actions, ...this.invoices.filter((i) => !i.isDraft), ...this.purchases);
    this.data.sort((a, b) =>
      a.created_at! > b.created_at! ? -1 : 1
    );
    this.chdRef.detectChanges();
    this.dataSource.data = this.actions;
    this.dataMostrada = this.data.slice(0, 5);
    if (this.isVehicle) {
      this.action_filters.splice(this.action_filters.length - 1);
    }
  }

  toggleMaxResults() {
    this.numberResults =
      this.numberResults == this.min ? this.data.length : this.min;
  }

  handleClickByAction(d: M_Action | M_Invoice) {
    if (d instanceof M_Action) {
      if (d.isOr()) {
        this.params.go(this.v.editOr, d!.id);
      } else if (d.isBudget()) {
        this.params.go(this.v.createEditWorkshopBudget, d!.id);
      }
    } else {
      this.paramsS.go(this.v.invoiceDetails, d.id!);
    }
  }

  isAction(val: M_Action | M_Invoice | M_Purchase): val is M_Action {
    return val instanceof M_Action;
  }
  isInvoice(val: M_Action | M_Invoice | M_Purchase): val is M_Invoice {
    return val instanceof M_Invoice;
  }
  isComercial(val: M_Action | M_Invoice | M_Purchase): val is M_Purchase {
    return val instanceof M_Purchase;
  }
  getIcon(val: M_Action | M_Invoice | M_Purchase) {
    if (val instanceof M_Action) {
      if (val.isOr()) {
        return 'build';
      }
      return 'calculate';
    }
    else if (val instanceof M_Invoice) {
      return 'receipt_long';
    }
    else if (val instanceof M_Purchase) {
      return 'sell';
    }
    else {
      return 'receipt_long'
    }
  }
  get displayedColumns() {
    return this.responsiveS.w > 514
      ? ['Ornumber', 'status', 'delivery', 'worker']
      : ['Ornumber', 'delivery', 'worker'];
  }

  goItem(val: M_Action | M_Invoice) {
    if (val instanceof M_Action) {
      if (val.isBudget()) {
        this.params.go(this.v.createEditWorkshopBudget, val!.id);
      } else {
        this.routerS.goWithQueryParams(ViewPath.editOr, { or: val.id });
      }
    } else {
      this.params.go(this.v.invoiceDetails, val.id!);
    }
  }

  hasAssigned(element: M_Action) {
    return element.assigned != undefined;
  }
  get isMin() {
    return this.numberResults == this.min;
  }

  get paginable() {
    return this.data.length > this.min;
  }
  mostrarMasFilas() {
    if (this.dataMostrada.length === 0) {
      this.dataMostrada = this.data.slice(0, 5); // Mostrar las primeras 5 filas
      this.textoBoton = 'Ver más';
    } else if (this.dataMostrada.length === this.data.length) {
      this.dataMostrada = this.data.slice(0, 5); // Mostrar las primeras 5 filas nuevamente
      this.textoBoton = 'Ver más';
    } else {
      this.dataMostrada = this.data; // Mostrar todas las filas
      this.textoBoton = 'Ocultar';
    }
  }

  applySearchFilter(searchValue: string) {
    this.dataMostrada = this.data.filter((element: M_Action | M_Invoice | M_Purchase) => {
      if (element instanceof M_Action) {
        return match(searchValue, element.title_id)
      }
      else if (element instanceof M_Invoice) {
        return match(searchValue, element.company_scope_id);
      }
      else {
        return match(searchValue, element.num_purchase);
      }
    });
  }

  refreshAction(filters: Filter[]) {
    this.dataFiltrada = [];
    if (!this.filter_html.activeFilters.length) {
      this.dataMostrada = this.data.slice(0, 5);
    } else {
      this.data.forEach((i) => {
        filters.forEach((e) => {
          if (e instanceof TagFilter && e.id == 1) {
            if (i instanceof M_Action) {
              let isOk = e.checkFilterByString(i.status.name);
              if (isOk) {
                this.dataFiltrada.push(i);
              }
            }
          } else if (e instanceof TagFilter && e.id == 2) {
            if (i instanceof M_Action) {
              let isOk = e.checkFilter(i.type.map((obj) => obj.num));
              if (isOk) {
                this.dataFiltrada.push(i);
              }
            }
          } else if (e instanceof TagFilter && e.id == 3) {
            if (i instanceof M_Invoice) {
              if (i.type_invoice != undefined) {
                let isOk = e.checkFilter([(i.type_invoice.num - 1)]);
                if (isOk) {
                  this.dataFiltrada.push(i);
                }
              }
            }
          }
        });
      });

      // Eliminar duplicados de los datos filtrados
      const uniqueElements = this.removeDuplicates(this.dataFiltrada, []);
      this.dataMostrada = uniqueElements;
    }
  }

  removeDuplicates(array1: (M_Action | M_Invoice | M_Purchase)[], array2: (M_Action | M_Invoice | M_Purchase)[]): (M_Action | M_Invoice | M_Purchase)[] {
    // Combinar los dos arrays
    const combinedArray = [...array1, ...array2];

    const seenIds = new Set<number | undefined>();
    const uniqueItems: (M_Action | M_Invoice | M_Purchase)[] = [];

    for (const item of combinedArray) {
      if (item.id !== undefined && !seenIds.has(item.id)) {
        seenIds.add(item.id);
        uniqueItems.push(item);
      }
    }

    return uniqueItems;
  }
  getFirstLetter(type: or_types) {
    return type.name.charAt(0);
  }
  isDiferentArray(parametro: any): boolean {
    return Array.isArray(parametro);
  }
}
