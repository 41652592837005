import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { endpoints } from 'src/app/constants/Endpoints';
import { action_type_budget, action_types } from 'src/app/custom-classes/action_types';
import { or_types } from 'src/app/custom-classes/or_types';
import { M_Action } from 'src/app/models/M_Action';
import { M_ActiveReception } from 'src/app/models/M_ActiveReception';
import { M_Contact } from 'src/app/models/M_Contact';
import { M_LastMovements } from 'src/app/models/M_LastMovement';
import { M_CustomProduct } from 'src/app/models/Products/M_CustomProduct';
import { M_Product } from 'src/app/models/Products/M_Product';
import { moveKeyToRoot } from 'src/app/utils/jsonUtils';

@Injectable({
  providedIn: 'root'
})
export class ApiActionService {

  constructor(private http: HttpClient) { }

  actionTypes() {
    return new Promise<any>(resolve => {
      this.http.get<any>(endpoints.actionTypes.url).subscribe(
        data => { resolve(data); });
    })
  }


  addClientVehicleAction(action_id: number, client_id: number, vehicle_id: number) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.addClientVehicleAction.url,
        { action_id: action_id, client_id: client_id, vehicle_id: vehicle_id }).subscribe(data => {
          resolve(data);
        });
    })
  }

  getAction(action_id: number) {
    return new Promise<M_Action | undefined>(resolve => {
      this.http.post<any>(endpoints.getAction.url, { action_id: action_id }).subscribe(data => {
        if (data == "Forbidden") {
          resolve(data);
        }
        resolve(new M_Action(data));
      },
        (_error) => {
          resolve(undefined)
        });
    })
  }

  addEditGroup(action_id: number, group_id: number | undefined, title: string, groupType: or_types) {
    return new Promise<any>(resolve => {
      let obj = {};
      if (group_id == undefined) { obj = { action_id: action_id, title: title, type: groupType.num, } }
      else { obj = { action_id: action_id, group_id: group_id, title: title, type: groupType.num } }
      this.http.post<any>(endpoints.addEditGroup.url, obj).subscribe(data => {
        resolve(data);
      }, _error => {
        resolve(-1)
      });
    })
  }


  addTask(group_id: number, product: M_CustomProduct | M_Product, quantity: number, disc: number) {
    return new Promise<any>(resolve => {
      let v = {};
      if (product instanceof M_Product) {
        v = {
          group_id: group_id,
          custom: 0,
          product_id: product.product_id,
          quantity: quantity,
          discount: disc,
        }
      }
      else {
        v = {
          group_id: group_id,
          custom: 1,
          name: product.name,
          reference: quantity,
          buy_price: product.discount,
          price: product.price,

        }
      }
      this.http.post<any>(endpoints.addTask.url, v).subscribe(data => {
        resolve(data);
      });
    })
  }

  assignAction(action_id: number, user_id: number) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.assignAction.url,
        { action_id: action_id, user_id: user_id }).subscribe(data => {
          resolve(data);
        });
    })
  }

  editDelivery(action_id: number, date: Date) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.editDelivery.url,
        { action_id: action_id, date: date.dataBaseFormat() }).subscribe(data => {
          resolve(data);
        });
    })
  }

  updateSchedule(action_id: number, date: Date) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.updateSchedule.url,
        { action_id: action_id, date: date.dataBaseFormat() }).subscribe(data => {
          resolve(data);
        });
    })
  }

  actions(type: action_types) {
    return new Promise<M_Action[]>(resolve => {
      this.http.post<any>(endpoints.actions.url, { type: type.num }).subscribe(data => {
        let action: M_Action[] = [];
        for (let i = 0; i < data.length; i++) {
          if (type.num == action_type_budget.num) {
            data[i]["isBudget"] = true;
          }
          action.push(new M_Action(data[i]));
        }
        resolve(action);
      });
    })
  }

  createOR(action: M_ActiveReception) {
    let data = action;
    /** Pass the 'client' key to te JSON root to simplificate backend functions */
    if (action.client) { data = moveKeyToRoot(action, "client"); }
    return new Promise<{ "or_id": number, "token": string, "action_id": number }>(resolve => {
      this.http.post<any>(endpoints.createOR.url, data).subscribe(data => {
        if (data) {
          resolve(data);
        }
      });
    })
  }

  /**
 * Well, seems like location and discount are different on "Tasks"
 */
  addEditTasks(t: (M_CustomProduct | M_Product)[]) {
    return new Promise<number[]>(resolve => {
      t.forEach(prod => {
        let product = prod as any;
        let selected_location = prod.instanceofProduct() ? prod.selected_location : null;
        let discount = prod.discount == null ? 0 : prod.discount; // For some reason, canno't be null
        product['location'] = selected_location;
        product['discount'] = discount;
      })
      this.http.post<any>(endpoints.addEditTasks.url, { tasks: t }).subscribe(data => {
        if (data.length != t.length) { }
        else {
          t.forEach((task, index) => {
            task.task_id = data[index];
          })
        }
        resolve(data);
      });
    })
  }

  schedules(from: Date, to: Date, showLoading: boolean) {
    endpoints.schedules.showLoading = showLoading;
    return new Promise<M_Action[]>(resolve => {
      this.http.post<any>(endpoints.schedules.url,
        { start_date: from.dataBaseFormat(), end_date: to.dataBaseFormat() }).subscribe(data => {
          let action: M_Action[] = [];
          for (let i = 0; i < data.length; i++) {
            action.push(new M_Action(data[i]));
          }
          resolve(action);
        });
    })
  }

  rmGroup(group_id: number) {
    return new Promise<M_Action[]>(resolve => {
      this.http.post<any>(endpoints.rmGroup.url, { group_id: group_id }).subscribe(data => {
        resolve(data);
      });
    })
  }

  rmTask(task_id: number | undefined) {
    if (task_id) {
      return new Promise<M_Action[]>(resolve => {
        this.http.post<any>(endpoints.rmTask.url, { task_id: task_id }).subscribe(data => {
          resolve(data);
        });
      })
    }
    else {
      return true;
    }
  }

  rmAction(action_id: number) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.rmAction.url, { action_id: action_id }).subscribe(data => {
        resolve(data);
      });
    })
  }

  invoiceOR(action_type_id : action_types, group_ids: number[], client: M_Contact | undefined, comments: string, last_movement: M_LastMovements[], date: Date, franchise?: number, expedient?: string | null) {
    return new Promise<{ "invoice_id": number, "invoice_name": string, "token": string }[]>(resolve => {
      var obj = {
        type_id : action_type_id.num,
        group_ids: group_ids,
        user_id: client?.client_id,
        comments: comments,
        last_movement: last_movement,
        date: date.dataBaseFormat(),
        franchise: franchise,
        expedient: expedient
      }
      this.http.post<any>(endpoints.invoiceOR.url, obj).subscribe(data => {
        resolve(data);
      });
    })
  }

  sortTask(tasks_ids: number[]) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.sortTask.url, { tasks_ids: tasks_ids }).subscribe(data => {
        resolve(data);
      },
        _error => {
          resolve(undefined)
        });
    })
  }

  changeTask(task_id: number, group_id: number) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.changeTask.url, { task_id: task_id, group_id: group_id }).subscribe(data => {
        resolve(data);
      },
        _error => {
          resolve(undefined)
        });
    })
  }

  createBudget(client_id: number, vehicle_id: number, title: string) {
    return new Promise<M_Action>(resolve => {
      this.http.post<any>(endpoints.createBudget.url, { client_id: client_id, vehicle_id: vehicle_id, title: title }).subscribe(data => {
        resolve(new M_Action(data));
      });
    })
  }

  createRecambiosBudget(client_id: number) {
    return new Promise<M_Action>(resolve => {
      this.http.post<any>(endpoints.createRecambiosBudget.url, { client_id: client_id }).subscribe(data => {
        resolve(new M_Action(data));
      });
    })
  }

  closeBudget(action_id: number) {
    return new Promise<M_Action>(resolve => {
      this.http.post<string>(endpoints.closeBudget.url, { action_id: action_id }).subscribe(data => {
        resolve(new M_Action(data));
      });
    })
  }

  closeGroup(group_id: number) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.closeGroup.url, { group_id: group_id }).subscribe(data => {
        resolve(data);
      });
    })
  }

  openGroup(group_id: number) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.openGroup.url, { group_id: group_id }).subscribe(data => {
        resolve(data);
      });
    })
  }

  reorderActions(action_ids: number[], showLoading: boolean = true) {
    endpoints.reorderActions.showLoading = showLoading;
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.reorderActions.url, { action_ids: action_ids }).subscribe(data => {
        resolve(data);
      },
        _error => {
          resolve(-1);
        });
    })
  }

  editGeneralOrInfo(action_id: number, km: number | undefined, assigned_to: number | undefined, schedule: Date, delivery?: Date, notes?: string, fuel?: number, hours?: number, minutes?: number) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.editAction.url, {
        action_id: action_id,
        km: km,
        assigned_to: assigned_to ? assigned_to : null,
        schedule: schedule.dataBaseFormat(),
        delivery: delivery?.dataBaseFormat(),
        notes: notes,
        fuel: fuel,
        estimated_time_hours: hours,
        estimated_time_minutes: minutes,
      }).subscribe(data => {
        resolve(data);
      });
    })
  }

}
