import { Component, Input, OnInit } from '@angular/core';
import { invoice_states, invoice_states_pending } from '../../custom-classes/invoice_states';
import { IInvoiceLabel } from 'src/app/interfaces/IInvoiceLabel';
import { M_Invoice } from 'src/app/models/M_Invoice';
import { M_Purchase } from 'src/app/models/M_Purchase';

@Component({
  selector: 'app-invoice-status',
  templateUrl: './invoice-status.component.html',
  styleUrls: ['./invoice-status.component.css']
})
export class InvoiceStatusComponent implements OnInit {

  @Input() showText : boolean = false;
  @Input() circularStatus : boolean = false;
  @Input() obj? : IInvoiceLabel | string;
  @Input() status! : invoice_states;
  @Input() object! : M_Invoice | M_Purchase;
  constructor() {}

  ngOnInit(): void {
    if (this.status == undefined){
      this.status = invoice_states_pending;
    }
  }

  get taglabel(){
    
    return this.obj? typeof this.obj == "string" ? this.obj : this.obj.tagLabel : "";
  }
}
