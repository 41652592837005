<video playsinline loop autoplay [muted]="true" id="loginVideo">
    <source src="../../../assets/video/login_back.mp4" type="video/mp4">
</video>

<div class="login-gradient"></div>
<div class="card-recover-password">
    <app-card [contentLoaded]="loaded_">
        <div *ngIf="loaded_; else error">
            <app-card-title>{{isCreate ? 'Crear contraseña' : 'Modificar contraseña'}}</app-card-title>
            <form [formGroup]="form">
                <div class="divFormColumn">
                    <app-password-input #pass [form]="form" formCName="password"></app-password-input>
                    <app-repeat-passowrd-input #pass2 class_="mt-20" [otherPass]="pass" [form]="form"
                        formCName="password_confirmation"></app-repeat-passowrd-input>
                    <div class="df jcc" style="margin-top: 20px;">
                        <button (click)="updatePassword()" color="primary" [disabled]="pass2.getDisabled()" mat-flat-button
                            style="width: 250px;">
                            {{isCreate ? 'Crear contraseña' : 'Modificar contraseña'}}
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <ng-template #error>
            <app-card-title class="tac">Página no encontrada</app-card-title>
            <p>Por favor, revisa el link que te hemos proporcionado.</p>
            <p>Ten en cuenta que es posible que el enlace ya haya sido utilizado</p>
            <button mat-flat-button color="primary" (click)="goLogin()"><mat-icon class="c_w">arrow_back</mat-icon>Ir al
                login</button>
        </ng-template>
    </app-card>
</div>