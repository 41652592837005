<ng-container *ngIf="isOnDialog">
    <app-close-dialog-button></app-close-dialog-button>
    <p mat-dialog-title>Horario</p>
</ng-container>


<div style="width: fit-content;" mat-dialog-content
    [ngClass]="{'conditional-mat-dialog-content' : !isOnDialog}">
    <app-card [noStyle]="true" [contentLoaded]="!loading">
        <ng-container *ngIf="isOnDialog">
            <mat-divider></mat-divider>
            <div class="df aic jcfs bc_b0">
                <div [ngTemplateOutlet]="accountingCheck"></div>
                <mat-icon class="vam mla"
                    [matTooltip]="'Se contabilizarán las horas de este usuario para la productividad de la carga de taller.'">info</mat-icon>
            </div>
            <mat-divider></mat-divider>
            <div class="mb20"></div>
        </ng-container>

        <app-scheudle-table [fullW]="fullWidthTable" [disabled]="undefined" [showDay]="true" [horario]="horario"></app-scheudle-table>


        <div class="df jcc" *ngIf="!isOnDialog">
            <div [ngTemplateOutlet]="saveTemplate"></div>
        </div>
    </app-card>
</div>


<div mat-dialog-actions *ngIf="isOnDialog">
    <div [ngTemplateOutlet]="saveTemplate"></div>
</div>

<ng-template #accountingCheck>
    <mat-checkbox *ngIf="showCheckbox && !loading" [formControl]="fc">Contabilizar para taller </mat-checkbox>
</ng-template>

<ng-template #saveTemplate>
    <button *ngIf="showSave && !loading" mat-flat-button color="primary" [disabled]="isDisabled"
        (click)="save()">Guardar</button>
</ng-template>