import { M_Product } from "./Products/M_Product";
import { M_StoreHouse } from "./M_StoreHouse";
import { MovTypeEnum } from "../enums/MovTypeEnum";
import { M_Location } from "./M_Location";
import { M_Order } from "./M_Order";
import { ViewPath } from "src/app/app-routing.module";
import { Views } from "../custom-classes/View";

export class M_Fault {
    id: number;
    mov_type: MovTypeEnum;
    mov_id: number; //Id of OR, Budget, Invoice...
    company_id: number;
    product_id: number;
    quantity: number;
    received: number;
    requested: boolean;
    document: string;
    storehouse: M_StoreHouse | undefined;
    location: M_Location | undefined;
    product: M_Product | undefined;
    ref_product: string | undefined;
    name_product: string | undefined;
    created_at: Date;
    updated_at: Date;
    order: M_Order | undefined;
    client_id: number | undefined;
    constructor(d: any) {
        this.id = d.id;
        this.company_id = d.company_id;
        this.product_id = d.product_id;
        this.quantity = d.quantity;
        this.received = d.received;
        this.requested = d.requested ? true : false;
        this.mov_id = d.mov_id;
        this.mov_type = d.mov_type;
        this.document = d.document;
        this.client_id = d.client_id;
        if (d.ref_product) {
            this.ref_product = d.ref_product;
        }
        if (d.name_product) {
            this.name_product = d.name_product;
        }
        this.created_at = new Date(d.created_at);
        this.updated_at = new Date(d.updated_at);
        if (d.product) {
            this.product = new M_Product(d.product);
            this.product.fault_id = this.id;
        }
        this.storehouse = d.location?.store ? new M_StoreHouse(d.location.store) : undefined;
        this.location = d.location ? new M_Location(d.location) : undefined;
        if (d.order) {
            this.order = d.order;
        }

        if (this.location && this.product) {
            this.product.fault_id = this.id;
            this.product.addNewLocation(this.location);
            this.product.initQuanitity(this.quantity);
        }
    }

    get getDestView(): Views | undefined {
        if (this.mov_type == MovTypeEnum.OR) { return ViewPath.editOr }
        if (this.mov_type == MovTypeEnum.RP) { return ViewPath.createEditWorkshopBudget }
        if (this.mov_type == MovTypeEnum.RM) { return ViewPath.contactDetails }
        if (this.mov_type == MovTypeEnum.AC) { return ViewPath.createEditAlbaran }
        return undefined;
    }

    get getMasterId(): number | undefined {
        if (this.mov_type == MovTypeEnum.OR) { return this.mov_id }
        if (this.mov_type == MovTypeEnum.RP) { return this.mov_id }
        if (this.mov_type == MovTypeEnum.RM) { return this.client_id }
        if (this.mov_type == MovTypeEnum.AC) { return this.mov_id }
        return undefined;
    }

    get hasProviders() {
        return this.product?.providers.length;
    }

    get isManual() {
        return this.mov_type == MovTypeEnum.FM;
    }

    get isPending() {
        return this.quantity != this.received;
    }

    get onTheWay() {
        return this.requested && this.isPending;
    }
}
