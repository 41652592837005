import { Component, Input } from '@angular/core';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';
import { ViewPath } from 'src/app/app-routing.module';
import { BaseModule } from 'src/app/models/Modules/Factory/BaseModule';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-module',
  templateUrl: './module.component.html',
  styleUrls: ['./module.component.css']
})
export class ModuleComponent {

  v = ViewPath;

  @Input({ required: true }) module!: BaseModule;
  @Input() welcomeDialog: boolean = false;

  welcomeFc : FormControl = new FormControl<boolean>(false)

  constructor(public companyS: CompanyService) { }

}
