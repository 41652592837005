type RGB = [number, number, number];

const defaultWhite = "#EFEFEF"
const defaultBlack = "#2F4858" // --> var(--t) text color

export class CustomColor {
    private color: string;
    private bestContrast: string;
    constructor(color?: string) {
        if (color == undefined || color == null) {
            this.color = defaultWhite;
        }
        else {
            this.color = color;
        }
        this.bestContrast = this.calculateBestContrast();
    }

    get value() {
        return this.color;
    }

    get contrastValue(): string {
        return this.bestContrast
    }

    private getRgbColorFromHex(hex: string) {
        hex = hex.slice(1);
        const value = parseInt(hex, 16);
        const r = (value >> 16) & 255;
        const g = (value >> 8) & 255;
        const b = value & 255;

        return [r, g, b] as RGB;
    };

    private calculateBestContrast() {
        let blackText = this.contrast(this.getRgbColorFromHex(defaultBlack), this.getRgbColorFromHex(this.color));
        let whiteText = this.contrast(this.getRgbColorFromHex(defaultWhite), this.getRgbColorFromHex(this.color));
        if (blackText < whiteText) {
            if (whiteText - blackText < 0.2) {
                return defaultWhite;
            }
        }
        return blackText > whiteText ? defaultWhite : defaultBlack;
    }

    private contrast(foregroundColor: RGB, backgroundColor: RGB) {
        const foregroundLumiance = this.luminance(foregroundColor);
        const backgroundLuminance = this.luminance(backgroundColor);
        return backgroundLuminance < foregroundLumiance
            ? ((backgroundLuminance + 0.05) / (foregroundLumiance + 0.05))
            : ((foregroundLumiance + 0.05) / (backgroundLuminance + 0.05));
    };

    private luminance(rgb: RGB) {
        const [r, g, b] = rgb.map((v) => {
            v /= 255;
            return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
        });
        return r * 0.2126 + g * 0.7152 + b * 0.0722;
    };

    get style() {
        return { 'background-color': this.value, 'color': this.contrastValue }
    }

    /** Amount : By defaulr 30 */
    darkenColor(amount?: number): string {

        let hexColor = this.value;
        let finalAmount = amount ? amount : 30;
        // Convierte el color hexadecimal a componentes RGB
        const red = parseInt(hexColor.slice(1, 3), 16);
        const green = parseInt(hexColor.slice(3, 5), 16);
        const blue = parseInt(hexColor.slice(5, 7), 16);

        // Reduce la intensidad de cada componente según la cantidad especificada
        const darkenedRed = Math.max(0, red - finalAmount);
        const darkenedGreen = Math.max(0, green - finalAmount);
        const darkenedBlue = Math.max(0, blue - finalAmount);

        // Convierte los nuevos valores de los componentes a formato hexadecimal
        const darkenedHex = `#${darkenedRed.toString(16).padStart(2, '0')}${darkenedGreen.toString(16).padStart(2, '0')}${darkenedBlue.toString(16).padStart(2, '0')}`;

        return darkenedHex;
    }

    applyOpacityToHex(opacity: number): string {
        opacity = Math.max(0, Math.min(1, opacity));
        const alpha = Math.round(opacity * 255).toString(16).padStart(2, '0');
        return `${this.color}${alpha}`;
    }
}