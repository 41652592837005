/*
type hour = 0|1|2|3|4|5|6|7|8|9|10|11|12|13|14|15|16|17|16|19|20|21|22|23
type minute = 0|1|2|3|4|5|6|7|8|9|10|11|12|13|14|15|16|17|18|19|20|
               21|22|23|24|25|26|27|28|29|30|31|32|33|34|35|36|37|38|39|40|
               41|42|43|44|45|46|47|48|49|50|51|52|53|54|55|56|57|58|59
export class Test {
    constructor (private h : hour, private m : minute){}
}*/

export class CustomTime {
    private hours: string;
    private minutes: string;
    private seconds: string;

    // Overloaded constructor signatures
    constructor(fullTime: string); // 10:05
    constructor(hours?: string, minutes?: string); // "10", "5"
    constructor(hours?: string, minutes?: string, seconds?: string); // "10", "5", "00"
    constructor(hours?: number, minutes?: number); // 10, 5
    constructor(hours?: number, minutes?: number, seconds?: number); // 10, 5, 0

    // Unified constructor implementation
    constructor(hours?: string | number, minutes?: string | number, seconds?: string | number) {
        const d = new Date();

        // Case 1: fullTime in "HH:MM" format
        if (typeof hours === 'string' && !minutes && !seconds) {
            const [hh, mm] = hours.split(":");
            this.hours = hh || d.getHours().toString();
            this.minutes = mm || d.getMinutes().toString();
            this.seconds = d.getSeconds().toString();
        }
        else {
            // Case 2: Individual values for hours, minutes, and seconds
            this.hours = this.parseTimeUnit(hours, d.getHours());
            this.minutes = this.parseTimeUnit(minutes, d.getMinutes());
            this.seconds = this.parseTimeUnit(seconds, d.getSeconds());
        }

        // Ensure all values are formatted to 2 digits
        this.hours = this.padZero(this.hours);
        this.minutes = this.padZero(this.minutes);
        this.seconds = this.padZero(this.seconds);
    }

    // Convert number or string inputs, falling back to current time unit
    private parseTimeUnit(value?: string | number, fallback?: number): string {
        if (typeof value === 'number') {
            return value.toString();
        } else if (typeof value === 'string') {
            return value;
        } else {
            return fallback ? fallback.toString() : '';
        }
    }

    // Helper method to pad single digits with leading zero
    private padZero(value: string): string {
        return value.length === 1 ? '0' + value : value;
    }

    getHours(): number {
        return Number(this.hours);
    }

    getMinutes(): number {
        return Number(this.minutes);
    }

    getSeconds(): number {
        return Number(this.seconds);
    }

    getText(): string {
        return this.hours + ":" + this.minutes;
    }

    getValue(): number {
        return this.getMinutes() + (this.getHours() * 60);
    }
}
