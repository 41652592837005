import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { ViewPath } from 'src/app/app-routing.module';
import { M_Center } from 'src/app/models/M_Center';
import { ApiService } from 'src/app/services/Api/api.service';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';
import { RouterService } from 'src/app/services/router.service';
import { HolidaysCompanyComponent } from 'src/app/views/settings/holidays-company/holidays-company.component';
import { SETTINGS_TAB } from 'src/app/views/settings/settings.component';
import { StorehousesComponent } from 'src/app/views/storehouses/storehouses.component';
import { CenterAccountingComponent } from './center-accounting/center-accounting.component';
import { SnackService } from 'src/app/services/snack.service';
import { ModulesEnum } from 'src/app/enums/ModulesEnum';

@Component({
  selector: 'app-center-form',
  templateUrl: './center-form.component.html',
  styleUrls: ['./center-form.component.css']
})
export class CenterFormComponent implements AfterViewInit {

  @ViewChild(HolidaysCompanyComponent) holidaysC!: HolidaysCompanyComponent;
  @ViewChild(StorehousesComponent) storehouses!: StorehousesComponent;
  // @ViewChild(WeekScheduleComponent) scheduleC!: WeekScheduleComponent;
  @ViewChild(CenterAccountingComponent) centerAccounting?: CenterAccountingComponent;
  @Input({ required: true }) center: M_Center | undefined;
  @Output() onCreateCenter: EventEmitter<M_Center> = new EventEmitter();

  v = ViewPath;
  ST = SETTINGS_TAB;
  ME = ModulesEnum;
  form: FormGroup;
  isCreation = false;

  constructor(private fb: FormBuilder, public companyS: CompanyService, private apis: ApiService, public routerS: RouterService, private snackS : SnackService) {
    this.form = this.fb.group({

      /** Center */
      id: [''],
      name: ['', Validators.required],
      address: ['', [Validators.required]],
      address2: [''],
      zip: ['', [Validators.required]],
      city: ['', [Validators.required]],
      ccaaObject: ['', [Validators.required]],
      ccaa: ['', [Validators.required]],
      province: ['', [Validators.required]],

      /** Workshop and appointments subform */
      workshopConfig: this.fb.group({

        /** Workshop */
        default_vehicle: [1],
        places: ['', []],
        price_hour: ['', []],
        iva: [0],

        /** Appointments */
        morning_work_schedule_start: ['',],
        morning_work_schedule_finish: ['',],
        afternoon_work_schedule_start: [''],
        afternoon_work_schedule_finish: [''],
        range_of_days: ['1'],
        granularity: [''],
        total_appointment: [''],
        id: [null]
      }),
      holidays: [''],
      exceptions: [''],
      storehouses: []
    });
  }

  ngAfterViewInit(): void {
    this.patchForm(this.center);
    if (this.center){ 
      // this.scheduleC.setSchedule(this.center?.schedule);
      this.centerAccounting?.setCenterAccounting(this.center);
    }
    
  }

  patchForm(c: M_Center | undefined) {
    if (c) {
      this.form.patchValue(c);
      this.holidaysC.initCenter(c);
      this.storehouses.initStorehouses(c.storehouses)
    }
    else {
      this.form.reset();
      this.isCreation = true;
    }
  }

  get WC(): UntypedFormGroup {
    return this.form.get('workshopConfig') as UntypedFormGroup;
  }

  get SC(): UntypedFormGroup {
    return this.form.get('storehouses') as UntypedFormGroup;
  }

  saveCenter() {
    this.form.get('holidays')?.setValue(this.holidaysC.holidays.companyHolidays)
    this.form.get('exceptions')?.setValue(this.holidaysC.holidays.exceptions)
    this.form.get('storehouses')?.setValue(this.storehouses.storehouses);
    let center = new M_Center(this.form.value);
    
    // center.schedule = this.scheduleC.getSchedule();
    center.type_payment_center = this.centerAccounting?.getAccounting() || [];
    console.log(center.schedule)

    this.apis.saveCenter(center).then(res => {
      this.companyS.addOrUpdateCenter(res);
      if (this.isCreation) {
        this.onCreateCenter.emit(res);
        this.snackS.show("¡Centro creado con éxito! 🏢")
        this.patchForm(undefined);
      }
      else {
        this.center = res;
        this.snackS.show("¡Centro editado con éxito! 🏢")
        this.patchForm(this.center);
      }
    })
  }

}
