import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { SubscriptionService } from 'src/app/services/EinaMainData/subscription.service';
import { filter } from 'src/app/custom-classes/MasterFilter';
import { ParamsService } from 'src/app/services/params.service';
import { M_Appointment } from 'src/app/models/M_Appointment';
import { M_AppointmentConfig } from 'src/app/models/M_AppointmentConfig';
import { AppointmentStatus } from 'src/app/enums/AppointmentStatus';
import { PageStructureComponent } from 'src/app/components/page-structure/page-structure.component';
import { getAccentColor, getPrimaryColor } from 'src/app/utils/FunctionUtils';
import { DayFilter, EnumFilter, Filter, FilterOption, TagFilter } from 'src/app/custom-classes/Filter';
import { RouterService } from 'src/app/services/router.service';
import { SessionService } from 'src/app/services/session.service';
import { M_Center } from 'src/app/models/M_Center';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';

export enum AppoPageFiltesrEnum {
  APPOINTMENT_DAY = 0,
  APPOINTMENT_STATUS = 1,
  APPOINTMENT_IS_CLIENT = 2,
  APPOINTMENT_ORIGIN = 3
}

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.css']
})
export class AppointmentsComponent implements OnInit {

  appo_status = AppointmentStatus;

  @ViewChild(PageStructureComponent) ps!: PageStructureComponent<M_Appointment>;
  v = ViewPath;
  center?: M_Center;
  config = M_AppointmentConfig;
  @Input() newTab: boolean = false;
  @Output() onClick: EventEmitter<any> = new EventEmitter();
  f = filter;
  primary = getPrimaryColor;
  accent = getAccentColor;

  filters: Filter[] = [
    new DayFilter("Fecha cita", undefined, true).setId(AppoPageFiltesrEnum.APPOINTMENT_DAY),
    new TagFilter("Estado cita", AppointmentStatus, new FilterOption("Pendiente"), new FilterOption("Hecha"), new FilterOption("Cancelada")).setId(AppoPageFiltesrEnum.APPOINTMENT_STATUS),
    new EnumFilter("Cliente", new FilterOption("Registrado", "how_to_reg"), new FilterOption("No registrado", "no_accounts")).setId(AppoPageFiltesrEnum.APPOINTMENT_IS_CLIENT),
    new TagFilter("Origen", undefined, new FilterOption("Eina", "eina"), new FilterOption("Web cita previa", "public")).setId(AppoPageFiltesrEnum.APPOINTMENT_ORIGIN),
  ]

  constructor(public routerS: RouterService, private apiS: ApiService,
    public sessionS: SessionService, public subS: SubscriptionService,
    public companyS: CompanyService,
    public paramsS: ParamsService) {
    this.getAllAppoitments();
    this.company();
  }

  ngOnInit(): void { }

  company() {
    this.apiS.getMyCenter().then(res => {
      if (res) {
        this.center = res;
      }
    });
  }

  get quickFilter() {
    let f = this.filters.find(f => f.id == AppoPageFiltesrEnum.APPOINTMENT_STATUS);
    if (f instanceof TagFilter) { return f };
    return undefined;
  }

  getAllAppoitments() {
    this.apiS.appointment().then(resp => {
      if (this.ps) {
        this.ps.initTable(resp);
      }
    });
  }

  appointmentIcon(a: M_Appointment) {
    return a.isClient ? "how_to_reg" : "no_accounts";
  }
  appointmentName(a: M_Appointment) {
    return a.clientName();
  }

  appointmentOrigin(a: M_Appointment) {
    return a.origin == "Web" ? "public" : "eina";
  }

  showAppointments() {
    this.routerS.goWithQueryParams(ViewPath.showAppointments, { center: this.center?.urlToken }, true);
  }
}
