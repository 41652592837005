<form [formGroup]="form" eForm>
    <div eForm-wrapper class="onboarding-company-users-form">
        <div eForm-section>
            <mat-form-field appearance="outline">
                <mat-label>Nombre</mat-label>
                <input matInput placeholder="Nombre" formControlName="name">
            </mat-form-field>
        </div>
        <div eForm-section *ngIf="superUser">
            <mat-form-field appearance="outline">
                <mat-label>Centro</mat-label>
                <mat-select formControlName="center">
                    <mat-option *ngFor="let center of einaDataS.company.centers" [value]="center.id">
                        {{ center.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div eForm-section>
            <app-email-input [formCName]="'email'" [form]="form"></app-email-input>
        </div>
        <div eForm-section *ngIf="superUser">
            <mat-form-field appearance="outline">
                <mat-label>Rol</mat-label>
                <!-- Only the ADMIN can change the roles-->
                <mat-select formControlName="role">
                    <mat-option *ngIf="originalUser?.admin" [value]="1">Administrador</mat-option>
                    <mat-option [value]="RE.GESTOR">Gestor</mat-option>
                    <mat-option [value]="RE.ADVISER">Asesor</mat-option>
                    <mat-option [value]="RE.COMERCIAL">Comercial</mat-option>
                    <mat-option [value]="RE.RECAMBISTA">Recambista</mat-option>
                    <mat-option [value]="RE.MECHANIC">Mecánico</mat-option>
                </mat-select>
                <mat-hint *ngIf="superUser" class="cp underline_hover c_p" (click)="openRolesExplanation()">¿Qué puede
                    hacer cada rol?
                    <mat-icon class="c_p">open_in_new</mat-icon>
                </mat-hint>
            </mat-form-field>
        </div>

        <ng-container *ngIf="showRoleAlert as alertTxt">
            <div class="bc_r1 p10 m10 br15 df fdc aic jcc">
                <p class="c_r tac cp"> {{alertTxt}}</p>
                <button mat-flat-button [go]="v.settings" [param]="{tab : ST.MODULES}" [newTab]="true">
                    Ver módulos
                    <mat-icon>open_in_new</mat-icon>
                </button>
            </div>
        </ng-container>

    </div>
</form>