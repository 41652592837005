import { Component, Inject } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { M_SigningTime } from 'src/app/models/Signing/M_SigningTime';
import { ApiService } from 'src/app/services/Api/api.service';

@Component({
  selector: 'app-edit-my-presence',
  templateUrl: './edit-my-presence.component.html',
  styleUrls: ['./edit-my-presence.component.css']
})
export class EditMyPresenceComponent {

  form: UntypedFormGroup;

  constructor(private fb: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: M_SigningTime, public dRef: MatDialogRef<EditMyPresenceComponent>,
    private apiS: ApiService) {
    this.form = this.fb.group({
      id: [this.data.id || undefined],
      day: [this.data.day || ''],
      morningStart: [this.data.morning_start?.getHHmm() || ''],
      morningEnd: [this.data.morning_end?.getHHmm() || ''],
      reason: [this.data.reason || undefined],
      afternoonStart: [this.data.afternoon_start?.getHHmm() || ''],
      afternoonEnd: [this.data.afternoon_end?.getHHmm() || ''],
      reason_2: [this.data.reason_2 || undefined],
    });
  }

  save() {

    console.log("Changing schedule")

    const provisionalNew = new M_SigningTime(this.form.value);
    let morningStart = this.form.get('morningStart')?.value;
    let morningEnd = this.form.get('morningEnd')?.value;
    let afternoonStart = this.form.get('afternoonStart')?.value;
    let afternoonEnd = this.form.get('afternoonEnd')?.value;
    provisionalNew.editPresence(morningStart, morningEnd, afternoonStart, afternoonEnd, this.form.get('reason')?.value ,this.form.get('reason_2')?.value);

    this.apiS.signing.editMyPresence(provisionalNew).then(res => {
      let s = this.data.schedule;
      this.data = res;
      this.data.schedule = s;
      this.dRef.close(this.data);
    })

  }
  
  get isAllOk() {
    let breakdown = this.isOkBreakdown;
    return breakdown.morningStart && breakdown.morningEnd && breakdown.afternoonStart && breakdown.afternoonEnd;
  }

  get isToday() {
    return this.data.day.isEquals(new Date());
  }

  get hasSchedule() {
    return (this.data.scheduleHasMorining || this.data.scheduleHasAfternoon);
  }

  get editable() {
    /** (!this.isToday || (this.isToday && this.data.finished)); */
    return this.hasSchedule;
  }

  get isOkBreakdown() {

    let isOk = {
      morningStart: true,
      morningEnd: true,
      afternoonStart: true,
      afternoonEnd: true,
    }

    if (!this.data.scheduleHasMorining && !this.data.scheduleHasAfternoon) {
      return isOk;
    }

    let morningStart = this.form.get('morningStart')?.value;
    let morningEnd = this.form.get('morningEnd')?.value;
    let afternoonStart = this.form.get('afternoonStart')?.value;
    let afternoonEnd = this.form.get('afternoonEnd')?.value;

    isOk.morningStart = morningEnd == "" || (morningStart != "" && morningEnd != "" && morningStart < morningEnd);
    isOk.morningEnd = morningStart == "" || (morningStart != "" && morningEnd != "" && morningStart < morningEnd);
    isOk.afternoonStart = afternoonEnd == "" || (morningStart != "" && morningEnd != "" && morningStart < morningEnd && morningEnd < afternoonStart);
    isOk.afternoonEnd = afternoonStart == "" || (morningStart != "" && morningEnd != "" && afternoonStart != "" && morningStart < morningEnd && morningEnd < afternoonStart && afternoonStart < afternoonEnd);

    return isOk;
  }

}
