import { ChangeDetectorRef, Component, ComponentRef, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { M_Contact } from 'src/app/models/M_Contact';
import { CreateProductComponent } from '../create-product.component';
import { MASTER_PROVIDER } from 'src/app/constants/masters';
import { ClassSearcherComponent } from 'src/app/components/class-searcher/class-searcher.component';
import { RouterService } from 'src/app/services/router.service';
import { ContactEnum } from 'src/app/enums/ContactEnum';

@Component({
  selector: 'app-provider-searcher',
  templateUrl: './provider-searcher.component.html',
  styleUrls: ['./provider-searcher.component.css']
})
export class ProviderSearcherComponent {
  v = ViewPath;
  provider = MASTER_PROVIDER;
  CE = ContactEnum;

  @ViewChild(ClassSearcherComponent, { static: true }) classSearcher!: ClassSearcherComponent<M_Contact>;
  @Input({ required: true }) ref!: ComponentRef<ProviderSearcherComponent>;
  @Output() onSelectProvider: EventEmitter<M_Contact> = new EventEmitter();
  @Output() onDestroy: EventEmitter<ProviderSearcherComponent> = new EventEmitter();

  constructor(@Inject(CreateProductComponent) public createProduct: CreateProductComponent, public routerS: RouterService, private chdRef: ChangeDetectorRef,) { }

  ngAfterViewInit(): void {
    this.focus();
  }

  disabledIf(providerRow: M_Contact) {
    return this.createProduct.formProviders.some(p => p.client_id == providerRow.client_id);
  }

  focus() {
    if (this.classSearcher) {
      this.classSearcher.focus();
      this.chdRef.detectChanges();
    }
  }

  destroy() {
    this.ref.destroy();
  }
}
