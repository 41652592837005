<!-- 3 DOTS BUTTON -->
<button *ngIf="showButton(product)" class="line-action-button" mat-icon-button [matMenuTriggerFor]="lineActionsMenu" (click)="loadProductQuants()">
    <mat-icon [class]="getIconClass(product)" aria-hidden="false" [matBadge]="showExlamation(product) ? '!' : null" matBadgeOverlap="true"
        matBadgePosition="after" [matBadgeColor]="getBadgeColor(product)">
        more_vert
    </mat-icon>
</button>

<!-- MAT-MENU -->
<mat-menu #lineActionsMenu="matMenu">

    <!-- Red banner-->
    <div class="top-line-banner">
        <mat-icon smartIcon>
            {{product.icon}}
        </mat-icon>
        <p class="two_lines nmb">{{product.name}}</p>
    </div>


    <!-- Skeleton loading -->
    <ng-template #skeleton>
        <div class="skeleton">
            <app-skeleton [fullW]="true" [height]="30"></app-skeleton>
            <app-skeleton [fullW]="true" [height]="30"></app-skeleton>
            <app-skeleton [fullW]="true" [height]="30"></app-skeleton>
        </div>
    </ng-template>


    <!-- Mat-menu contents : !! Only show if there is some content !! -->
    <ng-container *ngIf="loaded; else skeleton">
        <div class="middle-actions-content"
            *ngIf="isActionShowing(product,'quantity-by-stock-min') || product.instanceofProduct() || (product.instanceofCustom())">

            <!-- Product middle content -->
            <ng-container *ngIf="product.instanceofProduct()">

                <!-- Extra info-->
                <p *ngIf="showExlamation(product)" class="tac c_r" [ngClass]="{'c_b_i' : productTable.isBudgetReservedRequestedAndRecived(product)}">
                    <span *ngIf="productTable.isRequested(product)">¡Unidades en camino!</span><br>
                    <span *ngIf="productTable.isReservedWithFaultRequestedNotRecived(product)">¡La reserva esta en falta!</span>
                    <span *ngIf="productTable.isBudgetReservedRequestedAndRecived(product)">¡Reserva recibida!</span>
                </p>

                <!-- Quantity by stock -->
                <ng-container *ngIf="isActionShowing(product,'quantity-by-stock-min')">
                    <p class="subtitle">
                        <span class="fw500">Disponible:</span>
                        <span [ngClass]="{'indeterminated' : product.stock == undefined}">
                            {{product.stock == undefined ? 'Sin determinar' : product.stock}}
                        </span>
                    </p>
                    <p class="subtitle">
                        <span class="fw500">Stock min.:</span>
                        <span [ngClass]="{'indeterminated' : product.stock_min == undefined}">
                            {{product.stock_min == undefined ? 'Sin determinar' : product.stock_min}}
                        </span>
                    </p>
                    <p class="subtitle">
                        <span class="fw500">Stock máx.:</span>
                        <span [ngClass]="{'indeterminated' : product.stock_max == undefined}">
                            {{product.stock_max == undefined ? 'Sin determinar' : product.stock_max}}
                        </span>
                    </p>
                </ng-container>

                <!-- Product quants-->
                <div>
                    <ng-container *ngIf="product.control_stock; else noControlStock">
                        <div *ngIf="product.productsQuants; else noProducItemQuants">
                            <p class="subtitle">
                                <mat-icon class="vam txt_icon">
                                    local_shipping
                                </mat-icon>
                                {{product.productsQuants.quant_engaged}} Uds. en camino
                            </p>
                            <p class="subtitle">
                                <mat-icon class="vam txt_icon">
                                    production_quantity_limits
                                </mat-icon>
                                {{product.productsQuants.quant_fault}} Uds. en falta
                            </p>
                            <p class="subtitle">
                                <mat-icon class="vam txt_icon">
                                    warehouse
                                </mat-icon>
                                {{product.productsQuants.quant_stored}} Uds. en Almacén
                            </p>
                        </div>
                    </ng-container>

                    <ng-template #noControlStock>
                        <p class="c_t2 nmb tac">Sin control de stock</p>
                    </ng-template>

                    <ng-template #noProducItemQuants>
                        <p class="c_t2 nmb tac">Guarda para ver mas detalles</p>
                    </ng-template>


                </div>
            </ng-container>

            <!-- Custom product middle content -->
            <ng-container *ngIf="product.instanceofCustom()">
                <p class="nmb c_t2">
                    {{product.isProduct ? 'Producto personalizado' : product.isTime ? 'Tiempo trabajado' : 'Comentario personalizado'}}
                </p>
            </ng-container>

        </div>

        <!-- Bottom actions -->
        <div class="actions-content" *ngIf="showButtons(product)">
            <div class="separator"></div>
            <ng-container *ngFor="let action of productTable.extendedInfoConfig?.actions;">
                <div *ngIf="lineActionsMenuItem[action].showIf(product)" mat-menu-item
                    [matTooltip]="lineActionsMenuItem[action].tooltip(product)"
                    (click)="lineActionsMenuItem[action].function(product, addRemove)">
                    <mat-icon>{{lineActionsMenuItem[action].icon}}</mat-icon>
                    {{lineActionsMenuItem[action].menuText}}
                </div>
            </ng-container>
        </div>
    </ng-container>

</mat-menu>
