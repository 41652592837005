import { VehicleType } from "../enums/VehicleType";

export class M_AppointmentConfig {

    /** Appointments */
    id: number | undefined;
    center_id: number;
    morning_work_schedule_start: number;
    morning_work_schedule_finish: number;
    afternoon_work_schedule_start: number;
    afternoon_work_schedule_finish: number
    granularity: number;
    range_of_days: number;
    total_appointment: number;
    company_logo: string | undefined;
    company_name: string;
    center_name: string;
    center_dir: string;
    primary_color: string
    secondary_color: string

    /** Workload */
    places: number | undefined;
    default_vehicle: VehicleType = 1;
    price_hour: number | undefined;
    iva: number | undefined;
    
    constructor(data: any) {

        //Workload
        this.places = data.places ? data.places : undefined;
        this.default_vehicle = data.default_vehicle ? data.default_vehicle : VehicleType.car;
        this.price_hour = data.price_hour ? data.price_hour : undefined;
        this.iva = data.iva != undefined ? data.iva : undefined;

        //Appointments
        this.id = data.id ? data.id : undefined;
        this.center_id = data.center_id;
        this.morning_work_schedule_start = data.morning_work_schedule_start;
        this.morning_work_schedule_finish = data.morning_work_schedule_finish;
        this.afternoon_work_schedule_start = data.afternoon_work_schedule_start;
        this.afternoon_work_schedule_finish = data.afternoon_work_schedule_finish;
        this.granularity = data.granularity;
        this.range_of_days = data.range_of_days;
        this.total_appointment = data.total_appointment;
        this.company_logo = data.company_logo;
        this.company_name = data.company_name;
        this.center_name = data.center_name;
        this.center_dir = data.center_dir;
        this.primary_color = data.primary_color;
        this.secondary_color = data.secondary_color;
    }
}