import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { M_Vehicle } from 'src/app/models/Vehicles/M_Vehicle';
import { M_Contact } from 'src/app/models/M_Contact';
import { MASTER_CLIENT_MINIFIY, MASTER_VECHILE_MINIFY } from '../constants/masters';
import { PdfDocumentsUploadComponent } from '../components/pdf-documents-upload/pdf-documents-upload.component';
import { ClassSearcherComponent } from '../components/class-searcher/class-searcher.component';

@Component({
  selector: 'app-buy-product-vehicle-form',
  templateUrl: './buy-product-vehicle-form.component.html',
  styleUrls: ['./buy-product-vehicle-form.component.css']
})
export class BuyProductVehicleFormComponent implements OnChanges, OnInit {

  @ViewChild(PdfDocumentsUploadComponent) pdfUploadComponent!: PdfDocumentsUploadComponent;
  /** Searcher buy recambio  */
  @ViewChild('csearcher') clientSearcherComponent?: ClassSearcherComponent<M_Contact>
  /** Searcger buy vehicle */
  @ViewChild('vehicleSearcher') vehicleSearcherComponent?: ClassSearcherComponent<M_Vehicle>

  @Input({ required: true }) selectedTab: number = 0;
  @Output() formSubmitted = new EventEmitter<FormData>();

  client = MASTER_CLIENT_MINIFIY;
  vehicle = MASTER_VECHILE_MINIFY;

  form!: FormGroup;
  minDate = new Date();
  client_select: M_Contact | undefined
  vehicle_select: M_Vehicle | undefined;

  constructor(private formBuilder: UntypedFormBuilder, private chdRef: ChangeDetectorRef) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedTab']) {

      this.vehicleSearcherComponent?.remove();
      this.form.patchValue({ vehicle_id: undefined });
      this.form.patchValue({ client_id: undefined });
      this.initializeForm();

      let currentVal = changes['selectedTab'].currentValue;

      if (currentVal == 1) {
        this.form.get('client_id')?.addValidators(Validators.required);
        this.form.get('vehicle_id')?.removeValidators(Validators.required);
      }
      else {
        this.form.get('client_id')?.removeValidators(Validators.required);
        this.form.get('vehicle_id')?.addValidators(Validators.required);
      }
    }
  }
  
  get isVehicle(){
    return this.selectedTab == 1;
  }
  
  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm() {
    this.vehicleSearcherComponent?.remove();
    this.clientSearcherComponent?.remove();
    if (!this.isVehicle) {
      // Buy recambio form
      this.form = this.formBuilder.group({
        client_id: ['', Validators.required],
        price: ['', Validators.required],
        tax: ['', Validators.required],
        comment: [''],
        pdf_file: [''],
        docum_prov_num: ['', Validators.required],
        date: [''],
        token: ['CV'],
        type: [],
        buy_transac_id: [''],
      });
    } else {
      // Buy vehicle form
      this.form = this.formBuilder.group({
        vehicle_id: ['', Validators.required],
        client_id: ['', Validators.required],
        price: ['', Validators.required],
        tax: ['', Validators.required],
        comment: [''],
        pdf_file: [''],
        docum_prov_num: [''],
        date: [''],
        token: ['CV'],
        type: [''],
        mode: ['view'],
        buy_transac_id: [''],
      });
    }
    
    if (!this.isVehicle) {
      this.form.get('vehicle_id')?.clearValidators();
    }
    else {
      this.form.get('vehicle_id')?.setValidators(Validators.required); // Obligatorio si es un vehículo
      this.form.get('client_id')?.valueChanges.subscribe(clientId => {
        if (clientId) {
          if (this.client_select) {
            if (this.client_select.type === 2 || this.client_select.type === 3) {
              this.form.get('vehicle_id')?.setValidators(Validators.required); // Por ejemplo
              this.form.get('docum_prov_num')?.setValidators(Validators.required); // Por ejemplo
            } else {
              this.form.get('vehicle_id')?.clearValidators();
              this.form.get('docum_prov_num')?.clearValidators();
            }
          }
        }
        this.form.get('vehicle_id')?.updateValueAndValidity();
      });
    }
  }

  handleClientSelection(selectedClient: M_Contact) {
    this.client_select = selectedClient;

    if (this.client_select != undefined && this.client_select.isCompany || this.client_select?.isProvider) {
      this.chdRef.detectChanges();
    }
  }
  changeClient(v: M_Vehicle) {
    console.log(v);
    this.vehicle_select = v;
    this.form.patchValue({ client_id: this.vehicle_select.client?.client_id });
    if (this.client_select != undefined && this.client_select.isCompany || this.client_select?.isProvider) {
      this.chdRef.detectChanges();
    }
  }
  disabledIf(contact: M_Contact) {
    if (contact.type == 2 && !this.isVehicle) {
      return false
    } else if (contact.type == 3 && !this.isVehicle) {
      return false
    }
    else if (this.isVehicle) {
      return false
    }
    return true
  }
  getform() {
    return this.form.value;
  }
}
