<app-close-dialog-button [onCloseData]="action" *ngIf="action"></app-close-dialog-button>
<p mat-dialog-title *ngIf="action">{{action.title_id}}</p>
<div mat-dialog-content>
    <app-card [contentLoaded]="action != undefined" [noStyle]="true">
        <ng-container *ngIf="action != undefined">

            <button *ngIf="!action.allInvoiced()" color="primary" (click)="startStopTime()" mat-flat-button class="work-or custom-button">
                <mat-icon>{{orTimeS.isOperatorWorking(action) ? 'stop_circle' : 'play_circle'}}</mat-icon>
                {{orTimeS.isOperatorWorking(action) ? orTimeS.isOperatorWorking(action)!.elapsed! : 'Trabajar'}}
            </button>

            <p mat-dialog-subtitle>Grupos</p>
            <div *ngFor="let group of action.groups">


                <div class="df aic gap5">
                    {{group.getTitle()}}
                    <app-or-status-label [menuIconOpen]="!group.isInvoiced()"
                        [mat-menu-trigger-for]="!group.isInvoiced() ? status : null" prevent
                        [status]="getStatus(group)">
                    </app-or-status-label>
                </div>


                <mat-menu #status="matMenu">
                    <button mat-menu-item (click)="openGroup(group)" *ngIf="group.state.num == 1" class="chip-option">
                        <app-or-status-label [pointer]="true" [status]="[s_o, 'Abrir']"></app-or-status-label>
                    </button>

                    <button mat-menu-item (click)="closeGroup(group)" *ngIf="group.state.num == 0"
                        [disabled]="!group.hasTasks()" class="chip-option">
                        <app-or-status-label [pointer]="true" [status]="[s_c, 'Cerrar']"></app-or-status-label>
                    </button>
                </mat-menu>

            </div>

        </ng-container>
    </app-card>
</div>
<div mat-dialog-actions *ngIf="action">
    <button color="primary" mat-flat-button [mat-dialog-close]="action" cdkFocusInitial>Ok</button>
</div>