import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { M_Invoice } from '../../models/M_Invoice';
import { M_Contact } from '../../models/M_Contact';
import { M_Company } from '../../models/M_Company';
import { M_CustomProduct } from '../../models/Products/M_CustomProduct';
import { M_Product } from '../../models/Products/M_Product';
import { M_User } from '../../models/M_User';
import { M_Vehicle } from '../../models/Vehicles/M_Vehicle';
import { endpoints } from '../../constants/Endpoints';
import { M_Action } from '../../models/M_Action';
import { M_Dashboard } from '../../models/M_Dashboard';
import { M_ActiveReception } from '../../models/M_ActiveReception';
import { M_Smpt } from '../../models/M_Smpt';
import { M_CompanyWelcome } from '../../models/M_CompanyWelcome';
import { fakeBigMama } from '../../components/global-searcher/fakeBigMama';
import { M_Brand } from '../../models/M_Brand';
import { M_Model } from '../../models/M_Model';
import { M_LastMovements } from '../../models/M_LastMovement';
import { M_TypePayment } from '../../models/M_TypePayment';
import { M_PayCometInvoice } from '../../models/M_PayCometInvoice';
import { M_Subscription } from '../../models/M_Subscription';
import { R_Zip } from '../../models/R_Zip';
import { M_TemplateField, templateTypedoc } from '../../models/M_TemplateField';
import { company_data } from '../../components/preview-dialog/preview-dialog-component';
import { M_Appointment } from '../../models/M_Appointment';
import { M_BookedHours } from '../../models/M_BookedHours';
import { generateHoliday } from 'src/app/utils/functions';
import { M_TaskAppointment } from '../../models/M_TaskAppointment';
import { M_AppointmentConfig } from '../../models/M_AppointmentConfig';
import { I_AppointmentClientSide } from 'src/app/views/create-appointment-client-side/create-appointment-client-side.component';
import { M_ORTime } from '../../models/M_ORTime';
import { M_EinaData } from '../../models/M_EinaData';
import { M_Order } from '../../models/M_Order';
import { M_StoreHouse } from '../../models/M_StoreHouse';
import { M_Fault } from '../../models/M_Fault';
import { M_PreOrderBreakdown } from '../../models/M_PreOrderBreakdown';
import { M_Reservation } from '../../models/M_Reservation';
import { M_Albaran } from '../../models/M_Albaran';
import { M_StoreHouseEntry } from '../../models/M_StoreHouseEntry';
import { ModuleFactory } from '../../models/Modules/Factory/ModuleFactory';
import { ApiNoAuthService } from './api-by-feature/api-no-auth.service';
import { ApiProfileService } from './api-by-feature/api-profile.service';
import { M_ShippingAddress } from '../../models/M_Address';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { M_QuantsProduct } from '../../models/M_QuantsProduct';
import { M_PDFTemplate } from '../../models/M_PDFTemplate';
import { M_DiscountGroup, M_DiscountGroupLine } from '../../models/M_DiscountGroupLine';
import { ApiVnvoService } from './api-by-feature/api-vnvo.service';
import { M_Concept } from '../../models/M_Concept';
import { M_BuyTransac } from '../../models/M_BuyTransac';
import { ApiClientService } from './api-by-feature/api-client.service';
import { ApiVehicleService } from './api-by-feature/api-vehicle.service';
import { feature } from 'src/app/utils/FeaturesController';
import { ModulesEnum } from 'src/app/enums/ModulesEnum';
import { MovTypeEnum } from 'src/app/enums/MovTypeEnum';
import { LoadingPanelService } from 'src/app/services/LoadingPanel/loading-panel.service';
import { downloadBlob, getArrayOf } from 'src/app/utils/FunctionUtils';
import { parseOBJ } from 'src/app/custom-classes/ModelParser';
import { CustomFile } from 'src/app/custom-classes/CustomFile';
import { or_types } from 'src/app/custom-classes/or_types';
import { INotification } from 'src/app/interfaces/INotification';
import { invoice_states } from 'src/app/custom-classes/invoice_states';
import { albaran_status } from 'src/app/custom-classes/albaran_status';
import { T_SaveDocumentation } from 'src/app/views/settings/company-tab/documentation-config/documentation-config.component';
import { M_Purchase } from 'src/app/models/M_Purchase';
import { M_StockVehicles } from 'src/app/models/M_StockVehicles';
import { M_GroupTask } from 'src/app/models/M_GroupTask';
import { massiveResponse } from 'src/app/components/massive-invoice/massive-invoice.component';
import { ApiActionService } from './api-by-feature/api-action.service';
import { M_Center } from 'src/app/models/M_Center';
import { ApiDevTestService } from './api-by-feature/api-dev-test.service';
import { M_AccountingGroupRow } from 'src/app/models/AccountingGroups/M_AccountingGroupRow';
import { M_AccountingGroup } from 'src/app/models/AccountingGroups/M_AccountingGroup';
import { M_Serie } from 'src/app/models/M_Serie';
import { M_Color } from 'src/app/models/Vehicles/M_Color';
import { ApiSigningService } from './api-by-feature/api-signing.service';
import { Endpoint } from 'src/app/custom-classes/Endpoint';
import { BaseModule } from 'src/app/models/Modules/Factory/BaseModule';
import { M_MTRModule } from 'src/app/models/Modules/M_MTRModule';
import { M_Schedule } from 'src/app/models/M_Schdeule';
import { M_Icis } from 'src/app/models/M_Icis';
import { icisPostData } from 'src/app/views/generic-reports/generic-reports.component';
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    public noauth: ApiNoAuthService,
    public profile: ApiProfileService,
    public vnvo: ApiVnvoService,
    public client: ApiClientService,
    public vehicle: ApiVehicleService,
    public action: ApiActionService,
    public signing: ApiSigningService,
    public test: ApiDevTestService,
    private http: HttpClient,
    private loadingS: LoadingPanelService) {
  }

  /** Eina main data */
  einaData() {
    console.log("🔄 Refreshing Eina main data")
    return new Promise<M_EinaData>(resolve => {
      this.http.get<any>(endpoints.einaData.url).subscribe(
        data => {
          resolve(new M_EinaData(data))
        }
      )
    })
  }

  dashboard() {
    return new Promise<M_Dashboard>(resolve => {
      this.http.get<any>(endpoints.dashboard.url).subscribe(
        data => {
          resolve(new M_Dashboard(data));
        }
      );
    })
  }

  updatePass(password: string) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.updatePass.url, { password: password }).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }

  order(id: number) {
    return new Promise<M_Order>(resolve => {
      this.http.post<any>(endpoints.getOrder.url, { order_id: id }).subscribe(
        data => {
          resolve(new M_Order(data));
        }
      );
    })
  }

  /**ORDERS */
  orders() {
    return new Promise<M_Order[]>(resolve => {
      this.http.get<any>(endpoints.getOrders.url).subscribe(
        data => {
          let data_: M_Order[] = [];
          for (let i = 0; i < data.length; i++) {
            data_.push(new M_Order(data[i]))
          }
          resolve(data_);
        }
      );
    })
  }

  changeSendedOrder(order: M_Order) {
    return new Promise<boolean>(resolve => {
      this.http.post<any>(endpoints.changeSended.url, { id: order.id, send: order.send }).subscribe(
        data => {
          resolve(true);
        }
      );
    })
  }

  appointment() {
    return new Promise<M_Appointment[]>(resolve => {
      this.http.get<any>(endpoints.appointment.url).subscribe(
        data => {
          let data_: M_Appointment[] = [];
          for (let i = 0; i < data.length; i++) {
            data_.push(new M_Appointment(data[i]))
          }
          resolve(data_);
        }
      );
    })
  }

  concepts() {
    return new Promise<M_Concept[]>(resolve => {
      this.http.get<any>(endpoints.getConcepts.url).subscribe(
        data => {
          let data_: M_Concept[] = [];
          for (let i = 0; i < data.length; i++) {
            data_.push(new M_Concept(data[i]))
          }
          resolve(data_);
        }
      );
    })
  }
  series() {
    return new Promise<M_Serie[]>(resolve => {
      this.http.get<any>(endpoints.getSeries.url).subscribe(
        data => {
          let data_: M_Serie[] = [];
          for (let i = 0; i < data.length; i++) {
            data_.push(new M_Serie(data[i]))
          }
          resolve(data_);
        }
      );
    })
  }

  getGroupAccouting() {
    return new Promise<M_AccountingGroup[]>(resolve => {
      this.http.get<any>(endpoints.getAccounting.url).subscribe(
        data => {
          let data_: M_AccountingGroup[] = [];
          for (let i = 0; i < data.length; i++) {
            data_.push(new M_AccountingGroup(data[i]))
          }
          resolve(data_);
        }
      );
    })
  }

  getSerieById(id: number) {
    return new Promise<M_Serie | undefined>(resolve => {
      this.http.get<any>(endpoints.getSerieById.url + "/" + id).subscribe(
        data => {
          if (data == "Forbidden") {
            resolve(undefined);
          }
          if (data && Object.keys(data).length != 0) {
            resolve(new M_Serie(data));
          }
          else {
            resolve(undefined)
          }
        }
        ,
        _error => {
          resolve(undefined)
        }
      );
    })
  }
  getBrandById(id: number) {
    return new Promise<M_Brand | undefined>(resolve => {
      this.http.get<any>(endpoints.getBrandById.url + "/" + id).subscribe(
        data => {
          if (data == "Forbidden") {
            resolve(undefined);
          }
          if (data && Object.keys(data).length != 0) {
            resolve(new M_Brand(data));
          }
          else {
            resolve(undefined)
          }
        }
        ,
        _error => {
          resolve(undefined)
        }
      );
    })
  }
  getColorById(id: number) {
    return new Promise<M_Color | undefined>(resolve => {
      this.http.get<any>(endpoints.getColorById.url + "/" + id).subscribe(
        data => {
          if (data == "Forbidden") {
            resolve(undefined);
          }
          if (data && Object.keys(data).length != 0) {
            resolve(new M_Color(data));
          }
          else {
            resolve(undefined)
          }
        }
        ,
        _error => {
          resolve(undefined)
        }
      );
    })
  }
  getModelById(id: number) {
    return new Promise<M_Model | undefined>(resolve => {
      this.http.get<any>(endpoints.getModelById.url + "/" + id).subscribe(
        data => {
          if (data == "Forbidden") {
            resolve(undefined);
          }
          if (data && Object.keys(data).length != 0) {
            resolve(new M_Model(data));
          }
          else {
            resolve(undefined)
          }
        }
        ,
        _error => {
          resolve(undefined)
        }
      );
    })
  }
  getBrands() {
    return new Promise<M_Brand[]>(resolve => {
      this.http.get<any>(endpoints.getBrands.url).subscribe(
        data => {
          let data_: M_Brand[] = [];
          for (let i = 0; i < data.length; i++) {
            data_.push(new M_Brand(data[i]))
          }
          resolve(data_);
        }
      );
    })
  }
  colors() {
    return new Promise<M_Color[]>(resolve => {
      this.http.get<any>(endpoints.getColors.url).subscribe(
        data => {
          let data_: M_Color[] = [];
          for (let i = 0; i < data.length; i++) {
            data_.push(new M_Color(data[i]))
          }
          resolve(data_);
        }
      );
    })
  }

  getBalance() {
    return new Promise<M_Invoice[] | M_Purchase[] | M_LastMovements[]>(resolve => {
      this.http.get<any>(endpoints.getBalance.url).subscribe(
        data => {
          console.log(data);
          let data_: M_Invoice[] | M_Purchase[] | M_LastMovements[] = [];
          if (data.invoices) {
            for (let i = 0; i < data.invoices.length; i++) {
              const invoice = data.invoices[i];
              data_.push(invoice);
            }
          }
          if (data.purchases) {
            for (let x = 0; x < data.purchases.length; x++) {

              const purchase = data.purchases[x];
              data_.push(purchase);
            }
          }
          if (data.advances) {

            for (let y = 0; y < data.advances.length; y++) {

              const advances = data.advances[y];
              data_.push(advances);
            }
          }
          resolve(data_);
        }
      );
    })
  }

  configCompanyAppointment() {
    return new Promise<M_AppointmentConfig>(resolve => {
      this.http.get<any>(endpoints.configCompanyAppointment.url).subscribe(
        data => {
          resolve(new M_AppointmentConfig(data))
        }
      )
    })
  }

  getFirstDay() {
    return new Promise<Date>(resolve => {
      this.http.get<any>(endpoints.showFirstDay.url).subscribe(
        data => {
          try {
            resolve(new Date(data))
          }
          catch (e: any) {
            resolve(new Date())
          }
        }
      )
    }
    )
  }


  /** Obtiene las horas ocupadas al crear una cita */
  getBookedHours(date: Date) {
    return new Promise<M_BookedHours>(resolve => {
      this.http.post<any>(endpoints.getBookedHours.url, { 'appointment_date': date.noHourFormat() }).subscribe(
        data => {
          if (data instanceof M_BookedHours) {
            resolve(new M_BookedHours(data))
          }
          else {
            resolve(data);
          }
        })
    }
    )
  }
  /**
     * Obtiene las horas ocupadas al crear una cita
     */
  updateConfigAppointment(config: any) {
    return new Promise<M_AppointmentConfig>(resolve => {
      this.http.post<any>(endpoints.updateConfigAppointment.url, config).subscribe(
        data => {
          if (data instanceof M_BookedHours) {
            resolve(new M_AppointmentConfig(data))
          }
          else {
            resolve(data);
          }
        })
    }
    )
  }

  /**DEVUELVE TODAS LAS CITAS A PARTIR DEL DÍA QUE SE LE PASA POR PARÁMETRO*/
  getCitaByDay(date: Date) {
    return new Promise<M_Appointment[]>(resolve => {
      this.http.post<string>(endpoints.getCitaByDay.url, { 'date_appointment': date.dataBaseFormat() }).subscribe(
        data => {
          let data_: M_Appointment[] = [];
          for (let i = 0; i < data.length; i++) {
            data_.push(new M_Appointment(data[i]))
          }
          resolve(data_);
        });
    }
    )
  }

  getHoliDays(center_id: number | undefined) {
    return new Promise<Date[]>(resolve => {
      if (center_id) {
        this.http.post<any>(endpoints.getHolidaysRider.url, { "center_id": center_id }).subscribe(res => {
          resolve(generateHoliday(res.day_holiday, "company"));
        })
      }
      else {
        this.http.post<any>(endpoints.getHolidays.url, {}).subscribe(res => {
          resolve(generateHoliday(res.day_holiday, "company"));
        })
      }
    })
  }

  /*VERSION DOS HOLIDAYS CHECKHOLIDAY*/
  checkHolidays(d: Date, id: number | undefined) {
    return new Promise<boolean | string>(resolve => {
      this.http.post<any>(endpoints.checkHolidays.url, { day: d.dataBaseFormat(), user_id: id }).subscribe(
        data => {
          resolve(true)
        },
        error => {
          resolve(error.message || "El día no se puede establecer como festivo")
        })
    })
  }

  /** ENDPOINTS PEDIR HORAS CLIENTE
     * Se utiliza para obtener las horas ocupadas al pedir una cita des de parte del cliente(rider)
    */
  getBookedHoursByCenterId(date: Date, centerId: number) {
    return new Promise<M_BookedHours | string>(resolve => {
      this.http.post<any>(endpoints.getBookedHoursByCompanyId.url, { 'appointment_date': date.noHourFormat(), 'center_id': centerId })
        .subscribe(
          data => {
            if (data instanceof M_BookedHours) {
              resolve(new M_BookedHours(data))
            }
            else {
              resolve(data);
            }
          })
    },
    )
  }

  /**CREAR CITA CLIENTE*/
  createAppointmentClient(body: any) {
    return new Promise<number>(resolve => {
      this.http.post<any>(endpoints.createAppointmentClient.url, body).subscribe(
        data => { resolve(data); });
    }
    )
  }

  /**CREAR CITA CLIENTE*/
  updateAppointmentClient(body: any) {
    return new Promise<M_Appointment>(resolve => {
      this.http.post<any>(endpoints.updateAppointmentClient.url, body).subscribe(
        data => { resolve(new M_Appointment(data)); });
    })
  }

  /**EDITAR CITA CLIENTE */
  editAppointmentClient(body: M_Appointment, hash: string) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.editAppointmentClient.url + hash, body).subscribe(
        data => { resolve(data); });
    }
    )
  }

  saveHolidaysByUserId(days: Date[], exceptions: M_Schedule[], id: number | undefined) {
    var finalDays = days.map(d => d.dataBaseFormat());
    return new Promise<M_User>(resolve => {
      this.http.post<any>(endpoints.setHolidays.url, { days_holiday: finalDays, exceptions: exceptions, user_id: id }).subscribe(data => {
        resolve(new M_User(data))
      })
    })
  }

  saveHolidayByCompanies(days: Date[], exceptions: M_Schedule[], id: number | undefined) {
    var finalDays = days.map(d => d.dataBaseFormat());
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.setHolidaysCompanies.url, { days_holiday: finalDays, exceptions: exceptions, user_id: id }).subscribe(data => {
        resolve(true)
      })
    })
  }

  getTasksAppointment() {
    return new Promise<M_TaskAppointment[]>(resolve => {
      this.http.get<any>(endpoints.getTaskAppointment.url).subscribe(
        data => {
          resolve(getArrayOf(M_TaskAppointment, data));
        }
      );
    })
  }

  getInvoiceId(invoice_id: number) {
    return new Promise<M_Invoice>(resolve => {
      this.http.post<any>(endpoints.getInvoice.url, { invoice_id: invoice_id }).subscribe(
        data => {
          resolve(new M_Invoice(data));
        }
      );
    })
  }

  vehiclesType() {
    return new Promise<number>(resolve => {
      this.http.get<any>(endpoints.vehicleType.url).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }

  /** PROVIDERS **/
  providers() {
    return new Promise<M_Contact[]>(resolve => {
      this.http.get<any>(endpoints.clients.url).subscribe(
        data => {
          let data_: M_Contact[] = [];
          for (let i = 0; i < data.length; i++) {
            if (data[i].provider === 1) {
              data_.push(new M_Contact(data[i]))
            }
          }
          resolve(data_);
        }
      );
    })
  }

  /** Cehck */
  getClientById(id: number) {
    return new Promise<M_Contact | undefined>(resolve => {
      this.http.post<any>(endpoints.getClientById.url, { id: id }).subscribe(
        data => {
          if (data == "Forbidden") {
            resolve(undefined);
          }
          if (data && Object.keys(data).length != 0) {
            resolve(new M_Contact(data));
          }
          else {
            resolve(undefined)
          }
        },
        _error => {
          resolve(undefined)
        }
      );
    })
  }

  getConceptById(id: number) {
    return new Promise<M_Concept | undefined>(resolve => {
      this.http.post<any>(endpoints.getConceptById.url, { id: id }).subscribe(
        data => {
          if (data == "Forbidden") {
            resolve(undefined);
          }
          if (data && Object.keys(data).length != 0) {
            resolve(new M_Concept(data));
          }
          else {
            resolve(undefined)
          }
        },
        _error => {
          resolve(undefined)
        }
      );
    })
  }

  getAppointmentId(id: number) {
    return new Promise<M_Appointment | undefined>(resolve => {
      this.http.post<any>(endpoints.getAppointmentId.url, { id: id }).subscribe(
        data => {
          if (data == "Forbidden") {
            resolve(undefined);
          }
          if (data && Object.keys(data).length != 0) {
            resolve(new M_Appointment(data));
          }
          else {
            resolve(undefined)
          }
        },
        _error => {
          resolve(undefined)
        }
      );
    })
  }

  getVehicles() {
    return new Promise<M_Vehicle[]>(resolve => {
      this.http.get<any>(endpoints.vehicles.url).subscribe(
        data => {
          let data_: M_Vehicle[] = [];
          for (let i = 0; i < data.length; i++) {
            data_.push(new M_Vehicle(data[i]))
          }
          resolve(data_);
        }
      );
    })
  }

  getVehicleById(id: number) {
    return new Promise<M_Vehicle | undefined>(resolve => {
      this.http.post<any>(endpoints.getVehicleById.url, { id: id }).subscribe(
        data => {
          if (data == "Forbidden") {
            resolve(undefined);
          }
          if (data && Object.keys(data).length != 0) {
            resolve(new M_Vehicle(data));
          }
          else {
            resolve(undefined)
          }
        }
        ,
        _error => {
          resolve(undefined)
        }
      );
    })
  }

  products() {
    return new Promise<M_Product[]>(resolve => {
      this.http.get<any>(endpoints.products.url).subscribe(
        data => {
          let data_: M_Product[] = [];
          for (let i = 0; i < data.length; i++) {
            data_.push(new M_Product(data[i]))
          }
          resolve(data_);
        }
      );
    })
  }

  reservation() {
    return new Promise<M_Reservation[]>(resolve => {
      this.http.get<any>(endpoints.getReservation.url).subscribe(
        data => {
          let data_: M_Reservation[] = [];
          for (let i = 0; i < data.length; i++) {
            data_.push(new M_Reservation(data[i]))
          }
          resolve(data_);
        }
      );
    })
  }

  createProduct(p: M_Product) {
    return new Promise<M_Product>(resolve => {
      this.http.post<any>(endpoints.createProduct.url, p).subscribe(
        data => {
          resolve(new M_Product(data));
        }
      );
    })
  }

  createConcept(c: M_Concept) {
    return new Promise<M_Concept>(resolve => {
      this.http.post<any>(endpoints.createUpdateConcept.url, c).subscribe(
        data => {
          resolve(new M_Concept(data));
        }
      );
    })
  }
  createSerie(s: M_Serie) {
    return new Promise<M_Serie>(resolve => {
      this.http.post<any>(endpoints.createUpdateSerie.url, s).subscribe(
        data => {
          resolve(new M_Serie(data));
        }
      );
    })
  }
  createModel(form: UntypedFormGroup) {
    return new Promise<M_Model>(resolve => {
      this.http.post<any>(endpoints.createUpdateModel.url, form).subscribe(
        data => {
          resolve(new M_Model(data));
        }
      );
    })
  }
  createColor(form: UntypedFormGroup) {
    return new Promise<M_Color>(resolve => {
      this.http.post<any>(endpoints.createUpdateColor.url, form).subscribe(
        data => {
          resolve(new M_Color(data));
        }
      );
    })
  }
  editProductProviderDiscount(discountEntries: any[]) {
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(endpoints.editProductProviderDiscount.url, discountEntries).subscribe(
        data => {
          resolve(data);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  createReserve(produtcs: M_Reservation[]) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.createReserve.url, produtcs).subscribe(
        data => {
          resolve(data);
        }
      );

    })
  }

  editProduct(p: M_Product, action_id?: number, draft_id?: number) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.createProduct.url, {
        product: p,
        action_id: action_id,
        draft_id: draft_id
      }).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }

  getProductById(id: number, viewAllCentersStock: boolean = false) {
    return new Promise<M_Product | undefined>(resolve => {
      this.http.post<any>(endpoints.getProductById.url, { "product_id": id, "viewAll": viewAllCentersStock }).subscribe(
        data => {
          if (data == "Forbidden") {
            resolve(undefined);
          }
          if (data && Object.keys(data).length != 0) {
            resolve(new M_Product(data));
          }
          else {
            resolve(undefined)
          }
        },
        _error => {
          resolve(undefined)
        }
      );
    })
  }

  linkProductToProvider(data: { action: "create" | "update" | "delete", client_id: number, product_id: number }) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.productProvider.url, { data: data }).subscribe(
        data => {
          resolve(data);
        },
      );
    });
  }

  updateUsualProvider(data: any) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.productProvider.url, { data }).subscribe(
        data => {
          resolve(data);
        },
      );
    });
  }

  linkAlternativeProduct(data: any) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.alternativeProducts.url, { data }).subscribe(
        data => {
          resolve(data);
        },
      );
    });
  }


  postPayedInvoice(invoice_id: number) {
    return new Promise<M_Product>(resolve => {
      this.http.post<any>(endpoints.payedInvoice.url, { "invoice_id": invoice_id }).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }
  editStock(product: M_Product, stock: number) {

    let v = stock - product.stock;
    let action = stock >= 0 ? "add" : "remove";

    return new Promise<M_Product>(resolve => {

      if (v == 0) { resolve(product) }

      this.http.post<any>(endpoints.editStock.url, { "product_id": product.product_id, "quantity": Math.abs(v), "action": action }).subscribe(
        data => {
          product.stock += v;
          resolve(data);
        }
      );
    })
  }

  manualStockMove(data: any) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.stockMove.url, { data }).subscribe(
        data => {
          resolve(data);
        },
      );
    });
  }

  company(showLoading: boolean = true) {
    return new Promise<M_Company>(resolve => {
      endpoints.company.showLoading = showLoading;
      this.http.get<any>(endpoints.company.url).subscribe(
        data => {
          resolve(new M_Company(data));
        }
      );
    })
  }

  getMyCenter() {
    return new Promise<M_Center>(resolve => {
      this.http.get<any>(endpoints.getUserCenter.url).subscribe(
        data => {
          resolve(new M_Center(data));
        }
      );
    })
  }

  showAppointmentSide(id: string, date: string) {
    return new Promise<M_Appointment[]>(resolve => {
      this.http.post<any>(endpoints.getAppointmentsSide.url + "/" + id, { date_appointment: date }).subscribe(
        data => {
          let data_: M_Appointment[] = [];
          for (let i = 0; i < data.length; i++) {
            data_.push(new M_Appointment(data[i]))
          }
          resolve(data_);
        }
      )
    }
    )
  }

  updateCompany(c: any) {
    return new Promise<M_Company>(resolve => {
      this.http.post<any>(endpoints.updateCompany.url, c).subscribe(
        data => {
          resolve(new M_Company(data));
        }
      );
    })
  }

  deleteCompany() {
    return new Promise<boolean>(resolve => {
      this.http.get<boolean>(endpoints.deleteCompany.url).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }

  welcomeCompany(companyWelcome: M_CompanyWelcome) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.welcomeCompany.url, companyWelcome).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }

  emailPromo(email: any) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.emailPromo.url, email).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }

  /** All users == true --> All center users */
  /** All users == false --> Only my center users */
  users(allUsers: boolean) {
    return new Promise<M_User[]>(resolve => {
      this.http.get<any>(allUsers ? endpoints.users.url : endpoints.usersCenters.url).subscribe(
        data => {
          let data_: M_User[] = [];
          for (let i = 0; i < data.length; i++) {
            data_.push(new M_User(data[i]))
          }
          resolve(data_);
        }
      );
    })
  }

  myCenter() {

  }

  usersCenters() {
    return this.users(false);
  }

  getPurchases() {
    return new Promise<M_Purchase[]>(resolve => {
      this.http.get<any>(endpoints.getPurchases.url).subscribe(
        data => {
          let data_: M_Purchase[] = [];
          for (let i = 0; i < data.length; i++) {
            data_.push(new M_Purchase(data[i]))
          }
          resolve(data_);
        }
      );
    })
  }

  createUser(u: M_User) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.createUser.url, u).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }

  updateUser(u: M_User) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.updateUser.url, u).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }

  editUser(u: M_User) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.updateUserName.url, {
        user_id: u.id,
        name: u.name
      }).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }

  clientsForVehicles() {
    return new Promise<any>(resolve => {
      this.http.get<any>(endpoints.clientsForVehicles.url).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }

  globalSearch(searched: string) {
    return new Promise<any>(resolve => {
      if (feature.bigMama) {
        this.http.post<any>(endpoints.globalSearch.url, { text: searched }).subscribe(
          data => { resolve(data); }
        );
      }
      else {
        /** Why clients? To make this async, and don't showt the loading panel */
        this.einaData().then(res => resolve(fakeBigMama))
      }
    })
  }

  linkVechicle(vehicle_id: number, client_id: number) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.linkVehicle.url, { "vehicle_id": vehicle_id, "client_id": client_id }).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }

  sellVehicleClient(formData: FormData) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.sellVehicleClient.url, formData).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }

  sellArticles(formData: FormData) {
    return new Promise<M_Purchase>(resolve => {
      this.http.post<any>(endpoints.sellArticles.url, formData).subscribe(
        data => {
          resolve(new M_Purchase(data));
        }
      );
    })
  }

  linkVechicleWithNewClient(vehicle_id: number, c: M_Contact) {
    let client = parseOBJ(c);
    client["vehicle_id"] = vehicle_id;
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.createUpdateClient.url, client).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }

  bills() {
    return new Promise<M_Invoice[]>(resolve => {
      this.http.get<any>(endpoints.bills.url).subscribe(
        data => {
          let data_: M_Invoice[] = [];
          for (let i = 0; i < data.length; i++) {
            var bill = new M_Invoice(data[i]);
            if (!bill.isDraft) {
              data_.push(bill)
            }
          }
          resolve(data_);
        }
      );
    })
  }

  bill(invoice_id: number) {
    return new Promise<M_Invoice>(resolve => {
      this.http.post<any>(endpoints.bill.url, { invoice_id: invoice_id }).subscribe(data => {
        resolve(new M_Invoice(data));
      });
    })
  }

  saveInvoice(invoice_id: number, comment: string, products: (M_Product | M_CustomProduct)[], client_id?: number) {
    return new Promise<number[] | undefined>(resolve => {
      this.http.post<any>(endpoints.saveInvoice.url,
        {
          invoice_id: invoice_id,
          comment: comment,
          products: products,
          client_id: client_id ? client_id : null,
        }).subscribe(data => {
          resolve(data);
        }, _error => {
          resolve(undefined)
        });
    })
  }

  sellVehicle(formValue: any, vehicle: M_Vehicle, agentId: number | undefined) {
    return new Promise<{ invoice?: M_Invoice, action?: M_Action }>(resolve => {
      formValue['concepts'] = vehicle.concepts;
      formValue['products'] = vehicle.products;
      if (agentId) {
        formValue['agent_id'] = agentId;
      }
      this.http.post<any>(endpoints.sellVehicle.url, formValue).subscribe(data => {
        const action = data.actions.original ? new M_Action(data.actions.original) : undefined;
        const invoice = data.invoice ? new M_Invoice(data.invoice) : undefined;
        resolve({ invoice, action });
      }, _error => {
        resolve({});
      });
    });
  }

  delProdInvoice(prod_id: number) {
    return new Promise<boolean>(resolve => {
      this.http.post<any>(endpoints.delProdInvoice.url, { prod_id: prod_id, }).subscribe(data => {
        resolve(true);
      })
    })
  }

  confirmInvoice(invoice_id: number, total: number, date: Date, expedient: string | undefined) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.confirmInvoice.url,
        {
          invoice_id: invoice_id,
          total: total,
          date: date.dataBaseFormat(),
          expedient: expedient
        }).subscribe(data => {
          resolve(data);
        });
    })
  }

  createBill() {
    return new Promise<any>(resolve => {
      this.http.get<any>(endpoints.createBill.url).subscribe(
        data => { resolve(data); });
    })
  }

  setPayedStatus(invoice_id: number) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.payedBill.url, { invoice_id: invoice_id }).subscribe(data => {
        resolve(data);
      }, _error => {
        resolve(undefined)
      });
    })
  }


  discardDraft(invoice_id: number) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.discardInvoice.url, { invoice_id: invoice_id }).subscribe(data => {
        resolve(data);
      });
    })
  }

  deleteImage(id: number) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.deleteImage.url, { image_id: id }).subscribe(data => {
        resolve(data);
      });
    })
  }

  uploadImage(c: CustomFile | File, name: "docu_img" | "vehicle_img" | "dni_front" | "dni_back" | "signature" | "action_image" | "profile",
    ...data: ["client_id" | "vehicle_id" | "action_id", number][]) {
    let form = new FormData();
    let f = c instanceof File ? c : c.file!;
    form.append("image", f)
    form.append("name", name);

    data.forEach(keyVal => {
      form.append(keyVal[0], keyVal[1].toString());
    })

    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.uploadImage.url, form).subscribe(data => {
        resolve(data);
      });
    })
  }

  deleteById(item: M_User | M_Contact | M_Vehicle | M_Product) {
    return new Promise<any>(resolve => {
      let data = item instanceof M_User ? { id: item.id!, type: "user" } :
        item instanceof M_Contact ? { id: item.client_id!, type: "client" } :
          item instanceof M_Vehicle ? { id: item.vehicle_id!, type: "vehicle" } :
            item instanceof M_Product ? { id: item.product_id!, type: "product" } : undefined;
      if (data != undefined) {
        this.http.post<any>(endpoints.deleteById.url, data).subscribe(data => {
          resolve(data);
        });
      }
    })
  }

  /** ?? */
  downloadInvoices(invoices: M_Invoice[]) {
    return new Promise<Blob | undefined>(resolve => {
      this.http.post(endpoints.downloadInvoices.url, { invoices: invoices }, { observe: 'response', responseType: 'blob' }).subscribe(data => {
        try {
          resolve(data.body ? data.body : undefined);
        }
        catch (e: any) {
          resolve(undefined)
        }
      });
    })
  }

  checkDni(data: string) {
    return new Promise<M_Contact | undefined>(resolve => {
      this.http.post<any>(endpoints.checkExist.url, { type: "dni", data: data }).subscribe(data => {
        if (data.nif) {
          resolve(new M_Contact(data));
        }
        else {
          resolve(undefined);
        }
      });
    })
  }

  checkPlate(plate: string) {
    return new Promise<M_Vehicle | undefined>(resolve => {
      this.http.post<any>(endpoints.checkExist.url, { type: "license", data: plate }).subscribe(data => {
        if (data[0]?.license) {
          resolve(new M_Vehicle(data[0]));
        }
        else {
          resolve(undefined);
        }
      });
    })
  }

  checkChasis(chasis: string) {
    return new Promise<M_Vehicle | undefined>(resolve => {
      this.http.post<any>(endpoints.checkExist.url, { type: "chasis", data: chasis }).subscribe(data => {
        if (data[0]?.license) {
          resolve(new M_Vehicle(data[0]));
        }
        else {
          resolve(undefined);
        }
      });
    })
  }

  checkReference(ref: string) {
    return new Promise<M_Product | undefined>(resolve => {
      this.http.post<any>(endpoints.checkExist.url, { type: "product", data: ref }).subscribe(data => {
        if (Array.isArray(data) && data[0]) {
          resolve(new M_Product(data[0]));
        }
        else {
          resolve(undefined);
        }
      });
    })
  }

  saveColors(primary_color: string, secondary_color: string) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.saveColors.url, {
        primary_color: primary_color,
        secondary_color: secondary_color
      }).subscribe(data => {
        resolve(data);
      });
    })
  }

  /** Resolve --> Non file Fiedlds */
  saveFootersAndTemplateFields(data: T_SaveDocumentation) {
    return new Promise<M_TemplateField[]>(resolve => {
      this.http.post<any>(endpoints.saveFooters.url, data).subscribe(data => {
        let response = getArrayOf(M_TemplateField, data).filter(f => f.add_type != "signature" && f.add_type != "damages");
        resolve(response);
      });
    })
  }

  saveTemplateFields(action_id: number, fields: M_TemplateField[]) {
    return new Promise<M_TemplateField[]>(resolve => {
      this.http.post<any>(endpoints.saveTemplateFields.url, { template_fields: fields, adv_numdoc: action_id }).subscribe(data => {
        resolve(getArrayOf(M_TemplateField, data));
      })
    })
  }


  saveCompanyFiles(fields: M_TemplateField[]) {
    return new Promise<M_TemplateField[]>(resolve => { resolve(this.saveTemplateFiles_(fields, true)) })
  }

  generateIdTransac(body: any) {
    const endpoint = endpoints.buyTransac.url;
    return new Promise<M_BuyTransac>(resolve => {
      this.http.post<M_BuyTransac>(endpoint, body).subscribe(data => {
        resolve(new M_BuyTransac(data));
      });
    });
  }

  saveTemplateFiles(id: number, fields: M_TemplateField[]) {
    return new Promise<M_TemplateField[]>(resolve => { resolve(this.saveTemplateFiles_(fields, false, id)) })
  }

  /** Resolve --> Only file fields. Same function for general company file save and transaction file save */
  private saveTemplateFiles_(fields: M_TemplateField[], isCompanyGeneral: boolean, action_id?: number, buyTransac?: any) {

    const endpoint = isCompanyGeneral ? endpoints.saveCompanyFiles.url : endpoints.saveTemplateImage.url;
    let finalResponse: M_TemplateField[] = [];
    let templatesWithFiles = fields.filter(f => f.adv_value instanceof File);

    /** If there is no file to upload, resolve empty array immediately */
    if (templatesWithFiles.length === 0) {
      return Promise.resolve([]);
    }

    /** For each file, create a new promise */
    const filePromises = templatesWithFiles.map(templateWithFile => {
      return new Promise<M_TemplateField[]>(resolve => {

        /** Construct the form Data */
        const formData = new FormData();
        formData.append("add_content", templateWithFile.add_content);
        formData.append("add_type", templateWithFile.add_type);
        formData.append("add_contentnext", templateWithFile.add_contentnext);
        formData.append("add_typedoc", templateWithFile.add_typedoc);
        formData.append("design_id", templateWithFile.design_id.toString());
        formData.append("value_id", templateWithFile.value_id ? templateWithFile.value_id.toString() : '');
        if (action_id) {
          formData.append("adv_numdoc", action_id.toString());
        }
        formData.set("adv_value", templateWithFile.adv_value);


        /** Make the api call with the form data */
        this.http.post<any>(endpoint, formData).subscribe(data => {
          resolve(getArrayOf(M_TemplateField, data.template_fields));
        });

      });
    });

    /** Await all the promises, an then, resolve the funcion general promise with array of the all results */
    return Promise.all(filePromises)
      .then(responses => {
        responses.forEach(res => {
          finalResponse.push(...res);
        })
        //Return only the 'signature' or 'damages'
        return finalResponse.filter(f => f.add_type == "signature" || f.add_type == "damages");
      });
  }

  genericExport(downloadName: string, endpoint: Endpoint, data: any[]) {
    return new Promise<any>((resolve, reject) => {
      this.http.post(`${endpoint.url}`, { data: data }, {
        responseType: 'blob',
      })
        .subscribe((data: Blob) => {
          var fileName = `${downloadName} -  ${new Date().dayMonthYearFormat()}`;
          downloadBlob(data, fileName);
          resolve(data);
        }, error => {
          reject(error);
        });
    });
  }

  brands(type: number) {
    return new Promise<M_Brand[]>(resolve => {
      this.http.post<any>(endpoints.brands.url, { type: type }).subscribe(data => {
        resolve(getArrayOf(M_Brand, data));
      });
    })
  }
  models() {
    return new Promise<M_Model[]>(resolve => {
      this.http.get<any>(endpoints.models.url).subscribe(data => {
        resolve(getArrayOf(M_Model, data));
      });
    })
  }

  brandModel(id_brand: number, id_serie?: number) {
    return new Promise<M_Model[]>(resolve => {
      this.http.post<any>(endpoints.brandModels.url, { id_brand: id_brand, id_serie: id_serie }).subscribe(data => {
        resolve(getArrayOf(M_Model, data));
      });
    })
  }
  brandSerie(id_brand: number) {
    return new Promise<M_Serie[]>(resolve => {
      this.http.post<any>(endpoints.brandSeries.url, { id_brand: id_brand }).subscribe(data => {
        resolve(getArrayOf(M_Serie, data));
      });
    })
  }

  assignClient(v: M_Vehicle, client: M_Contact) {
    return new Promise<M_Contact>(resolve => {
      this.http.post<any>(endpoints.assignClient.url, {
        vehicle_id: v.vehicle_id,
        client_id: client.client_id,
        selected_adress: client.selected_extra_adress
      }).subscribe(data => {
        resolve(new M_Contact(data));
      });
    })
  }

  reSendInvoice(token: string) {
    this.loadingS.show();
    var endpointURL = endpoints.invoicePDF.url + token + "/2";
    return new Promise<any>(resolve => {
      this.http.get<any>(endpointURL).subscribe(data => {
        resolve(data);
      });
    })
  }

  reSendBudget(token: string) {
    this.loadingS.show();
    var endpointURL = endpoints.budgetPDF.url + token + "/2";
    return new Promise<any>(resolve => {
      this.http.get<any>(endpointURL).subscribe(data => {
        resolve(data);
      });
    })
  }

  reSendConfirmation(user_id: number) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.resendConfirmation.url, { user_id: user_id }).subscribe(data => {
        resolve(data);
      });
    })
  }

  pickUpMail(user_id: number, name: string, email: string | undefined, company_id: number, vehicle: any) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.pickUpMail.url, { user_id: user_id, name: name, email: email, company_id: company_id, vehicle: vehicle }).subscribe(data => {
        resolve(data);
      });
    })
  }

  requestPDF(company: M_Company, reference: string) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.requestPDF.url, { company: company, reference: reference }).subscribe(data => {
        resolve(data);
      });
    })
  }

  sendPresentation() {
    return new Promise<any>(resolve => {
      this.http.get<any>(endpoints.presentationMail.url).subscribe(data => {
        resolve(data);
      });
    })
  }

  sendSMTPTestEmail() {
    return new Promise<any>(resolve => {
      this.http.get<any>(endpoints.testEmail.url).subscribe(data => {
        resolve(data);
      });
    })
  }

  editKm(action_id: number, km: string) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.editKm.url, { action_id: action_id, km: km }).subscribe(data => {
        resolve(data);
      });
    })
  }

  editNotes(action_id: number, notes: string) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.editNotes.url, { action_id: action_id, notes: notes }).subscribe(data => {
        resolve(data);
      });
    })
  }

  editTitle(action_id: number, title: string) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.editTitle.url, { action_id: action_id, title: title }).subscribe(data => {
        resolve(data);
      });
    })
  }

  hasDraft() {
    return new Promise<any>(resolve => {
      this.http.get<any>(endpoints.hasDraft.url).subscribe(data => {
        resolve(data == 1 ? true : false);
      });
    })
  }

  /** Load a a pdf URL of a object with no id */
  loadOnFlyPDFURL(type: templateTypedoc, object: any) {
    return new Promise<Blob | undefined>(resolve => {
      if (object == undefined) { resolve(undefined) }
      var endpoint: string | undefined = "";
      switch (type) {
        case "RA":
          endpoint = endpoints.previewRA.url;
          break;
        case "CV":
          endpoint = endpoints.previewCV.url
          break;
        case "FC":
          endpoint = endpoints.previewFC.url
          break;
        default:
          endpoint = undefined;
      }

      if (!endpoint) {
        resolve(undefined);
      }

      if (endpoint) {
        this.http.post(endpoint, object, { responseType: 'blob' }).subscribe(
          (response: Blob) => {
            resolve(response);
          },
          (error) => {
            resolve(undefined);
          }
        );
      }
    })
  }

  /** Load a real pdf file from database */
  loadRealPDFURL(type: templateTypedoc, token: string | undefined) {
    return new Promise<Blob | undefined>(resolve => {
      if (token == undefined) { resolve(undefined) }
      var endpoint: string | undefined = "";
      switch (type) {
        case "I":
          endpoint = endpoints.invoicePDF.url + token + "/1";
          break;
        case "FL":
          endpoint = endpoints.invoicePDF.url + token + "/2";
          break;
        case "A":
          endpoint = endpoints.invoicePDF.url + token + "/1";
          break;
        case "OR":
          endpoint = endpoints.invoicePDF.url + token + "/1";
          break;
        case "RA":
          endpoint = endpoints.raPDF.url + token;
          break;
        case "B":
          endpoint = endpoints.budgetPDF.url + token + "/1";
          break;
        case "AN":
          endpoint = endpoints.createAdvancePdf.url + token + "/1";
          break;
        case "AOR":
          endpoint = endpoints.ORalbaranPDF.url + token + "/1";
          break;
        case "AC":
          endpoint = endpoints.albaranPDF.url + token + "/1";
          break;
        case "EA":
          endpoint = endpoints.storehousePDF.url + token + "/1";
          break;
        case "CB":
          endpoint = endpoints.comercialBudgetPDF.url + token + "/1";
          break;
        default:
          endpoint = undefined;
      }

      if (!endpoint) {
        resolve(undefined);
      }

      if (endpoint) {
        fetch(endpoint).then(res => {
          try {
            res.blob().then(blob => {
              resolve(blob);
            });
          }
          catch (e: any) {
            resolve(undefined)
          }
        })
      }
    })
  }

  /** Generate in real time the template with the params data
   * Endpoint  : /api/pdfExample
  */
  generatCompanyTemplate(type: templateTypedoc, company_data: company_data) {
    return new Promise<Blob | undefined>(resolve => {
      this.http.post(endpoints.pdfExample.url, {
        type: type,
        primary: company_data.p.value,
        secondary: company_data.s.value,
        footer: company_data.footer
      }, { responseType: 'blob' }).subscribe(data => {
        try {
          const blob = new Blob([data], { type: 'application/pdf' });
          resolve(blob);
        }
        catch (e: any) {
          resolve(undefined)
        }
      });
    })
  }

  saveSMTP(body: M_Smpt) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.smtp.url, body).subscribe(data => {
        resolve(data);
      });
    })
  }

  /** Budget to OR, Or to Budget, OR to OR
   *
   * OR case :
   * { "or_id": number, "token": string, "action_id": number }
   *
   * Budget case :
   * number
   *
   */
  transferAction(action_id: number, to: "or" | "budget" | "albaran", data: { client?: M_Contact, ra?: M_ActiveReception }) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.copyAction.url, {
        action_id: action_id,
        client: data.client, // Only have value on budgets
        ra: data.ra, // Only have value on OR
        to_budget: to == "budget" ? 1 : 0,
        to_or: to == "or" ? 1 : 0,
        to_albaran: to == "albaran" ? 1 : 0,
      }).subscribe(data => {
        resolve(data);
      });
    })
  }

  abonar(invoice_id: number) {
    return new Promise<{ invoice_id: number, token: string }>(resolve => {
      this.http.post<{ invoice_id: number, token: string }>(endpoints.abono.url, { invoice_id: invoice_id }).subscribe(data => {
        resolve(data);
      });
    })
  }

  /**
 * Solicita las notificaciones del usuario
 * @returns Todas las notificaciones del usuario
 */
  getNotifications() {
    return new Promise<INotification[]>(resolve => {
      this.http.post<INotification[]>(endpoints.getNotifications.url, {}).subscribe(
        data => {
          var notifications: INotification[] = [];
          notifications.sort((a, b) => a.time > b.time ? -1 : 1);
          resolve(notifications);
        })
    })
  }

  /**
  * Establece una notificación como leída
  * @param id Id de la notificación
  */
  setNotificationReaded(not: INotification) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.updateNotifications.url, { 'notify_id': not.id, type: not.type }).subscribe(
        data => { resolve(data) })
    })
  }

  /**Marca todas las notificaciones del usuario como leídas */
  readAllNotifications() {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.readAllNotifications.url, {}).subscribe(data => { resolve(data) })
    })
  }

  payCometSubscribe(code?: string) {
    return new Promise<string | undefined>(resolve => {
      this.http.post<{ url: string }>(endpoints.payComet.url, { call: "firstPurchase", code: code }).subscribe(data => {
        if (data.url) {
          resolve(data.url)
        }
        else {
          resolve(undefined);
        }
      })
    })
  }

  getLocationByZip(postalCode: string) {
    return new Promise<R_Zip[] | []>(resolve => {
      this.http.get<any>(endpoints.getLocationByZip.url + postalCode).subscribe(
        data => {
          if (typeof data == "object" && Object.keys(data).length > 0) {
            if (Array.isArray(data) && data.length > 0) {
              // Mapear cada elemento del array a un objeto R_Zip
              const resultArray = data.map(item => new R_Zip(item));
              resolve(resultArray);
            }
          }
          else {
            resolve([])
          }
        },
        error => {
          resolve([])
        })
    })
  }

  getFieldsByCCAA(ccaid: number, general: boolean) {
    /** /CTAddFields_Show/{ccaa}/{general} */
    return new Promise<M_TemplateField[] | undefined>(resolve => {
      this.http.get<any>(endpoints.getFieldsByCCAA.url + ccaid + "/" + (general ? "1" : "2")).subscribe(
        data => {
          resolve(getArrayOf(M_TemplateField, data));
        })
    })
  }

  getFieldsFromDoc(typeDoc: templateTypedoc) {
    return new Promise<M_TemplateField[] | undefined>(resolve => {
      this.http.get<any>(endpoints.getFieldsFromDoc.url + typeDoc).subscribe(
        data => {
          resolve(getArrayOf(M_TemplateField, data));
        })
    })
  }

  checkUserSubscription() {
    return new Promise<M_Subscription>(resolve => {
      this.http.get<{ subscription: M_Subscription }>(endpoints.checkSubscription.url).subscribe(data => {
        resolve(new M_Subscription(data.subscription));
      })
    })
  }

  /** Retrurns the paycomet url to pay for the new users */
  payCometAddUsers(users: number) {
    return new Promise<M_Subscription | undefined>(resolve => {
      this.http.post<{ subscription: M_Subscription | undefined }>(endpoints.payComet.url, { users: users, call: "addUser" }).subscribe(
        data => {
          if (data.subscription) {
            resolve(new M_Subscription(data.subscription));
          }
          resolve(undefined);
        },
        error => {
          resolve(undefined)
        })
    })
  }

  /** Remove paycomed payed users */
  payCometRemoveUsers(users: number) {
    return new Promise<M_Subscription | undefined>(resolve => {
      this.http.post<{ subscription: M_Subscription | undefined }>(endpoints.payComet.url, { users: users, call: "addUser" }).subscribe(
        data => {
          if (data.subscription) {
            resolve(new M_Subscription(data.subscription));
          }
          resolve(undefined);
        },
        error => {
          resolve(undefined)
        })
    })
  }

  /** Cancel the company subscription */
  cancelPayCometSubscription() {
    return new Promise<M_Subscription | undefined>(resolve => {
      this.http.post<{ subscription: M_Subscription | undefined }>(endpoints.payComet.url, { call: "cancel" }).subscribe(
        data => {
          if (data.subscription) {
            resolve(new M_Subscription(data.subscription));
          }
          resolve(undefined);
        },
        error => {
          resolve(undefined)
        })
    })
  }

  /** Cancel the company subscription */
  reActivatePayCometSubscription() {
    return new Promise<M_Subscription | undefined>(resolve => {
      this.http.post<{ subscription: M_Subscription | undefined }>(endpoints.payComet.url, { call: "activate" }).subscribe(
        data => {
          if (data.subscription) {
            resolve(new M_Subscription(data.subscription));
          }
          resolve(undefined);
        },
        error => {
          resolve(undefined)
        })
    })
  }

  /** Get all the PayCometInvoices */
  payCometInvoices() {
    return new Promise<M_PayCometInvoice[]>(resolve => {
      this.http.get<any>(endpoints.payCometInvoices.url).subscribe(data => {
        resolve(getArrayOf(M_PayCometInvoice, data));
      })
    })
  }

  getStockBrandAndModel(brand_id: number, model_id: number) {
    return new Promise<M_StockVehicles>(resolve => {
      this.http.post<any>(endpoints.getStockBrandAndModel.url, { brand_id, model_id }).subscribe(_data => {
        resolve(new M_StockVehicles(_data))
      })
    })
  }
  getStockforBrand() {
    return new Promise<M_StockVehicles>(resolve => {
      this.http.get<any>(endpoints.getStockforBrand.url).subscribe(data => {
        resolve(new M_StockVehicles(data));
      })
    })
  }

  changeStateInvoice(object: M_Invoice | M_Purchase, state: invoice_states) {
    if (object instanceof M_Purchase) {
      let name = state.isPartial ? 'partial' : state.isPending ? 'created' : state.isPayed ? 'payed' : 'paid';

      return new Promise<any>(resolve => {
        this.http.post<any>(endpoints.invoiceState.url, { 'buy_num': object.id, state: name }).subscribe(
          data => { resolve(data) })
      })
    }
    else {
      let name = state.isPartial ? 'partial' : state.isPending ? 'created' : state.isPayed ? 'payed' : 'paid';

      return new Promise<any>(resolve => {
        this.http.post<any>(endpoints.invoiceState.url, { 'id_invoice': object.id, state: name }).subscribe(
          data => { resolve(data) })
      })
    }
  }

  getPaymentType() {
    return new Promise<M_TypePayment[]>(resolve => {
      this.http.get<any>(endpoints.showTypePayment.url).subscribe(
        data => { resolve(getArrayOf(M_TypePayment, data)) })
    })
  }

  createLastPayment(body: any, advance: M_LastMovements | undefined) {
    body.dynamicDate = new Date(body.dynamicDate).dataBaseFormat();
    return new Promise<M_LastMovements[]>(resolve => {
      this.http.post<any>(endpoints.CreateLastPayment.url, { movement: body, advance: advance }).subscribe(
        data => { resolve(getArrayOf(M_LastMovements, data)) })
    })
  }

  createAdvanceClient(body: any) {
    return new Promise<M_LastMovements>(resolve => {
      this.http.post<any>(endpoints.CreateAdvancesClient.url, body).subscribe(
        data => { resolve(new M_LastMovements(data)) })
    })
  }

  deleteAdvanceClient(id: number) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.DeleteAdvancesClient.url, { id: id }).subscribe(
        data => { resolve(data) })
    })
  }

  EditAdvanceClient(body: M_LastMovements) {
    return new Promise<M_LastMovements>(resolve => {
      this.http.post<any>(endpoints.EditAdvancesClient.url, body).subscribe(
        data => { resolve(new M_LastMovements(data)) })
    })
  }

  deleteLastPayment(last_movement: M_LastMovements) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.DeleteMovementInvoice.url, last_movement).subscribe(
        data => {
          last_movement.deleted = false;
          resolve(data);
        })
    });
  }

  deleteTypePayment(type_payment_id: number) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.DeleteTypePayment.url, { type_payment_id: type_payment_id }).subscribe(
        data => { resolve(data) })
    });
  }

  paymentByCompany(company_id: number) {
    return new Promise<M_PayCometInvoice[]>(resolve => {
      this.http.post(endpoints.paymentByCompany.url, { company_id: company_id }).subscribe(
        data => {
          resolve(getArrayOf(M_PayCometInvoice, data))

        })
    })
  }

  changeORDelivery(action_id: number, delivery: Date) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.editAction.url, { action_id: action_id, delivery: delivery.dataBaseFormat() }).subscribe(
        data => { resolve(data) })
    });
  }

  /** Massive invoice */
  getForMassiveInvoiceOR() {
    return new Promise<M_GroupTask[]>(resolve => {
      this.http.get<M_GroupTask[]>(endpoints.getForMassiveInvoiceOR.url).subscribe(
        data => {
          resolve(getArrayOf(M_GroupTask, data))
        })
    });
  }

  getForMassiveInvoiceDNote() {
    return new Promise<M_Albaran[]>(resolve => {
      this.http.get<M_Albaran[]>(endpoints.getForMassiveInvoiceDNote.url).subscribe(
        data => {
          resolve(getArrayOf(M_Albaran, data))
        })
    });
  }

  /**{error : string[], invoiced : []} */
  invoiceMassiveOr(ids: { id: number }[]) {
    return new Promise<massiveResponse>(resolve => {
      this.http.post<massiveResponse>(endpoints.invoiceMassiveOR.url, ids).subscribe(
        data => {
          data.invoiced.forEach(i => {
            i.invoice = new M_Invoice(i.invoice);
          })
          resolve(data);
        });
    })
  }

  invoiceMassiveDNote(ids: { id: number }[]) {
    return new Promise<massiveResponse>(resolve => {
      this.http.post<massiveResponse>(endpoints.invoiceMassiveDNote.url, ids).subscribe(
        data => {
          data.invoiced.forEach(i => {
            i.invoice = new M_Invoice(i.invoice);
          })
          resolve(data);
        });
    })
  }

  appointments(from: Date, to: Date, _showloading: boolean) {
    return new Promise<M_Appointment[]>(resolve => {
      this.http.post<any>(endpoints.appointemtsRange.url, { startDate: from.datePickerFormat(), endDate: to.datePickerFormat() }).subscribe(data => {
        resolve(getArrayOf(M_Appointment, data))
      })
    })
  }

  /** NEED TO CREATE THE ENDPOINT AND  */
  getUserById(id: number) {
    return new Promise<M_User | undefined>(resolve => {
      this.http.post<any>(endpoints.getUsersById.url, { id: id }).subscribe(
        data => {
          if (data == "Forbidden") {
            resolve(undefined);
          }
          if (data && Object.keys(data).length != 0) {
            resolve(new M_User(data));
          }
          else {
            resolve(undefined)
          }
        }
        ,
        _error => {
          resolve(undefined)
        }
      );
    })
  }

  getPurchaseById(id: number) {
    return new Promise<M_Purchase | undefined>(resolve => {
      this.http.post<any>(endpoints.getPurchaseById.url, { id: id }).subscribe(
        data => {
          if (data == "Forbidden") {
            resolve(undefined);
          }
          if (data && Object.keys(data).length != 0) {
            resolve(new M_Purchase(data));
          }
          else {
            resolve(undefined)
          }
        }
        ,
        _error => {
          resolve(undefined)
        }
      );
    })
  }

  getCompanyInfoAppointmentClientSide(id: string) {
    return new Promise<M_AppointmentConfig>(resolve => {
      this.http.get<any>(endpoints.getCompanyInfoClientSide.url + "/" + id).subscribe(
        data => {
          resolve(new M_AppointmentConfig(data))
        }
      )
    }
    )
  }

  createAppointmentClientSide(body: I_AppointmentClientSide) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.createAppointmentClientSide.url, body).subscribe(
        data => { resolve(new M_Appointment(data)); });
    }
    )
  }

  lastInvoiceDate() {
    return new Promise<Date | undefined>(resolve => {
      this.http.get<any>(endpoints.lastInvoiceDate.url).subscribe(
        data => {
          if (data.date) {
            resolve(new Date(data.date))
          }
          else {
            resolve(undefined)
          }
        }
      )
    });
  }

  downloadEinvoiceXML(token: string) {
    return new Promise<Blob | undefined>(resolve => {
      if (token == undefined) { resolve(undefined) }
      var endpoint = endpoints.eInvoicePDF.url + token;
      if (endpoint) {
        fetch(endpoint).then(res => {
          try {
            res.blob().then(blob => {
              resolve(blob);
            });
          }
          catch (e: any) {
            resolve(undefined)
          }
        })
      }
    })
  }
  createClientAdmincenter(data: any): Promise<any> {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.clientAdmincenters.url, { data }).subscribe(
        response => {
          resolve(response);
        },
      );
    });
  }

  currentlyWorking() {
    return new Promise<M_Action[] | undefined>(resolve => {
      this.http.post<M_Action[] | M_Action | undefined>(endpoints.currentlyWorking.url, {}).subscribe(data => {
        if (data) {
          if (Array.isArray(data)) {
            resolve(getArrayOf(M_Action, data))
          }
          else {
            resolve([new M_Action(data)]);
          }
        }
        resolve(undefined)
      })
    })
  }

  // REFRESH OR
  startTimeOperator(or_id: number) {
    return new Promise<M_ORTime>((resolve, reject) => {
      this.http.post<any>(endpoints.startTime.url, { or_id: or_id }).subscribe(
        data => {
          resolve(new M_ORTime(data))
        },
        (error: Error) => {
          reject(error.message);
        })
    })
  }

  // REFRESH OR
  endTimeOperator(time_id: number) {
    return new Promise<boolean>((resolve, reject) => {
      this.http.post<any>(endpoints.endTime.url, { time_id: time_id }).subscribe(
        data => {
          resolve(true)
        },
        (error: Error) => {
          reject(error.message);
        })
    })
  }

  addManualTime(or_id: number, user_id: number, hours: number, minutes: number, d: Date) {
    return new Promise<M_ORTime>((resolve, reject) => {
      this.http.post<M_ORTime>(endpoints.addManualTime.url, { or_id: or_id, user_id: user_id, hours: hours, minutes: minutes, day: d.datePickerFormat() }).subscribe(
        data => {
          resolve(new M_ORTime(data))
        },
        (error: Error) => {
          reject(error.message);
        })
    })
  }

  deleteTimeLine(time_id: number) {
    return new Promise<boolean>((resolve, reject) => {
      this.http.post<any>(endpoints.deleteTimeHistory.url, { time_id: time_id }).subscribe(
        data => {
          resolve(true)
        },
        (error: Error) => {
          reject(error.message);
        })
    })
  }

  storeHouses() {
    return new Promise<M_StoreHouse[]>(resolve => {
      this.http.get<any>(endpoints.storehouses.url).subscribe(
        data => {
          resolve(getArrayOf(M_StoreHouse, data));
        }
      );
    })
  }

  deleteStoreHouse(storeHouse: M_StoreHouse) {
    return new Promise<boolean>((resolve, reject) => {
      this.http.post<any>(endpoints.deleteStorehouse.url, { id: storeHouse.id }).subscribe(
        data => {
          resolve(true)
        }
      )
    })
  }

  deleteLocation(location_id: number) {
    return new Promise<boolean>((resolve, reject) => {
      this.http.post<any>(endpoints.deleteLocation.url, { id: location_id }).subscribe(
        data => {
          resolve(true);
        }
      )
    })
  }

  /** StoreHouses */
  createStoreHouse(storeHouse: M_StoreHouse) { return this.sotreHouseCUD(storeHouse, "create"); }
  updateSotreHouse(storeHouse: M_StoreHouse) { return this.sotreHouseCUD(storeHouse, "update"); }
  sotreHouseCUD(storeHouse: M_StoreHouse, action: "create" | "update" | "delete") {
    let postData = { ...storeHouse as any };
    postData['action'] = action;
    return new Promise<M_StoreHouse>((resolve, reject) => {
      this.http.post<any>(endpoints.storehouseCUD.url, { data: postData }).subscribe(
        data => {
          resolve(new M_StoreHouse(data.store))
        }
      )
    })
  }

  /** Flatas */
  getFaults() {
    return new Promise<M_Fault[]>(resolve => {
      this.http.get<any>(endpoints.faults.url).subscribe(
        data => {
          resolve(getArrayOf(M_Fault, data));
        }
      );
    })
  }

  createOrder(preOrder: M_PreOrderBreakdown[]) {
    return new Promise<M_Order[]>(resolve => {
      this.http.post<any>(endpoints.createOrder.url, preOrder).subscribe(
        data => {
          resolve(getArrayOf(M_Order, data))
        }
      )
    })
  }

  editOrder(order: M_Order) {
    return new Promise<boolean>(resolve => {
      this.http.post<any>(endpoints.editOrder.url, {
        order_id: order.id,
        products: order.products
      }).subscribe(
        data => {
          resolve(true)
        }
      )
    })
  }

  deleteOrderProduct(order_id: number, prod_line_id: number) {
    return new Promise<boolean>(resolve => {
      this.http.post<any>(endpoints.deleteOrderProduct.url, {
        order_id: order_id,
        prod_line_id: prod_line_id
      }).subscribe(
        data => {
          resolve(true)
        }
      )
    })
  }

  manualReservation(data: M_Reservation) {
    return new Promise<M_Reservation>(resolve => {
      this.http.post<any>(endpoints.manualReservation.url, data).subscribe(
        data => {
          resolve(new M_Reservation(data))
        }
      )
    })
  }

  /** ALBARANES */
  albaranes() {
    return new Promise<M_Albaran[]>(resolve => {
      this.http.get<any>(endpoints.albaranes.url).subscribe(
        data => {
          resolve(getArrayOf(M_Albaran, data));
        }
      );
    })
  }

  albaranById(id: number) {
    return new Promise<M_Albaran>(resolve => {
      this.http.post<any>(endpoints.albaranById.url, { id: id }).subscribe(
        data => {
          resolve(new M_Albaran(data));
        }
      );
    })
  }

  createAlbaran(c: M_Contact, date: Date | null) {
    return new Promise<M_Albaran>(resolve => {
      this.http.post<any>(endpoints.createAlbaran.url,
        {
          client_id: c.client_id,
          vehicle_id: 0,
          date_user: date,
        }).subscribe(
          data => {
            resolve(new M_Albaran(data));
          }
        );
    })
  }

  saveAlbaranInfo(id: number, albaran: M_Albaran) {
    return new Promise<M_Albaran>(resolve => {
      this.http.post<any>(endpoints.saveAlbaranInfo.url, { deliverynote_id: id, DNote: albaran }).subscribe(
        data => {
          resolve(new M_Albaran(data));
        }
      );
    })
  }

  saveAlbaranProducts(id: number, albaran: M_Albaran) {
    return new Promise<number[]>(resolve => {
      this.http.post<any>(endpoints.saveAlbaranProducts.url, { deliverynote_id: id, DNote: albaran }).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }

  removeAlbaranProduct(p: M_Product | M_CustomProduct) {
    return new Promise<boolean>(resolve => {
      this.http.post<any>(endpoints.removeAlbaranProduct.url, p).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }

  changeAlbaranStatus(id: number, status: albaran_status) {
    return new Promise<boolean>(resolve => {
      this.http.post<any>(endpoints.changeAlbaranStatus.url, { id: id, status: status.num }).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }

  changeAlbaranType(id: number, type: or_types) {
    return new Promise<boolean>(resolve => {
      this.http.post<any>(endpoints.changeAlbaranType.url, { id: id, type: type.num }).subscribe(
        data => {
          resolve(true);
        }
      );
    })
  }

  deleteAlbaran(id: number) {
    return new Promise<boolean>(resolve => {
      resolve(true)
      this.http.post<any>(endpoints.deleteAlbaran.url, { id: id }).subscribe(
        data => {
          resolve(true);
        }
      );
    })
  }

  albaranPDF(token: string) {
    this.loadingS.show();
    var endpointURL = endpoints.albaranPDF.url + token + "/2";
    return new Promise<any>(resolve => {
      this.http.get<any>(endpointURL).subscribe(data => {
        resolve(data);
      });
    })
  }

  getModules() {
    return new Promise<BaseModule[]>(resolve => {
      this.http.get<any>(endpoints.modules.url).subscribe(
        data => {
          resolve(ModuleFactory.createModules(data));
        }
      );
    })
  }

  getModuleById(id: number) {
    return new Promise<BaseModule>(resolve => {
      this.http.post<any>(endpoints.moduleById.url, { id: id }).subscribe(
        data => {
          resolve(ModuleFactory.createModule(data));
        }
      );
    })
  }

  getRecanviosClient(id: number) {
    return new Promise<M_Reservation[]>(resolve => {
      this.http.post<any>(endpoints.recanviosClient.url, { product_id: id }).subscribe(
        data => {
          resolve(getArrayOf(M_Reservation, data));
        }
      );
    })
  }

  toggleModule(module: ModulesEnum) {
    return new Promise<boolean>(resolve => {
      this.http.post<any>(endpoints.toggleModule.url, { id_module: module }).subscribe(
        data => { resolve(true); }
      );
    })
  }

  saveMTR(m: M_MTRModule) {
    return new Promise<boolean>(resolve => {
      this.http.post<any>(endpoints.saveMTR.url, m).subscribe(
        data => { resolve(true); }
      );
    })
  }


  /** Get all storehouse entries */
  getAllStoreHouseEntries() {
    return new Promise<M_StoreHouseEntry[]>(resolve => {
      this.http.get<any>(endpoints.getEntryIntoStore.url).subscribe(data => {
        resolve(getArrayOf(M_StoreHouseEntry, data));
      });
    })
  }

  /** Sotrehouse entry */
  storeHouseEntry(entry: M_StoreHouseEntry) {
    return new Promise<boolean>(resolve => {
      resolve(true)
      this.http.post<any>(endpoints.entryIntoStore.url, entry).subscribe(
        data => {
          resolve(true);
        }
      );
    })
  }

  /** Add manual fault */
  manualFault(product_id: number, location_id: number, quantity: number) {
    return new Promise<M_Fault>(resolve => {
      this.http.post<any>(endpoints.manualFault.url,
        {
          product_id: product_id,
          location_id: location_id,
          quantity: quantity
        }
      ).subscribe(
        data => {
          resolve(new M_Fault(data));
        }
      );
    })
  }

  deleteFault(fault_id: number) {
    return new Promise<boolean>(resolve => {
      this.http.post<any>(endpoints.deleteFault.url, { fault_id: fault_id, }).subscribe(
        data => {
          resolve(true);
        }
      );
    })
  }

  createExtraAddress(fg: FormGroup) {
    return new Promise<M_ShippingAddress>(resolve => {
      this.http.post<any>(endpoints.addExtraAddress.url, fg.value).subscribe(
        data => {
          console.log("Shipping address?")
          resolve(new M_ShippingAddress(data));
        }
      );
    })
  }

  productItemQuants(mov_type: MovTypeEnum, item_id: number) {
    return new Promise<M_QuantsProduct>(resolve => {
      this.http.post<any>(endpoints.getProductItemQuants.url,
        {
          mov_type: mov_type,
          item_id: item_id
        }).subscribe(
          data => {
            resolve(new M_QuantsProduct(data));
          }
        );
    })
  }


  invoiceAlbaran(albran_id: number) {
    return new Promise<M_PDFTemplate[]>(resolve => {
      this.http.post<M_Albaran>(endpoints.invoiceAlbaran.url,
        {
          id: albran_id
        }).subscribe(
          data => {
            let pdfs = data.urlpdf;
            let urls: M_PDFTemplate[] = pdfs && Array.isArray(pdfs) ? getArrayOf(M_PDFTemplate, pdfs) : [];
            resolve(urls);
          }
        );
    })
  }

  getDiscountGroupById(group_discount: number) {
    return new Promise<M_DiscountGroup>(resolve => {
      this.http.post<any>(endpoints.getDiscountGroupById.url, { product_group: group_discount }).subscribe(
        data => {
          resolve(new M_DiscountGroup(data))
        }
      );
    })
  }

  addLinesToDiscountGroup(id: number | undefined, name: string, lines: M_DiscountGroupLine[]) {
    return new Promise<boolean>(resolve => {
      this.http.post<any>(endpoints.createEditDiscountGroup.url, { id: id, name: name, lines: lines }).subscribe(
        data => {
          resolve(true)
        }
      );
    })
  }

  getAllDiscountGroups() {
    return new Promise<M_DiscountGroup[]>(resolve => {
      this.http.get<any>(endpoints.discountGroups.url).subscribe(
        data => {
          resolve(getArrayOf(M_DiscountGroup, data));
        }
      );
    })
  }

  deleteDiscountGroup(id: number) {
    return new Promise<boolean>(resolve => {
      this.http.post<any>(endpoints.deleteDiscountGroup.url, { id: id }).subscribe(
        data => {
          resolve(true)
        }
      );
    })
  }

  checkDuplicatedStoreHouseDocu(val: string) {
    return new Promise<boolean>(resolve => {
      this.http.post<any>(endpoints.checkDuplicatedStoreHouseDocu.url, { dnote_prov_num: val }).subscribe(
        data => {
          resolve(data.duplicated);
        }
      );
    })
  }

  saveGroupDetails(formVal: Partial<{ invoice_contact_id: number | null; invoice_doc_number: string | null; invoice_state_id: number | null; }>) {
    return new Promise<boolean>(resolve => {
      this.http.post<any>(endpoints.saveGroupDetails.url, formVal).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }

  saveCenter(val: M_Center) {
    return new Promise<M_Center>(resolve => {
      this.http.post<any>(endpoints.saveCenter.url, val).subscribe(
        data => {
          resolve(new M_Center(data));
        }
      );
    })
  }


  accountingGroups() {
    return new Promise<M_AccountingGroup[]>(resolve => {
      this.http.get<any>(endpoints.getAccountingGroups.url).subscribe(
        data => {
          resolve(getArrayOf(M_AccountingGroup, data));
        }
      );
    })
  }

  accountingGroup(id: number) {
    return new Promise<M_AccountingGroup>(resolve => {
      this.http.get<any>(endpoints.getAccountingGroup.url + "/" + id).subscribe(
        data => {
          resolve(new M_AccountingGroup(data));
        }
      );
    })
  }

  saveAccountingGroup(ag: M_AccountingGroup) {
    return new Promise<M_AccountingGroupRow[]>(resolve => {
      this.http.post<any>(endpoints.saveAccountingGroup.url, ag).subscribe(
        data => {
          resolve(getArrayOf(M_AccountingGroupRow, data));
        }
      );
    })
  }

  deleteAccountingGroup(ag: M_AccountingGroup) {
    return new Promise<boolean>(resolve => {
      this.http.post<any>(endpoints.deleteAccountingGroup.url, { id: ag.id }).subscribe(
        data => {
          resolve(true);
        }
      );
    })
  }

  createAccountingGroup(ag: M_AccountingGroup) {
    return new Promise<M_AccountingGroup>(resolve => {
      this.http.post<any>(endpoints.createAccountingGroup.url, ag).subscribe(
        data => {
          resolve(new M_AccountingGroup(data.accGroup));
        }
      );
    })
  }

  pasosTaller(start_date: Date, end_date: Date) {
    return new Promise<Blob | undefined>(resolve => {
      this.http.post(endpoints.exportPasosTaller.url, { start_date: start_date, end_date: end_date }, { observe: 'response', responseType: 'blob' }).subscribe(data => {
        try {
          resolve(data.body ? data.body : undefined);
        }
        catch (e: any) {
          resolve(undefined)
        }
      });
    })
  }

  exportToMTR(value: { type: string, center_id: number, dateIni: Date, dateEnd: Date }) {
    return new Promise<Blob | undefined>(resolve => {
      this.http.post(endpoints.exportToMTR.url, value, { observe: 'response', responseType: 'blob' }).subscribe(data => {
        try {
          resolve(data.body ? data.body : undefined);
        }
        catch (e: any) {
          resolve(undefined)
        }
      });
    })
  }

  icis(postData?: icisPostData, showLoading?: boolean) {

    let year: number | undefined = undefined;
    let month: number | undefined = undefined;
    let start: Date | undefined = undefined;
    let end: Date | undefined = undefined;

    if (postData?.range) {
      if ("month" in postData.range) { year = postData.range.year; month = postData.range.month; }
      else if ("start" in postData.range && "end" in postData.range) { start = postData.range.start; end = postData.range.end; }
    }

    if (showLoading) { endpoints.icis.showLoading = true; }
    else { endpoints.icis.showLoading = false; }

    return new Promise<M_Icis>(resolve => {
      this.http.post<any>(endpoints.icis.url, {
        company_id: postData?.company_id,
        center_id: postData?.center_id,
        user_id: postData?.user_id,
        year: year,
        month: month,
        start: start,
        end: end,
        front: 1
      }).subscribe(
        data => {
          resolve(new M_Icis(data));
        }
      );
    })
  }

  companiesCentersUsers() {
    return new Promise<M_Company[]>(resolve => {
      this.http.get<any>(endpoints.companiesCentersUsers.url).subscribe(
        data => {
          resolve(getArrayOf(M_Company, data));
        }
      );
    })
  }

  importTarifa(provider: M_Contact, products: M_Product[]) {
    return new Promise<boolean>(resolve => {
      this.http.post<any>(endpoints.ratesArticlesRecalc.url, {
        provider_id: provider.client_id,
        products: products
      }).subscribe(
        data => {
          resolve(true);
        }
      );
    })
  }

}