<div *ngIf="!canAssign; else cheangeUsr">
    <div class="p10">
        <p class="c_t2 nmb">No puedes cambiar el operario</p>
    </div>
</div>
<ng-template #cheangeUsr>
    <div *ngIf="canChangebyStatus; else cannotChangeByStatus">
        <div class="select-user" (click)="onClickUser(u)" [ngClass]="{'hover' : isClickable(u)}"
            *ngFor="let u of allUsers" [matTooltipPosition]="'right'"
            [matTooltip]="userHasHolidays(u) ? 'El usuario tiene vacaciones' : ''">
            <app-circular-letter [showTooltip]="false" [disabled]="userHasHolidays(u)" [user]="u"></app-circular-letter>
            <div class="already-assigned" *ngIf="isUserAlreadyAssigned(u)">Asignado</div>
        </div>
    </div>
</ng-template>
<ng-template #cannotChangeByStatus>
    <div class="p10">
        <p class="c_t2 nmb">{{textCannotChange}}</p>
    </div>
</ng-template>