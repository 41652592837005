import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModulesEnum } from 'src/app/enums/ModulesEnum';
import { ApiService } from 'src/app/services/Api/api.service';
import { ModulesExplanationComponent } from './modules-explanation/modules-explanation.component';
import { IntegrationsExplanationComponent } from './integrations-explanation/integrations-explanation.component';
import { BaseModule } from 'src/app/models/Modules/Factory/BaseModule';
import { environment } from 'src/environments/environment';
import { M_FakeModule } from 'src/app/models/Modules/M_FakeModule';

export const dontShowModule = [ModulesEnum.TEMPLATES];

@Component({
  selector: 'app-module-selector',
  templateUrl: './module-selector.component.html',
  styleUrls: ['./module-selector.component.css']
})
export class ModuleSelectorComponent {

  modules: BaseModule[] = [];
  integration: BaseModule[] = [];
  fakes: BaseModule[] = [];
  loaded = false;
  constructor(private apiS: ApiService, private d: MatDialog) {
    this.getModules();
  }

  getModules() {

    this.apiS.getModules().then(res => {

      this.modules = res.filter(m => m.isModule);
      this.modules = this.modules.filter(m => !dontShowModule.includes(m.id));

      this.integration = res.filter(m => m.isIntegration);
      this.integration = this.integration.filter(m => !dontShowModule.includes(m.id));
      this.generateFakeModules();

      this.loaded = true;
    })
  }

  openExplanation(explanation: "modules" | "integrations") {
    if (explanation == "modules") { this.d.open(ModulesExplanationComponent, { maxWidth: 600 }) }
    else { this.d.open(IntegrationsExplanationComponent, { maxWidth: 600 }) }
  }

  generateFakeModules() {
    if (!environment.production) {
      this.fakes.push(new M_FakeModule(100, "Yamaha", "Integración con Yamaha", "Con esta integración, podrás usar las funcionalidades de Yamaha."));
      this.fakes.push(new M_FakeModule(98, "BMW", "Integración con BMW", "Con esta integración, podrás usar las funcionalidades de BMW."));
      this.fakes.push(new M_FakeModule(99, "Triumph", "Integración con Triumph", "Con esta integración, podrás usar las funcionalidades de Triumph."));
    }
  }

}