export enum TIME_G {
    years = 0,
    months = 1,
    days = 2,
    hours = 3,
    minutes = 4,
    seconds = 5,
}

export type timeBreakdown = {
    years: number;
    months: number;
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
};

export type timeDifferenceType = {
    breakdown: timeBreakdown;
    totals : timeBreakdown;
    format: string;
}

export type timeDiffConfig = {
    /** Large (Año / Años).  Short (a)*/
    prefix?: "large" | "short"
    /** Leading 0 means the values smallers than 10, will appear with a leading 0. Ex : 09, 01, 02... */
    leadingzero?: boolean
    /** Separation of te values. Ex. 24:59:59*/
    timeSeparation?: boolean
}
