export class M_SigningPause {

    id: number;
    start_time: Date;
    end_time: Date | undefined;

    constructor(d: any) {
        this.id = d.id;
        this.start_time = new Date(d.start_time);
        this.end_time = d.end_time ? new Date(d.end_time) : undefined;
    }

}