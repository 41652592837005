import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SessionService } from '../../services/session.service';
import { INotification } from 'src/app/interfaces/INotification';

/**
 * Notifications component
 * 
 * The component auto-refresh the notifications every 5 minutes or the 'refreshMinutes' variable input 
 * 
 * Needs 3 endpoints :
 * - Get all notifications : NotificationInterface[]
 * - Read all notifications : NotificationInterface[]
 * - Set notification readed (not : NotificationInterface) : any
 * 
 * Has one otput event : 
 * - Emits an event when a notification is clicked. Emits a "NotificationInterface"
 */

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {

  @Input() refreshMinutes: number = 5;
  /** [getAll]="apiS.endpointCall.bind(apiS)" **/
  @Input() getAll!: () => Promise<INotification[]>;
  /** [readAll]="apiS.endpointCall.bind(apiS)" **/
  @Input() readAll!: () => Promise<any[]>;
  /** [setReaded]="apiS.endpointCall.bind(apiS)" **/
  @Input() setReaded!: (not: INotification) => Promise<any[]>;
  /** Notification icon classes */
  @Input() class_?: string;
  /** Auto update notifications */
  @Input() autoUpdate?: boolean;
  /** Emmits a event with the notification when is emited */
  @Output() onClickNotification: EventEmitter<INotification> = new EventEmitter();
  /** Count of all notifications not readed */
  notificationNumber: number = 0;
  /** All the notifications user */
  notifications: INotification[] | undefined;
  /** Determine if the button 'Macrac como leídas' esta activo o no */
  markAsReadedActive: boolean = true;
  /** Determine if the component is loaded */
  loaded = false;

  constructor(private chdRef: ChangeDetectorRef, private sessionS: SessionService) { }

  ngOnInit(): void {
    this.getAllNotifications();
  }

  /** Get all the user notifications **/
  getAllNotifications() {
    if (this.sessionS.hasSession()) {
      this.getAll().then(response => {
        this.notifications = response;
        this.notificationNumber = this.notifications.filter(n => !n.readed).length;
        this.refreshButtonStatus();
        this.loaded = true;
        this.chdRef.detectChanges();
      })
    }
    else{
      this.loaded = true;
      this.chdRef.detectChanges();
    }
  }

  /** Get all the user notifications. This call is made every {{this.refreshMinutes}} minutes */
  autoUpdateNotifications() {
    if (this.autoUpdate || this.refreshMinutes) {
      this.loaded = false;
      this.chdRef.detectChanges();
      this.getAllNotifications();
    }
    setTimeout(() => {
      this.autoUpdateNotifications();
    }, 1000 * 60 * this.refreshMinutes);
  }

  /** Mira si el botón de 'Marcar todas como leíads' debe estar activado o no */
  refreshButtonStatus() {
    let find = false;
    this.notifications?.forEach(n => {
      if (n.readed == false) {
        this.markAsReadedActive = true;
        find = true;
      }
    })
    if (!find) {
      this.markAsReadedActive = false;
    }
  }

  /** Marca como leídas todas las notificaciones */
  markAllNotificationsAsReded() {
    if (this.markAsReadedActive) {
      this.notifications?.forEach(n => {
        this.setNotReaded(n, false) //--> Se pasa el 'false', ya que posteriormente, marcamos todas las notificaciones como leídas en una sola llamada
      })
      this.readAll().then(res => {
        this.notificationNumber = 0;
      });
      this.markAsReadedActive = false;
    }
  }

  /** Al hacer click en una notificación, se marca como leída (si no lo estaba) y se ejecuta su acción*/
  clickNotification(not: INotification) {
    if (!not.readed) {
      this.setNotReaded(not, true);
    }
    this.onClickNotification.emit(not);
  }

  /** Marca una notificación como leída 
   * @param makeCall Si se quiere hacer la llamada al servidor para marcar la notificación como leída
  */
  setNotReaded(not: INotification, makeCall: boolean) {
    if (makeCall) {
      this.setReaded(not);
      this.notificationNumber--;
    }
    not.readed = true;
    this.refreshButtonStatus();
  }

  notificationCompany(not: INotification): string | undefined {
    return undefined;
  }

}



