<div class="advanced-details-container">
    <app-generic-reports [config]="{
        showReport : {
            stock : false,
            sells : false,
            ors : true,
        },
        dataOf: 'einaCompany'
    }">
    </app-generic-reports>
</div>