import { AfterViewInit, ChangeDetectorRef, Component, ComponentRef, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { M_CustomProduct } from 'src/app/models/Products/M_CustomProduct';
import { M_Product } from 'src/app/models/Products/M_Product';
import { ProductLineTableComponent } from '../product-line-table.component';
import { ViewPath } from 'src/app/app-routing.module';
import { ClassSearcherComponent } from 'src/app/components/class-searcher/class-searcher.component';
import { RouterService } from 'src/app/services/router.service';

@Component({
  selector: 'app-product-searcher',
  templateUrl: './product-searcher.component.html',
  styleUrls: ['./product-searcher.component.css']
})
export class ProductSearcherComponent implements AfterViewInit {
  v = ViewPath;

  @ViewChild(ClassSearcherComponent, { static: true }) classSearcher!: ClassSearcherComponent<M_Product>;
  @Input({ required: true }) ref!: ComponentRef<ProductSearcherComponent>;
  @Output() onSelectProduct: EventEmitter<M_Product | M_CustomProduct> = new EventEmitter();
  @Output() onDestroy: EventEmitter<ProductSearcherComponent> = new EventEmitter();

  constructor(@Inject(ProductLineTableComponent) public productTable: ProductLineTableComponent, public routerS: RouterService, private chdRef: ChangeDetectorRef) {

  }

  ngAfterViewInit(): void {
    this.focus();
  }

  disabledIf(rowProduct: M_Product) {
    return this.productTable.getTableProducts().filter(p => p.product_id == rowProduct.product_id).length != 0
  }

  focus() {
    if (this.classSearcher) {
      this.classSearcher.focus();
      this.chdRef.detectChanges();
    }
  }

  destroy() {
    this.ref.destroy();
  }

}
