export class M_Schedule {
  id: number | undefined
  name : string | undefined;
  day_exceptional?: Date;
  morningStart: string;
  morningEnd: string;
  afternoonStart: string;
  afternoonEnd: string;
  account_work: boolean;

  constructor(d: any) {
    this.id = d.id;
    this.name = d.name;
    this.day_exceptional = d.day_exceptional ? new Date(d.day_exceptional) : undefined;
    this.morningStart = d.morning_start || d.morningStart || "";
    this.morningEnd = d.morning_end || d.morningEnd || "";
    this.afternoonStart = d.afternoon_start || d.afternoonStart || "";
    this.afternoonEnd = d.afternoon_end || d.afternoonEnd || "";
    this.account_work = d.account_work || false;
  }

  // Helper function to calculate the difference in time (in minutes) between two times (format: HH:mm)
  private calculateTimeDifference(start: string, end: string): number {
    const [startHour, startMinute] = start.split(':').map(Number);
    const [endHour, endMinute] = end.split(':').map(Number);

    const startTimeInMinutes = startHour * 60 + startMinute;
    const endTimeInMinutes = endHour * 60 + endMinute;

    return endTimeInMinutes - startTimeInMinutes;
  }

  // Function to calculate total time in minutes for this day's schedule
  getTotalTimeInMinutes(): number {
    let total = 0;
    if (this.morningStart && this.morningEnd) {
      total += this.calculateTimeDifference(this.morningStart, this.morningEnd);
    }
    if (this.afternoonStart && this.afternoonEnd) {
      total += this.calculateTimeDifference(this.afternoonStart, this.afternoonEnd);
    }
    return total;
  }

  getTotalTimeString(): string | undefined {
    const totalMinutes = this.getTotalTimeInMinutes();
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    if (hours == 0 && minutes == 0) { return undefined }
    return `${hours}h ${minutes}m`;
  }

  get break(): string {
    if (this.morningEnd && this.afternoonStart) {
      const breakMinutes = this.calculateTimeDifference(this.morningEnd, this.afternoonStart);
      const hours = Math.floor(breakMinutes / 60);
      const minutes = breakMinutes % 60;
      return `${hours}h ${minutes}m`;  // Formato de horas y minutos
    }
    return "0h 0m";
  }

  get isOk() {
    const vB = this.validationBreakdown();
    return vB.morningStart && vB.morningEnd && vB.afternoonStart && vB.afternoonEnd;
  }

  validationBreakdown(): { morningStart: boolean, morningEnd: boolean, afternoonStart: boolean, afternoonEnd: boolean } {
    let isOk = {
      morningStart: this.morningEnd == "" || (this.morningStart != "" && this.morningEnd != "" && this.morningStart < this.morningEnd),
      morningEnd: this.morningStart == "" || (this.morningStart != "" && this.morningEnd != "" && this.morningStart < this.morningEnd),
      afternoonStart: this.afternoonEnd == "" || (this.morningStart != "" && this.morningEnd != "" && this.morningStart < this.morningEnd && this.morningEnd < this.afternoonStart),
      afternoonEnd: this.afternoonStart == "" || (this.morningStart != "" && this.morningEnd != "" && this.afternoonStart != "" && this.morningStart < this.morningEnd && this.morningEnd < this.afternoonStart && this.afternoonStart < this.afternoonEnd),
    }
    return isOk;
  }

  reset() {
    this.morningStart = "";
    this.morningEnd = "";
    this.afternoonStart = "";
    this.afternoonEnd = "";
  }
}
