import { Week } from "./Week";

export class Month {
    weeks: Week[] = []
    constructor() { };
    appendWeek(w: Week) {
      this.weeks.push(w);
    }
    get first() {
      return this.weeks[0].first;
    }
    get last() {
      return this.weeks[this.weeks.length - 1].last;
    }
  }