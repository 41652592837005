<table [ngClass]="{'no-pointer not-edit': !canModifyTable}" class="eina-table">
    <thead>
        <tr>
            <th *ngIf="showSave" class="unsaved-action"></th>
            <th>Nombre</th>
            <th>Tipo</th>
            <th>PVP</th>
            <th>Coste</th>
            <th>Aplicar IEDMT
                <mat-icon class="vam c_t2" mat-icon-button #tooltip="matTooltip"
                    matTooltip="IEDMT es el impuesto de matriculación" matTooltipPosition="above">info</mat-icon>
            </th>
            <th>Aplicar en factura</th>
            <th class="small-action" *ngIf="canModifyTable"></th> <!-- Delete icon -->
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let concept of concepts">

            <!-- Unsaved dot -->
            <td *ngIf="showSave" class="unsaved-action">
                <mat-icon class="unsavedDot" *ngIf="concept.line_hasChanges" [filled]="true">circle</mat-icon>
            </td>

            <!-- Name -->
            <td>
                <div class="df vac jcfs">
                    <mat-icon class="mr10">playlist_add_check_circle</mat-icon>
                    {{concept.name}}
                </div>
            </td>
            <td>
                <div class="df aic jcc">
                    <mat-chip [ngClass]="concept.getTag()">{{concept.getType}}</mat-chip>
                </div>
            </td>

            <!-- Price -->
            <td [ngClass]="{'not_display_td':this.notShowPvP(concept)}">
                <div class="df vac jcc" *ngIf="isInfo">
                    <span money [val]="concept.price"></span>
                    <span
                        *ngIf=" this.vehicle && concept.registration_tax && this.vehicle.registration_tax">+({{this.vehicle.registration_tax}})%</span>
                </div>
                <mat-form-field appearance="outline" class="small-form-field input_concepts_td" *ngIf="!isInfo">
                    <input matInput [(ngModel)]="concept.price" type="number" [(ngModel)]="concept.price"
                        (input)="concept.line_hasChanges = true" (blur)="adjustPriceValue(concept)"
                        [disabled]="isPVPDisabled(concept)" (input)="adjustPriceValue(concept)">
                    <span matSuffix>€</span>
                    <mat-error *ngIf="concept.price < 0 && concept.concept_type !== CTP.DESCUENTO">
                        El precio de venta debe ser positivo.
                    </mat-error>
                    <mat-error *ngIf="concept.price >= 0 && concept.concept_type === CTP.DESCUENTO">
                        El precio de descuento debe ser negativo.
                    </mat-error>
                    <!-- Discount hint -->
                    <!-- <mat-hint *ngIf="isDiscountActiveFor(product, 'price')">
                            <ng-container *ngTemplateOutlet="discountHint"></ng-container>
                        </mat-hint> -->
                </mat-form-field>
            </td>

            <!-- Cost -->
            <td [ngClass]="{'not_display_td':this.notShowCost(concept)}">
                <!-- <span money [val]="concept.cost"></span> -->
                <mat-form-field appearance="outline" class="small-form-field" *ngIf="!isInfo">
                    <input matInput [(ngModel)]="concept.cost" type="number" (input)="concept.line_hasChanges = true"
                        [disabled]="isPVPDisabled(concept)">
                    <span matSuffix>€</span>
                    <!-- Discount hint -->
                    <!-- <mat-hint *ngIf="isDiscountActiveFor(product, 'price')">
                            <ng-container *ngTemplateOutlet="discountHint"></ng-container>
                        </mat-hint> -->
                </mat-form-field>

                <div class="df vac jcc" *ngIf="isInfo">
                    <span money [val]="concept.cost"></span>
                </div>
            </td>

            <!-- Tax -->
            <td>
                <div class="df vac jcc">
                    <mat-icon [ngClass]="concept.registration_tax ? 'c_r' : 'c_g'">
                        {{concept.registration_tax ? 'cancel' : 'done_outline'}}
                    </mat-icon>
                </div>
            </td>
            <td>
                <div class="df vac jcc">
                    <mat-icon [ngClass]="concept.in_invoice ? 'c_r' : 'c_g'">
                        {{concept.in_invoice ? 'cancel' : 'done_outline'}}
                    </mat-icon>
                </div>
            </td>

            <!-- Delete icon -->
            <td *ngIf="canModifyTable">
                <div class="df vac jcc">
                    <button mat-icon-button (click)="removeConcept(concept)">
                        <mat-icon [filled]="true">delete</mat-icon>
                    </button>
                </div>
            </td>
        </tr>
    </tbody>
</table>

<div class="eina-table-bottom">
    <div *ngIf="canModifyTable && !concepts.length && !searchProviderContainer?.length">
        <p class="c_t2"> ¿Sin conceptos? ¡No esperes más! 👇🏻</p>
        <app-ctrl-space-shortcut [text]="'Atajo para agregar concepto'"
            [combination]="'Ctrl+C'"></app-ctrl-space-shortcut>
    </div>
    <div #searchConceptContainerHTML></div>
    <button class="mt10" type="button" mat-stroked-button [color]="'primary'" (click)="addConceptSearcher()" *ngIf="canModifyTable">
        <mat-icon>playlist_add_check_circle</mat-icon>
        Añadir concepto
    </button>
    <!-- <button class="mt10 ml10" type="button" mat-stroked-button [color]="'primary'" [disabled]="true" [matTooltip]="'Proximamente(DEV)'" (click)="addConceptSearcher()" *ngIf="canModifyTable">
        <mat-icon>palette</mat-icon>
        Añadir color (Proximamente 'DEV')
    </button> -->

    <div *ngIf="!canModifyTable && !concepts.length">
        <p class="c_t2">Sin conceptos</p>
    </div>
</div>