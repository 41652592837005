import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MASTER_USER } from 'src/app/constants/masters';
import { M_User } from 'src/app/models/M_User';
import { ClassSearcherComponent } from '../class-searcher/class-searcher.component';

@Component({
  selector: 'app-worker-selector',
  templateUrl: './worker-selector.component.html',
  styleUrls: ['./worker-selector.component.css']
})
export class WorkerSelectorComponent implements AfterViewInit {
  USER = MASTER_USER;
  @ViewChild(ClassSearcherComponent) workerSearcher?: ClassSearcherComponent<M_User>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { users: M_User[], selected: M_User }, public dRef: MatDialogRef<WorkerSelectorComponent>) {}

  ngAfterViewInit(): void {
    this.workerSearcher?.select(this.data.selected);
  }
}
