<div>
    <!-- En caso que le pasemos Product -->
    <mat-form-field *ngIf="product">
        <mat-label>{{label ? label : 'Ubicación'}}</mat-label>
        <mat-select [(value)]="selectedLocation" (selectionChange)="onSelectLocationByProduct($event)">
            <mat-select-trigger>
                <div class="df nmb">
                    <mat-icon class="vam" [filled]="true" [style]="selectedLocation?.bookmarkColorStyle">bookmark</mat-icon>
                    <p class="nmb">{{selectedLocation?.store_name}} - {{selectedLocation?.name}} <span
                           class="nmb fsxs c_t2 ml10"> (Disponible : {{selectedLocation?.disp}})</span></p>
                </div>
            </mat-select-trigger>

            <mat-option *ngFor="let location of product.locations" [value]="location">
                <div class="df nmb">
                    <mat-icon class="vam" [filled]="true" [style]="location.bookmarkColorStyle">bookmark</mat-icon>
                    <p class="nmb">{{location.store_name}} - {{location.name}}</p>
                </div>
                <p class="nmb fsxs c_t2" style="margin-left: 45px;">
                    Disponible : {{location.disp}}
                </p>
            </mat-option>
        </mat-select>
    </mat-form-field>


    <!-- En caso que le pasemos stores -->

    <mat-form-field *ngIf="stores && stores.length > 0">
        <mat-label>Ubicación</mat-label>
        <mat-select (selectionChange)="onSelectLocationByStore($event)">
            <mat-select-trigger>
                <div class="df nmb">
                    <mat-icon class="vam" [filled]="true" [style]="selectedStore?.bookmarkColorStyle">bookmark</mat-icon>
                    <p class="nmb">{{selectedStore?.name}} - {{selectedLocationStore?.name}}</p>
                </div>
            </mat-select-trigger>

            <div *ngFor="let store of stores">
                <mat-option *ngFor="let loc of store.locations" [value]="loc">
                    <mat-icon class="vam" [filled]="true" [style]="store.bookmarkColorStyle">bookmark</mat-icon>
                    {{store.name}} - {{loc.name}}

                </mat-option>
            </div>
        </mat-select>
    </mat-form-field>



</div>