import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { WorkloadService } from 'src/app/services/workload.service';
import { DragTutorialComponent } from '../carga-taller/drag-tutorial/drag-tutorial.component';
import { ChangeDeliveryOrDialogComponent } from './change-delivery-or-dialog/change-delivery-or-dialog.component';
import { M_Appointment } from 'src/app/models/M_Appointment';
import { ApiService } from 'src/app/services/Api/api.service';
import { ModifyAppointmentHourComponent } from 'src/app/components/modify-appointment-hour/modify-appointment-hour.component';
import { SubscriptionService } from 'src/app/services/EinaMainData/subscription.service';
import { UserService } from 'src/app/services/EinaMainData/user.service';
import { RouterService } from 'src/app/services/router.service';
import { RolesEnum } from 'src/app/enums/RolesEnum';
import { ViewPath } from 'src/app/app-routing.module';
import { M_Action } from 'src/app/models/M_Action';
import { PeriodEnum } from 'src/app/enums/PeriodEnum';
import { CoreCargaTallerComponent } from 'src/app/components/carga-taller/core-carga-taller.component';
import { SETTINGS_TAB } from '../settings/settings.component';
import { sortWorkloadAppointments } from 'src/app/utils/FunctionUtils';

@Component({
  selector: 'app-carga-taller-parent',
  templateUrl: './carga-taller-parent.component.html',
  styleUrls: ['./carga-taller-parent.component.css']
})
export class CargaTallerParentComponent {

  R = RolesEnum;
  v = ViewPath;
  ST = SETTINGS_TAB;

  @ViewChild(CoreCargaTallerComponent) public cargaTaller!: CoreCargaTallerComponent;

  constructor(public userS: UserService, private d: MatDialog, public wS: WorkloadService,
    private apiS: ApiService, public subS: SubscriptionService, public routerS: RouterService) {

    this.wS.refresh();

    if (!this.userS.userWorkloadTutorial) {
      this.d.open(DragTutorialComponent, { disableClose: true });
      this.userS.setuserWorkloadTutorial = true;
    }
  }

  onDragFinished() {
    this.reOrderAppointments();
  }

  onModifyAppointment(change: [M_Appointment, ("hour")]): void {
    var appointment = change[0];
    var changeName = change[1];
    if (changeName == "hour") {
      this.onChangeHour(appointment as M_Appointment);
    }
  }

  /** OR delivery Warn */
  openWarnDialog(data: M_Action) {
    this.d.open(ChangeDeliveryOrDialogComponent, { data: data });
  }

  onChangeHour(appointment: M_Appointment) {
    const dialogRef = this.d.open(ModifyAppointmentHourComponent, {
      data: appointment,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //A partir del resultado del dialogo, modificamos la fecha de la cita
        appointment.day = new Date(result);
        this.apiS.editAppointmentClient(appointment!, appointment!.hash).then(_res => {
          this.reOrderAppointments();
        })
      }
    });
  }

  reOrderAppointments() {
    if (!this.cargaTaller.workloadData.isOrView) {
      switch (this.cargaTaller.selectedPeriod) {
        case (PeriodEnum.WEEK): {
          this.cargaTaller.currentWeek = this.cargaTaller.getweekDaysByDay(new Date(this.cargaTaller.refDay));
          sortWorkloadAppointments(this.cargaTaller.currentWeek.days);
          break;
        }

        case (PeriodEnum.MONTH): {
          this.cargaTaller.currentMonth = this.cargaTaller.getMonthByDay(new Date(this.cargaTaller.refDay));
          this.cargaTaller.currentMonth.weeks.forEach(week => { sortWorkloadAppointments(week.days) })
          break;
        }
      }
      this.cargaTaller.chdRef.detectChanges();
    }
  }

}