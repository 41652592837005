<mat-chip-set *ngIf="!circularStatus; else circular">
    <mat-chip [class]="'a_status a_status' + statusNum" [ngClass]="{'cp_i' : pointer}">
        <mat-icon matChipAvatar class="status_icon" *ngIf="status != undefined && showIcon && canceled">event_busy</mat-icon>
        <mat-icon matChipAvatar class="status_icon" *ngIf="status != undefined && showIcon && pending">schedule</mat-icon>
        <mat-icon matChipAvatar class="status_icon" *ngIf="status != undefined && showIcon && done">event_available</mat-icon>
        <span [class]="'a_status-text' + statusNum">{{statusText}}</span>
        <mat-icon style="pointer-events: none;" matChipRemove *ngIf="menuIconOpen" class="drop-down-icon">arrow_drop_down</mat-icon>
    </mat-chip>
</mat-chip-set>

<ng-template #circular>
    <div [class]=" 'df jcfs aic fss a_status ' + ' a_' + statusNum">
        <mat-icon matChipAvatar [filled]="true">circle</mat-icon> <span *ngIf="showText">{{statusText}}</span>
    </div>
</ng-template>