import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-pdf-documents-upload',
  templateUrl: './pdf-documents-upload.component.html',
  styleUrls: ['./pdf-documents-upload.component.css']
})
export class PdfDocumentsUploadComponent {
  @Input() images?: boolean;
  imageUrl: string | null = null;
  @Output() fileSelected = new EventEmitter<File | null>();
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  public selectedFile: File | null = null;
  isDragging = false;

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragging = true;
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragging = false;

    if (event.dataTransfer?.files.length) {
      this.handleFile(event.dataTransfer.files[0]);
    }
  }

  onFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length) {
      this.handleFile(input.files[0]);
    } else {
      this.selectedFile = null;
      this.fileSelected.emit(null);
    }
  }
  displayImage(file: File) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.imageUrl = e.target.result;
    };
    reader.readAsDataURL(file);
    this.fileSelected.emit();
  }
  handleFile(file: File) {
    const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/tiff'];
  
    if (this.images) {
      // Si this.images es true, solo permitir imágenes
      if (validImageTypes.includes(file.type)) {
        this.selectedFile = file;
        this.displayImage(file);
        this.fileSelected.emit(file);
      } else {
        alert('Por favor, sube una imagen.');
      }
    } else {
      // Si this.images es false o no está definido, solo permitir archivos PDF
      if (file.type === 'application/pdf') {
        this.selectedFile = file;
        this.fileSelected.emit(file);
      } else {
        alert('Por favor, sube un archivo PDF.');
      }
    }
  }
  clearImage() {
    this.selectedFile = null;
    this.imageUrl = null;
  }
  openFileDialog() {
    this.fileInput.nativeElement.value = ''; // Reset the file input
    this.fileInput.nativeElement.click();
  }

  removeFile(event: MouseEvent) {
    event.stopPropagation(); // Prevent triggering the click event on the container
    this.selectedFile = null;
    this.fileInput.nativeElement.value = ''; // Reset the file input
    this.fileSelected.emit(null);
  }
}
