<app-page-structure [masterClass]="undefined" pageTitle="Pedidos" [autocompleteID]="'orders'"
    searchLabel="Buscar pedido" listTitleText="Listado de pedidos"
    noDataCreateNew="Crear un nuevo pedido" [data]="[]"
    [filters]="filters" [filter]="f" [rightMenu]="rightMenu"
    [displayedHeader]=" ['Número',   'Proveedor',        'Envío',        'Entrado',          'Estado',           'Creación']"
    [displayedColumns]="['order',    'provider.name',    'sendOrder',    'enteredDivision',  'order_status',     'created_at']"
    [specialText]="     [undefined,   client_name,        send,           undefined,          undefined ,         undefined]"
    [specialClass]="    [undefined,   undefined,          undefined,      undefined,          undefined,          undefined]"
    [preIcon]="         [preIcon,     undefined,          undefined,      undefined,          undefined,          undefined]"
    [cellZize]="        [undefined,   undefined,          undefined,      'small',            undefined,          undefined]"
    [quickFilter]="quickFilter"
    (onclickRow)="params.go(v.editOrder, $event.id)"
    [createButton]="
        {
            text : 'Crear pedido' , 
            icon : 'local_shipping', 
            view : v.createOrder, 
            cssClass : 'onboarding-create-order'
        }">
</app-page-structure>

<ng-template #rightMenu>
    <button mat-menu-item (click)="openFaultsDialog()">
        <mat-icon>production_quantity_limits</mat-icon>
        Faltas
    </button>
</ng-template>