import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { SubscriptionService } from '../../services/EinaMainData/subscription.service';
import { filter } from '../../custom-classes/MasterFilter';
import { M_Albaran } from '../../models/M_Albaran';
import { ViewPath } from 'src/app/app-routing.module';
import { ParamsService } from 'src/app/services/params.service';
import { ApiService } from 'src/app/services/Api/api.service';
import { isSomethingMissing } from 'src/app/utils/recambiosFuntions';
import { PageStructureComponent } from 'src/app/components/page-structure/page-structure.component';
import { getAccentColor, getPrimaryColor, getWarnColor } from 'src/app/utils/FunctionUtils';
import { DayFilter, Filter, FilterOption, TagFilter } from 'src/app/custom-classes/Filter';
import { albaran_status } from 'src/app/custom-classes/albaran_status';
import { MatDialog } from '@angular/material/dialog';
import { IMassiveType, MassiveInvoiceComponent } from '../../components/massive-invoice/massive-invoice.component';
import { OR_TYPE_FILTER } from 'src/app/constants/SharedFilters';

export enum AlbaranPageFiltesrEnum {
  STATUS = 0,
  TYPE = 1,
  CREATED_AT = 2,
}

@Component({
  selector: 'app-albaranes',
  templateUrl: './albaranes.component.html',
  styleUrls: ['./albaranes.component.css']
})
export class AlbaranesComponent implements AfterViewInit {

  @ViewChild(PageStructureComponent) ps!: PageStructureComponent<M_Albaran>;

  primary = getPrimaryColor;
  accent = getAccentColor;
  warn = getWarnColor;
  f = filter;
  v = ViewPath;

  filters: Filter[] = [
    new TagFilter("Estado", albaran_status, new FilterOption("Abiero"), new FilterOption("Cerrado"), new FilterOption("Facturado")).setId(AlbaranPageFiltesrEnum.STATUS),
    OR_TYPE_FILTER().setId(AlbaranPageFiltesrEnum.TYPE),
    new DayFilter("Fecha realización").setId(AlbaranPageFiltesrEnum.CREATED_AT),
  ]

  constructor(public subS: SubscriptionService, public params: ParamsService, private apiS: ApiService, private d: MatDialog) { }

  ngAfterViewInit(): void {
    this.load();
  }

  load() {
    this.apiS.albaranes().then(res => {
      const noDeletedAlbaran = res.filter(item => !item.deleted);
      this.ps.initTable(noDeletedAlbaran);
    })
  }

  get quickFilter() {
    let f = this.filters.find(f => f.id == AlbaranPageFiltesrEnum.STATUS);
    if (f instanceof TagFilter) { return f };
    return undefined;
  }

  getClient(albaran: M_Albaran) {
    return albaran.client?.getName();
  }

  preicon(albaran: M_Albaran) {
    return isSomethingMissing(albaran.faults)
  }

  openMassiveAlbaran() {
    this.d.open<MassiveInvoiceComponent, IMassiveType>(MassiveInvoiceComponent, {
      data: {
        type: 'albaran',
      },
      autoFocus: false
    }).afterClosed().subscribe(res => {
      if (res == true) {
        this.load();
      }
    });
  }

}
