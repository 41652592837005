import { Injectable } from '@angular/core';
import { feature } from '../utils/FeaturesController';

declare global {
  interface Window {
    HubSpotConversations: any; // Cambia `any` por un tipo específico si lo conoces
  }
}

@Injectable({
  providedIn: 'root'
})
export class HubspotService {

  constructor() {
    // if (feature.hubSpot) {

    //   this.loadHubSpotScript();

    // router.events.subscribe((val) => {
    //   if (val instanceof NavigationStart) {
    //     let v = routerS.getViewFromRoute(val.url);
    //     if (v) {
    //       if (routerS.hasGuard(v, AuthGuard)) {
    //         this.loadHubSpotScript();
    //       }
    //       else {
    //         this.removeHubSpotScript();
    //       }
    //     }
    //   }
    // });
    // }
  }

  loadHubSpotScript() {
    if (feature.hubSpot) {
      console.log("🟢 Loading Hubspot script...")
      const scriptId = 'hs-script-loader';
      // Evitar agregar el script varias veces
      if (document.getElementById(scriptId)) {
        console.log("🟢 HubsPot already added")
        return;
      }
      const script = document.createElement('script');
      script.id = scriptId;
      script.src = "//js-eu1.hs-scripts.com/145453541.js";
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
      console.log("🟢 HubsPot ADDED")
    }
  }

  // removeHubSpotScript() {
  //   console.log("🟠 Removing HubSpot script...")
  //   const script = document.getElementById('hs-script-loader');
  //   if (script) {
  //     script.parentNode?.removeChild(script);
  //     console.log("🟠 HubSpot REMOVED")
  //   }
  //   else {
  //     console.log("🟠 HubSpot (Nothing to remove)")
  //   }
  // }


  openHubSpotChat() {
    if (feature.hubSpot) {
      window.HubSpotConversations.widget.open();
    }
    else {
      alert("Hubspot no disponible en este entorno")
    }
  }


}
