<app-card [contentLoaded]="companyTab.loaded" [cornerMatMenu]="deleteButton" [noStyle]="true">
    <app-card-title>
        <div class="df div_btn">
            <span>Tu empresa
                <span style="margin-left: 10px"
                    [matBadge]="companyS.companyMissingInfo? '!' : ''"></span>
            </span>
            <p *ngIf="companyS.companyMissingInfo" class="missing-company-info">
                Rellena la información de tu empresa para poder realizar facturas
            </p>

            <ng-template #deleteButton>
                <button mat-icon-button
                    [mat-menu-trigger-for]="deleteMenu"><mat-icon>more_vert</mat-icon></button>
                <mat-menu #deleteMenu="matMenu">
                    <button mat-menu-item [disabled]="!userS.isSuperUser" (click)="deleteCompany()">
                        <mat-icon>delete</mat-icon> Eliminar empresa
                    </button>
                </mat-menu>
            </ng-template>
        </div>
    </app-card-title>
    <div class="df fww aic jcse" style="justify-content: space-evenly; gap: 10px;">
        <div class="df fdc aic jcc">
            <app-card-subtitle>Logo
                <mat-icon [filled]="true" [matTooltip]="!imgSaved ? 'Guarda para aplicar los cambios':''"
                    class="unsavedimg c_r"
                    [ngClass]="imgSaved ? 'hide_save' : 'save_appear'">fiber_manual_record</mat-icon>
            </app-card-subtitle>
            <lib-file-upload [crop]="true" [maintainAspectRatio]="false" [maxSize]="6"
                (stateChanged)="onFileupload($event)"></lib-file-upload>
        </div>
        <div class="df fdc aic jcc mb10">
            <app-card-subtitle>Color corporativo
                <mat-icon [filled]="true" [matTooltip]="cpicker.touched ? 'Guarda para aplicar los cambios':''"
                    class="unsavedimg c_r"
                    [ngClass]="!cpicker.touched ? 'hide_save' : 'save_appear'">fiber_manual_record</mat-icon>
            </app-card-subtitle>
            <app-documentation-color-picker #cpicker></app-documentation-color-picker>
        </div>
    </div>
    <form [formGroup]="form" (ngSubmit)="makeCall()" eForm>
        <div class="onboarding-company-genera-tab-info">
            <app-card-subtitle>Información básica</app-card-subtitle>

            <div eForm-section>
                <app-cif [form]="form" [formCNmae]="'cif'"></app-cif>
                <mat-form-field appearance="outline">
                    <mat-label>Nombre empesa</mat-label>
                    <input #input matInput placeholder="Nombre empresa" formControlName="name">
                </mat-form-field>
            </div>
            <div eForm-section>
                <app-email-input [form]="form" [formCName]="'email'"></app-email-input>
                <app-phone-input [form]="form" [formCName]="'phone'"></app-phone-input>
            </div>

            <app-card-subtitle>Ubicación</app-card-subtitle>
            <app-full-address [form]="form" [city]="c?.city"></app-full-address>
        </div>
        <div class="df jcc form" style="margin-top: 20px;">
            <button [disabled]="!form.valid || this.form.get('zip')?.value.length !=5" subscribed-user
                color="primary" class="onboarding-company-genera-tab-finish" mat-flat-button
                type="submit">Guardar</button>
        </div>
    </form>
</app-card>