<app-page-structure [masterClass]="'vehicle'" pageTitle="Stock de vehículos" [autocompleteID]="'vehicle_garage'"
    searchLabel="Buscar vehículo" noDataCreateNew="Crear VN/VO" [data]="vehicles"  noDataCreateNew="Comprar vehículo"
    listTitleText="Listado de stock de vehículos" [filter]="filter" [filters]="filters"
    [displayedHeader]=" ['Chassis', 'Marca',    'Modelo',  'Precio',    'Tipo',        ]"
    [displayedColumns]="['chassis', 'brand_name','model_name',   'price',     'comercialType']"
    [specialText]="     [undefined,  undefined,  undefined, 'money',    undefined,      ]"
    [specialClass]="    [undefined,  undefined,  undefined, undefined,  undefined  ]"
    [cellZize]="        ['big',      undefined,  undefined, undefined,  'small']" [preIcon]="[preIcon]"
    [filter]="filter" [rightMenu]="rightMenu" (onclickRow)="paramsS.go(v.vnvoDetails, $event.vehicle_id)"
    [quickFilter]="quickFilter" [createButton]="
        {
            text : 'Comprar vehículo' , 
            icon : 'garage', 
            view : v.createVnVo, 
            cssClass:'onboarding-create-garage'
        }">
</app-page-structure>

<ng-template #rightMenu>
    <button mat-menu-item (click)="openBrand()">
        <mat-icon>style</mat-icon>
        Marcas
    </button>
    
    <button mat-menu-item (click)="openModel()">
        <mat-icon>transportation</mat-icon>
        Modelos
    </button>

    <button mat-menu-item (click)="openSeries()">
        <mat-icon>barcode_reader</mat-icon>
        Series
    </button>

    <button mat-menu-item (click)="openColors()" *ngIf="f.colors">
        <mat-icon>palette</mat-icon>
       Colores
    </button>

    <button mat-menu-item (click)="routerS.goTo(v.concepts)">
        <mat-icon>playlist_add_check_circle</mat-icon>
        Conceptos
    </button>
</ng-template>