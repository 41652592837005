<app-close-dialog-button></app-close-dialog-button>

<p mat-dialog-title>Reserva manual</p>

<div mat-dialog-content [formGroup]="form" class="form">

    <app-card [noStyle]="true" [contentLoaded]="searchersLoaded">

        <!-- Product -->
            <app-card-subtitle [first]="true" mat-dialog-subtitle>Datos reserva</app-card-subtitle>
            <app-class-searcher class="mr10" #productSearcher [form_]="form" [formCname]="'product_id'"
                [masterClass]="product" [required]="true"
                [selectedOnLoad]="data && data.product? data.product!.product_id : undefined" [width100]="true"
                [searchPlaceHolder]="'Buscar producto'" (onRemove)="onRemoveProduct($event)"
                (onSelect)="onSelectProduct($event)"
                [createData]="undefined"
                >
            </app-class-searcher>
            <!-- <ng-template #>
                <p class="c_t2 vam">{{data?.product?.name}}</p>
            </ng-template> -->
            <!-- Location -->
            <mat-form-field class="mr10">
                <mat-label>Ubicación</mat-label>
                <!-- Could be nice to group by storehouse -->
                <!-- <mat-optgroup *ngFor="let group of pokemonGroups" [label]="group.name" [disabled]="group.disabled">
                    <mat-option *ngFor="let pokemon of group.pokemon" [value]="pokemon.value">
                        {{pokemon.viewValue}}
                    </mat-option>
                </mat-optgroup> -->
                <mat-select formControlName="location_id">
                    <mat-select-trigger>
                        <ng-container *ngIf="selectedLocation">
                            <mat-icon class="vam" [filled]="true" [style]="selectedLocation.bookmarkColorStyle">bookmark</mat-icon>
                            {{selectedLocation.store_name}}
                            <span class="fss">{{selectedLocation.name}} </span>
                        </ng-container>
                    </mat-select-trigger>
                    <mat-option *ngFor="let location of productSearcherComponent?.selected?.locations"
                        [value]="location.id">
                        <p class="nmb">
                            <mat-icon class="vam" [filled]="true" [style]="location.bookmarkColorStyle">bookmark</mat-icon>
                            {{location.store_name}}
                            <span class="fss">{{location.name}} </span>
                        </p>
                        <p class="nmb fsxs c_t2" style="margin-left: 20px;">
                            Disponible : {{location.disp}}
                        </p>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!-- Quantity-->
            <!-- <app-card-subtitle [first]="true" mat-dialog-subtitle>Cantidad</app-card-subtitle>
            <div class="df aic">
                <app-add-remove class="manual-reservation" [initValue]="1" [label]="'Cantidad'" [minValue]="1"
                    [addRemoveOutside]="false" [forceMinMaxValue]="true" [canModify]="productSearcher.selected != undefined"
                    [showSaveIcon]="false" [form]="form" [formCName]="'quantity'"></app-add-remove>
            </div> -->


            <!-- Client -->
            <div class="df">
                <app-class-searcher class="mr10" #clientSearcher [form_]="form" [formCname]="'client_id'"
                    [width100]="true" [masterClass]="client"
                    [selectedOnLoad]="data && data.client? data.client.client_id : undefined"
                    searchPlaceHolder="Buscar cliente" [extendedInfo]="false"
                    [createData]="undefined"
                    >
                </app-class-searcher>

                <div class="df aic">
                    <app-add-remove class="manual-reservation mr10 removeadd" [initValue]="1" [label]="'Cantidad'"
                        [minValue]="1" [addRemoveOutside]="false" [forceMinMaxValue]="true"
                        [canModify]="productSearcher.selected != undefined" [showSaveIcon]="false" [form]="form"
                        [formCName]="'quantity'"></app-add-remove>
                </div>
            </div>
            <mat-divider></mat-divider>
            <p *ngIf="form.valid" class="c_t2 fss tac">
                Se
                <span plural [val]="[addRemoveComponent?.getValue(), 'reservará', 'reservarán']"></span>
                <span class="highlight-span">
                    {{addRemoveComponent?.getValue()}}
                    <span plural [val]="[addRemoveComponent?.getValue(), 'unidad', 'unidades']"></span>
                </span>
                <span> de </span>
                <span class="highlight-span">
                    <mat-icon class="small-icon vam" smartIcon>{{productSearcher.selected?.icon}}</mat-icon>
                    {{productSearcher.selected?.name}}
                </span>
                para
                <span class="highlight-span">
                    <mat-icon class="small-icon vam" smartIcon>{{clientSearcher.selected?.icon}}</mat-icon>
                    {{clientSearcher.selected?.getName()}}
                </span>
            </p>
    </app-card>

</div>

<div mat-dialog-actions>
    <button mat-flat-button color='white' matDialogClose="true">Cancelar</button>
    <button mat-flat-button [disabled]="!form.valid" color='primary' (click)="makeReservation()">Guardar</button>
</div>