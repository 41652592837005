import { Component, Optional, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { ComercialConceptType } from 'src/app/enums/ComercialConceptType';
import { ViewPath } from 'src/app/app-routing.module';
import { M_Concept } from '../../models/M_Concept';
import { ApiService } from 'src/app/services/Api/api.service';
import { DialogConfirmPlateComponent } from '../vehicleform/dialog-confirm-plate/dialog-confirm-plate.component';
import { ParameterStateComponent } from '../parameter-state/parameter-state.component';
import { RouterService } from 'src/app/services/router.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { FormService } from 'src/app/services/form.service';
import { ParamsService } from 'src/app/services/params.service';

@Component({
  selector: 'app-create-concept',
  templateUrl: './create-concept.component.html',
  styleUrls: ['./create-concept.component.css']
})
export class CreateConceptComponent extends ParameterStateComponent {
  loaded = false;
  CTP = ComercialConceptType;
  public form: UntypedFormGroup;
  v = ViewPath;
  isEdit = false;
  conceptEdit: M_Concept | undefined;

  @ViewChild(MatCheckbox) checkbox?: MatCheckbox;
  @ViewChild(MatCheckbox) checkInv?: MatCheckbox;
  @ViewChild("conceptToggle") toggleC!: MatSlideToggle;
  @ViewChild("facturaToggle") toggleF!: MatSlideToggle;
  @ViewChild("HistoricToggle") toggleH!: MatSlideToggle;

  constructor(route: ActivatedRoute, routerS: RouterService, public responsiveS: ResponsiveService, public fs: FormService, public formBuilder: FormBuilder,
    public apiS: ApiService, @Optional() public dialogRef: MatDialogRef<CreateConceptComponent>, public dialog: MatDialog,public paramS:ParamsService) {
    super(routerS, route, ["concept"]);
    this.form = this.formBuilder.group({
      concept_id: [],
      name: ['', Validators.required],
      price: ['', Validators.min(0)],
      cost: [''],
      tax: [''],
      exempt_type: [''],
      in_invoice: [false],
      record_historic: [false],
      accounting_id: [''],
      concept_type: [1, Validators.required],
      registration_tax: [false],
      exento: ['']
    });

  }
  ngOnInit(): void {
    this.form.get('concept_type')?.valueChanges.subscribe(value => {
      this.adjustPriceValidation(value);
    });

    // Initialize validation based on the default value
    this.adjustPriceValidation(this.form.get('concept_type')?.value);
  }
  get isOnDialog() {
    return Object.keys(this.dialogRef).length != 0;
  }
  ensureCorrectValue() {
    const priceControl = this.form.get('price');
    const conceptType = this.form.get('concept_type')?.value;

    let value = priceControl?.value;
    if (!value) return;

    if (conceptType === this.CTP.DESCUENTO) {
      if (value > 0) {
        value = -value;
      }
    } else {
      if (value < 0) {
        value = -value;
      }
    }
    priceControl?.setValue(value, { emitEvent: false });
  }

  override onParam(_k: any, v: any) {
    this.apiS.getConceptById(v).then(res => {
      this.isEdit = true;
      if (res) {
        this.conceptEdit = res;
        this.fillDataWithProduct(res);
        this.loaded = true
      }
    })
    this.form.get('price')?.hasError('required')
  }

  override noParams(): void {
    if (this.isOnDialog) {
      this.loaded = true;
    }
    else {
      this.loaded = true;
    }
  }

  checkIfPositive(value: number): void {
    const priceControl = this.form.get('price');
    if (value < 0) {
      priceControl?.setErrors({ negative: true });
    } else {
      if (priceControl?.hasError('negative')) {
        delete priceControl.errors?.['negative'];
        if (Object.keys(priceControl.errors || {}).length === 0) {
          priceControl.setErrors(null);
        }
      }
    }
  }

  create() {
    const conceptType = this.form.get('concept_type')?.value;
    if ((this.form.get('tax')?.value == 0 && !this.toggleC.checked) && conceptType != this.CTP.DESCUENTO) {
      this.dialog.open(DialogConfirmPlateComponent, {
        data: {
          title: "Validación de los campos",
          message: "Hay un error en el campo IVA, seleccionar el porcentaje.",
          error: true
        }
      }).afterClosed().subscribe(res => {
        if (res) {
          this.form.patchValue({ tax: undefined });
          this.form.get('tax')?.setErrors({ found: true });
        }
      })
    } else {
      if (this.isEdit) {
        if (this.conceptEdit) {
          this.conceptEdit = this.fillDataEditConcept(this.conceptEdit);
          if (this.toggleC.checked) {
            this.conceptEdit.tax = 0;
          } else {
            this.conceptEdit.tax = this.form.get('tax')?.value;
          }
          if (this.form.get('tax')?.value != 0) {
            this.conceptEdit.exempt_type = undefined;
          }

          if (this.form.get('concept_type')?.value != this.CTP.DESCUENTO) {
            this.conceptEdit.percentage = 0;
          } else {
            this.conceptEdit.percentage = this.form.get('percentage')?.value;
          }
          this.apiS.createConcept(this.conceptEdit).then(res => {
            if (res instanceof M_Concept) {
              if (this.isOnDialog) {
                this.dialogRef.close(res);
              }
              else {
                this.routerS.goTo(this.v.concepts)
              }
            }
          });
        }
      }
      else {
        if (this.fs.isOk(this.form)) {
          let new_concept = new M_Concept(this.form.value)
          if (this.toggleC.checked) {
            new_concept.tax = 0;
          } else {
            new_concept.tax = this.form.get('tax')?.value;
          }
          if (this.form.get('tax')?.value != 0) {
            new_concept.exempt_type = undefined;
          }

          if (this.form.get('concept_type')?.value != this.CTP.DESCUENTO) {
            new_concept.percentage = 0;
          } else {
            new_concept.percentage = this.form.get('percentage')?.value;
          }
          this.apiS.createConcept(new_concept).then(res => {
            if (res instanceof M_Concept) {
              if (this.isOnDialog) {
                this.dialogRef.close(res);
              }
              else {
                this.routerS.goTo(this.v.concepts)
              }
            }
          });
        }
      }
    }
  }

  adjustPriceValidation(conceptType: ComercialConceptType): void {
    const priceControl = this.form.get('price');
    const costControl = this.form.get('cost');
    const taxControl = this.form.get('tax');
    priceControl?.clearValidators();
    costControl?.clearValidators();

    if (conceptType === this.CTP.NORMAL) {
      priceControl?.setValidators([Validators.required, Validators.min(0)]);
      taxControl?.setValidators([Validators.required]);
      taxControl?.updateValueAndValidity();
    } else if (conceptType === this.CTP.DESCUENTO) {
      taxControl?.removeValidators(Validators.required);
      taxControl?.updateValueAndValidity();
      priceControl?.setValidators([Validators.required]);
    } else if (conceptType === this.CTP.COMPRA) {
      priceControl?.clearValidators();
      // No validation needed

      costControl?.clearValidators();
    }
    costControl?.updateValueAndValidity();
    priceControl?.updateValueAndValidity();
  }

  adjustPriceValue(): void {
    const priceControl = this.form.get('price');
    const conceptType = this.form.get('concept_type')?.value;

    if (!priceControl) return;

    let value = priceControl.value;
    if (conceptType === this.CTP.DESCUENTO && value > 0) {
      value = -value;
    } else if (conceptType !== this.CTP.DESCUENTO && value < 0) {
      value = -value;
    }

    priceControl.setValue(value, { emitEvent: false });
  }

  isDiscount() {
    this.adjustPriceValue();
    this.adjustPriceValidation(this.CTP.DESCUENTO);
    this.form.patchValue({ in_invoice: false });
  }

  notDiscount(param: String) {
    if (param === "N") {
      this.adjustPriceValue();
      this.form.patchValue({ in_invoice: false });
      this.adjustPriceValidation(this.CTP.NORMAL);
    } else {
      this.form.patchValue({ in_invoice: true });
      this.adjustPriceValidation(this.CTP.COMPRA);
    }
  }

  fillDataWithProduct(c: M_Concept) {
    this.form.patchValue({
      concept_id: c.id,
      name: c.name,
      price: c.price,
      cost: c.cost,
      tax: c.tax,
      accounting_id: c.accounting_id,
      record_historic: c.record_historic,
      concept_type: c.concept_type,
      in_invoice: c.in_invoice,
      registration_tax: c.registration_tax
    });
    if (c.exempt_type != undefined) {
      this.form.patchValue({
        exento: true,
        exempt_type: c.exempt_type
      });
    }
  }
  
  fillDataEditConcept(c: M_Concept) {
    c = this.form.value;
    return c;
  }

  get isExento() {
    if (this.toggleC) {
      if (this.toggleC.checked) {
        this.form.get('exempt_type')?.addValidators(Validators.required);
        this.form.patchValue({ tax: 0 });
        this.form.get('tax')?.disable(); // Deshabilita el control 'tax' en el formulario
        return true;
      } else {
        this.form.get('exempt_type')?.removeValidators(Validators.required);
        this.form.get('exempt_type')?.updateValueAndValidity();
        this.form.get('tax')?.setErrors({ found: true });
        this.form.get('tax')?.enable(); // Habilita el control 'tax' en el formulario
        return false;
      }
    } else {
      return false;
    }
  }

}
