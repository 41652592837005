<!-- Optional close button-->
<app-close-dialog-button></app-close-dialog-button>

<!-- Mat dialog title-->
<div class="df aic">
    <p mat-dialog-title class="dialog-title"> 
        {{data ? 'Editar almacén' : 'Crear almacén'}}
        <span class="ml10" *ngIf="data && !data.default && data.id">
            <button mat-mini-fab [color]="'white'" [matMenuTriggerFor]="deleteMenu">
                <mat-icon>more_horiz</mat-icon>
            </button>
            <mat-menu #deleteMenu>
                <button mat-menu-item (click)="deleteStoreHouse(sotreHouseComponent)">
                    <mat-icon>delete</mat-icon>
                    Eliminar
                </button>
            </mat-menu>
        </span>
    </p>
</div>


<!-- Dialog content -->
<div mat-dialog-content>
    <app-sotre-house-form [sh]="data" #sotreHouseComponent></app-sotre-house-form>
</div>

<!-- Mat dialog actions -->
<div mat-dialog-actions>
    <button mat-button mat-dialog-close>Cancelar</button>
    <button color="primary" [disabled]="!sotreHouseComponent.form.valid" mat-flat-button
        (click)="createSotreHouse(sotreHouseComponent)">
        Guardar
    </button>
</div>