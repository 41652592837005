import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, HostListener, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { ApiService } from 'src/app/services/Api/api.service';
import { ParameterStateComponent } from 'src/app/components/parameter-state/parameter-state.component';
import { M_Appointment } from 'src/app/models/M_Appointment';
import { M_AppointmentConfig } from 'src/app/models/M_AppointmentConfig';
import { M_Company } from 'src/app/models/M_Company';
import { RouterService } from 'src/app/services/router.service';
import { defineClientSideColors } from 'src/app/utils/FunctionUtils';


/** 5 minutes */
const REFRESH_APPOINTMENTS_MINUTES = 5;
/** 10 seconds */
const REFRESH_SECONDS = 10;

@Component({
  selector: 'app-show-appointments-company',
  templateUrl: './show-appointments-company.component.html',
  styleUrls: ['./show-appointments-company.component.css']
})
export class ShowAppointmentsCompanyComponent extends ParameterStateComponent implements OnInit {
  today_appointments: M_Appointment[] = [];
  company?: M_Company;
  actual_hour = new Date();
  loaded = false;
  invalidToken = false;
  hiddentoolbar = false;
  showActions = true;
  conutMouseMove = 0
  elem;
  config: M_AppointmentConfig | undefined;
  token : string | undefined = undefined;

  @HostListener('mousemove', ['$event'])
  handleMousemove() {
    this.removeActionsDelay()
  }
  constructor(route: ActivatedRoute, private apiS: ApiService, @Inject(DOCUMENT) private document: any, private chdRef: ChangeDetectorRef, router: Router, routerS: RouterService) {
    super(routerS, route, ['center'])
    this.elem = document.documentElement;
    this.refreshCitas();
    this.refreshAfterMinutes();
    this.refreshHour();
    this.removeActionsDelay();

    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        document.getElementsByTagName("mat-toolbar")[0]?.classList.remove("dn")
      }
    });
  }
  ngOnInit(): void {
    document.body.classList.add("show-appointments-company");
  }
  removeActionsDelay() {
    this.showActions = true;
    this.conutMouseMove += 1;
    setTimeout(() => {
      this.conutMouseMove -= 1;
      if (this.conutMouseMove == 0) {
        this.showActions = false;
      }
    }, 1000);
  }
  override noParams(): void {
    this.loaded = true;
    this.invalidToken = true;
  }

  override onParam(param: string, value: string): void {
    if (param == "center") {
      const a = this.apiS.getCompanyInfoAppointmentClientSide(value);
      const b = this.apiS.showAppointmentSide(value, this.actual_hour.dataBaseFormat());
      forkJoin([a, b]).subscribe(res => {
        this.config = res[0];
        this.defineCompanyColors(this.config);
        this.today_appointments = this.removeUnecessaryAppointments(res[1]);
        this.loaded = true;
        this.token = value;
      })
    }
  }

  get isFullScreen() {
    return !window.screenTop && !window.screenY
  }

  defineCompanyColors(config: M_AppointmentConfig) {
    if (config) {
      defineClientSideColors(config);
    }
  }
  /** Refresh the current appointments */
  refreshCitas() {
    if (this.loaded && this.token) {
      this.apiS.showAppointmentSide(this.token, this.actual_hour.dataBaseFormat()).then(res => {
        this.today_appointments = this.removeUnecessaryAppointments(res);
        this.loaded = true;
      })
    }

  }
  /** Refresh the appointments style after 10 seconds
   *  Refresh the current time */
  refreshHour() {
    setTimeout(() => {
      this.actual_hour = new Date();
      this.refreshHour();
      this.chdRef.detectChanges();
      this.today_appointments = this.removeUnecessaryAppointments(this.today_appointments);
    }, 1000 * REFRESH_SECONDS);
  }
  /** Refresh the appointments (via endpoint) after 5 minutes */
  refreshAfterMinutes() {
    setTimeout(() => {
      this.refreshCitas();
      this.refreshAfterMinutes()
    }, 1000 * 60 * REFRESH_APPOINTMENTS_MINUTES);
  }
  removeUnecessaryAppointments(c: M_Appointment[]) {
    var remove = 0;
    var unecessary = 0;
    c.sort((a, b) => { return a.date_appointment > b.date_appointment ? 1 : -1 })
    c.forEach(c => {
      if (this.isAppontmentDone(c)) {
        unecessary++;
        if (unecessary > 2) {
          remove++;
        }
      }
    })

    for (let i = 0; i < remove; i++) {
      c.shift();
    }

    return c;
  }
  openFullscreen() {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
  }
  /* Close fullscreen */
  closeFullscreen() {
    if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
    }
  }
  isAppontmentDone(c: M_Appointment) {
    if (!this.config) { return false; }
    var maxTime = new Date(c.date_appointment.getTime() + this.config.granularity * 60000);
    return (this.actual_hour > maxTime);

  }
  toggleFullScreen() {
    this.isFullScreen ? this.openFullscreen() : this.closeFullscreen();
  }
  isAppointmentNow(c: M_Appointment) {
    if (!this.config) { return false; }
    var citaTime = c.date_appointment;
    var now = this.actual_hour;
    var maxTime = new Date(new Date(c.date_appointment.getTime() + this.config.granularity * 60000));
    return now >= citaTime && now <= maxTime;
  }
}
