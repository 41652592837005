import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { or_status, or_status_open } from '../../../custom-classes/or_states';
import { M_GroupTask } from 'src/app/models/M_GroupTask';

type generalStatus = { numOfTasks: number, status: or_status | undefined };

@Component({
  selector: 'app-group-status-type-text',
  templateUrl: './group-status-type-text.component.html',
  styleUrls: ['./group-status-type-text.component.css']
})

export class GroupStatusTypeTextComponent {

  @Input() groups!: M_GroupTask[];
  @Input() preferWidth: boolean = false;
  @Input() titleHint: boolean = true;
  generalStatus: generalStatus = { numOfTasks: -1, status: or_status_open }
  /** If  numOfTasks == -1, no group tasks on the OR*/

  constructor(private chdRef: ChangeDetectorRef) { }

  ngAfterViewInit() {
    this.generalStatus = this.getGeneralStatusText();
    this.preferWidth = false;
    this.chdRef.detectChanges();
  }

  getGeneralStatusText(): generalStatus {

    let lowestStatus: number | undefined = undefined;
    var issinicloud = false;

    for (let i = 0; i < this.groups.length; i++) {
      var state = this.groups[i].state;
      if (state) {
        if (lowestStatus == undefined) {
          lowestStatus = this.groups[i].state?.num;
        }
        else {
          if (state.num < lowestStatus) {
            lowestStatus = this.groups[i].state?.num;
          }
        }
      }
      else {
        issinicloud = true;
      }
    }

    var status = issinicloud ? undefined :  new or_status(lowestStatus ? lowestStatus : "Abierta");
    var num = this.groups.length == 0 ? -1 : this.groups.filter(g => g.state?.num == lowestStatus).length;

    return { numOfTasks: num, status: status }
  }
}
