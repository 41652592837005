import { Injectable } from '@angular/core';
import { M_Company } from '../../models/M_Company';
import { EinaDataService } from './eina-data.service';
import { ModulesEnum } from '../../enums/ModulesEnum';
import { ConfirmDialogService } from '../confirm-dialog.service';
import { IModuleService } from 'src/app/interfaces/IModuleService';
import { M_Center } from 'src/app/models/M_Center';
import { ApiService } from '../Api/api.service';
import * as confetti from 'canvas-confetti';
import { M_StoreHouse } from 'src/app/models/M_StoreHouse';

@Injectable({
  providedIn: 'root'
})
export class CompanyService implements IModuleService {
  constructor(private einaDataService: EinaDataService, private confirmD: ConfirmDialogService, private apiS: ApiService) { }

  /** Company object */
  get company(): M_Company { return this.einaDataService.company; };
  /** Update the company on the service. Emits 'onEinaDataChange' event */
  setNewCompany(d: M_Company) {
    this.einaDataService.setNewCompany(d);
  }
  addOrUpdateCenter(c: M_Center) { this.company.addOrUpdateCenter(c); }
  removeCenter(c: M_Center) { this.company.removeCenter(c); }
  removeStoreHouse(sh: M_StoreHouse) { this.company.removeStoreHouse(sh); }
  get companyFirstLogin(): boolean { return this.company.first_login; }
  get companyTax(): number { return this.company.iva; }
  get companyMissingInfo(): boolean { return this.company!.missing_info }
  /** The company has the 'Recambios' module */
  get recambiosModule(): boolean { return this.company!.recambios }
  /** The company has the 'VnVo' module */
  get vnvoModule(): boolean { return this.company!.vnvo }
  /** The company has the 'MTR' module */
  get mtr(): boolean { return this.company!.mtr }
  /** Is the company using  recambios module with the discount table ? */
  get discountTable(): boolean { return true; }
  showMissingCompanyDialog() {
    this.confirmD.showError("Todavía no se puede facturar", "Antes de poder facturar, debes rellenar toda la información sobre tu empresa");
  }


  /** Modules */
  get canTogglemodule() { return true; }
  hasModule(module: ModulesEnum) { return this.company.hasModule(module); }
  getModules(): ModulesEnum[] {
    if (!this.company) { return [] }
    return this.company.modules.map(m => m.id);
  }
  toggleModule(module: ModulesEnum, showConfetti : boolean = true) {
    this.apiS.toggleModule(module).then(res => {
      this.company.toggleModule(module);
      if (this.hasModule(module) && showConfetti) {
        var canvas: any = document.getElementById('my-canvas')!;
        canvas.confetti = canvas.confetti || confetti.create(canvas, { resize: true });
        canvas.confetti({
          spread: 70,
          origin: { y: 1.2 }
        });
      }
    })
  }


}
