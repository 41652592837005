import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { M_Signing } from 'src/app/models/Signing/M_Signing';
import { M_SigningTime } from 'src/app/models/Signing/M_SigningTime';
import { EditMyPresenceComponent } from 'src/app/views/my-presence/edit-my-presence/edit-my-presence.component';

@Component({
  selector: 'app-today-signing-breakdown',
  templateUrl: './today-signing-breakdown.component.html',
  styleUrls: ['./today-signing-breakdown.component.css']
})
export class TodaySigningBreakdownComponent {
  @Input({ required: true }) signingData: M_Signing | undefined;
  constructor(private d: MatDialog) { }
  openPresenceEdit(signing: M_SigningTime | undefined) {
    this.d.open(EditMyPresenceComponent, { data: signing, autoFocus: false }).afterClosed().subscribe(res => {
      console.log("Changing today signing time!")
      if (res instanceof M_SigningTime && this.signingData?.workingTimer){
        this.signingData.workingTimer = res;
        this.signingData.refresHistory(this.signingData.workingTimer);
      }
    })
  }
}
