import { Component, OnInit, ViewChild } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { filter } from 'src/app/custom-classes/MasterFilter';
import { M_ComercialBudget } from 'src/app/models/M_ComercialBudget';
import { SubscriptionService } from 'src/app/services/EinaMainData/subscription.service';
import { ParamsService } from 'src/app/services/params.service';
import { ButtonToggleFilter, Filter, FilterOption, SliderFilter, TagFilter } from 'src/app/custom-classes/Filter';
import { or_status } from 'src/app/custom-classes/or_states';
import { getAccentColor, getWarnColor } from 'src/app/utils/FunctionUtils';
import { PageStructureComponent } from 'src/app/components/page-structure/page-structure.component';


export enum ComercialBudgetPageFiltesrEnum {
  BUDGET_STATUS = 0,
  BUDGET_PRICE = 1,
  STOCK_ENTERED = 2
}

@Component({
  selector: 'app-comercial-budget',
  templateUrl: './comercial-budget.component.html',
  styleUrls: ['./comercial-budget.component.css']
})
export class ComercialBudgetComponent implements OnInit {

  v = ViewPath;
  f = filter;
  sf = new SliderFilter("Precio", 999).setId(ComercialBudgetPageFiltesrEnum.BUDGET_PRICE);
  filters: Filter[] = [
    new TagFilter("Estado", or_status, new FilterOption("Abierto"), new FilterOption("Cerrado")).setId(ComercialBudgetPageFiltesrEnum.BUDGET_STATUS),
    new ButtonToggleFilter("Stock", "Entrado a stock", true).setId(ComercialBudgetPageFiltesrEnum.STOCK_ENTERED),
    this.sf,
  ]

  accent = getAccentColor;
  warn = getWarnColor;

  @ViewChild(PageStructureComponent) ps!: PageStructureComponent<M_ComercialBudget>;

  constructor(public subS: SubscriptionService, private apiS: ApiService, public params: ParamsService) { }

  ngOnInit(): void {
    this.load();
  }

  get quickFilter() {
    let f = this.filters.find(f => f.id == ComercialBudgetPageFiltesrEnum.BUDGET_STATUS);
    if (f instanceof TagFilter) { return f };
    return undefined;
  }

  load() {
    this.apiS.vnvo.getComercialBudgets().then(resp => {
      if (this.ps) {
        this.sf.changeMax(Math.max(...resp.map(o => o.price || 0)))
        this.ps.initTable(resp);
      }
    });
  }

  brand(cb: M_ComercialBudget) { return cb.brand?.name }
  model(cb: M_ComercialBudget) { return cb.model?.name }
  preIcon(cb: M_ComercialBudget) { return cb.vehicle ? cb.vehicle.icon : undefined }

}
