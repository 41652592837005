<div class="advanced-details-container">
    <div class="title">
        <app-view-title>Abonar factura</app-view-title>
    </div>

    <!-- Vehicle on OR, general info on invoice-->
    <div class="abono">
        <app-card [contentLoaded]="invoice != undefined" class="bc_b1">
            <app-advanced-details *ngIf="invoice" [showButton]="false">

                <app-advanced-title>
                    {{invoice.company_scope_id}}
                </app-advanced-title>

                <app-advanced-subtitle>
                    {{invoice.created_at!.humanFormat()}}
                </app-advanced-subtitle>

                <app-advanced-heading>
                    <p money [val]="invoice.total"></p>
                    <p *ngIf="invoice.comment">{{invoice.comment}}</p>
                </app-advanced-heading>
            </app-advanced-details>

            <app-advanced-details-footer *ngIf="invoice" [showTitle]="false">
                <app-footer-right>
                   <app-contact-client-rectangle *ngIf="invoice.client" [client]="invoice.client"></app-contact-client-rectangle>
                </app-footer-right>
            </app-advanced-details-footer>

        </app-card>
    </div>

    <!-- Products / Products and time-->
    <div class="products">
        <app-card [contentLoaded]="invoice != undefined">

            <app-card-title>Productos / intervenciones</app-card-title>
            <p>Se abonarán todos los productos o intervenciones de la factura.</p>

            <!-- If Invoice-->
            <div *ngIf="invoice && invoice.breakdown">
                <app-product-line-table [products]="invoice.breakdown.all" [canModifyTable]="false"
                    [showLocation]="false" [showProductsStock]="false" [lineTypes]="[]">
                </app-product-line-table>
                <div class="df jcc">
                    <button mat-flat-button color="primary" (click)="abonar()">
                        Abonar
                    </button>
                </div>
            </div>

            <table class="breakdown no-pointer" *ngIf="invoice && invoice.getTotalBreakdown(false) as totals">
                <tr>
                    <td class="fw400 total">
                        Subtotal
                        <p class="fss fsi nmb" *ngIf="totals.client_excent_iva">Cliente exento de IVA</p>
                    </td>
                    <td><span class="c_p fw600" money [val]="totals.subtotal"></span></td>
                </tr>
                <tr *ngFor="let row of totals.iva_rows">
                    <td *ngIf="row.iva != 0" class="fw400 total">IVA ({{row.iva}}%)</td>
                    <td *ngIf="row.iva == 0" class="fw400 total">EXENTO</td>
                    <td><span class="c_p fw600" money [val]="row.iva == 0? row.subtotal : row.total"></span></td>
                </tr>
                <tr>
                    <td class="fsm fw500 total">Total</td>
                    <td><span class="c_p fw600" money [val]="totals.total"></span></td>
                </tr>
            </table>
        </app-card>
    </div>

</div>