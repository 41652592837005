<h1 mat-dialog-title>Modificar IVA de la factura</h1>
<div mat-dialog-content>
    <app-card [noStyle]="true">
        <div class="df">
            <mat-form-field class="w100" appearance="outline">
                <mat-label>IVA</mat-label>
                <input type="number" matInput placeholder="IVA" [formControl]="iva">
            </mat-form-field>
        </div>
    </app-card>
</div>
<div mat-dialog-actions>
    <button [mat-dialog-close]="false" mat-button>Cancelar</button>
    <button mat-flat-button (click)="change()" color="primary">Guardar</button>
</div>