import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[eForm-section]'
})
export class EinaFormSectionDirective {

  constructor(elementRef: ElementRef<HTMLElement>) {
    elementRef.nativeElement.classList.add('eina-form-section');
  }

}
