<div *ngIf="timelapse.length; else noData" class="timelapse-parent" [ngClass]="'activity-parent'">
    <div *ngFor="let step of timelapse; let last = last" class="timelapse" [ngClass]="{'hoverable' : !isCurrentItem(step)}" >
        <div class="df" [go]="!isCurrentItem(step) ? step.view : undefined" [param]="step.id">
            <div class="df fdc aic">
                 <mat-icon class="docu-icon" [filled]="isCurrentItem(step)">{{step.icon}}</mat-icon>
                <div class="circle-line" [ngClass]="{'last': last}"></div>
            </div>
            <div class="text-section">
                <p class="title">{{step.name}} <mat-icon class="ml5 c_t2 vam" *ngIf="!isCurrentItem(step)">chevron_right</mat-icon> </p>
                <p class="subtitle">
                    <span>{{step.date?.dayMonthYearFormat()}}</span>
                    <span class="more"></span>
                </p>
            </div>
        </div>
    </div>
</div>

<ng-template #noData>
    <p class="c_t2">Sin actividad</p>
</ng-template>