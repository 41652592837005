<div class="advanced-details-container" [ngClass]="{'recambios' : companyS.recambiosModule}"
    *ngIf="p != undefined || (p == undefined && !loaded); else notfound">


    <div class="back">
        <app-go-back class="back" [text]="'Listado productos'" [v]="v.products" [show]="loaded"></app-go-back>
    </div>


    <div class="product">
        <app-card [contentLoaded]="loaded" class="w100 bc_b1">

            <app-advanced-details *ngIf="p">

                <app-advanced-title>
                    {{p.name}}
                </app-advanced-title>

                <app-advanced-subtitle>
                    {{p.reference}}
                </app-advanced-subtitle>

                <app-advanced-main-actions>
                    <button mat-flat-button color="primary" [go]="v.createInvoice"
                        [param]="{'newproductid' : p.product_id, 'highlight_prod_id' : p.product_id}">
                        <mat-icon>receipt_long</mat-icon>
                        Facturar artículo
                    </button>
                    <app-main-manual-movement-button [p]="p"></app-main-manual-movement-button>
                </app-advanced-main-actions>

                <app-advanced-menu-actions>
                    <button mat-menu-item (click)="params.go(v.createProduct, p.product_id!)">
                        <mat-icon>edit</mat-icon>
                        Editar
                    </button>

                    <mat-divider></mat-divider>

                    <app-main-manual-movement-button [p]="p" [style]="'menu-option'"></app-main-manual-movement-button>

                    <!-- EINA-1598 -->
                    <!-- <button recambios mat-menu-item (click)="openManualReservation()">
                        <mat-icon smartIcon>reserve_active</mat-icon>
                        Reservar
                    </button> -->

                    <mat-divider></mat-divider>

                    <div [matTooltip]="!p.canBeDeleted ? 'El producto tiene faltas pendientes' : ''">
                        <button super-user mat-menu-item (click)="deleteS.delete(p!)" [disabled]="!p.canBeDeleted">
                            <mat-icon>delete</mat-icon>
                            Eliminar
                        </button>
                    </div>
                </app-advanced-menu-actions>
            </app-advanced-details>

            <app-advanced-details-footer>
                <!-- Title-->
                <app-footer-title>Ver más datos del producto</app-footer-title>
                <!-- Content on title right -->
                <app-footer-right class="df fww gap10">
                    <button mat-flat-button [color]="'ice'" (click)="stockDialog()">
                        <span *ngIf="p">
                            <span *ngIf="!companyS.recambiosModule">
                                Existencias
                                <span class="fw600" decimal [val]="p.stock < 0 ? 0 : p.stock"></span>
                            </span>
                            <span *ngIf="companyS.recambiosModule">
                                Existencias
                                <span class="fw600" decimal [val]="p.totalDisp < 0 ? 0 : p.totalDisp"></span>
                            </span>
                        </span>
                    </button>
                    <button recambios mat-flat-button [color]="'ice'" (click)="reservesDialog()">
                        Reservas <span class="fw600">{{p?.reservations?.length}}</span>
                    </button>
                    <button recambios mat-flat-button [color]="'ice'" (click)="faultDialog()">
                        Faltas <span class="fw600">{{p?.unfinishedFaults?.length}}</span>
                    </button>
                </app-footer-right>
                <!-- Footer content-->
                <app-footer-content>
                    <app-section>
                        <app-section-title>Información del producto</app-section-title>
                        <app-section-content>

                            <div *ngIf="p != undefined && companyS.recambiosModule">
                                <!-- Con Modulo -->
                                <app-card-row-content [freeAlign]="true" [noPaddingLeft]="true" [data]="
                                    [['Referencia', p.reference],
                                    ['Ref. fabricante', p.ref_factory],    
                                    ['PVP', p.price, {money : true}],
                                    ['PVP + IVA', p.price_iva, {money : true}],
                                    ['PVP (tarifa)', p.pvp_t, {money: true}],
                                    ['PVP (margen)', p.PVP_margin, {symbol: p.PVP_margin != 0 ? '%' : undefined}],
                                    ['Coste', p.buy_price,  {money : true}],
                                    ['Valor coste', p.buy_price_value <= 0 ? 0 : p.buy_price_value, {money: true}],
                                    ['PMP', (p.pmp), {money : true}],
                                    ['Valor PMP', p.pmp_value <= 0 ? 0 : p.pmp_value, {money : true}],
                                    ['IVA', p.tax == 0 ? 'Sin IVA' : p.tax, {symbol : p.tax != 0 ? '%' : undefined}],
                                    ['Categoría', p.getCategoryName]]">
                                </app-card-row-content>
                            </div>
                            <!-- Sin Modulo -->
                            <div *ngIf="p != undefined && !companyS.recambiosModule">
                                <app-card-row-content [freeAlign]="true" [noPaddingLeft]="true" [data]="
                                    [['Referencia', p.reference],
                                    ['PVP', p.price, {money : true}],
                                    ['PVP + IVA', p.price_iva, {money : true}],
                                    ['Coste', p.buy_price,  {money : true}],
                                    ['IVA', p.tax == 0 ? 'Sin IVA' : p.tax, {symbol : p.tax != 0 ? '%' : undefined}],
                                    ['Categoría', p.getCategoryName]]">
                                </app-card-row-content>
                            </div>
                        </app-section-content>
                    </app-section>
                </app-footer-content>
            </app-advanced-details-footer>
        </app-card>
    </div>

    <div class="providers" *ngIf="p != undefined && companyS.recambiosModule">
        <app-card [contentLoaded]="loaded" [cornerMatMenu]="add_provider">
            <app-card-title [first]="true">Proveedores</app-card-title>
            <div class="card-contents-150 card-wrapper">
                <app-product-providers-row [providers]="p.providers"></app-product-providers-row>
            </div>
            <ng-template #add_provider>
                <button mat-mini-fab color="primary" (click)="openAddProvider()">
                    <mat-icon>add</mat-icon>
                </button>
            </ng-template>
        </app-card>
    </div>

    <div class="orders" *ngIf="p != undefined && companyS.recambiosModule">
        <app-card [contentLoaded]="loaded" [cornerMatMenu]="add_order">
            <app-card-title [first]="true" class="df aic">
                Pedidos en trámite
            </app-card-title>
            <div class="card-contents-150 card-wrapper">
                <app-incoming-orders-provider [orders]="p.orders"
                    *ngIf="companyS.recambiosModule"></app-incoming-orders-provider>
            </div>
            <ng-template #add_order>
                <button mat-mini-fab color="primary" (click)="routerS.goTo(v.createOrder)">
                    <mat-icon>add</mat-icon>
                </button>
            </ng-template>
        </app-card>
    </div>

    <!-- With recambios module -->
    <div class="moves" *ngIf="p != undefined && companyS.recambiosModule">
        <app-card [contentLoaded]="loaded" [noData]="allStockMoves.length == 0">
            <div class="df jcsb">
                <div>
                    <app-card-title [first]="true">Últimos movimientos</app-card-title>
                </div>
                <div>
                    <p class="c_t2 mr20">{{p.name}} {{p.smartRef(companyS.recambiosModule)}}</p>
                </div>
            </div>
            <app-produproductmove-recambios-module *ngIf="loaded && allStockMoves.length != 0"
                [allStockMoves]="allStockMoves"></app-produproductmove-recambios-module>
        </app-card>
    </div>


    <!-- Without recambios module -->
    <div class="moves" *ngIf="p != undefined && !companyS.recambiosModule">
        <app-card [contentLoaded]="loaded" [noData]="allMoves.length == 0">
            <div class="df jcsb">
                <div>
                    <app-card-title [first]="true">Últimos movimientos</app-card-title>
                </div>
                <div>
                    <p class="c_t2 mr20">{{p.name}} {{p.smartRef(companyS.recambiosModule)}}</p>
                </div>
            </div>
            <app-productmove *ngIf="loaded && allMoves.length != 0" [allmoves]="allMoves"></app-productmove>
        </app-card>
    </div>
</div>

<ng-template #notfound>
    <app-model-not-found [title]="'No se ha enctontrado el producto'" [goText]="'Ir al listado de productos'"
        [view]="v.products">
    </app-model-not-found>
</ng-template>