type icisRow = {
    id: number,
    name: string,
    col_id: string,
    value: number
}

export class M_Icis {
    jefe_de_ventas: icisRow | undefined;
    vendedores_ventas: icisRow | undefined;
    aprendices_ventas: icisRow | undefined;
    administracion_ventas: icisRow | undefined;
    otros_ventas: icisRow | undefined;
    jefe_de_taller_mano_de_obra: icisRow | undefined;
    tecnicos_mano_de_obra: icisRow | undefined;
    aprendices_mano_de_obra: icisRow | undefined;
    administracion_mano_de_obra: icisRow | undefined;
    otros_mano_de_obra: icisRow | undefined;
    jefe_de_recambios_recambios_y_accesorios: icisRow | undefined;
    vendedor_de_recambios_recambios_y_accesorios: icisRow | undefined;
    responsable_de_tienda_recambios_y_accesorios: icisRow | undefined;
    aprendices_recambios_y_accesorios: icisRow | undefined;
    administracion_recambios_y_accesorios: icisRow | undefined;
    otros_recambios_y_accesorios: icisRow | undefined;
    gerente_responsable_de_instalacion_administracion_general: icisRow | undefined;
    aprendices_administracion_general: icisRow | undefined;
    administracion_administracion_general: icisRow | undefined;
    otros_administracion_general: icisRow | undefined;
    otros_departamentos: icisRow | undefined;
    horas_de_presencia_mano_de_obra_horas: icisRow | undefined;
    horas_productivas_trabajadas_mano_de_obra_horas: icisRow | undefined;
    stock_nuevas_0_30_dias: icisRow | undefined;
    stock_nuevas_0_30_importe: icisRow | undefined;
    stock_nuevas_31_60_dias: icisRow | undefined;
    stock_nuevas_31_60_importe: icisRow | undefined;
    stock_nuevas_61_90_dias: icisRow | undefined;
    stock_nuevas_61_90_importe: icisRow | undefined;
    stock_nuevas_91_180_dias: icisRow | undefined;
    stock_nuevas_91_180_importe: icisRow | undefined;
    stock_nuevas_181_364_dias: icisRow | undefined;
    stock_nuevas_181_364_importe: icisRow | undefined;
    stock_nuevas_365_dias: icisRow | undefined;
    stock_nuevas_365_importe: icisRow | undefined;
    stock_usadas_honda_0_30_dias: icisRow | undefined;
    stock_usadas_honda_0_30_importe: icisRow | undefined;
    stock_usadas_honda_31_60_dias: icisRow | undefined;
    stock_usadas_honda_31_60_importe: icisRow | undefined;
    stock_usadas_honda_61_90_dias: icisRow | undefined;
    stock_usadas_honda_61_90_importe: icisRow | undefined;
    stock_usadas_honda_91_180_dias: icisRow | undefined;
    stock_usadas_honda_91_180_importe: icisRow | undefined;
    stock_usadas_honda_181_364_dias: icisRow | undefined;
    stock_usadas_honda_181_364_importe: icisRow | undefined;
    stock_usadas_honda_365_dias: icisRow | undefined;
    stock_usadas_honda_365_importe: icisRow | undefined;
    stock_usadas_honda_dias_sin_desglose: icisRow | undefined;
    stock_usadas_honda_importe_sin_desglose: icisRow | undefined;
    stock_usadas_otras_marcas_0_30_dias: icisRow | undefined;
    stock_usadas_otras_marcas_0_30_importe: icisRow | undefined;
    stock_usadas_otras_marcas_31_60_dias: icisRow | undefined;
    stock_usadas_otras_marcas_31_60_importe: icisRow | undefined;
    stock_usadas_otras_marcas_61_90_dias: icisRow | undefined;
    stock_usadas_otras_marcas_61_90_importe: icisRow | undefined;
    stock_usadas_otras_marcas_91_180_dias: icisRow | undefined;
    stock_usadas_otras_marcas_91_180_importe: icisRow | undefined;
    stock_usadas_otras_marcas_181_354_dias: icisRow | undefined;
    stock_usadas_otras_marcas_181_354_importe: icisRow | undefined;
    stock_usadas_otras_marcas_365_dias: icisRow | undefined;
    stock_usadas_otras_marcas_365_importe: icisRow | undefined;
    stock_usadas_otras_marcas_dias_sin_desglose: icisRow | undefined;
    stock_usadas_otras_marcas_importe_sin_desglose: icisRow | undefined;
    demo_cortesia_dias: icisRow | undefined;
    demo_cortesia_importe: icisRow | undefined;
    ventas_directas_super_sport_unidades: icisRow | undefined;
    ventas_directas_sport_touring_unidades: icisRow | undefined;
    ventas_directas_touring_unidades: icisRow | undefined;
    ventas_directas_naked_unidades: icisRow | undefined;
    ventas_directas_custom_unidades: icisRow | undefined;
    ventas_directas_on_off_unidades: icisRow | undefined;
    ventas_directas_125cc_on_unidades: icisRow | undefined;
    ventas_directas_125_on_off_unidades: icisRow | undefined;
    ventas_directas_big_scooter_unidades: icisRow | undefined;
    ventas_directas_scooter_125cc_unidades: icisRow | undefined;
    ventas_directas_off_road_unidades: icisRow | undefined;
    ventas_directas_otros_unidades: icisRow | undefined;
    ventas_de_cesion_vn_unidades: icisRow | undefined;
    ventas_subagentes_super_sport_unidades: icisRow | undefined;
    ventas_subagentes_sport_touring_unidades: icisRow | undefined;
    ventas_subagentes_touring_unidades: icisRow | undefined;
    ventas_subagentes_naked_unidades: icisRow | undefined;
    ventas_subagentes_custom_unidades: icisRow | undefined;
    ventas_subagentes_on_off_unidades: icisRow | undefined;
    ventas_subagentes_125cc_on_unidades: icisRow | undefined;
    ventas_subagentes_125_on_off_unidades: icisRow | undefined;
    ventas_subagentes_big_scooter_unidades: icisRow | undefined;
    ventas_subagentes_scooter_125cc_unidades: icisRow | undefined;
    ventas_subagentes_off_road_unidades: icisRow | undefined;
    ventas_subagentes_otros_unidades: icisRow | undefined;
    ventas_motos_usadas_honda_unidades: icisRow | undefined;
    ventas_motos_usadas_otras_marcas_unidades: icisRow | undefined;
    ventas_motos_usadas_honda_option_unidades: icisRow | undefined;
    comisiones_financiaciones_motos_nuevas_casos: icisRow | undefined;
    comisiones_seguros_motos_nuevas_casos: icisRow | undefined;
    comisiones_financiaciones_motos_usadas_casos: icisRow | undefined;
    comisiones_seguros_motos_usadas_casos: icisRow | undefined;
    financiamiento_motos_nuevas_importe: icisRow | undefined;
    financiacion_motos_usadas_importe: icisRow | undefined;
    mano_de_obra_honda_clientes_num_or: icisRow | undefined;
    mano_de_obra_honda_clientes_h_vendidas: icisRow | undefined;
    mano_de_obra_honda_internas_num_or: icisRow | undefined;
    mano_de_obra_honda_internas_h_vendidas: icisRow | undefined;
    mano_de_obra_honda_garantias_num_or: icisRow | undefined;
    mano_de_obra_honda_garantias_h_vendidas: icisRow | undefined;
    mano_de_obra_honda_siniestro_num_or: icisRow | undefined;
    mano_de_obra_honda_siniestro_h_vendidas: icisRow | undefined;
    mano_de_obra_otras_marcas_clientes_num_or: icisRow | undefined;
    mano_de_obra_otras_marcas_clientes_h_vendidas: icisRow | undefined;
    mano_de_obra_otras_marcas_internas_num_or: icisRow | undefined;
    mano_de_obra_otras_marcas_internas_h_vendidas: icisRow | undefined;
    mano_de_obra_otras_marcas_garantias_num_or: icisRow | undefined;
    mano_de_obra_otras_marcas_garantias_h_vendidas: icisRow | undefined;
    mano_de_obra_otras_marcas_siniestro_num_or: icisRow | undefined;
    mano_de_obra_otras_marcas_siniestro_h_vendidas: icisRow | undefined;
    coste_mano_de_obra: icisRow | undefined;
    precio_venta_publico_clientes_mano_de_obra_honda: icisRow | undefined;
    precio_venta_publico_clientes_mano_de_obra_otras_marcas: icisRow | undefined;
    precio_venta_interna_mano_de_obra_honda: icisRow | undefined;
    precio_venta_interna_mano_de_obra_otras_marcas: icisRow | undefined;
    precio_venta_garantia_mano_de_obra_honda: icisRow | undefined;
    precio_venta_garantia_mano_de_obra_otras_marcas: icisRow | undefined;
    precio_venta_siniestro_mano_de_obra_honda: icisRow | undefined;
    precio_venta_siniestro_mano_de_obra_otras_marcas: icisRow | undefined;
    ventas_aceites_litros: icisRow | undefined;
    horas_de_presencia_mano_de_obra_horas_126: icisRow | undefined;
    horas_productivas_trabajadas_mano_de_obra_horas_127: icisRow | undefined;
    motos_de_intercambio_ventas_usadas_casos: icisRow | undefined;
    motos_de_intercambio_ventas_usadas_importe: icisRow | undefined;



    constructor(d: any) {
        this.jefe_de_ventas = d.jefe_de_ventas;
        this.vendedores_ventas = d.vendedores_ventas;
        this.aprendices_ventas = d.aprendices_ventas;
        this.administracion_ventas = d.administracion_ventas;
        this.otros_ventas = d.otros_ventas;
        this.jefe_de_taller_mano_de_obra = d.jefe_de_taller_mano_de_obra;
        this.tecnicos_mano_de_obra = d.tecnicos_mano_de_obra;
        this.aprendices_mano_de_obra = d.aprendices_mano_de_obra;
        this.administracion_mano_de_obra = d.administracion_mano_de_obra;
        this.otros_mano_de_obra = d.otros_mano_de_obra;
        this.jefe_de_recambios_recambios_y_accesorios = d.jefe_de_recambios_recambios_y_accesorios;
        this.vendedor_de_recambios_recambios_y_accesorios = d.vendedor_de_recambios_recambios_y_accesorios;
        this.responsable_de_tienda_recambios_y_accesorios = d.responsable_de_tienda_recambios_y_accesorios;
        this.aprendices_recambios_y_accesorios = d.aprendices_recambios_y_accesorios;
        this.administracion_recambios_y_accesorios = d.administracion_recambios_y_accesorios;
        this.otros_recambios_y_accesorios = d.otros_recambios_y_accesorios;
        this.gerente_responsable_de_instalacion_administracion_general = d.gerente_responsable_de_instalacion_administracion_general;
        this.aprendices_administracion_general = d.aprendices_administracion_general;
        this.administracion_administracion_general = d.administracion_administracion_general;
        this.otros_administracion_general = d.otros_administracion_general;
        this.otros_departamentos = d.otros_departamentos;
        this.horas_de_presencia_mano_de_obra_horas = d.horas_de_presencia_mano_de_obra_horas;
        this.horas_productivas_trabajadas_mano_de_obra_horas = d.horas_productivas_trabajadas_mano_de_obra_horas;
        this.stock_nuevas_0_30_dias = d.stock_nuevas_0_30_dias;
        this.stock_nuevas_0_30_importe = d.stock_nuevas_0_30_importe;
        this.stock_nuevas_31_60_dias = d.stock_nuevas_31_60_dias;
        this.stock_nuevas_31_60_importe = d.stock_nuevas_31_60_importe;
        this.stock_nuevas_61_90_dias = d.stock_nuevas_61_90_dias;
        this.stock_nuevas_61_90_importe = d.stock_nuevas_61_90_importe;
        this.stock_nuevas_91_180_dias = d.stock_nuevas_91_180_dias;
        this.stock_nuevas_91_180_importe = d.stock_nuevas_91_180_importe;
        this.stock_nuevas_181_364_dias = d.stock_nuevas_181_364_dias;
        this.stock_nuevas_181_364_importe = d.stock_nuevas_181_364_importe;
        this.stock_nuevas_365_dias = d.stock_nuevas_365_dias;
        this.stock_nuevas_365_importe = d.stock_nuevas_365_importe;
        this.stock_usadas_honda_0_30_dias = d.stock_usadas_honda_0_30_dias;
        this.stock_usadas_honda_0_30_importe = d.stock_usadas_honda_0_30_importe;
        this.stock_usadas_honda_31_60_dias = d.stock_usadas_honda_31_60_dias;
        this.stock_usadas_honda_31_60_importe = d.stock_usadas_honda_31_60_importe;
        this.stock_usadas_honda_61_90_dias = d.stock_usadas_honda_61_90_dias;
        this.stock_usadas_honda_61_90_importe = d.stock_usadas_honda_61_90_importe;
        this.stock_usadas_honda_91_180_dias = d.stock_usadas_honda_91_180_dias;
        this.stock_usadas_honda_91_180_importe = d.stock_usadas_honda_91_180_importe;
        this.stock_usadas_honda_181_364_dias = d.stock_usadas_honda_181_364_dias;
        this.stock_usadas_honda_181_364_importe = d.stock_usadas_honda_181_364_importe;
        this.stock_usadas_honda_365_dias = d.stock_usadas_honda_365_dias;
        this.stock_usadas_honda_365_importe = d.stock_usadas_honda_365_importe;
        this.stock_usadas_honda_dias_sin_desglose = d.stock_usadas_honda_dias_sin_desglose;
        this.stock_usadas_honda_importe_sin_desglose = d.stock_usadas_honda_importe_sin_desglose;
        this.stock_usadas_otras_marcas_0_30_dias = d.stock_usadas_otras_marcas_0_30_dias;
        this.stock_usadas_otras_marcas_0_30_importe = d.stock_usadas_otras_marcas_0_30_importe;
        this.stock_usadas_otras_marcas_31_60_dias = d.stock_usadas_otras_marcas_31_60_dias;
        this.stock_usadas_otras_marcas_31_60_importe = d.stock_usadas_otras_marcas_31_60_importe;
        this.stock_usadas_otras_marcas_61_90_dias = d.stock_usadas_otras_marcas_61_90_dias;
        this.stock_usadas_otras_marcas_61_90_importe = d.stock_usadas_otras_marcas_61_90_importe;
        this.stock_usadas_otras_marcas_91_180_dias = d.stock_usadas_otras_marcas_91_180_dias;
        this.stock_usadas_otras_marcas_91_180_importe = d.stock_usadas_otras_marcas_91_180_importe;
        this.stock_usadas_otras_marcas_181_354_dias = d.stock_usadas_otras_marcas_181_354_dias;
        this.stock_usadas_otras_marcas_181_354_importe = d.stock_usadas_otras_marcas_181_354_importe;
        this.stock_usadas_otras_marcas_365_dias = d.stock_usadas_otras_marcas_365_dias;
        this.stock_usadas_otras_marcas_365_importe = d.stock_usadas_otras_marcas_365_importe;
        this.stock_usadas_otras_marcas_dias_sin_desglose = d.stock_usadas_otras_marcas_dias_sin_desglose;
        this.stock_usadas_otras_marcas_importe_sin_desglose = d.stock_usadas_otras_marcas_importe_sin_desglose;
        this.demo_cortesia_dias = d.demo_cortesia_dias;
        this.demo_cortesia_importe = d.demo_cortesia_importe;
        this.ventas_directas_super_sport_unidades = d.ventas_directas_super_sport_unidades;
        this.ventas_directas_sport_touring_unidades = d.ventas_directas_sport_touring_unidades;
        this.ventas_directas_touring_unidades = d.ventas_directas_touring_unidades;
        this.ventas_directas_naked_unidades = d.ventas_directas_naked_unidades;
        this.ventas_directas_custom_unidades = d.ventas_directas_custom_unidades;
        this.ventas_directas_on_off_unidades = d.ventas_directas_on_off_unidades;
        this.ventas_directas_125cc_on_unidades = d.ventas_directas_125cc_on_unidades;
        this.ventas_directas_125_on_off_unidades = d.ventas_directas_125_on_off_unidades;
        this.ventas_directas_big_scooter_unidades = d.ventas_directas_big_scooter_unidades;
        this.ventas_directas_scooter_125cc_unidades = d.ventas_directas_scooter_125cc_unidades;
        this.ventas_directas_off_road_unidades = d.ventas_directas_off_road_unidades;
        this.ventas_directas_otros_unidades = d.ventas_directas_otros_unidades;
        this.ventas_de_cesion_vn_unidades = d.ventas_de_cesion_vn_unidades;
        this.ventas_subagentes_super_sport_unidades = d.ventas_subagentes_super_sport_unidades;
        this.ventas_subagentes_sport_touring_unidades = d.ventas_subagentes_sport_touring_unidades;
        this.ventas_subagentes_touring_unidades = d.ventas_subagentes_touring_unidades;
        this.ventas_subagentes_naked_unidades = d.ventas_subagentes_naked_unidades;
        this.ventas_subagentes_custom_unidades = d.ventas_subagentes_custom_unidades;
        this.ventas_subagentes_on_off_unidades = d.ventas_subagentes_on_off_unidades;
        this.ventas_subagentes_125cc_on_unidades = d.ventas_subagentes_125cc_on_unidades;
        this.ventas_subagentes_125_on_off_unidades = d.ventas_subagentes_125_on_off_unidades;
        this.ventas_subagentes_big_scooter_unidades = d.ventas_subagentes_big_scooter_unidades;
        this.ventas_subagentes_scooter_125cc_unidades = d.ventas_subagentes_scooter_125cc_unidades;
        this.ventas_subagentes_off_road_unidades = d.ventas_subagentes_off_road_unidades;
        this.ventas_subagentes_otros_unidades = d.ventas_subagentes_otros_unidades;
        this.ventas_motos_usadas_honda_unidades = d.ventas_motos_usadas_honda_unidades;
        this.ventas_motos_usadas_otras_marcas_unidades = d.ventas_motos_usadas_otras_marcas_unidades;
        this.ventas_motos_usadas_honda_option_unidades = d.ventas_motos_usadas_honda_option_unidades;
        this.comisiones_financiaciones_motos_nuevas_casos = d.comisiones_financiaciones_motos_nuevas_casos;
        this.comisiones_seguros_motos_nuevas_casos = d.comisiones_seguros_motos_nuevas_casos;
        this.comisiones_financiaciones_motos_usadas_casos = d.comisiones_financiaciones_motos_usadas_casos;
        this.comisiones_seguros_motos_usadas_casos = d.comisiones_seguros_motos_usadas_casos;
        this.financiamiento_motos_nuevas_importe = d.financiamiento_motos_nuevas_importe;
        this.financiacion_motos_usadas_importe = d.financiacion_motos_usadas_importe;
        this.mano_de_obra_honda_clientes_num_or = d.mano_de_obra_honda_clientes_num_or;
        this.mano_de_obra_honda_clientes_h_vendidas = d.mano_de_obra_honda_clientes_h_vendidas;
        this.mano_de_obra_honda_internas_num_or = d.mano_de_obra_honda_internas_num_or;
        this.mano_de_obra_honda_internas_h_vendidas = d.mano_de_obra_honda_internas_h_vendidas;
        this.mano_de_obra_honda_garantias_num_or = d.mano_de_obra_honda_garantias_num_or;
        this.mano_de_obra_honda_garantias_h_vendidas = d.mano_de_obra_honda_garantias_h_vendidas;
        this.mano_de_obra_honda_siniestro_num_or = d.mano_de_obra_honda_siniestro_num_or;
        this.mano_de_obra_honda_siniestro_h_vendidas = d.mano_de_obra_honda_siniestro_h_vendidas;
        this.mano_de_obra_otras_marcas_clientes_num_or = d.mano_de_obra_otras_marcas_clientes_num_or;
        this.mano_de_obra_otras_marcas_clientes_h_vendidas = d.mano_de_obra_otras_marcas_clientes_h_vendidas;
        this.mano_de_obra_otras_marcas_internas_num_or = d.mano_de_obra_otras_marcas_internas_num_or;
        this.mano_de_obra_otras_marcas_internas_h_vendidas = d.mano_de_obra_otras_marcas_internas_h_vendidas;
        this.mano_de_obra_otras_marcas_garantias_num_or = d.mano_de_obra_otras_marcas_garantias_num_or;
        this.mano_de_obra_otras_marcas_garantias_h_vendidas = d.mano_de_obra_otras_marcas_garantias_h_vendidas;
        this.mano_de_obra_otras_marcas_siniestro_num_or = d.mano_de_obra_otras_marcas_siniestro_num_or;
        this.mano_de_obra_otras_marcas_siniestro_h_vendidas = d.mano_de_obra_otras_marcas_siniestro_h_vendidas;
        this.coste_mano_de_obra = d.coste_mano_de_obra;
        this.precio_venta_publico_clientes_mano_de_obra_honda = d.precio_venta_publico_clientes_mano_de_obra_honda;
        this.precio_venta_publico_clientes_mano_de_obra_otras_marcas = d.precio_venta_publico_clientes_mano_de_obra_otras_marcas;
        this.precio_venta_interna_mano_de_obra_honda = d.precio_venta_interna_mano_de_obra_honda;
        this.precio_venta_interna_mano_de_obra_otras_marcas = d.precio_venta_interna_mano_de_obra_otras_marcas;
        this.precio_venta_garantia_mano_de_obra_honda = d.precio_venta_garantia_mano_de_obra_honda;
        this.precio_venta_garantia_mano_de_obra_otras_marcas = d.precio_venta_garantia_mano_de_obra_otras_marcas;
        this.precio_venta_siniestro_mano_de_obra_honda = d.precio_venta_siniestro_mano_de_obra_honda;
        this.precio_venta_siniestro_mano_de_obra_otras_marcas = d.precio_venta_siniestro_mano_de_obra_otras_marcas;
        this.ventas_aceites_litros = d.ventas_aceites_litros;
        this.horas_de_presencia_mano_de_obra_horas_126 = d.horas_de_presencia_mano_de_obra_horas_126;
        this.horas_productivas_trabajadas_mano_de_obra_horas_127 = d.horas_productivas_trabajadas_mano_de_obra_horas_127;
        this.motos_de_intercambio_ventas_usadas_casos = d.motos_de_intercambio_ventas_usadas_casos;
        this.motos_de_intercambio_ventas_usadas_importe = d.motos_de_intercambio_ventas_usadas_importe;
    }

    /** STOCK */
    get STOCK_total_stock() {
        return this.STOCK_stock_VN_honda + this.STOCK_stock_total_VO_honda + this.STOCK_stock_VO_otras;
    }

    get STOCK_stock_VN_honda() {
        return (this.stock_nuevas_0_30_dias?.value || 0) +
            (this.stock_nuevas_31_60_dias?.value || 0) +
            (this.stock_nuevas_61_90_dias?.value || 0) +
            (this.stock_nuevas_91_180_dias?.value || 0);
    }

    get STOCK_stock_total_VO_honda() {
        return (this.stock_usadas_honda_0_30_dias?.value || 0) +
            (this.stock_usadas_honda_31_60_dias?.value || 0) +
            (this.stock_usadas_honda_61_90_dias?.value || 0) +
            (this.stock_usadas_honda_91_180_dias?.value || 0);
    }

    get STOCK_stock_VO_otras() {
        return (this.stock_usadas_otras_marcas_0_30_dias?.value || 0) +
            (this.stock_usadas_otras_marcas_31_60_dias?.value || 0) +
            (this.stock_usadas_otras_marcas_61_90_dias?.value || 0) +
            (this.stock_usadas_otras_marcas_91_180_dias?.value || 0);
    }

    /** SELLS */
    get SELLS_total_sells() {
        return this.SELLS_total_direct_sells + this.SELLS_total_agents_sells;
    }

    get SELLS_total_direct_sells() {
        return (this.ventas_directas_super_sport_unidades?.value || 0) +
            (this.ventas_directas_sport_touring_unidades?.value || 0) +
            (this.ventas_directas_touring_unidades?.value || 0) +
            (this.ventas_directas_naked_unidades?.value || 0) +
            (this.ventas_directas_custom_unidades?.value || 0) +
            (this.ventas_directas_on_off_unidades?.value || 0) +
            (this.ventas_directas_125cc_on_unidades?.value || 0) +
            (this.ventas_directas_125_on_off_unidades?.value || 0) +
            (this.ventas_directas_big_scooter_unidades?.value || 0) +
            (this.ventas_directas_scooter_125cc_unidades?.value || 0) +
            (this.ventas_directas_off_road_unidades?.value || 0) +
            (this.ventas_directas_otros_unidades?.value || 0);
    }

    get SELLS_total_agents_sells() {
        return (this.ventas_directas_super_sport_unidades?.value || 0) +
            (this.ventas_directas_sport_touring_unidades?.value || 0) +
            (this.ventas_directas_touring_unidades?.value || 0) +
            (this.ventas_directas_naked_unidades?.value || 0) +
            (this.ventas_directas_custom_unidades?.value || 0) +
            (this.ventas_directas_on_off_unidades?.value || 0) +
            (this.ventas_directas_125cc_on_unidades?.value || 0) +
            (this.ventas_directas_125_on_off_unidades?.value || 0) +
            (this.ventas_directas_big_scooter_unidades?.value || 0) +
            (this.ventas_directas_scooter_125cc_unidades?.value || 0) +
            (this.ventas_directas_off_road_unidades?.value || 0) +
            (this.ventas_directas_otros_unidades?.value || 0);
    }

    /** ORs */
    ORS_total_ors(honda: boolean) {
        let honda_ = (this.mano_de_obra_honda_clientes_num_or?.value || 0) +
            (this.mano_de_obra_honda_internas_num_or?.value || 0) +
            (this.mano_de_obra_honda_garantias_num_or?.value || 0) +
            (this.mano_de_obra_honda_siniestro_num_or?.value || 0);

        let other = (this.mano_de_obra_otras_marcas_clientes_num_or?.value || 0) +
            (this.mano_de_obra_otras_marcas_internas_num_or?.value || 0) +
            (this.mano_de_obra_otras_marcas_garantias_num_or?.value || 0) +
            (this.mano_de_obra_otras_marcas_siniestro_num_or?.value || 0);

        return honda ? honda_ + other : other;
    }

    ORS_total_invoiced_hours(honda: boolean) {
        let honda_ = (this.mano_de_obra_honda_clientes_h_vendidas?.value || 0) +
            (this.mano_de_obra_honda_internas_h_vendidas?.value || 0) +
            (this.mano_de_obra_honda_garantias_h_vendidas?.value || 0) +
            (this.mano_de_obra_honda_siniestro_h_vendidas?.value || 0);

        let other = (this.mano_de_obra_otras_marcas_clientes_h_vendidas?.value || 0) +
            (this.mano_de_obra_otras_marcas_internas_h_vendidas?.value || 0) +
            (this.mano_de_obra_otras_marcas_garantias_h_vendidas?.value || 0) +
            (this.mano_de_obra_otras_marcas_siniestro_h_vendidas?.value || 0);
            
        return honda ? honda_ + other : other;
    }

    ORS_presence_hours(honda: boolean) {
        return this.horas_de_presencia_mano_de_obra_horas?.value || 0;
    }

    ORS_worked_hours(honda: boolean) {
        return this.horas_productivas_trabajadas_mano_de_obra_horas?.value || 0;
    }

    ORS_hours_profit(honda: boolean) {
        const laborCost = this.coste_mano_de_obra?.value || 0;
        const workedHours = this.ORS_worked_hours(honda) || 0;
        const invoicedHours = this.ORS_total_invoiced_hours(honda) || 0;
        if (workedHours == 0 || invoicedHours == 0) { return 0; }
        const costPerHour = laborCost / workedHours;
        const totalRevenue = invoicedHours * costPerHour;
        const profit = totalRevenue - laborCost;
        const percentageProfit = (profit / laborCost) * 100;
        return percentageProfit.castDecimals(2);
    }

    ORS_occupation(honda: boolean) {
        if (this.ORS_worked_hours(honda) == 0 || this.ORS_presence_hours(honda) == 0) { return 0; }
        return (this.ORS_worked_hours(honda) / this.ORS_presence_hours(honda)).castDecimals(2);
    }

    ORS_efficiency(honda: boolean) {
        if (this.ORS_total_invoiced_hours(honda) == 0 || this.ORS_worked_hours(honda) == 0) { return 0; }
        return (this.ORS_total_invoiced_hours(honda) / this.ORS_worked_hours(honda)).castDecimals(2);
    }

    ORS_productivity(honda: boolean) {
        if (this.ORS_total_invoiced_hours(honda) == 0 || this.ORS_presence_hours(honda) == 0) { return 0; }
        return (this.ORS_total_invoiced_hours(honda) / this.ORS_presence_hours(honda)).castDecimals(2);
    }
}
