<form [formGroup]="form" eForm class="normal-toggle">
    <!-- CONTACT TYPE SELECTOR -->
    <div class="jcc" eForm-section>
        <mat-button-toggle-group [ngClass]="{'dn' : edit}" class="oxa_i" [formControlName]="'type'"
            (change)="onContactTypeChange()">
            <mat-button-toggle [value]="contactEnum.PERSON" [disabled]="isD(contactEnum.PERSON)">
                <mat-icon>person</mat-icon>Persona
            </mat-button-toggle>
            <mat-button-toggle [value]="contactEnum.COMPANY" [disabled]="isD(contactEnum.COMPANY)">
                <mat-icon>business</mat-icon>Empresa
            </mat-button-toggle>
            <mat-button-toggle recambios [value]="contactEnum.PROVIDER" [disabled]="isD(contactEnum.PROVIDER)"
                [checked]="clientType ==  contactEnum.PROVIDER">
                <mat-icon>local_shipping</mat-icon>Proveedor
            </mat-button-toggle>
            <mat-button-toggle vnvo [value]="contactEnum.AGENT" [checked]="clientType == contactEnum.AGENT"
                [disabled]="isD(contactEnum.AGENT)">
                <mat-icon>work</mat-icon>Agente
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>

    <!-- ON EDIT, CANNO'T CHANGE THE CONTANCT TYPE-->
    <p *ngIf="edit" class="c_t2">Tipo de contacto :
        <span [ngSwitch]="form.get('type')?.value" class="c_p">
            <span *ngSwitchCase="contactEnum.PERSON"> <mat-icon class="vam mr5">person</mat-icon>Persona</span>
            <span *ngSwitchCase="contactEnum.COMPANY"> <mat-icon class="vam mr5">business</mat-icon>Empresa</span>
            <span *ngSwitchCase="contactEnum.PROVIDER"><mat-icon
                    class="vam mr5">local_shipping</mat-icon>Proveedor</span>
            <span *ngSwitchCase="contactEnum.AGENT"><mat-icon class="vam mr5">work</mat-icon>Agente</span>
        </span>
    </p>


    <app-card-subtitle>
        {{requiredFieldsTitle ? requiredFieldsTitle : 'Datos básicos'}}
    </app-card-subtitle>
    <div class="onboarding-client-required" eForm-wrapper>
        <div eForm-section>
            <mat-select-country class="w100" [label]="'Seleccionar país'" [placeHolder]="'Seleccionar país'"
                [required]="true" [formControlName]="'country'" (onCountrySelected)="onCountrySelected($event)">
            </mat-select-country>


            <mat-form-field class="w100" appearance="outline" color="primary">
                <mat-label>{{getIdentifierPlaceholder}}</mat-label>
                <app-async-validator-spinner matSuffix
                    [control]="form.get('nif')"></app-async-validator-spinner>
                <input matInput formControlName="nif" [disabled]="!isEditing">
                <button *ngIf="edit" mat-icon-button matPrefix type="button" (click)="enableEditing()">
                    <mat-icon>edit</mat-icon>
                </button>
                <mat-error *ngIf="form.get('nif')?.hasError('nif-exists')">
                    El identificador ya existe.
                </mat-error>
            </mat-form-field>
        </div>
        <div eForm-section>
            <mat-form-field [ngClass]="isCompany || isProvider ? 'w100' : 'w50'" appearance="outline">
                <mat-label>Nombre</mat-label>
                <input matInput placeholder="Nombre" formControlName="name">
            </mat-form-field>
            <mat-form-field [ngClass]="isCompany || isProvider ? 'dn_i' : 'w50'" appearance="outline">
                <mat-label>Apellidos</mat-label>
                <input matInput placeholder="Apellidos" formControlName="surname">
            </mat-form-field>
        </div>
    </div>

    <p></p>
    
    <mat-tab-group color="primary" mat-stretch-tabs="true" class="client-tabs">

        <!-- CONTACTO -->
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon>perm_contact_calendar</mat-icon>
                Ficha
            </ng-template>

            <app-card-subtitle>Ficha</app-card-subtitle>

            <div eForm-wrapper>
                <div eForm-section>
                    <app-email-input class="w100" [formCName]="'email'" [form]="form"
                        [required]="false"></app-email-input>
                </div>

                <div eForm-section>
                    <app-phone-input [formCName]="'phone'" [form]="form" [required]="false"></app-phone-input>
                    <app-phone-input [label]="'Teléfono 2'" [placeHolder]="'Teléfono 2'" [name] [formCName]="'phone2'"
                        [form]="form" [required]="false"></app-phone-input>

                    <mat-form-field *ngIf="isPerson" class="w33" appearance="outline">
                        <mat-label>Fecha de nacimiento</mat-label>
                        <!-- On change update picker input -->
                        <input #pickerInput matInput [matDatepicker]="picker" [max]="today" placeholder="dd/mm/aaaa"
                            formControlName="dob">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>

                <div eForm-section>
                    <mat-form-field appearance="outline" class="w100">
                        <mat-label>Observaciones</mat-label>
                        <textarea matInput formControlName="observations" cdkTextareaAutosize cdkAutosizeMinRows="2"
                            cdkAutosizeMaxRows="5" [placeholder]="'Observaciones'">
                                </textarea>
                    </mat-form-field>
                </div>
            </div>
        </mat-tab>

        <!-- DIRECCIÓN -->
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon>location_on</mat-icon>
                Dirección
            </ng-template>
            <div [ngClass]="{'dn':onlyRequired}">
                <app-card-subtitle>Dirección de facturación</app-card-subtitle>

                <div eForm-wrapper>
                    <div eForm-section>
                        <mat-form-field appearance="outline">
                            <mat-label>Dirección</mat-label>
                            <input [required]="someBillingAdressIsFilled" matInput
                                placeholder="Nombre y número de la calle" formControlName="address">
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Complemento dirección</mat-label>
                            <input matInput placeholder="Apartamento, suite, unidad, edificio o piso"
                                formControlName="address2">
                        </mat-form-field>
                    </div>
                    <div eForm-section>

                        <mat-form-field appearance="outline">
                            <mat-label>Código Postal</mat-label>
                            <input [required]="someBillingAdressIsFilled" matInput placeholder="Código Postal"
                                formControlName="zip">
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Población</mat-label>
                            <mat-select #selectedValue formControlName="city" (selectionChange)="stateChange($event)"
                                [disabled]="!this.form.get('zip')?.value?.length">
                                <!-- <mat-select-trigger>
                                            {{ selectedPayment ? selectedPayment.poblacio : form.get('city')?.value}}
                                          </mat-select-trigger> -->
                                <mat-option *ngFor="let zip of this.arrayZips" [value]="zip.id">
                                    <p class="nmb df aic">{{zip.poblacio}}
                                    </p>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Provincia</mat-label>
                            <input [required]="someBillingAdressIsFilled" matInput placeholder="Provincia"
                                formControlName="province">
                        </mat-form-field>
                    </div>
                </div>

                <!-- Extra adress -->
                <ng-container>
                    <app-card-subtitle>Otras direcciones</app-card-subtitle>
                    <form [formGroup]="getExtraAdress(i)"
                        *ngFor="let extraAddress of extraAddressSubForm.controls; let i = index">
                        <div eForm-wrapper>

                            <div class="mt10" eForm-section>
                                <mat-form-field appearance="outline">
                                    <mat-icon matPrefix>location_on</mat-icon>
                                    <mat-label>Nombre</mat-label>
                                    <input matInput placeholder="Nombre de la dirección" formControlName="name">
                                    <button matSuffix mat-icon-button (click)="removeExtraAdress(i)"
                                        matTooltip="Eliminar dirección">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </mat-form-field>
                            </div>

                            <div eForm-section>
                                <mat-select-country [label]="'Seleccionar país'" [placeHolder]="'Seleccionar país'"
                                    [required]="true" [formControlName]="'country'">
                                </mat-select-country>
                                <app-phone-input [form]="getExtraAdress(i)" [formCName]="'phone'"></app-phone-input>
                            </div>

                            <div eForm-section>
                                <mat-form-field appearance="outline">
                                    <mat-label>Dirección</mat-label>
                                    <input matInput placeholder="Nombre y número de la calle" formControlName="address">
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <mat-label>Complemento dirección</mat-label>
                                    <input matInput placeholder="Apartamento, suite, unidad, edificio o piso"
                                        formControlName="address2">
                                </mat-form-field>
                            </div>

                            <div eForm-section>
                                <mat-form-field appearance="outline">
                                    <mat-label>Código Postal</mat-label>
                                    <input matInput placeholder="Código Postal" formControlName="zip">
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <mat-label>Población</mat-label>
                                    <input matInput placeholder="Ciudad" formControlName="city">
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <mat-label>Provincia</mat-label>
                                    <input matInput placeholder="Provincia" formControlName="province">
                                </mat-form-field>
                            </div>
                        </div>
                    </form>
                    <button class="w100 mt10" mat-stroked-button color="primary" (click)="addExtraAdress()">
                        <mat-icon>add</mat-icon>
                        Añadir
                    </button>
                </ng-container>

            </div>
        </mat-tab>

        <!-- VENTAS. On client (person) and client (company) is always true-->
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon>{{isClient? 'sell' : 'highlight_off'}}</mat-icon>
                Ventas
            </ng-template>
            <div class="form">
                <app-card-title class="mt10">
                    <span>Ventas</span>
                    <mat-slide-toggle *ngIf="isProvider" [color]="'primary'" class="ml10" #clientToggle
                        formControlName="c_client"></mat-slide-toggle>
                    <button type="button" mat-icon-button class="absolute_info"
                        (click)="sellsInfoDialog()"><mat-icon>info</mat-icon></button>
                </app-card-title>
                <div eForm-wrapper class="sells-section" [ngClass]="isClient ? 'active' : 'no-active'">
                    <app-card-subtitle>Atributos</app-card-subtitle>
                    <div class="checkbox-section">
                        <mat-checkbox formControlName="tax_free"
                            [matTooltip]="responsiveS.w < 900 ? 'Por defecto en los clientes fuera de España' : ''">
                            <p>Exento IVA</p>
                            <span>Por defecto en los clientes fuera de España</span>
                        </mat-checkbox>
                        <mat-checkbox #toggle formControlName="email_always">
                            <p>Recibir facturas por correo</p>
                            <span>¡Recuerda introducir el correo en la ficha!</span>
                        </mat-checkbox>
                    </div>

                    <app-card-subtitle>Descuento cliente</app-card-subtitle>
                    <p class="c_t2">El descuento para el cliente se aplicará en las facturas de recambios,
                        {{companyS.recambiosModule? 'OR, presupuestos y albaranes' : 'OR y presupuestos'}}.</p>
                    <mat-form-field class="w33" appearance="outline">
                        <mat-label>Descuento</mat-label>
                        <input #input type="number" matInput placeholder="Porcentaje dto." formControlName="discount"
                            type="number" DecimalInput>
                        <mat-icon matSuffix>percent</mat-icon>
                        <mat-error *ngIf="form.get('discount')?.touched && form.get('discount')?.errors">Valor máx.
                            :
                            100</mat-error>
                    </mat-form-field>

                    <div recambios>
                        <app-card-subtitle>Tipo de cliente</app-card-subtitle>
                        <p class="c_t2">Se aplicará el descuento correspondiente de la <span class="c_a underline_hover"
                                (click)="goDiscountGroups()">tabla de descuentos</span>.
                        </p>
                        <mat-form-field class="w33" appearance="outline">
                            <mat-label>Tipo de cliente</mat-label>
                            <mat-select formControlName="discount_cat">
                                <mat-option [value]="cte.NOTYPE">--</mat-option>
                                <mat-option [value]="cte.TYPE1">Tipo 1</mat-option>
                                <mat-option [value]="cte.TYPE2">Tipo 2</mat-option>
                                <mat-option [value]="cte.TYPE3">Tipo 3</mat-option>
                                <mat-option [value]="cte.TYPE4">Tipo 4</mat-option>
                                <mat-option [value]="cte.TYPE5">Tipo 5</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <!-- E-Invoice -->
                    <app-card-subtitle>Factura electrónica
                        <mat-slide-toggle #eInvoice [disabled]="!isClient"
                            formControlName="cli_is_invoice_e"></mat-slide-toggle></app-card-subtitle>

                    <p *ngIf="!eInvoice.checked" class="c_t2 nmb">Permite descargar las facturas de este cliente en
                        formato XLM y añadir <span (click)="openAdminCenterInfo()" class="cp"
                            style="text-decoration: underline;">centros administrativos <mat-icon
                                class="c_t2 vam">info</mat-icon></span></p>

                    <p *ngIf="eInvoice.checked" class="c_a">Factura electrónica activada.</p>


                    <form>
                        <div [ngClass]="eInvoice.checked ? 'form' : 'dn'">
                            <div class="w33p" [ngClass]="{'mt20' : responsiveS.w <= 500}"
                                [formGroup]="getClientAdminCenter(i)"
                                *ngFor="let cadmincenter of adminCenterSubForm.controls; let i = index">
                                <mat-form-field appearance="outline" class="w33">
                                    <mat-label>Rol</mat-label>
                                    <input type="number" matInput placeholder="Rol" formControlName="post">
                                </mat-form-field>

                                <mat-form-field class="" appearance="outline" class="w33">
                                    <mat-label>Código</mat-label>
                                    <input matInput placeholder="Código" formControlName="code">
                                </mat-form-field>
                                <mat-form-field class="" appearance="outline" class="w33">
                                    <mat-label>Descripción</mat-label>
                                    <input matInput placeholder="Descripción" formControlName="name">
                                    <button matSuffix (click)="removeAdminCenter(getClientAdminCenter(i).value.id)"
                                        mat-icon-button matTooltip="Eliminar">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </mat-form-field>
                            </div>
                        </div>

                    </form>
                    <div *ngIf="adminCenterError" class="c_r fw600 df w100 jcc" style="font-size: large;">
                        Debe rellenar todos los campos de factura electrónica
                    </div>

                    <!-- Add admin center-->
                    <p class="c_t2" *ngIf="!adminCenterSubForm.controls.length && eInvoice.checked">
                        Todavía no has añadido ningún <span (click)="openAdminCenterInfo()" class="cp"
                            style="text-decoration: underline;">centro administrativo <mat-icon
                                class="c_t2 vam">info</mat-icon></span>
                        <button mat-button color="accent" (click)="addAdminCenter()">Añadir</button>
                    </p>

                    <div [ngClass]="eInvoice.checked ? 'df w100' : 'dn'" *ngIf="adminCenterSubForm.controls.length">
                        <button class="w100" [color]="'primary'" mat-stroked-button (click)="addAdminCenter()">
                            <mat-icon>add</mat-icon>
                            Centro administrativo
                        </button>
                    </div>
                </div>
            </div>
        </mat-tab>


        <!-- Bank and paymen method -->
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon>account_balance_wallet</mat-icon>
                Datos bancarios
            </ng-template>


            <app-card-subtitle [first]="true">Datos de pago</app-card-subtitle>
            <div eForm-section eForm-wrapper>
                <mat-form-field appearance="outline">
                    <mat-label>Número contable</mat-label>
                    <input matInput id="account_num" formControlName="account_num" type="text" />
                </mat-form-field>


                <mat-form-field appearance="outline">
                    <mat-label>Forma de pago</mat-label>
                    <mat-select formControlName="payment_method">
                        <mat-option [value]="CPM.CUSTOMER_WITH_RECEIPT">Cliente con recibo</mat-option>
                        <mat-option [value]="CPM.BLOCKED_CUSTOMER">Cliente bloqueado</mat-option>
                        <mat-option [value]="CPM.REPLACEMENT_WITHOUT_RECEIPT">Reposición sin recibo</mat-option>
                        <mat-option [value]="CPM.REPLACEMENT_WITH_RECEIPT">Reposición con recibo</mat-option>
                        <mat-option [value]="CPM.CASH_WITHOUT_RECEIPT">Contado sin recibo</mat-option>
                        <mat-option [value]="CPM.CASH_WITH_RECEIPT">Contado con recibo</mat-option>
                        <mat-option [value]="CPM.RECEIPT_ON_DEMAND">Recibo a la vista</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <app-card-subtitle>Plazos de pago</app-card-subtitle>
            <div eForm-section eForm-wrapper>
                <mat-form-field appearance="outline">
                    <mat-label>Plazo de pago 1</mat-label>
                    <input matInput id="payment_term_1" formControlName="payment_term_1" type="number" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Plazo de pago 2</mat-label>
                    <input matInput id="payment_term_2" formControlName="payment_term_2" type="number" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Plazo de pago 3</mat-label>
                    <input matInput id="payment_term_3" formControlName="payment_term_3" type="number" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Plazo de pago 4</mat-label>
                    <input matInput id="payment_term_4" formControlName="payment_term_4" type="number" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Plazo de pago 5</mat-label>
                    <input matInput id="payment_term_5" formControlName="payment_term_5" type="number" />
                </mat-form-field>
            </div>


            <app-card-subtitle>Días de pago</app-card-subtitle>
            <div eForm-section eForm-wrapper>
                <mat-form-field appearance="outline">
                    <mat-label>Día de pago 1</mat-label>
                    <input matInput id="payment_day_1" formControlName="payment_day_1" type="number" min="1" max="31" />
                    <mat-error
                        *ngIf="form.get('payment_day_1')?.hasError('min') || form.get('payment_day_1')?.hasError('max')">
                        El día debe estar entre 1 y 31
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Día de pago 2</mat-label>
                    <input matInput id="payment_day_2" formControlName="payment_day_2" type="number" min="1" max="31" />
                    <mat-error
                        *ngIf="form.get('payment_day_2')?.hasError('min') || form.get('payment_day_2')?.hasError('max')">
                        El día debe estar entre 1 y 31
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Día de pago 3</mat-label>
                    <input matInput id="payment_day_3" formControlName="payment_day_3" type="number" min="1" max="31" />
                    <mat-error
                        *ngIf="form.get('payment_day_3')?.hasError('min') || form.get('payment_day_3')?.hasError('max')">
                        El día debe estar entre 1 y 31
                    </mat-error>
                </mat-form-field>
            </div>

            <app-card-subtitle>Información bancaria</app-card-subtitle>
            <div eForm-section eForm-wrapper>
                <!-- <mat-form-field appearance="outline">
                    <mat-label>Prefijo IBAN</mat-label>
                    <input matInput id="iban_prefix" formControlName="iban_prefix" type="text" />
                    <mat-error *ngIf="form.get('iban_prefix')?.hasError('required')">Este campo es
                        obligatorio</mat-error>
                </mat-form-field> -->

                <mat-form-field appearance="outline">
                    <mat-label>Código BIC / SWIFT</mat-label>
                    <input matInput id="swift" formControlName="swift" type="text" />
                    <mat-error>Introduce un código de 8 o 11 caracteres.</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>IBAN</mat-label>
                    <input matInput id="iban" formControlName="iban" type="text" />
                    <mat-error>Mínimo 34 caracteres</mat-error>
                </mat-form-field>
            </div>

        </mat-tab>

    </mat-tab-group>
</form>