import { Component, Inject } from "@angular/core";
import { M_Action } from "src/app/models/M_Action";
import { M_Appointment } from "src/app/models/M_Appointment";
import { ResponsiveService } from "src/app/services/responsive.service";
import { DragAndDropGridComponent } from "../drag-and-drop-grid.component";
import { filterItem } from "src/app/utils/WorkloadUtils";

@Component({
    selector: 'app-month-grid',
    templateUrl: './month-grid.component.html',
    styleUrls: ['../drag-and-drop-grid.component.css']
})

export class MonthGridComponent {
    constructor(@Inject(DragAndDropGridComponent) public grid: DragAndDropGridComponent, public respoisiveS: ResponsiveService) { }

    /** Something is wrong with this */
    filterOrByWorker(actions: M_Action[] | M_Appointment[]): any {
        let filteredActions: any = [];
        for (let i = 0; i < actions.length; i++) {

            var filtersOk = filterItem(actions[i], ...this.grid.parent.currentFilters);

            if (filtersOk)
                filteredActions.push(actions[i]);
        }
        return filteredActions;
    }
}