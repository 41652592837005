import { BookMarkEnum, getBookMarkColorStyle } from "../enums/BookMarkEnum";
import { SotreHouseIcon } from "../enums/SotreHouseLord";
import { M_Location } from "./M_Location";
import { getArrayOf } from "../utils/FunctionUtils";

export class M_StoreHouse {
    id: number;
    company_id: number;
    name: string;
    deleted: boolean;
    default : boolean;
    tel: string | undefined;
    contact: string | undefined;
    locations: M_Location[] = [];
    bookmark: BookMarkEnum;
    lordicon: SotreHouseIcon;

    constructor(d: any) {
        this.id = d.id;
        this.company_id = d.company_id;
        this.name = d.name;
        this.deleted = d.deleted;
        this.tel = d.tel;
        this.default = d.default;
        this.contact = d.contact;
        this.bookmark = d.bookmark ? d.bookmark : BookMarkEnum.PRIMARY;
        this.lordicon = d.lordicon ? d.lordicon : SotreHouseIcon.DEFAULT;
        this.locations = getArrayOf(M_Location, d.locations);
    }

    get bookmarkColorStyle() {
        return getBookMarkColorStyle(this.bookmark);
    }
}