<div class="df fww w100 jce action-totals-container" *ngIf="showTotals" [ngClass]="{'responsive-totals' : responsive}">

    <span class="subtotal">
        Subtotal <span class="ml5 fw600" money [val]="totalsBreakdown.subtotal"></span>
        <span class="c_t2 ml5 mr5 fw600">•</span>
    </span>

    <span class="iva">
        IVA <span class=" ml5 fw600" money [val]="totalsBreakdown.totalIva"></span>
        <span class="c_t2 ml5 mr5 fw600">•</span>
    </span>

    Total
    <span>
        <span class="ml5 fw600" money [val]="totalsBreakdown.total"></span>
    </span>

</div>