<script src="https://cdn.lordicon.com/qjzruarw.js"></script>

<div class="upload-cards-parent">
    <app-data-upload-item #ui1 [expectetFields]="['Referencia', 'Nombre', 'Cantidad', 'Coste' , 'Precio unidad']"
        [formatNumber]="[false,false,true,true,true]" [title]="'Entrada de almacén'"
        [class_]="'onboarading-product-import'" [importclass_]="'onboarading-product-import-link'"
        [layoutURL]="'../../../../assets/layouts/Plantilla_Entrada_Almacen.xlsx'" [endpoint]="endpoint.importStorage">
    </app-data-upload-item>

    <app-data-upload-item #ui2 class="clients-upload"
        [expectetFields]="['DNI*','Nombre*','Apellidos*', 'Empresa (S/N)', 'Email', 'Recibir facturas (S/N)', 'Dirección', 'Complemento de dirección', 'Código postal', 'Población', 'Provincia', 'Teléfono', 'Teléfono 2', 'Fecha nacimiento (dd/mm/aaaa)', 'Observaciones', 'Marketing (S/N)', 'Perfil (S/N)', 'Newslatter (S/N)',              
                           'Tipo vehículo* (coche, moto)', 'Matrícula*', 'Chassis', 'Marca', 'Modelo', 'Serie (click para ver todos los tipos)', 'Color', 'Cilindrada', 'Tipo de motor', 'Nº serie bataría', 'Nº serie batería extra', 'Kilómetros', 'Fecha matriculación (dd/mm/aaaa)', 'Fecha última ITV (dd/mm/aaaa)', 'Fecha próxima ITV (dd/mm/aaaa)']"
        [title]="'Importación de contactos'" [class_]="'onboarading-client-import'"
        [importclass_]="'onboarading-client-import-link'"
        [layoutURL]="'../../../../assets/layouts/Plantilla_Importacion_Clientes.xlsx'"
        [endpoint]="endpoint.importClients">
    </app-data-upload-item>

    <app-data-upload-item #ui3 *ngIf="feature.importTariff" [expectetFields]="['Referencia', 'Nombre', 'Precio']"
        [title]="'Importación de tarifas'" [layoutURL]="'../../../../assets/layouts/Plantilla_Importacion_Tarifas.xlsx'"
        [endpoint]="endpoint.importTarifas" [expectetFields]="['new','updated']">
        <lord-icon class="lord-large" [ngClass]=" ui3.data != undefined ? 'lord-uploaded' : ''"
            src="https://cdn.lordicon.com/ihmcpmzz.json" trigger="hover">
        </lord-icon>
    </app-data-upload-item>
</div>