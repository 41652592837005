import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { DayFilter } from '../../../custom-classes/Filter';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.css']
})
export class DateFilterComponent implements OnInit {
  @ViewChild("d1") picker_1!: MatDatepicker<any>;
  @ViewChild("d2") picker_2!: MatDatepicker<any>;
  @Input() from?: Date;
  @Input() to?: Date;
  minD2: Date | null = null;
  form: UntypedFormGroup;
  @Output() onFilterChanges : EventEmitter<number> = new EventEmitter<number>(); 

  constructor(@Inject(DayFilterComponent) private parent: DayFilterComponent, private fb: UntypedFormBuilder) {
    this.form = this.fb.group({
      c1: [''],
      c2: ['', { disabled: true }],
    });

    this.c1.valueChanges.subscribe(v => {
      this.onFilterChanges.emit(-1) //improve this
    })

    this.c2.valueChanges.subscribe(v => {
      this.onFilterChanges.emit(-1) //improve this
    })
  }

  ngOnInit(): void {
    if (this.from && this.to) {
      this.form.patchValue({
        c1: this.from?.datePickerFormat(),
        c2: this.to?.datePickerFormat(),
      }, { emitEvent: false })
      if (this.to != undefined) {
        this.c2.enable();
      }
      if (this.from) {
        this.minD2 = new Date(this.c1.value).plusDays(1);
      }
    }
  }

  ngAfterContentInit() {
    this.c1.valueChanges.subscribe(v => {
      this.onChangeDate1(v);
    })
    this.c2!.valueChanges.subscribe(v => {
      this.onChangeDate2(v);
    })
  }

  onChangeDate1(v: any) {
    this.parent.filter.setFrom(v);
    if (v == null) {
      this.c2.setValue(null);
      this.c2.disable();
    }
    else {
      this.minD2 = new Date(this.c1.value).plusDays(1);
      if (this.c2.value) {
        var date2DateValue = new Date(this.c2.value);
        if (this.minD2 > date2DateValue) {
          this.c2.setValue(this.minD2);
        }
      }
      else if (this.minD2 > this.c2.value) {
        this.c2.setValue(this.minD2);
      }
      this.c2.enable();
    }
  }

  getDates(): [Date, Date] | undefined {
    if (!this.c1.value && !this.c2.value) {
      return undefined
    }
    return [new Date(this.c1.value), new Date(this.c2.value)];
  }

  onChangeDate2(v: any) {
    this.parent.filter.setTo(v);
  }

  get isSelected() {
    return true;
  }

  get c1() {
    return this.form.get("c1")!;
  }

  get c2() {
    return this.form.get("c2")!;
  }

}


@Component({
  selector: 'app-day-filter',
  templateUrl: './day-filter.component.html',
  styleUrls: ['./day-filter.component.css']
})
export class DayFilterComponent implements OnInit {
  @Input() filter! : DayFilter;
  @Input() label: boolean = true;
  @ViewChild(MatSelect) ms! : MatSelect;
  @ViewChild(DateFilterComponent) dfc? : DateFilterComponent;
  @Output() onFilterChanges : EventEmitter<number> = new EventEmitter<number>(); 
  constructor() {}
  
  ngOnInit(): void {}

  /** MatSelect value
   * By default return 0
   */
  get value() {
    if (this.ms) {
      return this.ms? this.ms.value : 0;
    }
  }
  emitChange(selected : MatSelectChange) {
    this.filter.selectedOption = selected.value;
    this.onFilterChanges.emit(selected.value);
  }

  /** By Default, the class DayFilter contgains the DateRange Filter at the end of the list
   * We show the component of data ranges if this component is DayFilter type and the selected option is the last (Date range)
   */
  showDateRange() {
    return this.filter instanceof DayFilter && this.value == this.filter.optionsLength -1;
  }
}
