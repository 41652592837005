// Constantes con valores directos en lugar de funciones

import { EnumSubscription } from "src/app/enums/EnumSubscription";
import { M_Subscription } from "src/app/models/M_Subscription";

export function TRIAL(remaining_days: number): M_Subscription {
    let s = {
        type: EnumSubscription.TRIAL,
        active: false,
        will_cancel: false,
        users: 0,
        remaining_days: remaining_days,
        amount: 0,
        is_demo: true,
        next_payment: null,
        typedetails: {
            first_user: 30,
        },
    }
    return s;
}

export const TRIAL_END: M_Subscription = {
    type: EnumSubscription.BASIC,
    active: false,
    will_cancel: false,
    users: 0,
    remaining_days: 0,
    amount: 0,
    is_demo: true,
    next_payment: null,
    typedetails: {
        first_user: 30,
    },
};

export const PRO: M_Subscription = {
    type: EnumSubscription.PRO,
    active: true,
    will_cancel: false,
    users: 3,
    remaining_days: 0,
    amount: 100,
    is_demo: false,
    next_payment: new Date().plusDays(2),
    first_payment : new Date(),
    typedetails: {
        first_user: 30,
    },
};

export const PRO_CANCELED: M_Subscription = {
    type: EnumSubscription.PRO,
    active: false,
    will_cancel: true,
    users: 3,
    remaining_days: 2,
    amount: 0,
    is_demo: false,
    next_payment: null,
    first_payment : new Date(),
    typedetails: {
        first_user: 30,
    },
};

export const PRO_ENDED: M_Subscription = {
    type: EnumSubscription.BASIC,
    active: false,
    will_cancel: true,
    users: 3,
    remaining_days: 2,
    amount: 0,
    is_demo: false,
    next_payment: null,
    first_payment : new Date().minusDays(20),
    typedetails: {
        first_user: 30,
    },
};
