<div [formGroup]="form">
    <div eForm-section>
        <mat-form-field appearance="outline">
            <mat-label>Dirección</mat-label>
            <input matInput placeholder="Nombre y número de la calle" formControlName="address">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Complemento dirección</mat-label>
            <input matInput placeholder="Apartamento, suite, unidad, edificio o piso" formControlName="address2">
        </mat-form-field>
    </div>
    <div eForm-section>
        <mat-form-field appearance="outline">
            <mat-label>Código Postal</mat-label>
            <input #zipInput type="tel" pattern="[0-9]*" matInput placeholder="Código Postal" formControlName="zip">
            <div matPrefix *ngIf="loadingZip">
                <mat-spinner color="primary" style="margin-right: 5px;" [strokeWidth]="6" [diameter]="25"
                    mode="indeterminate"></mat-spinner>
            </div>
            <mat-error *ngIf="form.get('zip')?.hasError('notfound')">Código postal no
                encontrado</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Comunidad autónoma</mat-label>
            <input type="text" [value]="" matInput placeholder="Comunidad autónoma" formControlName="ccaaObject">
            <mat-hint>Se deduce del código postal</mat-hint>
        </mat-form-field>

    </div>
    <div eForm-section>
        <mat-form-field appearance="outline">
            <mat-label>Población</mat-label>
            <mat-select #selectedValue formControlName="city" (selectionChange)="stateChange($event)"
                [disabled]="!this.form.get('zip')?.value?.length">
                <mat-option *ngFor="let zip of this.arrayZips" [value]="zip.id">
                    <p class="nmb df aic">{{zip.poblacio}}
                    </p>
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Provincia</mat-label>
            <input type="text" matInput placeholder="Provincia" formControlName="province">
            <mat-hint>Se deduce del código postal</mat-hint>
        </mat-form-field>
    </div>
</div>