<!-- Tabla -->
<div class="tableSection">
  <div class="table">

    <app-go-back *ngIf="goBackCustom" [v]="goBackCustom.view" [text]="goBackCustom.text || 'Atrás'"></app-go-back>
    <div class="headerSection df jcsb" *ngIf="pageTitle || createButton">
      <app-view-title class="mb10">{{pageTitle}}</app-view-title>
      <div class="df gap10">
        <app-page-structure-add-new *ngIf="createButton"></app-page-structure-add-new>
        <button mat-mini-fab color="primary" *ngIf="rightMenu || exportExcel" [matMenuTriggerFor]="rightMenuTag">
          <mat-icon>more_horiz</mat-icon>
        </button>
      </div>
    </div>

    <mat-menu #rightMenuTag>
      <div *ngIf="rightMenu" [ngTemplateOutlet]="rightMenu"></div>
      <app-generic-export *ngIf="exportExcel" #gExport [nameSingular]="exportExcel.singular" [namePlural]="exportExcel.plural"
        [endpoint]="exportExcel.endpoint"></app-generic-export>
    </mat-menu>


    <app-card [noStyle]="!cardStyle">
      <div class="df aic jcsb mb10">
        <app-card-title class="nmb" [first]="true">{{listTitleText}}</app-card-title>
        <div *ngIf="filters" class="df">
          <app-tagfilter class="quickFilter" *ngIf="quickFilter" [filter]="quickFilter" [quickFilter]="true"
            (change)="filtersComponent.applyFilters()"></app-tagfilter>
          <app-filters #filtersComponent (onfiltersDone)="onFiltersDone.emit($event);" [ps]="this"
            [filters]="filters"></app-filters>
        </div>
      </div>

      <mat-form-field appearance="outline"
        [class]="'white page-search ' + (searchOnboardingClass ? searchOnboardingClass :'')">
        <mat-label>{{searchLabel}}</mat-label>
        <input #searchInput matInput placeholder={{searchLabel}} (keyup)="applySearchFilter(searchInput.value)"
          [matAutocomplete]="auto.autocomplete" [formControl]="auto.stateCtrl">
        <mat-icon matSuffix class="cp" (click)="applySearchFilter(searchInput.value)">search</mat-icon>
        <app-autocomplete #auto [autocompleteID]="autocompleteID" (click)="applySearchFilter(searchInput.value)">
        </app-autocomplete>
      </mat-form-field>

      <div *ngIf="customPageContent" [ngTemplateOutlet]="customPageContent"></div>

      <table *ngIf="customPageContent == undefined" mat-table [dataSource]="dataSource" matSort multiTemplateDataRows
        (matSortChange)="onSortChanged($event)" [ngClass]="{'no-pointer' : false}">
        <ng-container *ngFor="let dc of displayedColumns; let i = index" [matColumnDef]="dc">
          <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="dc == dotsColumnName"
            [style]="getSyleBySize(i,dc)"> {{displayedHeader[i]}}</th>
          <td mat-cell *matCellDef="let row; let cellIndex = index">
            <div [class]="getClass(row, dc, i)" *ngIf="haveRowData(row, dc, i); else nodata">

              <mat-icon smartIcon [class]="'vam mr10 ' + (preIconClass ? preIconClass : '')"
                *ngIf="getPreIcon(row, dc, i) as icon">
                {{icon}}
              </mat-icon>

              <!--Specific case of users view-->
              <div *ngIf="circularLetter && dc == 'name'; else orlabel" class="df aic">
                <app-circular-letter style="margin-right: 5px;" [showFullName]="false" [showTooltip]="false"
                  [user]="row">
                </app-circular-letter>
                {{getRow(row, dc, i)}}
              </div>

              <ng-template #orlabel>
                <div *ngIf="dc == 'type' || dc == 'type_or'; else status">
                  <app-or-type-label [wrapOn]="2" [showText]="false" [tipo]="getRow(row, dc, i)">
                  </app-or-type-label>
                </div>
              </ng-template>

              <ng-template #status>
                <div *ngIf="dc == 'status'; else invoice_status">

                  <ng-container *ngIf="masterClass && masterClass == 'albaran'; else or_status_tag">
                    <app-albaran-status-label [status]="getRow(row, dc, i)"></app-albaran-status-label>
                  </ng-container>

                  <ng-template #or_status_tag>
                    <app-or-status-label [showText]="false" [action]="getRow(row, dc, i)"></app-or-status-label>
                    <div class="df fww or-groups-status-page-structure" *ngIf="showGroupsCircles(row)">
                      <app-or-status-label *ngFor="let g of row.groups" [circularStatus]="true"
                        [status]="g.state"></app-or-status-label>
                    </div>
                  </ng-template>

                </div>
              </ng-template>

              <ng-template #invoice_status>
                <div *ngIf="dc == 'state'; else invoice_type_or">
                  <app-invoice-status *ngIf="isInvoice(row, dc)" [showText]="false" [status]="getRow(row, dc, i)"
                    [obj]="getInvoiceTagLabel(row)"></app-invoice-status>
                  <lib-appointment-status-label *ngIf="isAppointment(row)"
                    [status]="getRow(row, dc, i)"></lib-appointment-status-label>
                </div>
              </ng-template>

              <ng-template #invoice_type_or>
                <div *ngIf="dc == 'invoice_type_or_type'; else orderStatus" class="df aic">
                  <span style="margin-right: 5px;" *ngIf="getRow(row, dc, i)[0]?.name">{{getRow(row, dc,
                    i)[0].name}}</span>
                  <app-or-type-label *ngIf="getRow(row, dc, i)[1]" [wrapOn]="1" [showText]="false"
                    [tipo]="[getRow(row, dc, i)[1]]"></app-or-type-label>
                </div>
              </ng-template>

              <ng-template #orderStatus>
                <div *ngIf="dc == 'order_status'; else checkDirective">
                  <app-order-status-label [status]="getRow(row, dc, i)"></app-order-status-label>
                </div>
              </ng-template>

              <ng-template #checkDirective>
                <span *ngIf="needDirective(i) && isMoney(i); else decimal" money [val]="getRow(row, dc, i)">
                  {{getRow(row, dc, i)}}
                </span>
              </ng-template>

              <ng-template #decimal>
                <span *ngIf="needDirective(i) && isDecimal(i); else vnvo" decimal [val]="getRow(row, dc, i)">
                  {{getRow(row, dc, i)}}
                </span>
              </ng-template>

              <ng-template #vnvo>
                <app-vn-vo-label *ngIf="dc == 'comercialType'; else dotsTemplate"
                  [type]="getRow(row, dc, i)"></app-vn-vo-label>
              </ng-template>

              <!-- Final dots -->
              <ng-template #dotsTemplate>
                <div *ngIf="dots && dc == dotsColumnName; else data">
                  <button mat-icon-button [matMenuTriggerFor]="dotsMenu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #dotsMenu="matMenu">
                    <ng-template [ngTemplateOutlet]="dots"></ng-template>
                  </mat-menu>
                </div>

              </ng-template>

              <!-- Row data -->
              <ng-template #data>
                {{getRow(row, dc, i)}}
              </ng-template>

            </div>
            <ng-template #nodata>
              <div class="c_t2">Sin datos</div>
            </ng-template>
          </td>
        </ng-container>

        <!--Encabezados de la tabla. Se oculta en caso de no tener datos-->
        <tr [ngClass]="{'dn' : loadedNoData()}" mat-header-row *matHeaderRowDef="displayedColumns"></tr>

        <!--Columnas a mostrar-->
        <tr #tr mat-row (click)="select(row)" [class.cell-selected]="item == row" (mouseenter)="onMouseEnter(row)"
          *matRowDef="let row; columns: displayedColumns; let rowIndex = index"
          [ngClass]="stateParamId && row.itemId && stateParamId == row.itemId? 'ps-highlight-item no-blue':'no-blue'">
        </tr>

        <!--En caso de no tener datos o si la pantalla esta cargando-->
        <tr class="mat-row" *matNoDataRow>

          <!-- Si tenemos objectos y la pantalla a cargado-->
          <td *ngIf="loaded_ == true && data.length != 0; else noObjects" class="mat-cell"
            [attr.colspan]="displayedColumns.length">

            <!-- If something is searched and no result-->
            <p class="nmb" *ngIf="searchInput.value && ! aplyedFilters">No se han encontrado resultados para la
              búsqueda "{{searchInput.value}}"</p>

            <!-- If something is searched and the user applayed filters-->
            <div #searchAndFilters *ngIf="searchInput.value && aplyedFilters;">
              <p class="nmb">No se han encontrado resultados
                para la búsqueda "{{searchInput.value}}"</p>
              <p class="nmb c_p" *ngIf="aplyedFilters">Ten en cuenta que tienes filtros aplicados</p>
            </div>

            <!-- No result with only the filters-->
            <p class="nmb" *ngIf="!searchInput.value && aplyedFilters;">No se han encontrado resultados para los filtros
              aplicados</p>

          </td>

          <!-- Si no hay objectos-->
          <ng-template #noObjects>
            <td *ngIf="loaded_" class="mat-cell" [attr.colspan]="displayedColumns.length">
              <div class="df jcc aic fww">
                <lord-icon src="https://cdn.lordicon.com/sypvjnev.json" trigger="loop" delay="2000"
                  colors="primary:#121331,secondary:#3a3347,tertiary:#646e78,quaternary:{{accent()}},quinary:#f9c9c0"
                  class="lord-big">
                </lord-icon>
                <div class="df fdc tac mb20">
                  <p class="nmb fsl fw500" style="margin-top: 20px; margin-right: 10px;">¡OOOPS!</p>
                  <p class="nmb fsm" style="margin-top: 20px;">Parece ser que aún no hay datos</p>
                  <button *ngIf="noDataCreateNew" mat-button color="accent" class="mt20" (click)="onClickAdd()">
                    {{noDataCreateNew}}
                  </button>
                  <!-- <p *ngIf="masterClass && masterClass == 'client'" class="fsm" style="margin-top: 20px;">
                    También puedes <span class="c_b mt20" (click)="routerS.goTo(v.dataupload)">importar tus
                      clientes</span>
                  </p> -->
                </div>
              </div>
            </td>
          </ng-template>
          <td *ngIf="loaded_ == false" class="mat-cell" [attr.colspan]="displayedColumns.length">
            <mat-progress-bar mode="buffer"></mat-progress-bar>
          </td>
        </tr>
      </table>
      <mat-paginator [ngClass]="{'dni' : !loaded_ || !showPagination || data.length==0}"
        [pageSizeOptions]="[10, 15, 30, 50]" (page)="onPaginateChange()"></mat-paginator>
    </app-card>
  </div>

</div>