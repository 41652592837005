<div class="df aic">
    <app-class-searcher #providerSearch [showLeftNumber]="false" class="w100"
        (onSelect)="onSelectProvider.emit($event);" [disableIf]="disabledIf.bind(this)" [required]="false"
        [masterClass]="provider" [width100]="true" searchPlaceHolder="Buscar proveedores" [extendedInfo]="true"
        [createData]="{
            text : 'Nuevo proveedor',
            by : [v.createContact,{ client_type: CE.PROVIDER }]
        }">
    </app-class-searcher>
    <button mat-icon-button (click)="onDestroy.emit()">
        <mat-icon>delete</mat-icon>
    </button>
</div>