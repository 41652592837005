import { M_CustomProduct } from "./Products/M_CustomProduct";
import { M_Product } from "./Products/M_Product";

/** Pass the lines in the constructor */
export class M_Breakdown {
    products: M_Product[] = []
    customs: M_CustomProduct[] = []
    constructor(lines: any) {
        if (lines) {
            for (let i = 0; i < lines.length; i++) {
                let extendedProduct = lines[i];
                if (extendedProduct.details) {
                    extendedProduct.details.location = extendedProduct.location; //Selected location is outside details
                    let p = extendedProduct.custom == "0" ? new M_Product(extendedProduct.details) : new M_CustomProduct(extendedProduct.details);
                    p.discount = extendedProduct.discount;
                    this.setUpProduct(extendedProduct, p);
                    if (p instanceof M_Product) { this.products.push(p); }
                    else { this.customs.push(p); }
                }
            }
        }
    }

    setUpProduct(extendedProduct: any, p: M_Product | M_CustomProduct) {
        p.setLineId(extendedProduct.id);
        p.initQuanitity(extendedProduct.quantity);
        p.discount = (extendedProduct.discount);
    }

    markSaved() {
        this.allWithChanges.forEach(p => { p.line_hasChanges = false; })
    }

    get all() {
        return [...this.products, ...this.customs];
    }

    get allWithChanges() {
        return this.all.filter(p => p.line_hasChanges);
    }

    get changes() {
        return this.allWithChanges.length != 0;
    }


}