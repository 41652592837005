<div class="report-card">
    <p class="nmb tac fw600">
        {{title}}
    </p>
    <ng-container *ngIf="val">
        <p class="nmb tac">
            <span>{{val[0]}} <span *ngIf="percentage">%</span></span>
            <ng-container *ngIf="val[1] != undefined">
                <span>
                    vs.
                </span>
                <span>{{val[1]}}</span>

                <ng-container *ngIf="percentageValue != undefined">
                    <span class="fss ml10">

                        <!-- Percentage incrment -->
                        <ng-container *ngIf="!isTotal(percentageValue)">
                            <span
                                [ngClass]="{'c_r' : percentageValue < 0, 'c_t2' : percentageValue == 0, 'c_g' : percentageValue > 0}">
                                {{percentageValue}}%
                            </span>
                            <mat-icon *ngIf="percentageValue < 0" class="vam c_r">trending_down</mat-icon>
                            <mat-icon *ngIf="percentageValue == 0" class="vam c_t2">trending_flat</mat-icon>
                            <mat-icon *ngIf="percentageValue > 0" class="vam c_g">trending_up</mat-icon>
                        </ng-container>

                        <!-- Not percetage. Ex : If 0 go to 6, or 0 go to 10. (The increment would be infinite) -->
                        <ng-container *ngIf="isTotal(percentageValue)">
                            <span
                                [ngClass]="{'c_r' : percentageValue.total < 0, 'c_t2' : percentageValue.total == 0, 'c_g' : percentageValue.total > 0}">
                                + {{percentageValue.total}}
                            </span>
                        </ng-container>
                    </span>
                </ng-container>

            </ng-container>
        </p>
    </ng-container>
</div>