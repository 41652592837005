
<div class="advanced-details-container import-tarifa">
    <app-go-back [v]="v.products" [text]="'Listado de productos'"></app-go-back>
    <app-card [contentLoaded]="loaded">
        <app-card-title>Importación de tarifas</app-card-title>

        <app-card-subtitle>Proveedor</app-card-subtitle>
        <div eForm-wrapper>
            <app-class-searcher [masterClass]="M_CLIENT" [createData]="{
                text : 'Crear cliente',
                by : 'dialog',
                forceType: ce.PROVIDER,
            }"></app-class-searcher>
        </div>


        <app-card-subtitle>Documento</app-card-subtitle>
        <app-excel-column-matcher #ecm [expectedColumns]="expectedColums" (onRowEnter)="onRowEnter($event)"
            (onRemoveExcel)="tariff_products = []" (onClickNext)="tariff_products = []">
            <app-product-line-table [products]="tariff_products" [lineTypes]="[]" [showQuantity]="false"
                [showTotal]="false" [clickableTitle]="false" [showSave]="false" [showLocation]="false"
                [showProductsStock]="false" [showPVP]="false" [showPVPtarifa]="true" [showNewOrPriceChange]="true"
                [itemsPerPage]="tariff_products.length ? 50 : undefined">
            </app-product-line-table>
        </app-excel-column-matcher>

        <div class="df jcc">
            <div class="df fdc aic jcc">
                <button style="width: fit-content;" mat-flat-button [disabled]="ecm.inProcess || !tariff_products.length || !cs.selected" (click)="enter()"
                    color="primary">Entrar</button>
            </div>
        </div>

    </app-card>
</div>