import { M_Action } from "src/app/models/M_Action";
import { M_Appointment } from "src/app/models/M_Appointment";
import { Filter, TagFilter, EnumFilter, DayFilter, UserFilter } from "../custom-classes/Filter";
import { or_status } from "../custom-classes/or_states";

export function filterItem(item: M_Action | M_Appointment, ...filters: Filter[]) {
  if (item instanceof M_Action) {
    return filterOR(item, ...filters)
  }
  else {
    return filterAppointment(item, ...filters);
  }
}

/** Filter appointment function */
export function filterAppointment(object: M_Appointment, ...filters: Filter[]) {
  var isStatusOk = true; //Screen filters
  var isAlreadyClientOK = true; //Screen filters
  filters.forEach(filter => {
    if (filter.activated) {
      if (filter instanceof TagFilter) {
        isStatusOk = filter.checkFilter([object.status]);
      }
      if (filter instanceof EnumFilter) {
        isAlreadyClientOK = filter.checkFilter(object.isClient ? 0 : 1);
      }
    }
  })
  return isStatusOk && isAlreadyClientOK;
}


/** Filter OR function */
export function filterOR(object: M_Action, ...filters: Filter[]) {
  var isDayFilterOk = true; //Screen filters
  var isEnumFilterOk = true; //Screen filters
  var isTypeOk = true; //Screen filters
  var isStatusOk = true; //Screen filters
  var isUserFilterOk = true; //Screen filters
  filters.forEach(filter => {
    if (filter.activated) {
      if (filter instanceof DayFilter && isDayFilterOk) {
        isDayFilterOk = filter.checkFilter(object.schedule)
      }
      else if (filter instanceof EnumFilter && isEnumFilterOk) {
        isEnumFilterOk = filter.checkFilter(object.status.num)
      }
      else if (filter instanceof TagFilter) {
        if (filter.typeOfLabel == or_status) {
          isStatusOk = filter.checkFilter([object.status.num])
        }
        else {
          isTypeOk = filter.checkFilter(object.type.map(obj => obj.num));
        }
      }
      else if (filter instanceof UserFilter && isUserFilterOk) {
        isUserFilterOk = filter.checkFilter(object.assigned?.id)
      }
    }

  })
  return isDayFilterOk && isEnumFilterOk && isStatusOk && isTypeOk && isUserFilterOk;
}

