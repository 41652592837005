<div *ngIf="group != undefined && group.products != undefined" class="drag-box">
    <div class="tasks">
        <mat-accordion class="example-headers-align">
            <mat-expansion-panel [expanded]="true" hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <div class="group gap5 w100">
                            <!-- NO EDIT -->
                            <div *ngIf="!isEdit && group.title != ''" class="df aic">
                                <div class="df fdc">
                                    <p prevent (click)="toggleEdit(group.title)" style="word-break: break-word;"
                                        class="one-line title-p">
                                        {{group.title}}
                                    </p>
                                    <p class="total-and-items" style="color:var(--t)">
                                        <app-action-totals [responsive]="true" [groups]="group"
                                            [invoiceClient]="dg.action?.defaultInvoiceClient"></app-action-totals>
                                    </p>
                                </div>
                            </div>

                            <!-- EDITAR -->
                            <div [ngClass]="isEdit || group.title == '' ? 'w100 df aic' : 'dn'">
                                <form [formGroup]="groupName"
                                    (keydown.enter)="editGroupName(nameInput.value); $event.stopPropagation()"
                                    class="w100">
                                    <mat-form-field appearance="outline"
                                        class="small-form-field no-wrapper edit-title-name">
                                        <input cdkFocusInitial #nameInput="matInput" matInput
                                            placeholder="Nombre de la intervención" [value]="group.title"
                                            formControlName="name" (focusout)="editGroupName(nameInput.value)"
                                            (keydown.space)="$event.stopPropagation()">
                                        <mat-icon matSuffix (click)="editGroupName(nameInput.value)">done</mat-icon>
                                    </mat-form-field>
                                </form>
                            </div>
                        </div>
                    </mat-panel-title>
                    <mat-panel-description class="d465">
                        <div class="df aic mla gap5">
                            <div class="status-type">

                                <!-- Show the matMenu if the action is OR and is not 'invoiced'-->
                                <app-or-status-label *ngIf="isOr()" [circularStatus]="responsiveS.w < 600"
                                    [showIcon]="responsiveS.w > 600" [menuIconOpen]="!group.isInvoiced()"
                                    [mat-menu-trigger-for]="!group.isInvoiced() ? status : null" prevent
                                    [status]="getStatus()">
                                </app-or-status-label>

                                <!-- Show the matMenu if the action is OR and the gorup is not invoiced-->
                                <app-or-type-label
                                    [mat-menu-trigger-for]="canModifyGroup && !isVnVoOr ? groupType : null" prevent
                                    [menuIconOpen]="canModifyGroup && !isVnVoOr && responsiveS.w > 600"
                                    [showText]="false" [wrapOn]="responsiveS.w > 600 ? 2 : 1" [tipo]="[group.type]">
                                </app-or-type-label>
                            </div>
                            <button [matTooltip]="!group.type.interno ? 'Ver factura' : 'Ver trámite'"
                                *ngIf="group.token" mat-mini-fab color="primary" prevent (click)="billPreview()">
                                <mat-icon class="c_w">receipt_long</mat-icon>
                            </button>
                            <button prevent mat-icon-button [mat-menu-trigger-for]="actions" *ngIf="canModifyGroup">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                        </div>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div>
                    <app-product-line-table [canModifyTable]="canModifyTable" [showReserves]="isBudgetPage"
                        [products]="group.getAllProducts()"
                        [lineTypes]="!isRecambiosBudget ? ['product', 'custom', 'time', 'comment'] : ['product', 'custom', 'comment']"
                        [showDrag]="true" [showPVP]="!group.type.interno" [showCost]="group.type.interno"
                        [extendedInfoConfig]="
                            {
                                showColumn: true, 
                                actions: isBudgetPage ? ['manual-fault'] : ['manual-fault','remove-reservation']
                            }">
                    </app-product-line-table>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>

<!---------------------------------------------------------------------------->
<!---------------------------------------------------------------------------->

<!-- MAT MENUS-->
<mat-menu #actions="matMenu">
    <button mat-menu-item [mat-menu-trigger-for]="status" *ngIf="isOr()">
        <span>Estado</span>
    </button>
    <button mat-menu-item [mat-menu-trigger-for]="groupType" *ngIf="isOr() && !isVnVoOr">
        <span>Tipo</span>
    </button>
    <button mat-menu-item [mat-menu-trigger-for]="groupSpecific" *ngIf="isOr()">
        <span>Facturación detallada</span>
    </button>
    <button mat-menu-item [disabled]="isDeleteDisabled" (click)="onRemoveGroup.emit(this.group);">
        <mat-icon>delete</mat-icon>
        <span>Eliminar</span>
    </button>
</mat-menu>

<!-- OR Group specific-->
<mat-menu #groupSpecific="matMenu">
    <div class="p10" prevent>
        <form eForm [formGroup]="groupDetailedInvoice">
            <mat-form-field appearance="outline" class="dn_i">
                <input type="number" matInput formControlName="id">
            </mat-form-field>
            <p>Cliente a facturar</p>
            <div eForm-section eForm-wrapper>
                <app-class-searcher [showLeftNumber]="false" #csearcher [masterClass]="client" [allData]=""
                    [width100]="true" searchPlaceHolder="Buscar cliente" [extendedInfo]="false" [required]="true"
                    [form_]="groupDetailedInvoice" [formCname]="'invoice_contact_id'" [createData]="undefined">
                </app-class-searcher>
            </div>
            <ng-container *ngIf="group.type.garantia">
                <p></p>
                <p>Número documento</p>
                <div eForm-section eForm-wrapper>
                    <mat-form-field appearance="outline">
                        <mat-label>Número documento</mat-label>
                        <input type="text" matInput formControlName="invoice_doc_number">
                    </mat-form-field>
                </div>
                <p></p>
                <p>Estado factura</p>
                <div eForm-section eForm-wrapper>
                    <mat-form-field appearance="outline">
                        <mat-label>Estado factura</mat-label>
                        <mat-select formControlName="invoice_state_id">
                            <mat-option [value]="null">--</mat-option>
                            <mat-option [value]="1">Pendiente</mat-option>
                            <mat-option [value]="2">Aprobado</mat-option>
                            <mat-option [value]="3">Rechazado</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </ng-container>
        </form>
    </div>
    <p></p>
    <div class="df jcc p10">
        <button mat-flat-button color="primary" (click)="saveGroupDetails()">Guardar</button>
    </div>
</mat-menu>

<!-- OR-TYPE -->
<mat-menu #groupType="matMenu">
    <app-or-type-selector [showInterno]="!dg.isBudgetPage"
        (onClickOption)="onTypeChange($event)"></app-or-type-selector>
</mat-menu>

<mat-menu #status="matMenu">
    <div [ngTemplateOutlet]="statusMenuOptions"></div>
</mat-menu>

<ng-template #statusMenuOptions>

    <button mat-menu-item (click)="openGroup()" *ngIf="isOr() && group.state.num == 1" class="chip-option">
        <app-or-status-label [pointer]="true" [status]="[s_o, 'Abrir']"></app-or-status-label>
    </button>

    <button mat-menu-item (click)="closeGroup()" *ngIf="isOr() && group.state.num == 0"
        [disabled]="!hasFacturableTaskas()" class="chip-option">
        <app-or-status-label [pointer]="true" [status]="[s_c, 'Cerrar']"></app-or-status-label>
    </button>

    <ng-container *ngIf="isOr()">
        <div *ngIf="canInvoiceS.data([group], this) as invocieData" [roles]="[R.ADMIN, R.GESTOR, R.ADVISER]"
            [matTooltip]="!isGroupClosed() ? 'Debes cerrar la intervención antes de poder facturarla' : invocieData.tooltip"
            [matTooltipPosition]="'right'">
            <button mat-menu-item *ngIf="hasFacturableTaskas()" (click)="invoiceGroupTask()" style="overflow: visible;"
                [disabled]="!isGroupClosed() || invocieData.disable" class="chip-option">
                <app-or-status-label [matBadge]="invocieData.badge" [matBadgeColor]="invocieData.badgeColor"
                    [pointer]="true"
                    [status]="[s_i, !group.type.interno ? 'Facturar' : 'Tramitar']"></app-or-status-label>
            </button>
        </div>
        <mat-divider></mat-divider>
        <div *ngIf="canInvoiceS.data([group], this) as invocieData" recambios>
            <button mat-menu-item *ngIf="invocieData.disable && !isGroupInvoiced() && isOr()"
                [go]="v.createOrder">
                Pedir faltas
            </button>
        </div>

    </ng-container>

</ng-template>