<app-close-dialog-button *ngIf="isOnDialog"></app-close-dialog-button>

<p mat-dialog-title *ngIf="isOnDialog">{{this.isEdit ? "Editar color" : "Crear color"}}</p>
<div class="advanced-details-container" mat-dialog-content>
    <app-card [noStyle]="isOnDialog">
        <form [formGroup]="form" eForm>
            <!-- tener en cuenta al actualizar -->
            <app-card-subtitle [first]="true">Datos técnicos</app-card-subtitle>

            <div eForm-wrapper>
                <div eForm-section>
                    <!-- <app-brand-model-input #bmi [form]="form" [OnlyBrand]="true" [OnlyModel]="true" [OnlySerie]="true"
                        [OnlyColor]="true" (onSelectSerie)="selectSerie($event)" (onSelectModel)="selectModel($event)"
                        (onSelectBrand)="selectBrand($event)" (onFieldClear)="clearSerie()"></app-brand-model-input> -->
                <app-brands-models-series [form]="form" [notRequiredSerie]="true" (onSelectBrandSearcher)="selectBrand($event)" (onSelectModelSearcher)="selectModel($event)" (onSelectSerieSearcher)="selectSerie($event)"></app-brands-models-series>
                </div>
                <div eForm-section>
                    <mat-form-field appearance="outline">
                        <mat-label>Versión</mat-label>
                        <input matInput formControlName="version" placeholder="Código serie">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Código color</mat-label>
                        <input matInput formControlName="code" placeholder="Código serie">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Nombre color</mat-label>
                        <input matInput formControlName="name" placeholder="Nombre serie">
                    </mat-form-field>
                </div>
                <div eForm-section>
                    <mat-form-field appearance="outline">
                        <mat-label>Tipo de color </mat-label>
                        <mat-select [(value)]="finishType" (selectionChange)="onFinishTypeChange($event.value)" formControlName="type">
                          <mat-option [value]="0">Normal</mat-option>
                          <mat-option [value]="1">Metalizada</mat-option>
                        </mat-select>
                      </mat-form-field>
                    <app-accounting-group-selector [form]="form" [type]="'C'"></app-accounting-group-selector>
                </div>
            </div>
            <app-card-subtitle>Precio color</app-card-subtitle>
            <div eForm-wrapper>
                <div eForm-section>
                    <mat-form-field appearance="outline">
                        <mat-label>P.V.P</mat-label>
                        <input matInput formControlName="price" type="number" placeholder="P.V.P">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Coste</mat-label>
                        <input matInput formControlName="cost" type="number" placeholder="P.V.P">
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Importe coste</mat-label>
                        <input #input type="number" matInput placeholder="Importe coste." formControlName="percentage_cost"
                            type="number" DecimalInput>
                        <mat-icon matSuffix>percent</mat-icon>
                        <mat-error *ngIf="form.get('import_cost')?.touched && form.get('import_cost')?.errors">Valor
                            máx.
                            :
                            21</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <!-- <app-card-subtitle>Datos opcionales</app-card-subtitle>
            <div eForm-wrapper>
                <div eForm-section>
                    <mat-form-field appearance="outline">
                        <mat-label>Grupo contable detalle</mat-label>
                        <input matInput formControlName="accounting_detail" placeholder="Grupo contable detalle">
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Grupo contable agente</mat-label>
                        <input matInput formControlName="accounting_agent" placeholder="Grupo contable agente">
                    </mat-form-field>
                </div>
            </div> -->
        </form>
        
    </app-card>
</div>
<div mat-dialog-actions>
    <div class="df aic jcc mt10">
        <button mat-flat-button [disabled]="!form.valid" color="primary" (click)="create()">{{isEdit ? 'Guardar color':'Crear color'}}
        </button>
    </div>
</div>