import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { dialogSchedule, WeekScheduleComponent } from 'src/app/components/week-schedule/week-schedule.component';
import { EditMyPresenceComponent } from './edit-my-presence/edit-my-presence.component';
import { M_SigningTime } from 'src/app/models/Signing/M_SigningTime';
import { UserService } from 'src/app/services/EinaMainData/user.service';
import { SigningTimeService } from 'src/app/services/signing-time.service';
import { M_Schedule } from 'src/app/models/M_Schdeule';

@Component({
  selector: 'app-my-presence',
  templateUrl: './my-presence.component.html',
  styleUrls: ['./my-presence.component.css']
})
export class MyPresenceComponent {

  today = new Date();
  refDay: FormControl<Date | null> = new FormControl(new Date());

  constructor(private d: MatDialog, private userS: UserService, public signingS: SigningTimeService) {
    this.refDay.valueChanges.subscribe(val => {
      if (this.signingS.signingData) {
        this.signingS.signingData.refDay = val;
        this.signingS.signingData.refresHistory();
      }
    })
  }

  openSchedule() {
    this.d.open<WeekScheduleComponent, dialogSchedule, M_Schedule[] | undefined>(WeekScheduleComponent, {
      data: {
        prespective: "user",
        showCheckbox: true,
        userId: this.userS.userId
      }, autoFocus: false
      , panelClass: "week-schedule-panel"
    }).afterClosed().subscribe(res => {
      if (res) {
        console.log("New schedule!", res);
        this.signingS.signingData?.changeSchedule(res);
      }
    });
  }

  get canPlus() {
    if (!this.refDay.value) { return false; }
    if (this.refDay.value.getYear() < this.today.getYear()){return true;}
    return this.refDay.value.getMonth() < this.today.getMonth();
  }

  plus() {
    if (this.refDay.value) {
      this.refDay.setValue(this.refDay.value?.plusMonths(1));
    }
    else {
      this.refDay.setValue(new Date());
    }
  }

  less() {
    if (this.refDay.value) {
      this.refDay.setValue(this.refDay.value?.minusMonths(1));
    }
    else {
      this.refDay.setValue(new Date());
    }
  }

  openPresenceEdit(signing: M_SigningTime) {
    this.d.open(EditMyPresenceComponent, { data: signing, autoFocus: false }).afterClosed().subscribe(res => {
      if (res instanceof M_SigningTime) {
        this.signingS.signingData?.refresHistory(res);
      }
    })
  }

  abs(n: number) { return Math.abs(n) }


}
