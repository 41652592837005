import { DatePipe } from "@angular/common";
import { DateFormats } from "../enums/DateFormats";
import { timeDifference } from "./TimeFunctions";
import { CustomTime } from "../custom-classes/CustomTime";
import { timeDiffConfig, timeDifferenceType } from "../types/time-difference";

const pipe = new DatePipe('es');

declare global {
    interface Date {

        /** ------------------- */
        /** Date format Getters */
        /** ------------------- */
        /**"yyyy-MM-dd HH:mm:ss" */
        dataBaseFormat(): string;
        /**"yyyy-MM-dd" */
        datePickerFormat(): string;
        /**"yyyy-MM-ddTHH:mm" */
        inputFormat(): string;
        /**"dd/MM/yyyy - HH:mm" */
        shortFormat(): string;
        /**"yyyy/MM/dd" */
        noHourFormat(): string;
        /** "dd/MM/yyyy" */
        dayMonthYearFormat(): string;
        /**"HH:mm:ss" */
        hourMinuteSecond(): string;
        /**"HH:mm" */
        hourFormat(): string;
        /**"dd/MM/yyyy - HH:mm:ss" */
        shortSecondsFormat(): string;
        /** Lunes, 1 de Enero de 2022 */
        humanFormat(): string;
        /** 1 de Enero de 2022 */
        humanFormatNoWeekDay(): string;
        /** LUN, 1 de Enero de 2022 */
        shortHumanFormat(): string;
        /** Hoy || Mañana || 1 Feb. ||23 Ago. ...*/
        numMonthFormat(): string;
        /** LUN. 11 MAR */
        getFullDay(): string;
        /** First day of the month */
        firstMonthDay(): Date;
        /** Last day of the month */
        lastMonthDay(): Date;
        /** Hoy -Lunes, 1 de Enero de 2022 || Ayer -   Lunes, 1 de Enero de 2022 || Mañana - Lunes, 1 de Enero de 2022 || En 15 días - Lunes, 1 de Enero de 2022 ||  Hace 15 días - Lunes, 1 de Enero de 2022*/
        temporalFormat(): string;
        montAbreviature(): string | Date | undefined | null;
        toFormat(df: string): string | Date | undefined | null;
        /** Monday = 0, Tuesday = 1... */
        weekDay(): number;
        differenceWith(d: Date | undefined, timeConfig?: timeDiffConfig): timeDifferenceType;


        /** ------------------- */
        /** Time operations     */
        /** ------------------- */
        plusDays(days: number): Date;
        minusDays(days: number): Date;
        plusMonths(months: number): Date;
        minusMonths(months: number): Date;


        /** ------------------- */
        /** Time comparations   */
        /** ------------------- */
        isEquals(d: Date): boolean;
        isDayMonthEquals(d: Date): boolean;
        isMonthEqual(d: Date): boolean;
        isToday(): boolean;
        todayOrBigger(): boolean;
        isTomorrow(): boolean;
        isYesterday(): boolean;



        /** -------------------------------- */
        /** Year month and day names getters */
        /** -------------------------------- */
        getYear(large?: boolean): string;
        getMonthName(large?: boolean): string;
        getDayName(large?: boolean): string;


        /** -------------------------------- */
        /** Custom time functions */
        /** -------------------------------- */
        /** Returns the date hour and minute with the 'hh:mm' format */
        getHHmm(): string;
        setHHmm(hhmm: string): Date;
        customTime(): CustomTime;
        setCustomTime(time: CustomTime): string;
    }
}



/** ------------------- */
/** Date format Getters */
/** ------------------- */

Date.prototype.dataBaseFormat = function (): string { return pipe.transform(this, (DateFormats.DATABSE_FORMATT))! }
Date.prototype.datePickerFormat = function (): string { return pipe.transform(this, (DateFormats.DATEPCIKER_FORMAT))! }
Date.prototype.inputFormat = function (): string { return pipe.transform(this, (DateFormats.INPUT_FORMATT))! }
Date.prototype.shortFormat = function (): string { return pipe.transform(this, (DateFormats.SHORT_FORMAT))! }
Date.prototype.noHourFormat = function (): string { return pipe.transform(this, (DateFormats.NO_HOUR_FORMATT))! }
Date.prototype.dayMonthYearFormat = function (): string { return pipe.transform(this, (DateFormats.DAY_MONTH_YEAR))! }
Date.prototype.hourMinuteSecond = function (): string { return pipe.transform(this, (DateFormats.HOUR_MINUTE_SECOND))! }
Date.prototype.hourFormat = function (): string { return pipe.transform(this, (DateFormats.HOUR_FORMAT))! }
Date.prototype.shortSecondsFormat = function (): string { return pipe.transform(this, (DateFormats.SHORT_WITH_SECONDS))! }
Date.prototype.humanFormat = function (): string { return this.getDayName(true) + ", " + this.getDate() + " de " + this.getMonthName(true) + " de " + this.getFullYear() }
Date.prototype.humanFormatNoWeekDay = function (): string { return this.getDate() + " de " + this.getMonthName(true) + " de " + this.getFullYear() }
Date.prototype.shortHumanFormat = function (): string { return this.getDayName() + ", " + this.getDate() + " de " + this.getMonthName() + " de " + this.getFullYear() }
Date.prototype.getFullDay = function (): string { return this.getDayName() + ". " + this!.getDate() + " " + this.getMonthName(); }
Date.prototype.firstMonthDay = function (): Date { return new Date(this.getFullYear(), this.getMonth(), 1); }
Date.prototype.lastMonthDay = function (): Date { return new Date(this.getFullYear(), this.getMonth() + 1, 0); }


Date.prototype.numMonthFormat = function (): string {
    if (this.isToday()) { return "Hoy" }
    else if (this.isTomorrow()) { return "Mañana" }
    else {
        let monthName = this.getMonthName(false);
        let finalMonthname = monthName.charAt(0) + monthName.slice(1).toLocaleLowerCase();
        return this.getDate() + " " + finalMonthname + "."
    }
}

Date.prototype.temporalFormat = function (): string {
    if (this.isToday()) {
        return "Hoy - " + this.humanFormat();
    }
    else if (this.isYesterday()) {
        return "Ayer - " + this.humanFormat()
    }
    else if (this.isTomorrow()) {
        return "Mañana - " + this.humanFormat();
    }
    else {

        /**El día de esta classe és en el futuro*/
        if (this > new Date) {
            let futureLimit = new Date().plusDays(3);
            if (this < futureLimit) {
                return "En " + (this.getDate() - new Date().getDate()) + " días - " + this.humanFormat();
            }
        }
        /**El día de esta classe ya ha passado */
        else {
            let pastLimit = new Date().minusDays(3);
            if (this > pastLimit) {
                return "Hace " + (new Date().getDate() - this.getDate()) + " días - " + this.humanFormat();
            }
        }
    }
    return this.humanFormat();
}


Date.prototype.montAbreviature = function (): string | Date | undefined | null {
    return pipe.transform(this, ('MMM'))?.toUpperCase();
}

Date.prototype.toFormat = function (df: string): string | Date | undefined | null {
    return pipe.transform(this, (df));
}

Date.prototype.weekDay = function () : number {
    return (this.getDay() - 1 + 7) % 7;
}


Date.prototype.differenceWith = function (d: Date | undefined, timeConfig?: timeDiffConfig) {
    return timeDifference(this, d, timeConfig);
}




/** ------------------- */
/** Time operations     */
/** ------------------- */

Date.prototype.plusDays = function (days: number): Date {
    this.setDate(this.getDate() + days);
    return this;
}

Date.prototype.minusDays = function (days: number): Date {
    this.setDate(this.getDate() - days);
    return this;
}

Date.prototype.minusMonths = function (months: number): Date {
    this.setMonth(this.getMonth() - months);
    return this;
}

Date.prototype.plusMonths = function (months: number): Date {
    this.setMonth(this.getMonth() + months);
    return this;
}



/** ------------------- */
/** Time comparations   */
/** ------------------- */

Date.prototype.isEquals = function (d: Date): boolean {
    return d.getDate() == this.getDate() &&
        d.getMonth() == this.getMonth() &&
        d.getFullYear() == this.getFullYear();
}

Date.prototype.isDayMonthEquals = function (d: Date): boolean {
    return d.getDate() == this.getDate() &&
        d.getMonth() == this.getMonth();
}

Date.prototype.isMonthEqual = function (d: Date): boolean {
    return d.getMonth() == this.getMonth() &&
        d.getFullYear() == this.getFullYear();
}

Date.prototype.isToday = function (): boolean {
    return this.isEquals(new Date());
}

Date.prototype.todayOrBigger = function (): boolean {
    return this.isEquals(new Date()) || this > new Date();
}

Date.prototype.isTomorrow = function (): boolean {
    return this.isEquals(new Date().plusDays(1));

}

Date.prototype.isYesterday = function (): boolean {
    return this.isEquals(new Date().minusDays(1));
}





/** -------------------------------- */
/** Year month and day names getters */
/** -------------------------------- */

Date.prototype.getYear = function (large?: boolean): string {
    let year = this.getFullYear().toString();
    return large ? year : year[2] + year[3]
}

Date.prototype.getMonthName = function (large?: boolean): string {
    switch (this.getMonth()) {
        case (0): return !large ? "ENE" : "Enero";
        case (1): return !large ? "FEB" : "Febrero";
        case (2): return !large ? "MAR" : "Marzo";
        case (3): return !large ? "ABR" : "Abril";
        case (4): return !large ? "MAY" : "Mayo";
        case (5): return !large ? "JUN" : "Junio";
        case (6): return !large ? "JUL" : "Julio";
        case (7): return !large ? "AGO" : "Agosto";
        case (8): return !large ? "SET" : "Septiembre";
        case (9): return !large ? "OCT" : "Octubre";
        case (10): return !large ? "NOV" : "Noviembre";
        case (11): return !large ? "DIC" : "Diciembre";
        default: return "";
    }
}

Date.prototype.getDayName = function (large?: boolean): string {
    switch (this.getDay()) {
        case (1): return !large ? "LUN" : "Lunes";
        case (2): return !large ? "MAR" : "Martes";
        case (3): return !large ? "MIE" : "Miércoles";
        case (4): return !large ? "JUE" : "Jueves";
        case (5): return !large ? "VIE" : "Viernes";
        case (6): return !large ? "SAB" : "Sábado";
        case (0): return !large ? "DOM" : "Domingo";
        default: return "";
    }
}


/** -------------------------------- */
/** Custom time functions */
/** -------------------------------- */

// Format as "HH:mm" (e.g., "09:05" if the hour is 9 and minutes are 5)
Date.prototype.getHHmm = function (): string {
    const hours = this.getHours();
    const minutes = this.getMinutes();
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}

Date.prototype.setHHmm = function (hhmm: string): Date {
    const [hh, mm] = hhmm.split(":");
    let hours = hh?.getNumber() || 0;
    let minutes = mm?.getNumber() || 0;
    this.setHours(hours);
    this.setMinutes(minutes);
    this.setSeconds(0);
    return this;
}

Date.prototype.customTime = function (): CustomTime {
    const h = this.getHours().toString();
    const m = this.getMinutes().toString();
    const s = this.getSeconds().toString();
    return new CustomTime(h, m, s);
};


Date.prototype.setCustomTime = function (time: CustomTime): string {
    this.setHours(time.getHours());
    this.setMinutes(time.getMinutes());
    this.setSeconds(time.getSeconds());
    return this.dataBaseFormat();
}


Date.prototype.toISOString = function (): string {
    return this.dataBaseFormat();
}

Date.prototype.toString = function (): string {
    return this.datePickerFormat();
}

export { }