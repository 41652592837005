import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_Company } from '../../models/M_Company';
import { ParamsService } from '../../services/params.service';
import { ViewPath } from 'src/app/app-routing.module';
import { PDF_DATA, PdfComponent } from '../pdf/pdf.component';
import { M_Invoice } from '../../models/M_Invoice';
import { PayAllInvoiceDialogComponent } from 'src/app/views/invoices/pay-check-control/pay-all-invoice-dialog.component';
import { nameByTemplateType } from 'src/app/utils/templateType';
import { CustomColor } from 'src/app/custom-classes/CustomColor';
import { downloadBlob } from 'src/app/utils/FunctionUtils';
import { RouterService } from 'src/app/services/router.service';
import { PS_HIGHLIGHT } from 'src/app/constants/constants';

export interface PREVIEW_DATA {
  showORcharge?: boolean
  canClose?: boolean,
  minifyDownload?: boolean
}
export interface PDF_CV {
  client_id:    number | undefined,
  vehicle_id:   number | undefined,
  price:        number |  undefined,
  tax:          number |   undefined,
  comment :     string | undefined;
  num_purchase :     string | undefined;
  type :        number | undefined;
  docum_prov_num :     string | undefined;
  mode : string | undefined;
  buy_transac_id : number | undefined;
  token : string | undefined;
}
export type company_data = { footer?: string, p: CustomColor, s: CustomColor, c: M_Company }

@Component({
  selector: 'app-preview',
  templateUrl: './preview-dialog-component.html',
  styleUrls: ['./preview-dialog-component.css']
})
export class PreviewDialog {
  loading: boolean = false;
  v = ViewPath;
  @ViewChild(PdfComponent, { static: true }) pdfComponent!: PdfComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { pdf: PDF_DATA, preview: PREVIEW_DATA, invoice: M_Invoice,params?:PDF_CV }, public dRef: MatDialogRef<PreviewDialog>, private apiS: ApiService, private paramS: ParamsService, private routerS: RouterService,
    private d: MatDialog) {
  }

  /** Download the invoice by the token */
  download() {
    if (this.pdfComponent.url && this.pdfComponent.file) {
      downloadBlob(this.pdfComponent.file, this.downloadName);
    }
  }

  downloadXML() {
    if (this.data.pdf.token) {
      this.apiS.downloadEinvoiceXML(this.data.pdf.token).then(res => {
        if (res) { downloadBlob(res, this.downloadName); }
      });
    }
  }

  get downloadName() {
    return (this.data.pdf.id ? (this.data.pdf.id + " - ") : '') + this.nameByType + " - " + new Date().dayMonthYearFormat();
  }

  get nameByType() {
    return nameByTemplateType(this.data.pdf.preview_type);
  }

  goOr() {
    if (this.data.pdf.id) {
      this.paramS.go(this.v.editOr, this.data.pdf.id)
    }

  }
  goCreateInvoice(invoice: M_Invoice) {
    this.d.open(PayAllInvoiceDialogComponent, { panelClass: 'customDialog', data: { invoice: invoice, isDialog: true } }).afterClosed().subscribe(res => {
      this.paramS.go(this.v.invoiceDetails, invoice.id!);
    });
  }
  goCobrarInvoice() {

    if (this.data.pdf.preview_type == 'OR' && this.data.pdf.id != undefined) {
      this.apiS.getInvoiceId(this.data.pdf.id).then(res => {
        this.goCreateInvoice(res);
      });
    }
    else if (this.data.invoice) {

      this.goCreateInvoice(this.data.invoice);
    }

  }

  goCobrarAbono() {
    if (this.data.pdf.id) {
      this.apiS.getInvoiceId(this.data.pdf.id).then(res => {
        this.goCreateInvoice(res);
      });
    }
  }

  goPurchase(){
    this.routerS.goWithQueryParams(this.v.purchases, {[PS_HIGHLIGHT] : this.data.params?.buy_transac_id});
  }

  goOrView() {
    this.routerS.goTo(this.v.or)
  }

  goInvoiceView(invoice_id?: number) {
    if (invoice_id) {
      this.paramS.go(this.v.invoiceDetails, invoice_id);
    }
    else {
      this.routerS.goTo(this.v.invoices);
    }

  }

}