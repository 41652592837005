import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/services/Api/api.service';
import { AbstractControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { M_Brand } from '../../models/M_Brand';
import { M_Model } from '../../models/M_Model';
import { VehicleType } from '../../enums/VehicleType';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { SearchService } from 'src/app/services/search.service';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';
import { M_Serie } from 'src/app/models/M_Serie';
import { CreateSerieComponent } from 'src/app/core/components/create-serie/create-serie.component';
import { MatDialog } from '@angular/material/dialog';
import { CreateModelsComponent } from '../create-models/create-models.component';
import { CreateEditBrandComponent } from 'src/app/create-edit-brand/create-edit-brand.component';

/** to do - Remove this */
@Component({
  selector: 'app-brand-model-input',
  templateUrl: './brand-model-input.component.html',
  styleUrls: ['./brand-model-input.component.css']
})
export class BrandModelInputComponent implements OnInit {

  loaded = false;
  loadingModels = false;
  MAX_RESULTS = 10;
  msg_error : boolean = false;
  selectingOption = false; // Nuevo flag
  @Input({ required: true }) form!: UntypedFormGroup;
  @Input({ required: false }) showPrice!: boolean;
  @Input({ required: false }) required!: boolean;
  @Input({ required: false }) customBrandModel: boolean = true;
  @Input({ required: false }) budgetComercial: boolean = false; // Nueva propiedad
  @Input({ required: false }) OnlyBrand: boolean = false; // only brand
  @Input({ required: false }) OnlyModel: boolean = false; // only model
  @Input({ required: false }) OnlySerie: boolean = false; // only serie
  @Input({ required: false }) OnlyColor: boolean = false; // only color
  @Input({ required: false }) allInputs: boolean = false; // only serie
  @Input({ required: false }) onlyVehicle: boolean = false; // only serie
  @Output() onSelectBrand: EventEmitter<M_Brand> = new EventEmitter();
  @Output() onSelectModel: EventEmitter<M_Model> = new EventEmitter();
  @Output() onSelectSerie: EventEmitter<M_Serie> = new EventEmitter();
  @Output() onFieldClear: EventEmitter<void> = new EventEmitter<void>();

  showingBrands: M_Brand[] = [];
  filteredBrands: M_Brand[] = [];
  filteredModels: M_Model[] = [];
  filteredSeries: M_Serie[] = [];
  showingModels: M_Model[] = [];
  showingSeries: M_Serie[] = [];
  savedBrands: [VehicleType, M_Brand[]][] = [];

  constructor(
    private apiS: ApiService,
    private searchS: SearchService,
    private chdRef: ChangeDetectorRef,
    private confirmD: ConfirmDialogService,
    private d: MatDialog
  ) {
    console.log(this.OnlyBrand);
   }

  ngOnInit(): void {
    this.brandControl.valueChanges.subscribe(val => {
      this.filterBrands(val);
    });

    this.modelControl.valueChanges.subscribe(val => {
      this.filterModels(val);
    });
    
    this.serieControl.valueChanges.subscribe(val=>{
      this.filterSeries(val);
    });

    this.form.get("type")?.valueChanges.subscribe(val => {
      this.initByVehicleType(val);
    });

    this.initByVehicleType(this.form.get("type")?.value).then(res => {
      this.loaded = true;
      this.brandControl?.setValue(this.brandControl.value);
    });

    if (this.required) {
      this.brandControl.addValidators(Validators.required);
      this.modelControl.addValidators(Validators.required);
    }

    if (!this.customBrandModel) {
      this.brandControl.addValidators(this.realBrand());
      this.modelControl.addValidators(this.realModel());
    }
    if(this.OnlySerie && this.OnlyModel){
      this.apiS.series().then(res=>{
        this.showingSeries = res;
      });
    }
  }

  // Validador para la marca
  realBrand(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const isBrandValid = control.value instanceof M_Brand || (typeof control.value === 'string' && control.value.trim().length > 0);
      return isBrandValid ? null : { isOk: false };
    };
  }
  onBrandBlur() {
    const brandValue = this.brandControl.value;

    // Verificar si la marca está vacía (sin selección o sin texto)
    if (!brandValue || (typeof brandValue === 'string' && brandValue.trim() === '')) {
      this.clearModel();  // Limpiar el modelo si la marca está vacía
    }
  }
  onModelSelected(selectedModel: M_Model) {
    this.modelControl.setValue(selectedModel); // Establece el modelo seleccionado
    this.selectingOption = true; // Establece el flag de selección
    this.onSelectModel.emit(selectedModel); // Emite el evento de selección
}
  onSerieSelected(selectedSerie : M_Serie,clear:boolean){
    console.log('Donde hago el input');
    this.serieControl.setValue(selectedSerie); 
    this.selectingOption = true;
    this.msg_error = false
    if(clear){
      this.clearModel();
    }
    this.apiS.brandModel(selectedSerie.brand_id,selectedSerie.id).then(res => {
      this.showingModels = res;
      this.filterModels(undefined);
      this.loadingModels = false;
      if(this.showingModels.length <=0){
        this.msg_error = true
      }else{
        this.msg_error = false;
      }
      this.chdRef.detectChanges();
    });
    this.onSelectSerie.emit(selectedSerie);
  }
  onModelBlur() {
    // Solo muestra la advertencia si no se está seleccionando una opción
    if (!this.selectingOption) {
        this.showModelWarning(); // Muestra la advertencia
    }
}
  // Validador para el modelo
  realModel(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const isModelValid = control.value instanceof M_Model || (typeof control.value === 'string' && control.value.trim().length > 0);
      return isModelValid ? null : { isOk: false };
    };
  }

  initByVehicleType(vt: VehicleType) {
    return new Promise<any>(resolve => {
      let isSaved = this.isbrandSaved(vt);
      if (isSaved) {
        this.initShowingBrands(isSaved[1]);
        resolve(true);
      } else {

          this.apiS.brands(vt === VehicleType.bike ? 0 : 0).then(res => {
            this.initShowingBrands(res);
            this.savedBrands.push([vt, res]);
            resolve(true);
          });
      }
    });
  }

  isbrandSaved(vt: VehicleType) {
    return this.savedBrands.find(bf => bf[0] === vt);
  }

  initShowingBrands(brands: M_Brand[]) {
    this.showingBrands = brands;
    this.refreshModelsBasedOnBrand(this.brandControl.value);
  }

  refreshModelsBasedOnBrand(val: MatAutocompleteSelectedEvent | M_Brand | undefined) {
    const brand = val instanceof M_Brand ? val : (val?.option?.value instanceof M_Brand ? val.option.value : undefined);

    // Limpia el modelo antes de buscar modelos asociados a la nueva marca seleccionada
    if(!this.OnlyBrand){
      this.clearModel();
      this.clearSerie();
    }    
    if (brand) {
      this.showingSeries = brand.series;

      if((this.OnlyModel && this.OnlySerie) || this.onlyVehicle){
        this.loadingModels = true;
        this.apiS.brandModel(brand.id).then(res => {
          this.showingModels = res;
          this.filterModels(undefined);
          this.loadingModels = false;
          this.chdRef.detectChanges();
        });
        if(this.OnlyColor){
          this.showingSeries = [];
        }
      }
    } else {
      this.showingModels = [];
    }
    this.onSelectBrand.emit(brand);
  }

  clearModel() {
    this.form.patchValue({ model: undefined });
    this.filteredModels = []; // Limpia las opciones filtradas
    this.onFieldClear.emit();
  }
  clearSerie(){
    this.form.patchValue({serie:undefined});
    this.filteredSeries = [];
    this.onFieldClear.emit();
  }
  // Muestra el mensaje de advertencia solo al desenfocar
  showModelWarning() {
    if (this.budgetComercial) {
      return; // Si budgetComercial es verdadero, no se muestran mensajes
    }

    const currentModel = this.modelControl.value;
    const currentBrand = this.brandControl.value;

    if (!(currentModel instanceof M_Model) && currentModel) {
      // Si no hay una marca válida
      if (!(currentBrand instanceof M_Brand)) {
        this.confirmD.show({
          title: "¡Atención!",
          body: "Se van a crear una marca y un modelo personalizados.",
          type: "danger",
          showCancel: false,
          confirmTxt: "Ok"
        });
      } else {
        // Si hay una marca válida
        this.confirmD.show({
          title: "¡Atención!",
          body: "Se va a crear un modelo personalizado.",
          type: "danger",
          showCancel: false,
          confirmTxt: "Ok"
        });
      }
    }
    this.selectingOption = false;
  }

  filterBrands(val: string | undefined) {
    if (typeof val !== "string") { return; }
    this.filteredBrands = val ? this.showingBrands.filter(option => this.searchS.match(val, option.name)) : this.showingBrands;
    this.selectByName("brand", val);
  }

  filterModels(val: string | undefined) {
    if (typeof val !== "string") { return; }
    this.filteredModels = val ? this.showingModels.filter(option => this.searchS.match(val, option.name)) : this.showingModels;
    this.selectByName("model", val);
  }
  filterSeries(val:string | undefined){
    if (typeof val !== "string") {  this.msg_error = false; return; }
    this.filteredSeries = val ? this.showingSeries.filter(option => this.searchS.match(val, option.name)) : this.showingSeries;
    this.selectByName("serie", val);
  }
  filterModelSeries(serie : M_Serie){
    this.filteredModels =  this.showingModels.filter(option => this.searchS.match(String(serie.id), String(option.details?.serie_id)));
  }

  selectByName(bm: "brand" | "model" | "serie", val: string) {
    if (bm === "brand") {
      const foundBrand = this.filteredBrands.find(b => b.name.toLowerCase() === val.toLowerCase());
      if (foundBrand) { this.brandControl.setValue(foundBrand); }
    }
    if (bm === "model") {
      const foundModel = this.filteredModels.find(m => m.name.toLowerCase() === val.toLowerCase());
      if (foundModel) { this.modelControl.setValue(foundModel); }
    }
    if (bm === "serie") {
      const foundSerie = this.filteredSeries.find(s => s.name.toLowerCase() === val.toLowerCase());
      if (foundSerie) { this.serieControl.setValue(foundSerie); }
    }
  }

  displayBrand(brand: M_Brand | string): string {
    return brand instanceof M_Brand ? brand.name : brand;
  }

  displayModel(model: M_Model | string): string {
    return model instanceof M_Model ? model.name : model;
  }
  displaySerie(serie: M_Serie | string): string {
    return serie instanceof M_Serie ? serie.name : serie;
  }
  get message(){
    return this.msg_error;
  }
  get showInputs(){
    if(this.allInputs){return true;}
   if(this.OnlyBrand && !this.OnlySerie && !this.OnlyModel){
    return true
   } 
   else if(this.OnlySerie && !this.OnlyBrand && !this.OnlyModel){
    return true;
   }
   else if(this.OnlyModel && !this.OnlyBrand && !this.OnlySerie){
    return true;
   }
    return false
  }
  get controlModel(){
    return this.serieControl.value != undefined &&  this.showingModels.length == 0;
  }
  openCreateBrandDialog(): void {
    this.d.open(CreateEditBrandComponent).afterClosed().subscribe(res=>{
      this.form.patchValue({brand:res});
    });
  }
  openCreateSerieDialog(): void {
    this.d.open(CreateSerieComponent).afterClosed().subscribe(res=>{
      this.form.patchValue({serie:res});
    });
  }
  openCreateModel(): void {
    this.d.open(CreateModelsComponent).afterClosed().subscribe(res=>{
      this.form.patchValue({model:res});
    });
  }
  get brandControl() { return this.form.get("brand")!; }
  get modelControl() { return this.form.get("model")!; }
  get serieControl() { return this.form.get("serie")!; }
}
