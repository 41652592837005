import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-report-card',
  templateUrl: './report-card.component.html',
  styleUrls: ['./report-card.component.css']
})
export class ReportCardComponent {

  @Input({ required: true }) title!: string;
  @Input({ required: true }) val: [number | undefined, number | undefined] | undefined;
  @Input() percentage: boolean = false;

  get percentageValue(): number | {total : number} | undefined {


    if (this.val && this.val[0] != undefined && this.val[1] != undefined) {
      let divisionUp = this.val[1] - this.val[0];
      let divisionDown = this.val[0];

      if (this.val[0] == 0 && this.val[1] == 0) { return 0 }
      if (this.val[0] == 0 && this.val[1] != 0) { return { total: this.val[1] } }
      if (divisionDown == 0) { return undefined; }

      return ((divisionUp / divisionDown) * 100).castDecimals(2)
    }
    return undefined;
  }

  isTotal(value: number | { total: number }): value is { total: number } {
    return typeof value === 'object' && value !== null && 'total' in value;
}

  get isPercentage(){
    return 
  }

}
