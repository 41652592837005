<mat-menu #bookmarkMenu>
    <div mat-menu-item (click)="updateBookmark(bookmarkEnum.PRIMARY)">
        <mat-icon [filled]="true" class="c_p_i">bookmark</mat-icon>Negro
    </div>
    <div mat-menu-item (click)="updateBookmark(bookmarkEnum.ACCENT)">
        <mat-icon [filled]="true" class="c_a_i">bookmark</mat-icon> Azul
    </div>
    <div mat-menu-item (click)="updateBookmark(bookmarkEnum.GREEN)">
        <mat-icon [filled]="true" class="c_g_i">bookmark</mat-icon> Verde
    </div>
    <div mat-menu-item (click)="updateBookmark(bookmarkEnum.ORANGE)">
        <mat-icon [filled]="true" class="c_o_i">bookmark</mat-icon> Naranja
    </div>
    <div mat-menu-item (click)="updateBookmark(bookmarkEnum.WARN)">
        <mat-icon [filled]="true" class="c_r_i">bookmark</mat-icon> Rojo
    </div>
</mat-menu>