import { Component, Input } from '@angular/core';
import { M_Timelapse, TimeLapseEnum } from 'src/app/models/M_Timelapse';

@Component({
  selector: 'app-timelapse',
  templateUrl: './timelapse.component.html',
  styleUrls: ['./timelapse.component.css']
})
export class TimelapseComponent {
  @Input({ required: true }) timelapse: M_Timelapse[] = [];
  @Input({ required: true }) currentItemId!: number;
  @Input({ required: true }) currentItemType!: TimeLapseEnum;


  isCurrentItem(step: M_Timelapse) {
    return step.id == this.currentItemId && step.object_type == this.currentItemType;
  }

}
