<div class="notes-and-images">
  <!-- Notes -->
  <app-note [form_]="orFormComponent" [canEdit]="!allInvoiced"
    (onUserSpeach)="onUserSpeach($event)"></app-note>
  <!-- Images -->
  <div class="w50 upload-container-or" style="overflow: hidden;" [ngClass]="{'upload-disabled' : allInvoiced}">
    <p class="nmb p10">Imágenes</p>
    <lib-file-upload #imageUpload [phoneMultipleUpload]="true" [ngClass]="{'hidde_add' : hideUploadImage(imageUpload)}"
      [uploadtype]="'multiple'" [maxImagesLength]="5" [maxSize]="6" (stateChanged)="onUploadImage($event)"
      [canDelete]="!allInvoiced" (onRemoveImage)="editORComponent ? editORComponent.removeImage($event) : ''">
    </lib-file-upload>
  </div>
</div>