import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { EinaDataService } from "../services/EinaMainData/eina-data.service";
import { RolesEnum } from "../enums/RolesEnum";
import { SnackService } from "../services/snack.service";
import { ViewPath } from "../app-routing.module";
import { ERROR_NOT_PERMISSION } from "../constants/constants";

@Injectable({
  providedIn: 'root'
})
export class RoleGuard {
  constructor(private einaDataS: EinaDataService, private snackS : SnackService, private router : Router) { }
  canActivate(next: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      /** Get Eina main user data */
      this.einaDataS.getEinaData().then(res => {
        if (!res) { resolve(false); }
        else {
          let roles: RolesEnum[] | undefined;
          if (next.data && next.data['roles']) {
            roles = next.data['roles'];
          }
          if (roles == undefined || !roles.length) { resolve(true); }
          else {
            let hasPermission = roles.includes(res.user.role);
            if (!hasPermission) {
              this.snackS.show(ERROR_NOT_PERMISSION);
              let url = ViewPath.pageNotFound.path;
              this.router.navigate([url]);
            }
            resolve(hasPermission);
          }
        }
      })
    }
    )
  }
}