<app-close-dialog-button></app-close-dialog-button>

<p mat-dialog-title>Seleccionar usuario</p>

<div mat-dialog-content>
    <app-class-searcher [cssClass]="'mw400'" [masterClass]="USER" [createData]="undefined"></app-class-searcher>
</div>

<div mat-dialog-actions>
    <button mat-button mat-dialog-close>Cancelar</button>
    <button color="primary" mat-flat-button [mat-dialog-close]="workerSearcher?.selected"
        [disabled]="!workerSearcher?.selected">Ok</button>
</div>