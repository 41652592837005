import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_ComercialBudget } from 'src/app/models/M_ComercialBudget';
import { M_Concept } from 'src/app/models/M_Concept';
import { M_Vehicle } from 'src/app/models/Vehicles/M_Vehicle';
import { ParamsService } from 'src/app/services/params.service';
import { ParameterStateComponent } from 'src/app/components/parameter-state/parameter-state.component';
import { SnackService } from 'src/app/services/snack.service';
import { RouterService } from 'src/app/services/router.service';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { validatorTax } from 'src/app/validators/tax';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';
import { TemplateFieldsService } from 'src/app/services/template-fields.service';
import { PreviewService } from 'src/app/services/preview.service';
import { M_Model } from 'src/app/models/M_Model';
import { M_Brand } from 'src/app/models/M_Brand';
import { M_Serie } from 'src/app/models/M_Serie';
import { VnVoFormComponent } from 'src/app/components/vehicleform/vn-vo-form/vn-vo-form.component';

export type CreateVNVObyBudetg = {
  budget: M_ComercialBudget
}
export interface TEMPLATE_PDF_PARAMS {
  client_id:    number | undefined,
  vehicle_id:   number | undefined,
  price:        number |  undefined,
  tax:          number |   undefined,
  comment :     string | undefined;
  type :        number | undefined;
  docum_prov_num :     string | undefined;
  mode : string | undefined;
  buy_transac_id:number | undefined;
  date: Date | undefined;
  token: string | undefined;
  type_new: number | undefined;
} 
@Component({
  selector: 'app-create-vn-vo',
  templateUrl: './create-vn-vo.component.html',
  styleUrls: ['./create-vn-vo.component.css']
})

export class CreateVNVOcomponent extends ParameterStateComponent implements OnInit {

  @ViewChild(VnVoFormComponent, { static: true }) vehicleForm!: VnVoFormComponent;

  loaded = false;
  isEdit = false;
  ve: M_Vehicle | undefined;
  v = ViewPath;
  formData_ = new FormData();
  ParamsTempl : TEMPLATE_PDF_PARAMS ={
    client_id:        undefined,
  vehicle_id:         undefined,
  price:              undefined,
  tax:                undefined,
  comment :           undefined,
  type :              undefined,
  docum_prov_num :    undefined,
  mode :              undefined,
  buy_transac_id:     undefined,
  date:               undefined,
  token:               undefined,
  type_new:               undefined,
  }
  public form: UntypedFormGroup;
  constructor(private apiS: ApiService, private snackS: SnackService,
    routerS: RouterService,public previewS: PreviewService,private templateService: TemplateFieldsService,public confirmD: ConfirmDialogService ,private formBuilder: FormBuilder,route: ActivatedRoute, private params: ParamsService,
    @Optional() public dialogRef: MatDialogRef<CreateVNVOcomponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: CreateVNVObyBudetg | null) {
    super(routerS, route, ["vnvo"]);
    this.form = this.formBuilder.group({
      vehicle_id: [''],
      client_id: [''],
      price: ['', Validators.required],
      tax: ['', validatorTax()],
      comment: [''],
      pdf_file: [''],
      type: [2],
      docum_prov_num: [''],
      mode: [''],
      buy_transac_id: [''],
      date: [''],
      token:['CV'],
      type_new:['']

    });
  }

  ngOnInit(): void {
    if (this.data?.budget) {
      this.patchFormWithComercaialBudget(this.data.budget);
    }
  }
  
  patchFormWithComercaialBudget(cBudget: M_ComercialBudget) {
    this.vehicleForm.provisionalConcepts = cBudget.concepts;
    this.vehicleForm.form.patchValue(
      {
        brand: cBudget.brand,
        model: cBudget.model,
        price: cBudget.price,
        budget_id: cBudget.id,
      }
    )
  }

  get isOnDialog() {
    return Object.keys(this.dialogRef).length != 0;
  }

  get title() {
    return this.isEdit ? 'Editar VN/VO' : 'Crear VN/VO'
  }

  goBackVehicle() {
    if (!this.ve) { return; }
    this.params.go(ViewPath.vnvoDetails, this.ve.vehicle_id);
  }

  override onParam(_k: string, v: any) {
    this.apiS.vnvo.getVehicleById(v).then(res => {
      if (res) {
        this.ve = res;
        this.vehicleForm.patchFormWithVehicle(this.ve);
      }
      this.isEdit = true;
      this.loaded = true;
    })
  }

  override noParams(): void {
    this.loaded = true;
  }

  getConceptsFromForm(): M_Concept[] {
    return this.vehicleForm.getConcepts();
  }

  create() {
    const formData = new FormData();
    if (this.vehicleForm.form.valid) {

        Object.keys(this.vehicleForm.form.controls).forEach(key => {
          const control = this.vehicleForm.form.get(key);
          if (control) {
            formData.append(key, control.value);
          }
        });

      let v = new M_Vehicle(this.vehicleForm.form.value);
      if (this.vehicleForm.brandsModelSerie?.modelSearcherComponent?.selected instanceof M_Model) {
        let m = this.vehicleForm.brandsModelSerie.modelSearcherComponent?.selected
        v.model = m;
        v.model_name = m.name;
        v.model_id =  m.id;
      }
      if(this.vehicleForm.brandsModelSerie?.brandSearcherComponent?.selected instanceof M_Brand){
        let b = this.vehicleForm.brandsModelSerie.brandSearcherComponent?.selected
        v.brand = b;
        v.brand_name = b.name;
        v.brand_id = b.id;
      }
      if(this.vehicleForm.brandsModelSerie?.serieSearcherComponent?.selected instanceof M_Serie){
        let s = this.vehicleForm.brandsModelSerie.serieSearcherComponent?.selected
        v.serie = s;
        v.serie_name = s.name
        v.serie_id = s.id;
      }
      v.concepts = this.getConceptsFromForm();
      if(this.isEdit){
        
        this.apiS.vnvo.createVNVO(v).then(res => {
          if (this.isEdit) {
            this.snackS.show("Vehículo editado con éxito");
            this.params.go(ViewPath.vnvoDetails, v.vehicle_id);
            this.ve = res;
          }
          else {
            // 
          }
        });
      }else{
        
        this.apiS.vnvo.createVNVO(v).then(res => {
          if (this.isEdit) {
            this.snackS.show("Vehículo editado con éxito");
            this.params.go(ViewPath.vnvoDetails, v.vehicle_id);
          }
          else {
            if(res instanceof M_Vehicle){
              this.ve = res;
              this.crateparams(this.ve);
              this.templateService.showTemplateSteps("CV", this.ve?.vehicle_id, "CV", this.ve?.type, undefined, this.ParamsTempl).afterClosed().subscribe(res => {
                if (res) {
                    //preview finish and save pdf after call endpoint sell vehicle.
                  
                    this.ParamsTempl.buy_transac_id = res;
                    this.ParamsTempl.mode= "save";
                    this.construct_form(this.ParamsTempl);
                    let hasData = false;
                    this.formData_.forEach((e) => {
                      hasData = true;
                    });
    
                    if (hasData) {
                      this.form.patchValue({ mode: "save", buy_transac_id: res });
                      this.previewS.showPreview("CV", "CV", undefined, undefined, false, undefined, undefined, undefined, this.form.value);
                      this.apiS.sellVehicleClient(this.formData_).then(_resp => {
                        this.params.go(this.v.vnvoDetails, this.ve!.vehicle_id)
                      });
    
                    } else {
                        // FormData está vacío
                    }
                }
              })
            }
            this.snackS.show("Vehículo creado con éxito");
            if (this.isOnDialog) {
              res.budget_id = this.vehicleForm.form.get('budget_id')?.value;
              this.dialogRef.close(res);
            }
            else {
              this.routerS.goTo(ViewPath.garage);
            }
          }
        })
      }
    
    }
    else{
      this.confirmD.show({
        title: "¡No tan deprisa!",
        body: "No puedes hacer una compra venta sin añadir el precio",
        type: "danger",
        showCancel: false,
        confirmTxt: "Ok"
      })
      return;
    }
  }
  crateparams(v: M_Vehicle){

    this.ParamsTempl = {
      client_id:  this.vehicleForm.ownerProvider?.selected?.client_id,  
      vehicle_id: v.vehicle_id,
      price:      this.vehicleForm.form.get('price')?.value, 
      tax:        this.vehicleForm.form.get('tax')?.value,         
      comment :   undefined,
      type :      2, 
      docum_prov_num :   this.vehicleForm.form.get('doc_prov_num')?.value,
      mode :              "view",
      buy_transac_id:     undefined,
      date:               this.vehicleForm.form.get('date')?.value,
      token:              'CV',
      type_new:              1,
    };
  }
  construct_form(params_form : TEMPLATE_PDF_PARAMS){
    if(params_form != undefined){
      this.form.patchValue({
        client_id:      params_form.client_id,  
        vehicle_id:     params_form.vehicle_id,
        price:          params_form.price, 
        tax:            params_form.tax,      
        comment :       params_form.comment,
        type :          params_form.type, 
        docum_prov_num :     params_form.docum_prov_num,
        mode :              "save",
        buy_transac_id:     params_form.buy_transac_id,
        date:               params_form.date,
        token:              'CV',
        type_new:              1,
      });

      this.formData_.append('vehicle_id',this.form.get('vehicle_id')?.value);
      this.formData_.append('client_id', this.form.get('client_id')?.value);
      this.formData_.append('price', this.form.get('price')?.value);
      this.formData_.append('tax', this.form.get('tax')?.value);
      this.formData_.append('docum_prov_num', this.form.get('docum_prov_num ')?.value);
      this.formData_.append('type', this.form.get('type')?.value);
      this.formData_.append('date', this.form.get('date')?.value);
      this.formData_.append('mode', 'view');
      this.formData_.append('token', 'CV');
      this.formData_.append('comment', 'CV');
  
      if (this.vehicleForm.ownerProvider?.selected?.isCompany || this.vehicleForm.ownerProvider?.selected?.isProvider) {
        if (this.vehicleForm.pdfUploadComponent.selectedFile) {
          this.formData_.append('pdf_file', this.vehicleForm.pdfUploadComponent.selectedFile);
        }
      }
    }
    
  }
}