import { endpoints } from "../../constants/Endpoints";
import { getSignusById, SIGAUS_PRICE_LITER, Signus, SIGNUS_N1 } from "../../constants/constants";
import { ProductCategory } from "../../enums/ProductCategory";
import { IClassSearcher } from "../../interfaces/IClassSearcher";
import { M_Contact } from "../M_Contact";
import { M_CustomProduct } from "./M_CustomProduct";
import { M_BaseProduct, mainPrice } from "./M_BaseProduct";
import { M_Location } from "../M_Location";
import { TypeOrder } from "../../enums/TypeOrder";
import { invoiceCallProduct } from "../../types/invoiceCallProduct";
import { M_Product_Invoice } from "../M_Product_Invoice";
import { M_Product_Move } from "../M_Product_Move";
import { M_Order } from "../M_Order";
import { M_Product_Move_Recambios } from "../M_Product_Move_Recambios";
import { M_Fault } from "../M_Fault";
import { M_Reservation } from "../M_Reservation";
import { M_QuantsProduct } from "../M_QuantsProduct";
import { ModulesEnum } from "../../enums/ModulesEnum";
import { M_DiscountGroupLine } from "../M_DiscountGroupLine";
import { EntryToEnum } from "../../enums/ContactEnum copy";
import { IPageStructureItem } from "src/app/interfaces/IPageStructureItem";
import { getArrayOf } from "src/app/utils/FunctionUtils";
import { match } from "src/app/services/search.service";

export class M_Product extends M_BaseProduct implements IPageStructureItem, IClassSearcher<M_Product> {

    /** ----------------- */
    /** BASIC EINA MODULE */
    /** ----------------- */
    product_id: number | undefined;
    reference: string;
    recycle: string;
    category: ProductCategory = ProductCategory.GENERAL;
    extra_field: number | Signus | undefined;
    product_invoice: M_Product_Invoice[] = [];
    product_move: M_Product_Move[] = [];
    created_at: Date | undefined;
    updated_at: Date | undefined;
    stock: number;
    cs_stock: number; //Class searcher stock

    /** ----------------- */
    /** RECAMBIOS MODULE  */
    /** ----------------- */
    pmp: number;
    pmp_value: number; // backend calculated
    buy_price_ant: number;
    buy_price_value: number;
    PVP_margin: number | undefined;
    obsolete: number;
    preobsolete: number;
    ref_factory: string;
    last_price_change: Date | undefined;
    last_entry: Date | undefined;
    last_exit: Date | undefined;
    accounting_id: number | undefined; //Grupo contable
    accounting_group_name: string | undefined; //Grupo contable en entrada de almacén/tarifas
    alternatives: any[] = []; // Refacator, canno't be any
    recProductsMov: M_Product_Move_Recambios[] = [] //Product moves with the recambios module

    /** Locations */
    locations: M_Location[] = []; //Locations where the product is currently located
    selected_location: number | undefined; //Location selected when imputing a product in OR, Invoice, Order...
    initialLocationValues: { location: number, quantity: number }[] = []; // Initial value of the product with locations
    location_dest: M_Location | undefined; // It is the destination location of the product of an order.

    /** Providers */
    providers: M_Contact[] = []; //Current product providers
    providerUsual: M_Contact | undefined; //The usual provider of the product. It can only be one.
    selected_provider: M_Contact | undefined; //Selected provider when imputing a product on a order.
    onlyProviders: boolean = false; //Used to create the providers class seracher component.

    /** Stock */
    override stock_min: number | undefined;
    override stock_max: number | undefined;
    totalPhysical: number = 0;
    totalDisp: number = 0;
    last_stock_calc: Date | undefined;
    totalEngaged: number = 0;
    totalReserved: number = 0;

    /** Faults */
    fault: M_Fault | undefined;
    faults: M_Fault[] = [];
    unfinishedFaults: M_Fault[] = [];
    fault_id: number | undefined;
    totalFaults: number = 0; //Total faults sum.
    fault_quantity: number | undefined; //The current fault quantity.
    pend_quant: number = 0; //Muestra la cantidad en falta (no la incrementada manualmente, solo la del item) 
    productsQuants: M_QuantsProduct | undefined;


    /** Orders */
    orders: M_Order[] = [];
    typeOrder: TypeOrder = TypeOrder.NORMAL;
    user_entered: number = 0;
    received: number = 0;
    line_deleted: boolean = false;

    /** Reservations */
    manual_reservation_client: M_Contact | undefined; //Manual reservation client_id;
    reservation: M_Reservation | undefined;
    reservations: M_Reservation[] = [];

    /** Storehouse entry */
    pvp_t_ant: number = 0;
    entry_to: EntryToEnum | undefined = EntryToEnum.DEST;

    /** Discount table */
    group_discount: number | undefined;
    group_discount_line: number | undefined;
    // discount_group: M_DiscountGroup | undefined;
    discount_line: M_DiscountGroupLine | undefined;

    /** New fields */
    min_order: number | undefined;
    discount_code: string | undefined;
    rate: number;
    rate_ant: number | undefined;
    intrastat: string | undefined;
    teileart: string | undefined;
    update_date: string | undefined;
    export_web: boolean;
    fuco: string | undefined;
    reference_ant: string | undefined;
    aps: string | undefined;
    type: string | undefined; // (R/A/L/N/M)
    extra_table_1: string | undefined;
    extra_table_2: string | undefined;
    extra_table_3: string | undefined;

    // id product comercial budget
    constructor(d: any) {
        super(d);
        this.product_id = d.product_id ? d.product_id : d.id ? d.id : undefined;
        this.reference = d.reference;
        this.buy_price = d.buy_price ? d.buy_price : 0;
        this.pmp = d.pmp ? d.pmp : 0;
        this.pmp_value = d.pmp_value;
        this.buy_price_ant = d.buy_price_ant ? d.buy_price_ant : 0;
        this.buy_price_value = d.buy_price_value ? d.buy_price_value : 0;
        this.reference = d.reference;
        this.tax = d.tax;
        this.recycle = d.recycle;

        let cat = d.category;
        this.category = typeof cat == "string" ? cat.getNumber() : cat;

        this.stock = d.stock;
        this.created_at = d.created_at ? new Date(d.created_at) : undefined;
        this.updated_at = d.updated_at ? new Date(d.updated_at) : undefined;
        this.setupExtraField(d.extra_field);
        this.setUpProdMovAndProdInvoice(d.hist);

        /** RECAMBIOS MODULE */
        this.obsolete = d.obsolete;
        this.PVP_margin = d.PVP_margin;
        this.preobsolete = d.preobsolete;
        this.ref_factory = d.ref_factory;
        this.last_price_change = d.last_price_change ? new Date(d.last_price_change) : undefined;
        this.last_entry = d.last_entry ? new Date(d.last_entry) : undefined;
        this.last_exit = d.last_exit ? new Date(d.last_exit) : undefined;
        this.accounting_id = d.accounting_id;
        this.alternatives = d.alternatives;
        this.recProductsMov = getArrayOf(M_Product_Move_Recambios, d.recProductsMov)

        /** Locations */
        this.locations = d.locations ? getArrayOf(M_Location, d.locations) : [];
        this.location_dest = d.location_dest ? new M_Location(d.location_dest) : undefined;
        this.setUpDefaultLocation(d);


        /** Providers */
        this.providers = d.providers ? getArrayOf(M_Contact, d.providers) : [];
        this.providerUsual = d.providerUsual ? new M_Contact(d.providerUsual) : undefined;
        this.selected_provider = this.setUpSelectedProvider();


        /** Stock */
        this.control_stock = d.control_stock ? d.control_stock : false;
        this.stock_min = d.stock_min || typeof d.stock_min == "number" ? d.stock_min : undefined;
        this.stock_max = d.stock_max ? d.stock_max : undefined;
        this.last_stock_calc = d.last_stock_calc ? new Date(d.last_stock_calc) : undefined;


        /** Faults */
        this.fault_id = d.fault_id;
        if (d.fault) {
            d.fault['product'] = this;
            this.fault = d.fault ? new M_Fault(d.fault) : undefined;
        }
        if (d.reservation) {
            this.reservation = new M_Reservation(d.reservation);
        } if (d.productItemQuants) {
            this.productsQuants = d.productItemQuants;
        } else {
            this.productsQuants = undefined;
        }
        if (d.pend_quant) {
            this.pend_quant = d.pend_quant;
        }

        /** Orders */
        this.orders = d.orders ? getArrayOf(M_Order, d.orders) : []
        this.received = d.received;
        if (d.reservations) {
            this.reservations = getArrayOf(M_Reservation, d.reservations);
        }
        if (d.faults) {
            this.faults = getArrayOf(M_Fault, d.faults);
            this.unfinishedFaults = this.faults.filter(fault => (fault.received - fault.quantity !== 0 && !fault.requested)); 
        }

        /** Storehouse entry */
        this.enter_by_cost = d.enter_by_cost ? d.enter_by_cost : false;
        this.entry_to = this.fault ? EntryToEnum.DEST : EntryToEnum.STOREHOUSE;

        /** Discount group */
        let dg = d.group_discount;
        this.group_discount = typeof dg == "string" ? dg.getNumber() : d.group_discount;
        this.group_discount_line = d.group_discount_line;
        // this.discount_group = d.discount_group ? new M_DiscountGroup(d.discount_group) : undefined
        this.discount_line = d.discount_line ? new M_DiscountGroupLine(d.discount_line) : undefined
        // Reservations products and faults products


        /** New fields */
        this.min_order = d.min_order ? d.min_order : undefined;
        this.discount_code = d.discount_code ? d.discount_code : undefined;
        this.rate = d.rate ? d.rate : 0;
        this.rate_ant = d.rate_ant ? d.rate_ant : undefined;
        this.intrastat = d.intrastat ? d.intrastat : undefined;
        this.teileart = d.teileart ? d.teileart : undefined;
        this.update_date = d.update_date ? d.update_date : undefined;
        this.export_web = d.export_web ? d.export_web : false;
        this.fuco = d.fuco ? d.fuco : undefined;
        this.reference_ant = d.reference_ant ? d.reference_ant : undefined;
        this.aps = d.aps ? d.aps : undefined;
        this.type = d.type ? d.type : undefined;
        this.extra_table_1 = d.extra_table_1 ? d.extra_table_1 : undefined;
        this.extra_table_2 = d.extra_table_2 ? d.extra_table_2 : undefined;
        this.extra_table_3 = d.extra_table_3 ? d.extra_table_3 : undefined;

        /** ------- */

        this.cs_stock = this.locations.length ? this.locations.reduce((sum, current) => sum + current.disp, 0) : d.stock;

        this.totalPhysical = this.locations.length
            ? this.locations.reduce((sum, current) => sum + (current.physical < 0 ? 0 : current.physical), 0)
            : 0;

        this.totalDisp = this.locations.length
            ? this.locations.reduce((sum, current) => sum + (current.disp < 0 ? 0 : current.disp), 0)
            : d.stock;

        this.totalFaults = this.locations.length
            ? this.locations.reduce((sum, current) => sum + (current.fault < 0 ? 0 : current.fault), 0)
            : 0;

        this.totalEngaged = this.locations.length
            ? this.locations.reduce((sum, current) => sum + (parseInt(current.engaged) < 0 ? 0 : parseInt(current.engaged)), 0)
            : 0;

        this.totalReserved = this.locations.length
            ? this.locations.reduce((sum, current) => sum + (parseInt(current.reserved) < 0 ? 0 : parseInt(current.reserved)), 0)
            : 0;

        this.setUpInitialValue();

    }

    override instanceofProduct(): this is M_Product { return true; }
    override  instanceofCustom(): this is M_CustomProduct { return false; }

    override setUpInitialValue() {
        this.initialValue = this.quantity;
        this.initialLocationValues = [];
        this.locations.forEach(l => {
            if (l.id == this.selected_location) { this.initialLocationValues.push({ location: l.id, quantity: this.quantity }) }
            else { this.initialLocationValues.push({ location: l.id, quantity: 0 }) }
        })
        return this.getInitialValue();
    }

    override getInitialValue() {
        if (this.locations.length) {
            try {
                if (this.locations.length) {
                    let q = this.initialLocationValues.find(l => l.location == this.selected_location)!.quantity;
                    if (q) { return q; }
                    return 0;
                }
            }
            catch {
                return 0;
            }
            return this.initialLocationValues.find(l => l.location == this.selected_location)!.quantity;
        }
        else { return this.initialValue }
    }

    /** Add a provider to the product. If the product has no providers, set it as the selected one (selected_provider) */
    addNewProvider(provider: M_Contact) {
        this.providers.push(provider)
        if (this.selected_provider == undefined) {
            this.selected_provider = provider;
        }
    }

    /** Add a location to the product. If the product has no locations, set it as the selected one (selected_location) */
    addNewLocation(location: M_Location) {
        this.locations.push(location);
        if (this.selected_location == undefined) {
            this.selected_location = location.id;
        }
    }

    setUpDefaultLocation(d: any) {
        let backendSelected = d.selected_location || d.location;
        if (backendSelected) { this.selected_location = backendSelected; }
        else if (this.locations.length) { this.selected_location = this.locations[0].id }
    }

    setUpSelectedProvider(): M_Contact | undefined {
        if (this.providerUsual) { return this.providerUsual }
        let usual = this.providers.find(p => { return p.usual == true });
        if (usual) { return usual; }
        return this.providers.length ? this.providers[0] : undefined;
    }

    /** Returns the object of the selected location or undefined */
    get selectedLocation(): M_Location | undefined {
        return this.locations.find(l => l.id == this.selected_location);
    }

    /** Set up the product extra field. */
    setupExtraField(extra_field: number | Signus | undefined) {
        if (extra_field) {
            if ((this.isLiquid && typeof extra_field == "number") || (this.isWheel && typeof extra_field != "number")) {
                this.extra_field = extra_field;
            }
            else if (this.isWheel && typeof extra_field == "number") {
                this.extra_field = getSignusById(extra_field);
            }
            else if (typeof extra_field == "string") {
                //throw Error("El campo extra de un producto NO PUEDE SER DE TIPO STRING")
            }
        }
        else if (this.category == ProductCategory.LIQUID) {
            this.extra_field = 1; //By default 1L
        }
        else if (this.category == ProductCategory.WHEEL) {
            this.extra_field = SIGNUS_N1;
        }
    }

    setUpProdMovAndProdInvoice(hist: any) {
        if (hist) {
            for (let i = 0; i < hist.length; i++) {
                if (hist[i].type) {
                    if (hist[i].type == "manual" || hist[i].type == "import") {
                        this.product_move.push(new M_Product_Move(hist[i]))
                    }
                    else if (hist[i].type == "invoice" || hist[i].type == "abono" || hist[i].type == "action") {
                        this.product_invoice.push(new M_Product_Invoice(hist[i]))
                    }
                }
            }
        }
    }

    get price_iva() {
        return (this.price + (this.price * (this.tax / 100))).toFixed(2)
    }

    /**
     * It is possible that when loading a screen, a product already has an imputed quantity. 
     * In this case, the imputed quantity does not have to subtract from the available quantity of the product.
     */
    absoluteValue() {
        return this.quantity - this.getInitialValue();
    }

    override getTotal(mainPrice: mainPrice, extrafields = false, applyDiscount = true): number {
        let total = super.getTotal(mainPrice, extrafields, applyDiscount);
        if (extrafields && mainPrice != "buy_price") {
            total += this.getExtraFieldTotalPrice;
        }
        return total;
    }

    copyToCustom() {
        return new M_CustomProduct(
            {
                name: this.name,
                reference: this.reference,
                quantity: this.quantity,
                buy_price: this.buy_price ? this.buy_price : 0,
                price: this.price,
                discount: this.discount,
            }
        )
    }

    override getInvoiceCallObject(modifyStock: boolean) {
        let obj: invoiceCallProduct = {
            prod_id: this.line_id!,
            price: this.price,
            selected_location: this.selected_location ? this.selected_location : null,
            quantity: !modifyStock ? "noaction" : undefined,
            custom: modifyStock ? this.quantity : undefined,
            discount: this.discount,
        };
        return obj;
    }

    /** Copy the main product properties from other product */
    override copyCoreAttributes(other: M_Product) {
        console.log("📋 Copy product core attributes");
        this.reference = other.reference;
        this.name = other.name;
        this.category = other.category;
        this.price = other.price;
        this.buy_price = other.buy_price;
        this.buy_price_ant = other.buy_price_ant;
        this.pmp = other.pmp;
        this.tax = other.tax;
        this.stock = other.stock;
        this.recycle = other.recycle;
        this.extra_field = other.extra_field;
    }

    defaultSearchFilter(text: string): boolean {
        text = text.toLocaleLowerCase();
        return match(text,
            this.reference,
            this.ref_factory ? this.ref_factory : undefined,
            this.name,
            this.price,
            this.buy_price,
            this.pmp,
            this.buy_price_ant,
            this.stock);
    }


    /** Calculate the best option for imputing the quantity of a product based on stock levels. */
    get quantityByStockMin() {
        if (this.stock_min != undefined) {
            if (this.stock < this.stock_min) { //Stock(disp) us lower than stock_min
                if (this.stock_max != undefined) { return this.stock_min - this.stock; }
                else { return this.stock_min - this.stock }
            }
        }
        return this.quantity;
    }

    /** Get product extra field :
     * @returns Liters of the product on LIQUID category. By default 1.
     * @returns SIGNUS (price x neumatic diameter) on WHEEL. By default SIGNUS_N1
     */
    get getExtraField() {
        if (!this.isLiquid && !this.isWheel) { return 0; }
        if (this.isLiquid) {
            if (typeof this.extra_field == "number") { return this.extra_field; }
            return 1; //Default 1L per product
        }
        else if (this.isWheel) {
            if (!this.extra_field) { return SIGNUS_N1.price; }
            if (typeof this.extra_field != "number") { return this.extra_field.price; }
            return SIGNUS_N1.price; //Default SIGNUS_N1
        }
        return 1;
    }

    get isLiquid() { return this.category == ProductCategory.LIQUID }
    get isWheel() { return this.category == ProductCategory.WHEEL }


    override get icon() {
        switch (this.category) {
            case ProductCategory.GENERAL:
                return "barcode"
            case ProductCategory.WRENCH:
                return "build_circle"
            case ProductCategory.WHEEL:
                return "tire_repair"
            case ProductCategory.LIGHT:
                return "lightbulb"
            case ProductCategory.LIQUID:
                return "water_drop"
            case ProductCategory.BOUTIQUE:
                return "checkroom"
            default:
                return "barcode"
        }
    }

    get getExtraFieldTotalPrice() {
        if (this.isLiquid) { return this.quantity * this.getExtraField * SIGAUS_PRICE_LITER; }
        else if (this.isWheel) { return this.quantity * this.getExtraField; }
        return 0;
    }

    get getCategoryName() {
        switch (this.category) {
            case ProductCategory.GENERAL:
                return "General";
            case ProductCategory.WRENCH:
                return "Recambios";
            case ProductCategory.WHEEL:
                return "Neumáticos";
            case ProductCategory.LIGHT:
                return "Electrónica";
            case ProductCategory.LIQUID:
                return "Aceite";
            case ProductCategory.BOUTIQUE:
                return "Boutique";
            default:
                return "Sin categoría"
        }
    }

    get usualProviderName() {
        let usual = this.providerUsual;
        return usual ? usual.getName() : this.providers.find(p => p.usual == true)?.getName();
    }

    get absoluteEntered() {
        return this.user_entered + this.received;
    }

    get orderDone() {
        return this.absoluteEntered == this.quantity && this.user_entered == 0;
    }

    get unfinishedOrders() {
        return this.orders.filter(o => o.finished == false);
    }

    get canBeDeleted() {
        return this.faults.length == 0 || this.faults.every(f => f.requested);
    }

    get isRequested() {
        return this.productsQuants != undefined && this.productsQuants.quant_engaged > 0;
    }

    /** If there is a provider ref and recambios module is active, return it. If not, return the normal reference */
    smartRef(recambios: boolean): string {
        if (recambios) {
            return this.ref_factory ? this.ref_factory : this.reference;
        }
        return this.reference;
    }

    /** ------------------------ */
    /** CLASS SEARCHER INTERFACE */
    /** ------------------------ */
    get endpoint() { return this.onlyProviders ? endpoints.productsOnlyProviders : endpoints.products }
    get itemId() { return this.product_id ? this.product_id : -1; }
    get typeof() { return M_Product }

    getLeftNumber(modules?: ModulesEnum[]) {
        let recambios = modules != undefined && modules.includes(ModulesEnum.RECAMBIOS);
        let sText = this.stockText(this.cs_stock, recambios);
        let sClass = "product-hint-class-searcher " + this.getStockClassByStock(this.cs_stock, recambios);
        return {
            value: sText ? sText : '',
            class_: sClass
        }
    }
    getInputText(): string { return this.name; }
    createNew(d: any) { return new M_Product(d) }
    getOptionText(modules?: ModulesEnum[]): [string, string] {
        let recambios = modules != undefined && modules.includes(ModulesEnum.RECAMBIOS);
        return ([this.name, this.smartRef(recambios)])
    }
}