<div class="advanced-details-container">

    <app-view-title>
        Crear factura compra
    </app-view-title>
    <app-card>

        <mat-tab-group #tabs mat-align-tabs="center" [color]="'primary'" (selectedTabChange)="onTabChange($event)">
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon class="tab-icon">archive</mat-icon>
                    Compra recambio
                </ng-template>
            </mat-tab>

            <!-- <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon class="tab-icon">garage</mat-icon>
                    Compra vehículo
                </ng-template>
            </mat-tab> -->
        </mat-tab-group>
        <p></p>
        <div eForm-wrapper>
            <app-buy-product-vehicle-form [selectedTab]="0">
            </app-buy-product-vehicle-form>
        </div>
        <div class="df aic jcc mt10">
            <button mat-flat-button [disabled]="!buyForm.form.valid" color="primary" (click)="create()">Crear factura
                compra</button>
        </div>

    </app-card>
</div>