import { BaseModule, ModuleDetails } from "./Factory/BaseModule";

export class M_MTRModule extends BaseModule {

    mTRData: {
        advances_collections: boolean;
        internal_cargo: boolean;
        cost_analytics: boolean;
    }

    override link = "https://www.mtr.es"

    constructor(d: any) {
        super(d);
        this.mTRData = d.mTRData || {
            advances_collections: false,
            internal_cargo: false,
            cost_analytics: false
        };
    }

    get name() { return "MTR"; }
    get corename() { return "MTR"; }
    get title() { return "Integración con MTR"; }
    get desc() { return "Integración que te permitirá configurar qué información deseas exportar a MTR y realizar la exportación directamente."; }

    generateFuncionalities() {
        return [
            { icon: "manufacturing", title: "Configuración", tooltip: "Ajustar que quieres exportar" },
            { icon: "file_export", title: "Exportación", tooltip: "Puedes exportar datos en un rango de dos fechas" }
        ];
    }

    generateModuleDetails(): ModuleDetails[] {
        return [
            {
                text: "¿Usas MTR para la gestión administrativa de tu empresa? ¡Esta es la integración que estabas buscando! 🙌",
                scrType: "img",
                src: `${this.basePath}/mtr1.png`
            },
            {
                text: "Al activar esta integración, podrás exportar los datos de Eina a MTR, configurando los elementos específicos que desees exportar 📤",
                scrType: "img",
                src: `${this.basePath}/mtr2.png`
            }
        ]
    }

    override isMTR(): this is M_MTRModule { return true; }

}