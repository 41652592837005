import { type_FAC } from "./M_AccountingGroup";

export class M_AccountingGroupRow {
    id: number;
    code: string;
    name: string;
    type: string;
    company_id: number;
    center_id: number;
    type_FAC: type_FAC;
    channel_title: string;
    channel_line: number;
    ventas_id: string;
    descuentos_id: string;

    ventasCI_id: string | null;
    costeCI_id: string | null;
    inventario_id: string | null;
    costes_id: string | null;
    VentasCI: string | null;
    CosteCI: string | null;

    Ventas: string;
    Descuentos: string;
    Inventario: string;
    Costes: string;

    updated: boolean = false;
    
    constructor(d: any) {
        this.id = d.id ?? 0;
        this.code = d.code ?? '';
        this.name = d.name ?? '';
        this.type = d.type ?? '';
        this.company_id = d.company_id ?? 0;
        this.center_id = d.center_id ?? 0;
        this.type_FAC = d.type_FAC ?? '';
        this.channel_title = d.channel_title ?? '';
        this.channel_line = d.channel_line;
        this.ventas_id = d.ventas_id ?? '';
        this.descuentos_id = d.descuentos_id ?? '';

        this.ventasCI_id = d.ventasCI_id ?? '';
        this.costeCI_id = d.costeCI_id ?? '';
        this.inventario_id = d.inventario_id ?? '';
        this.costes_id = d.costes_id ?? '';
        this.VentasCI = d.VentasCI ?? '';
        this.CosteCI = d.CosteCI ?? '';

        this.Ventas = d.Ventas ?? '';
        this.Descuentos = d.Descuentos ?? '';
        this.Inventario = d.Inventario ?? '';
        this.Costes = d.Costes ?? '';
    }

    get hasChanges() {
        return this.updated == true;
    }
}
