import { Component, Input } from '@angular/core';
import { acType, type_FAC } from 'src/app/models/AccountingGroups/M_AccountingGroup';
import { M_AccountingGroupRow } from 'src/app/models/AccountingGroups/M_AccountingGroupRow';

export const MAX_ACCOUNTING_LEN = 12;

@Component({
  selector: 'app-accounting-group-table',
  templateUrl: './accounting-group-table.component.html',
  styleUrls: ['./accounting-group-table.component.css']
})
export class AccountingGroupTableComponent {

  max = MAX_ACCOUNTING_LEN;

  @Input({ required: true }) data!: M_AccountingGroupRow[];
  @Input({ required: true }) acType!: acType;
  @Input({ required: true }) type!: type_FAC;

  get showCI() { return this.acType == "T" || this.acType == "A" }
  displayNone(index: number) {
    const byType = this.acType == "A";
    let byIndex = false;
    switch (this.type) {
      case 'F':
        byIndex = index == 1 || index == 6;
        break;
      case 'A':
        byIndex = index == 1 || index == 6;
        break;
      case 'C':
        byIndex = index == 0 || index == 1;
        break;
    }
    return byType && byIndex;
  }
  get typeByFac() { return this.type == "F" ? "Factura Ventas" : this.type == "A" ? "Abono ventas" : "Compras proveedor" }

}
