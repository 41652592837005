<div [formGroup]="form" eForm-section>

    <!-- Brand -->
    <mat-form-field class="brand-form-field" appearance="outline"
        *ngIf="this.allInputs ? this.allInputs : this.OnlyBrand">
        <mat-label>Marca</mat-label>
        <input #brand matInput placeholder="Marca" formControlName="brand" [matAutocomplete]="brandAuto"
            (focus)="filterBrands(brand.value)" (blur)="onBrandBlur()">
        <mat-autocomplete #brandAuto="matAutocomplete" (optionSelected)="refreshModelsBasedOnBrand($event.option.value)"
            [displayWith]="displayBrand">
            <ng-container *ngIf="filteredBrands  && filteredBrands.length > 0; else noBrands">
            <mat-option *ngFor="let b of filteredBrands | slice:0:MAX_RESULTS" [value]="b">{{b.name}}</mat-option>
            </ng-container>
            <ng-template #noBrands [matTooltip]="'Todavía no disponible 🕒'">
                <mat-option (click)="openCreateSerieDialog()" [disabled]="true">
                    <p><mat-icon class="vam">add_circle</mat-icon> Nueva Marca</p>
                </mat-option>
            </ng-template>
        </mat-autocomplete>
    </mat-form-field>
    <!-- Serie -->
    <mat-form-field class="model-form-field" appearance="outline"
        *ngIf="this.allInputs ? this.allInputs : this.OnlySerie">
        <mat-label>Serie</mat-label>

        <div matSuffix [ngClass]="{'vh' : !loadingModels}">
            <mat-spinner [strokeWidth]="6" [diameter]="25" mode="indeterminate" style="margin-right: 5px;"
                *ngIf="loadingModels"></mat-spinner>
        </div>

        <input #series matInput placeholder="Serie" formControlName="serie" [matAutocomplete]="serieAuto"
            (focus)="filterSeries(series.value)">
        <mat-autocomplete #serieAuto="matAutocomplete" (optionSelected)="onSerieSelected($event.option.value,true)"
            [displayWith]="displaySerie">
            <ng-container *ngIf="filteredSeries  && filteredSeries.length > 0; else noSeries">
                <mat-option *ngFor="let s of filteredSeries | slice:0:MAX_RESULTS" [value]="s"
                    (mousedown)="selectingOption = true; onSerieSelected(s,true)">{{s.name}}
                </mat-option>
            </ng-container>
            <ng-template #noSeries>
                <mat-option (click)="openCreateSerieDialog()">
                    <p><mat-icon class="vam">add_circle</mat-icon> Nueva serie</p>
                </mat-option>
            </ng-template>
        </mat-autocomplete>
    </mat-form-field>

    <!-- Model -->
    <mat-form-field class="model-form-field" appearance="outline"
        *ngIf="this.allInputs ? this.allInputs : this.OnlyModel">
        <mat-label>Modelo</mat-label>

        <div matSuffix [ngClass]="{'vh' : !loadingModels}">
            <mat-spinner [strokeWidth]="6" [diameter]="25" mode="indeterminate" style="margin-right: 5px;"
                *ngIf="loadingModels"></mat-spinner>
        </div>

        <input #model matInput placeholder="Modelo" formControlName="model" [matAutocomplete]="modelAuto"
            (focus)="filterModels(model.value)">
        <mat-autocomplete #modelAuto="matAutocomplete" (optionSelected)="onModelSelected($event.option.value)"
            [displayWith]="displayModel">
            <ng-container *ngIf="filteredModels  && filteredModels.length > 0; else noModels">
                <mat-option *ngFor="let m of filteredModels | slice:0:MAX_RESULTS" [value]="m"
                    (mousedown)="selectingOption = true; onModelSelected(m)">{{m.name}}</mat-option>
            </ng-container>
                <ng-template #noModels>
                    <mat-option (click)="openCreateModel()">
                        <p><mat-icon class="vam">add_circle</mat-icon> Nuevo modelo</p>
                    </mat-option>
                </ng-template>
        </mat-autocomplete>


        <mat-hint *ngIf="message" class="msg_hint c_r">
            No existe modelo para esa Serie
        </mat-hint>
    </mat-form-field>



</div>